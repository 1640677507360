/*
 * ThemeMascot custom-bootstrap-margin-padding
 * -----------------------------------------------
*/
/* Margin */
.m--0 {
  margin: 0px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.m-25 {
  margin: 25px !important; }

.m-30 {
  margin: 30px !important; }

.m-40 {
  margin: 40px !important; }

.m-50 {
  margin: 50px !important; }

.m-60 {
  margin: 60px !important; }

.m-70 {
  margin: 70px !important; }

.m-80 {
  margin: 80px !important; }

.m-90 {
  margin: 90px !important; }

.m-100 {
  margin: 100px !important; }

.m-110 {
  margin: 110px !important; }

.m-120 {
  margin: 120px !important; }

.m-130 {
  margin: 130px !important; }

.m-140 {
  margin: 140px !important; }

.m-150 {
  margin: 150px !important; }

.m-200 {
  margin: 200px !important; }

.m-250 {
  margin: 250px !important; }

.m--10 {
  margin: -10px !important; }

.m--15 {
  margin: -15px !important; }

.m--20 {
  margin: -20px !important; }

.m--25 {
  margin: -25px !important; }

.m--30 {
  margin: -30px !important; }

.m--40 {
  margin: -40px !important; }

.m--50 {
  margin: -50px !important; }

.m--60 {
  margin: -60px !important; }

.m--70 {
  margin: -70px !important; }

.m--80 {
  margin: -80px !important; }

.m--90 {
  margin: -90px !important; }

.m--100 {
  margin: -100px !important; }

.mt--0 {
  margin-top: 0px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-110 {
  margin-top: 110px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mt-130 {
  margin-top: 130px !important; }

.mt-140 {
  margin-top: 140px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-200 {
  margin-top: 200px !important; }

.mt-250 {
  margin-top: 250px !important; }

.mt--10 {
  margin-top: -10px !important; }

.mt--15 {
  margin-top: -15px !important; }

.mt--20 {
  margin-top: -20px !important; }

.mt--25 {
  margin-top: -25px !important; }

.mt--30 {
  margin-top: -30px !important; }

.mt--40 {
  margin-top: -40px !important; }

.mt--50 {
  margin-top: -50px !important; }

.mt--60 {
  margin-top: -60px !important; }

.mt--70 {
  margin-top: -70px !important; }

.mt--80 {
  margin-top: -80px !important; }

.mt--90 {
  margin-top: -90px !important; }

.mt--100 {
  margin-top: -100px !important; }

.mb--0 {
  margin-bottom: 0px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-110 {
  margin-bottom: 110px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-130 {
  margin-bottom: 130px !important; }

.mb-140 {
  margin-bottom: 140px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

.mb-250 {
  margin-bottom: 250px !important; }

.mb--10 {
  margin-bottom: -10px !important; }

.mb--15 {
  margin-bottom: -15px !important; }

.mb--20 {
  margin-bottom: -20px !important; }

.mb--25 {
  margin-bottom: -25px !important; }

.mb--30 {
  margin-bottom: -30px !important; }

.mb--40 {
  margin-bottom: -40px !important; }

.mb--50 {
  margin-bottom: -50px !important; }

.mb--60 {
  margin-bottom: -60px !important; }

.mb--70 {
  margin-bottom: -70px !important; }

.mb--80 {
  margin-bottom: -80px !important; }

.mb--90 {
  margin-bottom: -90px !important; }

.mb--100 {
  margin-bottom: -100px !important; }

.mr--0 {
  margin-right: 0px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mr-110 {
  margin-right: 110px !important; }

.mr-120 {
  margin-right: 120px !important; }

.mr-130 {
  margin-right: 130px !important; }

.mr-140 {
  margin-right: 140px !important; }

.mr-150 {
  margin-right: 150px !important; }

.mr-200 {
  margin-right: 200px !important; }

.mr-250 {
  margin-right: 250px !important; }

.mr--10 {
  margin-right: -10px !important; }

.mr--15 {
  margin-right: -15px !important; }

.mr--20 {
  margin-right: -20px !important; }

.mr--25 {
  margin-right: -25px !important; }

.mr--30 {
  margin-right: -30px !important; }

.mr--40 {
  margin-right: -40px !important; }

.mr--50 {
  margin-right: -50px !important; }

.mr--60 {
  margin-right: -60px !important; }

.mr--70 {
  margin-right: -70px !important; }

.mr--80 {
  margin-right: -80px !important; }

.mr--90 {
  margin-right: -90px !important; }

.mr--100 {
  margin-right: -100px !important; }

.ml--0 {
  margin-left: 0px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-60 {
  margin-left: 60px !important; }

.ml-70 {
  margin-left: 70px !important; }

.ml-80 {
  margin-left: 80px !important; }

.ml-90 {
  margin-left: 90px !important; }

.ml-100 {
  margin-left: 100px !important; }

.ml-110 {
  margin-left: 110px !important; }

.ml-120 {
  margin-left: 120px !important; }

.ml-130 {
  margin-left: 130px !important; }

.ml-140 {
  margin-left: 140px !important; }

.ml-150 {
  margin-left: 150px !important; }

.ml-200 {
  margin-left: 200px !important; }

.ml-250 {
  margin-left: 250px !important; }

.ml--10 {
  margin-left: -10px !important; }

.ml--15 {
  margin-left: -15px !important; }

.ml--20 {
  margin-left: -20px !important; }

.ml--25 {
  margin-left: -25px !important; }

.ml--30 {
  margin-left: -30px !important; }

.ml--40 {
  margin-left: -40px !important; }

.ml--50 {
  margin-left: -50px !important; }

.ml--60 {
  margin-left: -60px !important; }

.ml--70 {
  margin-left: -70px !important; }

.ml--80 {
  margin-left: -80px !important; }

.ml--90 {
  margin-left: -90px !important; }

.ml--100 {
  margin-left: -100px !important; }

@media (max-width: 1199.98px) {
  .m-lg--0 {
    margin: 0px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .m-lg-70 {
    margin: 70px !important; }
  .m-lg-80 {
    margin: 80px !important; }
  .m-lg-90 {
    margin: 90px !important; }
  .m-lg-100 {
    margin: 100px !important; }
  .m-lg-110 {
    margin: 110px !important; }
  .m-lg-120 {
    margin: 120px !important; }
  .m-lg-130 {
    margin: 130px !important; }
  .m-lg-140 {
    margin: 140px !important; }
  .m-lg-150 {
    margin: 150px !important; }
  .m-lg-200 {
    margin: 200px !important; }
  .m-lg-250 {
    margin: 250px !important; }
  .m-lg--10 {
    margin: -10px !important; }
  .m-lg--15 {
    margin: -15px !important; }
  .m-lg--20 {
    margin: -20px !important; }
  .m-lg--25 {
    margin: -25px !important; }
  .m-lg--30 {
    margin: -30px !important; }
  .m-lg--40 {
    margin: -40px !important; }
  .m-lg--50 {
    margin: -50px !important; }
  .m-lg--60 {
    margin: -60px !important; }
  .m-lg--70 {
    margin: -70px !important; }
  .m-lg--80 {
    margin: -80px !important; }
  .m-lg--90 {
    margin: -90px !important; }
  .m-lg--100 {
    margin: -100px !important; }
  .mt-lg--0 {
    margin-top: 0px !important; }
  .mt-lg-10 {
    margin-top: 10px !important; }
  .mt-lg-15 {
    margin-top: 15px !important; }
  .mt-lg-20 {
    margin-top: 20px !important; }
  .mt-lg-25 {
    margin-top: 25px !important; }
  .mt-lg-30 {
    margin-top: 30px !important; }
  .mt-lg-40 {
    margin-top: 40px !important; }
  .mt-lg-50 {
    margin-top: 50px !important; }
  .mt-lg-60 {
    margin-top: 60px !important; }
  .mt-lg-70 {
    margin-top: 70px !important; }
  .mt-lg-80 {
    margin-top: 80px !important; }
  .mt-lg-90 {
    margin-top: 90px !important; }
  .mt-lg-100 {
    margin-top: 100px !important; }
  .mt-lg-110 {
    margin-top: 110px !important; }
  .mt-lg-120 {
    margin-top: 120px !important; }
  .mt-lg-130 {
    margin-top: 130px !important; }
  .mt-lg-140 {
    margin-top: 140px !important; }
  .mt-lg-150 {
    margin-top: 150px !important; }
  .mt-lg-200 {
    margin-top: 200px !important; }
  .mt-lg-250 {
    margin-top: 250px !important; }
  .mt-lg--10 {
    margin-top: -10px !important; }
  .mt-lg--15 {
    margin-top: -15px !important; }
  .mt-lg--20 {
    margin-top: -20px !important; }
  .mt-lg--25 {
    margin-top: -25px !important; }
  .mt-lg--30 {
    margin-top: -30px !important; }
  .mt-lg--40 {
    margin-top: -40px !important; }
  .mt-lg--50 {
    margin-top: -50px !important; }
  .mt-lg--60 {
    margin-top: -60px !important; }
  .mt-lg--70 {
    margin-top: -70px !important; }
  .mt-lg--80 {
    margin-top: -80px !important; }
  .mt-lg--90 {
    margin-top: -90px !important; }
  .mt-lg--100 {
    margin-top: -100px !important; }
  .mb-lg--0 {
    margin-bottom: 0px !important; }
  .mb-lg-10 {
    margin-bottom: 10px !important; }
  .mb-lg-15 {
    margin-bottom: 15px !important; }
  .mb-lg-20 {
    margin-bottom: 20px !important; }
  .mb-lg-25 {
    margin-bottom: 25px !important; }
  .mb-lg-30 {
    margin-bottom: 30px !important; }
  .mb-lg-40 {
    margin-bottom: 40px !important; }
  .mb-lg-50 {
    margin-bottom: 50px !important; }
  .mb-lg-60 {
    margin-bottom: 60px !important; }
  .mb-lg-70 {
    margin-bottom: 70px !important; }
  .mb-lg-80 {
    margin-bottom: 80px !important; }
  .mb-lg-90 {
    margin-bottom: 90px !important; }
  .mb-lg-100 {
    margin-bottom: 100px !important; }
  .mb-lg-110 {
    margin-bottom: 110px !important; }
  .mb-lg-120 {
    margin-bottom: 120px !important; }
  .mb-lg-130 {
    margin-bottom: 130px !important; }
  .mb-lg-140 {
    margin-bottom: 140px !important; }
  .mb-lg-150 {
    margin-bottom: 150px !important; }
  .mb-lg-200 {
    margin-bottom: 200px !important; }
  .mb-lg-250 {
    margin-bottom: 250px !important; }
  .mb-lg--10 {
    margin-bottom: -10px !important; }
  .mb-lg--15 {
    margin-bottom: -15px !important; }
  .mb-lg--20 {
    margin-bottom: -20px !important; }
  .mb-lg--25 {
    margin-bottom: -25px !important; }
  .mb-lg--30 {
    margin-bottom: -30px !important; }
  .mb-lg--40 {
    margin-bottom: -40px !important; }
  .mb-lg--50 {
    margin-bottom: -50px !important; }
  .mb-lg--60 {
    margin-bottom: -60px !important; }
  .mb-lg--70 {
    margin-bottom: -70px !important; }
  .mb-lg--80 {
    margin-bottom: -80px !important; }
  .mb-lg--90 {
    margin-bottom: -90px !important; }
  .mb-lg--100 {
    margin-bottom: -100px !important; }
  .mr-lg--0 {
    margin-right: 0px !important; }
  .mr-lg-10 {
    margin-right: 10px !important; }
  .mr-lg-15 {
    margin-right: 15px !important; }
  .mr-lg-20 {
    margin-right: 20px !important; }
  .mr-lg-25 {
    margin-right: 25px !important; }
  .mr-lg-30 {
    margin-right: 30px !important; }
  .mr-lg-40 {
    margin-right: 40px !important; }
  .mr-lg-50 {
    margin-right: 50px !important; }
  .mr-lg-60 {
    margin-right: 60px !important; }
  .mr-lg-70 {
    margin-right: 70px !important; }
  .mr-lg-80 {
    margin-right: 80px !important; }
  .mr-lg-90 {
    margin-right: 90px !important; }
  .mr-lg-100 {
    margin-right: 100px !important; }
  .mr-lg-110 {
    margin-right: 110px !important; }
  .mr-lg-120 {
    margin-right: 120px !important; }
  .mr-lg-130 {
    margin-right: 130px !important; }
  .mr-lg-140 {
    margin-right: 140px !important; }
  .mr-lg-150 {
    margin-right: 150px !important; }
  .mr-lg-200 {
    margin-right: 200px !important; }
  .mr-lg-250 {
    margin-right: 250px !important; }
  .mr-lg--10 {
    margin-right: -10px !important; }
  .mr-lg--15 {
    margin-right: -15px !important; }
  .mr-lg--20 {
    margin-right: -20px !important; }
  .mr-lg--25 {
    margin-right: -25px !important; }
  .mr-lg--30 {
    margin-right: -30px !important; }
  .mr-lg--40 {
    margin-right: -40px !important; }
  .mr-lg--50 {
    margin-right: -50px !important; }
  .mr-lg--60 {
    margin-right: -60px !important; }
  .mr-lg--70 {
    margin-right: -70px !important; }
  .mr-lg--80 {
    margin-right: -80px !important; }
  .mr-lg--90 {
    margin-right: -90px !important; }
  .mr-lg--100 {
    margin-right: -100px !important; }
  .ml-lg--0 {
    margin-left: 0px !important; }
  .ml-lg-10 {
    margin-left: 10px !important; }
  .ml-lg-15 {
    margin-left: 15px !important; }
  .ml-lg-20 {
    margin-left: 20px !important; }
  .ml-lg-25 {
    margin-left: 25px !important; }
  .ml-lg-30 {
    margin-left: 30px !important; }
  .ml-lg-40 {
    margin-left: 40px !important; }
  .ml-lg-50 {
    margin-left: 50px !important; }
  .ml-lg-60 {
    margin-left: 60px !important; }
  .ml-lg-70 {
    margin-left: 70px !important; }
  .ml-lg-80 {
    margin-left: 80px !important; }
  .ml-lg-90 {
    margin-left: 90px !important; }
  .ml-lg-100 {
    margin-left: 100px !important; }
  .ml-lg-110 {
    margin-left: 110px !important; }
  .ml-lg-120 {
    margin-left: 120px !important; }
  .ml-lg-130 {
    margin-left: 130px !important; }
  .ml-lg-140 {
    margin-left: 140px !important; }
  .ml-lg-150 {
    margin-left: 150px !important; }
  .ml-lg-200 {
    margin-left: 200px !important; }
  .ml-lg-250 {
    margin-left: 250px !important; }
  .ml-lg--10 {
    margin-left: -10px !important; }
  .ml-lg--15 {
    margin-left: -15px !important; }
  .ml-lg--20 {
    margin-left: -20px !important; }
  .ml-lg--25 {
    margin-left: -25px !important; }
  .ml-lg--30 {
    margin-left: -30px !important; }
  .ml-lg--40 {
    margin-left: -40px !important; }
  .ml-lg--50 {
    margin-left: -50px !important; }
  .ml-lg--60 {
    margin-left: -60px !important; }
  .ml-lg--70 {
    margin-left: -70px !important; }
  .ml-lg--80 {
    margin-left: -80px !important; }
  .ml-lg--90 {
    margin-left: -90px !important; }
  .ml-lg--100 {
    margin-left: -100px !important; } }

@media (max-width: 991.98px) {
  .m-md--0 {
    margin: 0px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .m-md-70 {
    margin: 70px !important; }
  .m-md-80 {
    margin: 80px !important; }
  .m-md-90 {
    margin: 90px !important; }
  .m-md-100 {
    margin: 100px !important; }
  .m-md-110 {
    margin: 110px !important; }
  .m-md-120 {
    margin: 120px !important; }
  .m-md-130 {
    margin: 130px !important; }
  .m-md-140 {
    margin: 140px !important; }
  .m-md-150 {
    margin: 150px !important; }
  .m-md-200 {
    margin: 200px !important; }
  .m-md-250 {
    margin: 250px !important; }
  .m-md--10 {
    margin: -10px !important; }
  .m-md--15 {
    margin: -15px !important; }
  .m-md--20 {
    margin: -20px !important; }
  .m-md--25 {
    margin: -25px !important; }
  .m-md--30 {
    margin: -30px !important; }
  .m-md--40 {
    margin: -40px !important; }
  .m-md--50 {
    margin: -50px !important; }
  .m-md--60 {
    margin: -60px !important; }
  .m-md--70 {
    margin: -70px !important; }
  .m-md--80 {
    margin: -80px !important; }
  .m-md--90 {
    margin: -90px !important; }
  .m-md--100 {
    margin: -100px !important; }
  .mt-md--0 {
    margin-top: 0px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mt-md-70 {
    margin-top: 70px !important; }
  .mt-md-80 {
    margin-top: 80px !important; }
  .mt-md-90 {
    margin-top: 90px !important; }
  .mt-md-100 {
    margin-top: 100px !important; }
  .mt-md-110 {
    margin-top: 110px !important; }
  .mt-md-120 {
    margin-top: 120px !important; }
  .mt-md-130 {
    margin-top: 130px !important; }
  .mt-md-140 {
    margin-top: 140px !important; }
  .mt-md-150 {
    margin-top: 150px !important; }
  .mt-md-200 {
    margin-top: 200px !important; }
  .mt-md-250 {
    margin-top: 250px !important; }
  .mt-md--10 {
    margin-top: -10px !important; }
  .mt-md--15 {
    margin-top: -15px !important; }
  .mt-md--20 {
    margin-top: -20px !important; }
  .mt-md--25 {
    margin-top: -25px !important; }
  .mt-md--30 {
    margin-top: -30px !important; }
  .mt-md--40 {
    margin-top: -40px !important; }
  .mt-md--50 {
    margin-top: -50px !important; }
  .mt-md--60 {
    margin-top: -60px !important; }
  .mt-md--70 {
    margin-top: -70px !important; }
  .mt-md--80 {
    margin-top: -80px !important; }
  .mt-md--90 {
    margin-top: -90px !important; }
  .mt-md--100 {
    margin-top: -100px !important; }
  .mb-md--0 {
    margin-bottom: 0px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .mb-md-70 {
    margin-bottom: 70px !important; }
  .mb-md-80 {
    margin-bottom: 80px !important; }
  .mb-md-90 {
    margin-bottom: 90px !important; }
  .mb-md-100 {
    margin-bottom: 100px !important; }
  .mb-md-110 {
    margin-bottom: 110px !important; }
  .mb-md-120 {
    margin-bottom: 120px !important; }
  .mb-md-130 {
    margin-bottom: 130px !important; }
  .mb-md-140 {
    margin-bottom: 140px !important; }
  .mb-md-150 {
    margin-bottom: 150px !important; }
  .mb-md-200 {
    margin-bottom: 200px !important; }
  .mb-md-250 {
    margin-bottom: 250px !important; }
  .mb-md--10 {
    margin-bottom: -10px !important; }
  .mb-md--15 {
    margin-bottom: -15px !important; }
  .mb-md--20 {
    margin-bottom: -20px !important; }
  .mb-md--25 {
    margin-bottom: -25px !important; }
  .mb-md--30 {
    margin-bottom: -30px !important; }
  .mb-md--40 {
    margin-bottom: -40px !important; }
  .mb-md--50 {
    margin-bottom: -50px !important; }
  .mb-md--60 {
    margin-bottom: -60px !important; }
  .mb-md--70 {
    margin-bottom: -70px !important; }
  .mb-md--80 {
    margin-bottom: -80px !important; }
  .mb-md--90 {
    margin-bottom: -90px !important; }
  .mb-md--100 {
    margin-bottom: -100px !important; }
  .mr-md--0 {
    margin-right: 0px !important; }
  .mr-md-10 {
    margin-right: 10px !important; }
  .mr-md-15 {
    margin-right: 15px !important; }
  .mr-md-20 {
    margin-right: 20px !important; }
  .mr-md-25 {
    margin-right: 25px !important; }
  .mr-md-30 {
    margin-right: 30px !important; }
  .mr-md-40 {
    margin-right: 40px !important; }
  .mr-md-50 {
    margin-right: 50px !important; }
  .mr-md-60 {
    margin-right: 60px !important; }
  .mr-md-70 {
    margin-right: 70px !important; }
  .mr-md-80 {
    margin-right: 80px !important; }
  .mr-md-90 {
    margin-right: 90px !important; }
  .mr-md-100 {
    margin-right: 100px !important; }
  .mr-md-110 {
    margin-right: 110px !important; }
  .mr-md-120 {
    margin-right: 120px !important; }
  .mr-md-130 {
    margin-right: 130px !important; }
  .mr-md-140 {
    margin-right: 140px !important; }
  .mr-md-150 {
    margin-right: 150px !important; }
  .mr-md-200 {
    margin-right: 200px !important; }
  .mr-md-250 {
    margin-right: 250px !important; }
  .mr-md--10 {
    margin-right: -10px !important; }
  .mr-md--15 {
    margin-right: -15px !important; }
  .mr-md--20 {
    margin-right: -20px !important; }
  .mr-md--25 {
    margin-right: -25px !important; }
  .mr-md--30 {
    margin-right: -30px !important; }
  .mr-md--40 {
    margin-right: -40px !important; }
  .mr-md--50 {
    margin-right: -50px !important; }
  .mr-md--60 {
    margin-right: -60px !important; }
  .mr-md--70 {
    margin-right: -70px !important; }
  .mr-md--80 {
    margin-right: -80px !important; }
  .mr-md--90 {
    margin-right: -90px !important; }
  .mr-md--100 {
    margin-right: -100px !important; }
  .ml-md--0 {
    margin-left: 0px !important; }
  .ml-md-10 {
    margin-left: 10px !important; }
  .ml-md-15 {
    margin-left: 15px !important; }
  .ml-md-20 {
    margin-left: 20px !important; }
  .ml-md-25 {
    margin-left: 25px !important; }
  .ml-md-30 {
    margin-left: 30px !important; }
  .ml-md-40 {
    margin-left: 40px !important; }
  .ml-md-50 {
    margin-left: 50px !important; }
  .ml-md-60 {
    margin-left: 60px !important; }
  .ml-md-70 {
    margin-left: 70px !important; }
  .ml-md-80 {
    margin-left: 80px !important; }
  .ml-md-90 {
    margin-left: 90px !important; }
  .ml-md-100 {
    margin-left: 100px !important; }
  .ml-md-110 {
    margin-left: 110px !important; }
  .ml-md-120 {
    margin-left: 120px !important; }
  .ml-md-130 {
    margin-left: 130px !important; }
  .ml-md-140 {
    margin-left: 140px !important; }
  .ml-md-150 {
    margin-left: 150px !important; }
  .ml-md-200 {
    margin-left: 200px !important; }
  .ml-md-250 {
    margin-left: 250px !important; }
  .ml-md--10 {
    margin-left: -10px !important; }
  .ml-md--15 {
    margin-left: -15px !important; }
  .ml-md--20 {
    margin-left: -20px !important; }
  .ml-md--25 {
    margin-left: -25px !important; }
  .ml-md--30 {
    margin-left: -30px !important; }
  .ml-md--40 {
    margin-left: -40px !important; }
  .ml-md--50 {
    margin-left: -50px !important; }
  .ml-md--60 {
    margin-left: -60px !important; }
  .ml-md--70 {
    margin-left: -70px !important; }
  .ml-md--80 {
    margin-left: -80px !important; }
  .ml-md--90 {
    margin-left: -90px !important; }
  .ml-md--100 {
    margin-left: -100px !important; } }

@media (max-width: 767.98px) {
  .m-sm--0 {
    margin: 0px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .m-sm-70 {
    margin: 70px !important; }
  .m-sm-80 {
    margin: 80px !important; }
  .m-sm-90 {
    margin: 90px !important; }
  .m-sm-100 {
    margin: 100px !important; }
  .m-sm-110 {
    margin: 110px !important; }
  .m-sm-120 {
    margin: 120px !important; }
  .m-sm-130 {
    margin: 130px !important; }
  .m-sm-140 {
    margin: 140px !important; }
  .m-sm-150 {
    margin: 150px !important; }
  .m-sm-200 {
    margin: 200px !important; }
  .m-sm-250 {
    margin: 250px !important; }
  .m-sm--10 {
    margin: -10px !important; }
  .m-sm--15 {
    margin: -15px !important; }
  .m-sm--20 {
    margin: -20px !important; }
  .m-sm--25 {
    margin: -25px !important; }
  .m-sm--30 {
    margin: -30px !important; }
  .m-sm--40 {
    margin: -40px !important; }
  .m-sm--50 {
    margin: -50px !important; }
  .m-sm--60 {
    margin: -60px !important; }
  .m-sm--70 {
    margin: -70px !important; }
  .m-sm--80 {
    margin: -80px !important; }
  .m-sm--90 {
    margin: -90px !important; }
  .m-sm--100 {
    margin: -100px !important; }
  .mt-sm--0 {
    margin-top: 0px !important; }
  .mt-sm-10 {
    margin-top: 10px !important; }
  .mt-sm-15 {
    margin-top: 15px !important; }
  .mt-sm-20 {
    margin-top: 20px !important; }
  .mt-sm-25 {
    margin-top: 25px !important; }
  .mt-sm-30 {
    margin-top: 30px !important; }
  .mt-sm-40 {
    margin-top: 40px !important; }
  .mt-sm-50 {
    margin-top: 50px !important; }
  .mt-sm-60 {
    margin-top: 60px !important; }
  .mt-sm-70 {
    margin-top: 70px !important; }
  .mt-sm-80 {
    margin-top: 80px !important; }
  .mt-sm-90 {
    margin-top: 90px !important; }
  .mt-sm-100 {
    margin-top: 100px !important; }
  .mt-sm-110 {
    margin-top: 110px !important; }
  .mt-sm-120 {
    margin-top: 120px !important; }
  .mt-sm-130 {
    margin-top: 130px !important; }
  .mt-sm-140 {
    margin-top: 140px !important; }
  .mt-sm-150 {
    margin-top: 150px !important; }
  .mt-sm-200 {
    margin-top: 200px !important; }
  .mt-sm-250 {
    margin-top: 250px !important; }
  .mt-sm--10 {
    margin-top: -10px !important; }
  .mt-sm--15 {
    margin-top: -15px !important; }
  .mt-sm--20 {
    margin-top: -20px !important; }
  .mt-sm--25 {
    margin-top: -25px !important; }
  .mt-sm--30 {
    margin-top: -30px !important; }
  .mt-sm--40 {
    margin-top: -40px !important; }
  .mt-sm--50 {
    margin-top: -50px !important; }
  .mt-sm--60 {
    margin-top: -60px !important; }
  .mt-sm--70 {
    margin-top: -70px !important; }
  .mt-sm--80 {
    margin-top: -80px !important; }
  .mt-sm--90 {
    margin-top: -90px !important; }
  .mt-sm--100 {
    margin-top: -100px !important; }
  .mb-sm--0 {
    margin-bottom: 0px !important; }
  .mb-sm-10 {
    margin-bottom: 10px !important; }
  .mb-sm-15 {
    margin-bottom: 15px !important; }
  .mb-sm-20 {
    margin-bottom: 20px !important; }
  .mb-sm-25 {
    margin-bottom: 25px !important; }
  .mb-sm-30 {
    margin-bottom: 30px !important; }
  .mb-sm-40 {
    margin-bottom: 40px !important; }
  .mb-sm-50 {
    margin-bottom: 50px !important; }
  .mb-sm-60 {
    margin-bottom: 60px !important; }
  .mb-sm-70 {
    margin-bottom: 70px !important; }
  .mb-sm-80 {
    margin-bottom: 80px !important; }
  .mb-sm-90 {
    margin-bottom: 90px !important; }
  .mb-sm-100 {
    margin-bottom: 100px !important; }
  .mb-sm-110 {
    margin-bottom: 110px !important; }
  .mb-sm-120 {
    margin-bottom: 120px !important; }
  .mb-sm-130 {
    margin-bottom: 130px !important; }
  .mb-sm-140 {
    margin-bottom: 140px !important; }
  .mb-sm-150 {
    margin-bottom: 150px !important; }
  .mb-sm-200 {
    margin-bottom: 200px !important; }
  .mb-sm-250 {
    margin-bottom: 250px !important; }
  .mb-sm--10 {
    margin-bottom: -10px !important; }
  .mb-sm--15 {
    margin-bottom: -15px !important; }
  .mb-sm--20 {
    margin-bottom: -20px !important; }
  .mb-sm--25 {
    margin-bottom: -25px !important; }
  .mb-sm--30 {
    margin-bottom: -30px !important; }
  .mb-sm--40 {
    margin-bottom: -40px !important; }
  .mb-sm--50 {
    margin-bottom: -50px !important; }
  .mb-sm--60 {
    margin-bottom: -60px !important; }
  .mb-sm--70 {
    margin-bottom: -70px !important; }
  .mb-sm--80 {
    margin-bottom: -80px !important; }
  .mb-sm--90 {
    margin-bottom: -90px !important; }
  .mb-sm--100 {
    margin-bottom: -100px !important; }
  .mr-sm--0 {
    margin-right: 0px !important; }
  .mr-sm-10 {
    margin-right: 10px !important; }
  .mr-sm-15 {
    margin-right: 15px !important; }
  .mr-sm-20 {
    margin-right: 20px !important; }
  .mr-sm-25 {
    margin-right: 25px !important; }
  .mr-sm-30 {
    margin-right: 30px !important; }
  .mr-sm-40 {
    margin-right: 40px !important; }
  .mr-sm-50 {
    margin-right: 50px !important; }
  .mr-sm-60 {
    margin-right: 60px !important; }
  .mr-sm-70 {
    margin-right: 70px !important; }
  .mr-sm-80 {
    margin-right: 80px !important; }
  .mr-sm-90 {
    margin-right: 90px !important; }
  .mr-sm-100 {
    margin-right: 100px !important; }
  .mr-sm-110 {
    margin-right: 110px !important; }
  .mr-sm-120 {
    margin-right: 120px !important; }
  .mr-sm-130 {
    margin-right: 130px !important; }
  .mr-sm-140 {
    margin-right: 140px !important; }
  .mr-sm-150 {
    margin-right: 150px !important; }
  .mr-sm-200 {
    margin-right: 200px !important; }
  .mr-sm-250 {
    margin-right: 250px !important; }
  .mr-sm--10 {
    margin-right: -10px !important; }
  .mr-sm--15 {
    margin-right: -15px !important; }
  .mr-sm--20 {
    margin-right: -20px !important; }
  .mr-sm--25 {
    margin-right: -25px !important; }
  .mr-sm--30 {
    margin-right: -30px !important; }
  .mr-sm--40 {
    margin-right: -40px !important; }
  .mr-sm--50 {
    margin-right: -50px !important; }
  .mr-sm--60 {
    margin-right: -60px !important; }
  .mr-sm--70 {
    margin-right: -70px !important; }
  .mr-sm--80 {
    margin-right: -80px !important; }
  .mr-sm--90 {
    margin-right: -90px !important; }
  .mr-sm--100 {
    margin-right: -100px !important; }
  .ml-sm--0 {
    margin-left: 0px !important; }
  .ml-sm-10 {
    margin-left: 10px !important; }
  .ml-sm-15 {
    margin-left: 15px !important; }
  .ml-sm-20 {
    margin-left: 20px !important; }
  .ml-sm-25 {
    margin-left: 25px !important; }
  .ml-sm-30 {
    margin-left: 30px !important; }
  .ml-sm-40 {
    margin-left: 40px !important; }
  .ml-sm-50 {
    margin-left: 50px !important; }
  .ml-sm-60 {
    margin-left: 60px !important; }
  .ml-sm-70 {
    margin-left: 70px !important; }
  .ml-sm-80 {
    margin-left: 80px !important; }
  .ml-sm-90 {
    margin-left: 90px !important; }
  .ml-sm-100 {
    margin-left: 100px !important; }
  .ml-sm-110 {
    margin-left: 110px !important; }
  .ml-sm-120 {
    margin-left: 120px !important; }
  .ml-sm-130 {
    margin-left: 130px !important; }
  .ml-sm-140 {
    margin-left: 140px !important; }
  .ml-sm-150 {
    margin-left: 150px !important; }
  .ml-sm-200 {
    margin-left: 200px !important; }
  .ml-sm-250 {
    margin-left: 250px !important; }
  .ml-sm--10 {
    margin-left: -10px !important; }
  .ml-sm--15 {
    margin-left: -15px !important; }
  .ml-sm--20 {
    margin-left: -20px !important; }
  .ml-sm--25 {
    margin-left: -25px !important; }
  .ml-sm--30 {
    margin-left: -30px !important; }
  .ml-sm--40 {
    margin-left: -40px !important; }
  .ml-sm--50 {
    margin-left: -50px !important; }
  .ml-sm--60 {
    margin-left: -60px !important; }
  .ml-sm--70 {
    margin-left: -70px !important; }
  .ml-sm--80 {
    margin-left: -80px !important; }
  .ml-sm--90 {
    margin-left: -90px !important; }
  .ml-sm--100 {
    margin-left: -100px !important; } }

@media (max-width: 575.98px) {
  .m-xs--0 {
    margin: 0px !important; }
  .m-xs-10 {
    margin: 10px !important; }
  .m-xs-15 {
    margin: 15px !important; }
  .m-xs-20 {
    margin: 20px !important; }
  .m-xs-25 {
    margin: 25px !important; }
  .m-xs-30 {
    margin: 30px !important; }
  .m-xs-40 {
    margin: 40px !important; }
  .m-xs-50 {
    margin: 50px !important; }
  .m-xs-60 {
    margin: 60px !important; }
  .m-xs-70 {
    margin: 70px !important; }
  .m-xs-80 {
    margin: 80px !important; }
  .m-xs-90 {
    margin: 90px !important; }
  .m-xs-100 {
    margin: 100px !important; }
  .m-xs-110 {
    margin: 110px !important; }
  .m-xs-120 {
    margin: 120px !important; }
  .m-xs-130 {
    margin: 130px !important; }
  .m-xs-140 {
    margin: 140px !important; }
  .m-xs-150 {
    margin: 150px !important; }
  .m-xs-200 {
    margin: 200px !important; }
  .m-xs-250 {
    margin: 250px !important; }
  .m-xs--10 {
    margin: -10px !important; }
  .m-xs--15 {
    margin: -15px !important; }
  .m-xs--20 {
    margin: -20px !important; }
  .m-xs--25 {
    margin: -25px !important; }
  .m-xs--30 {
    margin: -30px !important; }
  .m-xs--40 {
    margin: -40px !important; }
  .m-xs--50 {
    margin: -50px !important; }
  .m-xs--60 {
    margin: -60px !important; }
  .m-xs--70 {
    margin: -70px !important; }
  .m-xs--80 {
    margin: -80px !important; }
  .m-xs--90 {
    margin: -90px !important; }
  .m-xs--100 {
    margin: -100px !important; }
  .mt-xs--0 {
    margin-top: 0px !important; }
  .mt-xs-10 {
    margin-top: 10px !important; }
  .mt-xs-15 {
    margin-top: 15px !important; }
  .mt-xs-20 {
    margin-top: 20px !important; }
  .mt-xs-25 {
    margin-top: 25px !important; }
  .mt-xs-30 {
    margin-top: 30px !important; }
  .mt-xs-40 {
    margin-top: 40px !important; }
  .mt-xs-50 {
    margin-top: 50px !important; }
  .mt-xs-60 {
    margin-top: 60px !important; }
  .mt-xs-70 {
    margin-top: 70px !important; }
  .mt-xs-80 {
    margin-top: 80px !important; }
  .mt-xs-90 {
    margin-top: 90px !important; }
  .mt-xs-100 {
    margin-top: 100px !important; }
  .mt-xs-110 {
    margin-top: 110px !important; }
  .mt-xs-120 {
    margin-top: 120px !important; }
  .mt-xs-130 {
    margin-top: 130px !important; }
  .mt-xs-140 {
    margin-top: 140px !important; }
  .mt-xs-150 {
    margin-top: 150px !important; }
  .mt-xs-200 {
    margin-top: 200px !important; }
  .mt-xs-250 {
    margin-top: 250px !important; }
  .mt-xs--10 {
    margin-top: -10px !important; }
  .mt-xs--15 {
    margin-top: -15px !important; }
  .mt-xs--20 {
    margin-top: -20px !important; }
  .mt-xs--25 {
    margin-top: -25px !important; }
  .mt-xs--30 {
    margin-top: -30px !important; }
  .mt-xs--40 {
    margin-top: -40px !important; }
  .mt-xs--50 {
    margin-top: -50px !important; }
  .mt-xs--60 {
    margin-top: -60px !important; }
  .mt-xs--70 {
    margin-top: -70px !important; }
  .mt-xs--80 {
    margin-top: -80px !important; }
  .mt-xs--90 {
    margin-top: -90px !important; }
  .mt-xs--100 {
    margin-top: -100px !important; }
  .mb-xs--0 {
    margin-bottom: 0px !important; }
  .mb-xs-10 {
    margin-bottom: 10px !important; }
  .mb-xs-15 {
    margin-bottom: 15px !important; }
  .mb-xs-20 {
    margin-bottom: 20px !important; }
  .mb-xs-25 {
    margin-bottom: 25px !important; }
  .mb-xs-30 {
    margin-bottom: 30px !important; }
  .mb-xs-40 {
    margin-bottom: 40px !important; }
  .mb-xs-50 {
    margin-bottom: 50px !important; }
  .mb-xs-60 {
    margin-bottom: 60px !important; }
  .mb-xs-70 {
    margin-bottom: 70px !important; }
  .mb-xs-80 {
    margin-bottom: 80px !important; }
  .mb-xs-90 {
    margin-bottom: 90px !important; }
  .mb-xs-100 {
    margin-bottom: 100px !important; }
  .mb-xs-110 {
    margin-bottom: 110px !important; }
  .mb-xs-120 {
    margin-bottom: 120px !important; }
  .mb-xs-130 {
    margin-bottom: 130px !important; }
  .mb-xs-140 {
    margin-bottom: 140px !important; }
  .mb-xs-150 {
    margin-bottom: 150px !important; }
  .mb-xs-200 {
    margin-bottom: 200px !important; }
  .mb-xs-250 {
    margin-bottom: 250px !important; }
  .mb-xs--10 {
    margin-bottom: -10px !important; }
  .mb-xs--15 {
    margin-bottom: -15px !important; }
  .mb-xs--20 {
    margin-bottom: -20px !important; }
  .mb-xs--25 {
    margin-bottom: -25px !important; }
  .mb-xs--30 {
    margin-bottom: -30px !important; }
  .mb-xs--40 {
    margin-bottom: -40px !important; }
  .mb-xs--50 {
    margin-bottom: -50px !important; }
  .mb-xs--60 {
    margin-bottom: -60px !important; }
  .mb-xs--70 {
    margin-bottom: -70px !important; }
  .mb-xs--80 {
    margin-bottom: -80px !important; }
  .mb-xs--90 {
    margin-bottom: -90px !important; }
  .mb-xs--100 {
    margin-bottom: -100px !important; }
  .mr-xs--0 {
    margin-right: 0px !important; }
  .mr-xs-10 {
    margin-right: 10px !important; }
  .mr-xs-15 {
    margin-right: 15px !important; }
  .mr-xs-20 {
    margin-right: 20px !important; }
  .mr-xs-25 {
    margin-right: 25px !important; }
  .mr-xs-30 {
    margin-right: 30px !important; }
  .mr-xs-40 {
    margin-right: 40px !important; }
  .mr-xs-50 {
    margin-right: 50px !important; }
  .mr-xs-60 {
    margin-right: 60px !important; }
  .mr-xs-70 {
    margin-right: 70px !important; }
  .mr-xs-80 {
    margin-right: 80px !important; }
  .mr-xs-90 {
    margin-right: 90px !important; }
  .mr-xs-100 {
    margin-right: 100px !important; }
  .mr-xs-110 {
    margin-right: 110px !important; }
  .mr-xs-120 {
    margin-right: 120px !important; }
  .mr-xs-130 {
    margin-right: 130px !important; }
  .mr-xs-140 {
    margin-right: 140px !important; }
  .mr-xs-150 {
    margin-right: 150px !important; }
  .mr-xs-200 {
    margin-right: 200px !important; }
  .mr-xs-250 {
    margin-right: 250px !important; }
  .mr-xs--10 {
    margin-right: -10px !important; }
  .mr-xs--15 {
    margin-right: -15px !important; }
  .mr-xs--20 {
    margin-right: -20px !important; }
  .mr-xs--25 {
    margin-right: -25px !important; }
  .mr-xs--30 {
    margin-right: -30px !important; }
  .mr-xs--40 {
    margin-right: -40px !important; }
  .mr-xs--50 {
    margin-right: -50px !important; }
  .mr-xs--60 {
    margin-right: -60px !important; }
  .mr-xs--70 {
    margin-right: -70px !important; }
  .mr-xs--80 {
    margin-right: -80px !important; }
  .mr-xs--90 {
    margin-right: -90px !important; }
  .mr-xs--100 {
    margin-right: -100px !important; }
  .ml-xs--0 {
    margin-left: 0px !important; }
  .ml-xs-10 {
    margin-left: 10px !important; }
  .ml-xs-15 {
    margin-left: 15px !important; }
  .ml-xs-20 {
    margin-left: 20px !important; }
  .ml-xs-25 {
    margin-left: 25px !important; }
  .ml-xs-30 {
    margin-left: 30px !important; }
  .ml-xs-40 {
    margin-left: 40px !important; }
  .ml-xs-50 {
    margin-left: 50px !important; }
  .ml-xs-60 {
    margin-left: 60px !important; }
  .ml-xs-70 {
    margin-left: 70px !important; }
  .ml-xs-80 {
    margin-left: 80px !important; }
  .ml-xs-90 {
    margin-left: 90px !important; }
  .ml-xs-100 {
    margin-left: 100px !important; }
  .ml-xs-110 {
    margin-left: 110px !important; }
  .ml-xs-120 {
    margin-left: 120px !important; }
  .ml-xs-130 {
    margin-left: 130px !important; }
  .ml-xs-140 {
    margin-left: 140px !important; }
  .ml-xs-150 {
    margin-left: 150px !important; }
  .ml-xs-200 {
    margin-left: 200px !important; }
  .ml-xs-250 {
    margin-left: 250px !important; }
  .ml-xs--10 {
    margin-left: -10px !important; }
  .ml-xs--15 {
    margin-left: -15px !important; }
  .ml-xs--20 {
    margin-left: -20px !important; }
  .ml-xs--25 {
    margin-left: -25px !important; }
  .ml-xs--30 {
    margin-left: -30px !important; }
  .ml-xs--40 {
    margin-left: -40px !important; }
  .ml-xs--50 {
    margin-left: -50px !important; }
  .ml-xs--60 {
    margin-left: -60px !important; }
  .ml-xs--70 {
    margin-left: -70px !important; }
  .ml-xs--80 {
    margin-left: -80px !important; }
  .ml-xs--90 {
    margin-left: -90px !important; }
  .ml-xs--100 {
    margin-left: -100px !important; } }

/* Padding */
.p--0 {
  padding: 0px !important; }

.p-10 {
  padding: 10px !important; }

.p-15 {
  padding: 15px !important; }

.p-20 {
  padding: 20px !important; }

.p-25 {
  padding: 25px !important; }

.p-30 {
  padding: 30px !important; }

.p-40 {
  padding: 40px !important; }

.p-50 {
  padding: 50px !important; }

.p-60 {
  padding: 60px !important; }

.p-70 {
  padding: 70px !important; }

.p-80 {
  padding: 80px !important; }

.p-90 {
  padding: 90px !important; }

.p-100 {
  padding: 100px !important; }

.p-110 {
  padding: 110px !important; }

.p-120 {
  padding: 120px !important; }

.p-130 {
  padding: 130px !important; }

.p-140 {
  padding: 140px !important; }

.p-150 {
  padding: 150px !important; }

.p-200 {
  padding: 200px !important; }

.p-250 {
  padding: 250px !important; }

.p--10 {
  padding: -10px !important; }

.p--15 {
  padding: -15px !important; }

.p--20 {
  padding: -20px !important; }

.p--25 {
  padding: -25px !important; }

.p--30 {
  padding: -30px !important; }

.p--40 {
  padding: -40px !important; }

.p--50 {
  padding: -50px !important; }

.p--60 {
  padding: -60px !important; }

.p--70 {
  padding: -70px !important; }

.p--80 {
  padding: -80px !important; }

.p--90 {
  padding: -90px !important; }

.p--100 {
  padding: -100px !important; }

.pt--0 {
  padding-top: 0px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-110 {
  padding-top: 110px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pt-140 {
  padding-top: 140px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-200 {
  padding-top: 200px !important; }

.pt-250 {
  padding-top: 250px !important; }

.pt--10 {
  padding-top: -10px !important; }

.pt--15 {
  padding-top: -15px !important; }

.pt--20 {
  padding-top: -20px !important; }

.pt--25 {
  padding-top: -25px !important; }

.pt--30 {
  padding-top: -30px !important; }

.pt--40 {
  padding-top: -40px !important; }

.pt--50 {
  padding-top: -50px !important; }

.pt--60 {
  padding-top: -60px !important; }

.pt--70 {
  padding-top: -70px !important; }

.pt--80 {
  padding-top: -80px !important; }

.pt--90 {
  padding-top: -90px !important; }

.pt--100 {
  padding-top: -100px !important; }

.pb--0 {
  padding-bottom: 0px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-110 {
  padding-bottom: 110px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

.pb-140 {
  padding-bottom: 140px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

.pb-200 {
  padding-bottom: 200px !important; }

.pb-250 {
  padding-bottom: 250px !important; }

.pb--10 {
  padding-bottom: -10px !important; }

.pb--15 {
  padding-bottom: -15px !important; }

.pb--20 {
  padding-bottom: -20px !important; }

.pb--25 {
  padding-bottom: -25px !important; }

.pb--30 {
  padding-bottom: -30px !important; }

.pb--40 {
  padding-bottom: -40px !important; }

.pb--50 {
  padding-bottom: -50px !important; }

.pb--60 {
  padding-bottom: -60px !important; }

.pb--70 {
  padding-bottom: -70px !important; }

.pb--80 {
  padding-bottom: -80px !important; }

.pb--90 {
  padding-bottom: -90px !important; }

.pb--100 {
  padding-bottom: -100px !important; }

.pr--0 {
  padding-right: 0px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pr-100 {
  padding-right: 100px !important; }

.pr-110 {
  padding-right: 110px !important; }

.pr-120 {
  padding-right: 120px !important; }

.pr-130 {
  padding-right: 130px !important; }

.pr-140 {
  padding-right: 140px !important; }

.pr-150 {
  padding-right: 150px !important; }

.pr-200 {
  padding-right: 200px !important; }

.pr-250 {
  padding-right: 250px !important; }

.pr--10 {
  padding-right: -10px !important; }

.pr--15 {
  padding-right: -15px !important; }

.pr--20 {
  padding-right: -20px !important; }

.pr--25 {
  padding-right: -25px !important; }

.pr--30 {
  padding-right: -30px !important; }

.pr--40 {
  padding-right: -40px !important; }

.pr--50 {
  padding-right: -50px !important; }

.pr--60 {
  padding-right: -60px !important; }

.pr--70 {
  padding-right: -70px !important; }

.pr--80 {
  padding-right: -80px !important; }

.pr--90 {
  padding-right: -90px !important; }

.pr--100 {
  padding-right: -100px !important; }

.pl--0 {
  padding-left: 0px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-80 {
  padding-left: 80px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pl-100 {
  padding-left: 100px !important; }

.pl-110 {
  padding-left: 110px !important; }

.pl-120 {
  padding-left: 120px !important; }

.pl-130 {
  padding-left: 130px !important; }

.pl-140 {
  padding-left: 140px !important; }

.pl-150 {
  padding-left: 150px !important; }

.pl-200 {
  padding-left: 200px !important; }

.pl-250 {
  padding-left: 250px !important; }

.pl--10 {
  padding-left: -10px !important; }

.pl--15 {
  padding-left: -15px !important; }

.pl--20 {
  padding-left: -20px !important; }

.pl--25 {
  padding-left: -25px !important; }

.pl--30 {
  padding-left: -30px !important; }

.pl--40 {
  padding-left: -40px !important; }

.pl--50 {
  padding-left: -50px !important; }

.pl--60 {
  padding-left: -60px !important; }

.pl--70 {
  padding-left: -70px !important; }

.pl--80 {
  padding-left: -80px !important; }

.pl--90 {
  padding-left: -90px !important; }

.pl--100 {
  padding-left: -100px !important; }

@media (max-width: 1199.98px) {
  .p-lg--0 {
    padding: 0px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .p-lg-70 {
    padding: 70px !important; }
  .p-lg-80 {
    padding: 80px !important; }
  .p-lg-90 {
    padding: 90px !important; }
  .p-lg-100 {
    padding: 100px !important; }
  .p-lg-110 {
    padding: 110px !important; }
  .p-lg-120 {
    padding: 120px !important; }
  .p-lg-130 {
    padding: 130px !important; }
  .p-lg-140 {
    padding: 140px !important; }
  .p-lg-150 {
    padding: 150px !important; }
  .p-lg-200 {
    padding: 200px !important; }
  .p-lg-250 {
    padding: 250px !important; }
  .p-lg--10 {
    padding: -10px !important; }
  .p-lg--15 {
    padding: -15px !important; }
  .p-lg--20 {
    padding: -20px !important; }
  .p-lg--25 {
    padding: -25px !important; }
  .p-lg--30 {
    padding: -30px !important; }
  .p-lg--40 {
    padding: -40px !important; }
  .p-lg--50 {
    padding: -50px !important; }
  .p-lg--60 {
    padding: -60px !important; }
  .p-lg--70 {
    padding: -70px !important; }
  .p-lg--80 {
    padding: -80px !important; }
  .p-lg--90 {
    padding: -90px !important; }
  .p-lg--100 {
    padding: -100px !important; }
  .pt-lg--0 {
    padding-top: 0px !important; }
  .pt-lg-10 {
    padding-top: 10px !important; }
  .pt-lg-15 {
    padding-top: 15px !important; }
  .pt-lg-20 {
    padding-top: 20px !important; }
  .pt-lg-25 {
    padding-top: 25px !important; }
  .pt-lg-30 {
    padding-top: 30px !important; }
  .pt-lg-40 {
    padding-top: 40px !important; }
  .pt-lg-50 {
    padding-top: 50px !important; }
  .pt-lg-60 {
    padding-top: 60px !important; }
  .pt-lg-70 {
    padding-top: 70px !important; }
  .pt-lg-80 {
    padding-top: 80px !important; }
  .pt-lg-90 {
    padding-top: 90px !important; }
  .pt-lg-100 {
    padding-top: 100px !important; }
  .pt-lg-110 {
    padding-top: 110px !important; }
  .pt-lg-120 {
    padding-top: 120px !important; }
  .pt-lg-130 {
    padding-top: 130px !important; }
  .pt-lg-140 {
    padding-top: 140px !important; }
  .pt-lg-150 {
    padding-top: 150px !important; }
  .pt-lg-200 {
    padding-top: 200px !important; }
  .pt-lg-250 {
    padding-top: 250px !important; }
  .pt-lg--10 {
    padding-top: -10px !important; }
  .pt-lg--15 {
    padding-top: -15px !important; }
  .pt-lg--20 {
    padding-top: -20px !important; }
  .pt-lg--25 {
    padding-top: -25px !important; }
  .pt-lg--30 {
    padding-top: -30px !important; }
  .pt-lg--40 {
    padding-top: -40px !important; }
  .pt-lg--50 {
    padding-top: -50px !important; }
  .pt-lg--60 {
    padding-top: -60px !important; }
  .pt-lg--70 {
    padding-top: -70px !important; }
  .pt-lg--80 {
    padding-top: -80px !important; }
  .pt-lg--90 {
    padding-top: -90px !important; }
  .pt-lg--100 {
    padding-top: -100px !important; }
  .pb-lg--0 {
    padding-bottom: 0px !important; }
  .pb-lg-10 {
    padding-bottom: 10px !important; }
  .pb-lg-15 {
    padding-bottom: 15px !important; }
  .pb-lg-20 {
    padding-bottom: 20px !important; }
  .pb-lg-25 {
    padding-bottom: 25px !important; }
  .pb-lg-30 {
    padding-bottom: 30px !important; }
  .pb-lg-40 {
    padding-bottom: 40px !important; }
  .pb-lg-50 {
    padding-bottom: 50px !important; }
  .pb-lg-60 {
    padding-bottom: 60px !important; }
  .pb-lg-70 {
    padding-bottom: 70px !important; }
  .pb-lg-80 {
    padding-bottom: 80px !important; }
  .pb-lg-90 {
    padding-bottom: 90px !important; }
  .pb-lg-100 {
    padding-bottom: 100px !important; }
  .pb-lg-110 {
    padding-bottom: 110px !important; }
  .pb-lg-120 {
    padding-bottom: 120px !important; }
  .pb-lg-130 {
    padding-bottom: 130px !important; }
  .pb-lg-140 {
    padding-bottom: 140px !important; }
  .pb-lg-150 {
    padding-bottom: 150px !important; }
  .pb-lg-200 {
    padding-bottom: 200px !important; }
  .pb-lg-250 {
    padding-bottom: 250px !important; }
  .pb-lg--10 {
    padding-bottom: -10px !important; }
  .pb-lg--15 {
    padding-bottom: -15px !important; }
  .pb-lg--20 {
    padding-bottom: -20px !important; }
  .pb-lg--25 {
    padding-bottom: -25px !important; }
  .pb-lg--30 {
    padding-bottom: -30px !important; }
  .pb-lg--40 {
    padding-bottom: -40px !important; }
  .pb-lg--50 {
    padding-bottom: -50px !important; }
  .pb-lg--60 {
    padding-bottom: -60px !important; }
  .pb-lg--70 {
    padding-bottom: -70px !important; }
  .pb-lg--80 {
    padding-bottom: -80px !important; }
  .pb-lg--90 {
    padding-bottom: -90px !important; }
  .pb-lg--100 {
    padding-bottom: -100px !important; }
  .pr-lg--0 {
    padding-right: 0px !important; }
  .pr-lg-10 {
    padding-right: 10px !important; }
  .pr-lg-15 {
    padding-right: 15px !important; }
  .pr-lg-20 {
    padding-right: 20px !important; }
  .pr-lg-25 {
    padding-right: 25px !important; }
  .pr-lg-30 {
    padding-right: 30px !important; }
  .pr-lg-40 {
    padding-right: 40px !important; }
  .pr-lg-50 {
    padding-right: 50px !important; }
  .pr-lg-60 {
    padding-right: 60px !important; }
  .pr-lg-70 {
    padding-right: 70px !important; }
  .pr-lg-80 {
    padding-right: 80px !important; }
  .pr-lg-90 {
    padding-right: 90px !important; }
  .pr-lg-100 {
    padding-right: 100px !important; }
  .pr-lg-110 {
    padding-right: 110px !important; }
  .pr-lg-120 {
    padding-right: 120px !important; }
  .pr-lg-130 {
    padding-right: 130px !important; }
  .pr-lg-140 {
    padding-right: 140px !important; }
  .pr-lg-150 {
    padding-right: 150px !important; }
  .pr-lg-200 {
    padding-right: 200px !important; }
  .pr-lg-250 {
    padding-right: 250px !important; }
  .pr-lg--10 {
    padding-right: -10px !important; }
  .pr-lg--15 {
    padding-right: -15px !important; }
  .pr-lg--20 {
    padding-right: -20px !important; }
  .pr-lg--25 {
    padding-right: -25px !important; }
  .pr-lg--30 {
    padding-right: -30px !important; }
  .pr-lg--40 {
    padding-right: -40px !important; }
  .pr-lg--50 {
    padding-right: -50px !important; }
  .pr-lg--60 {
    padding-right: -60px !important; }
  .pr-lg--70 {
    padding-right: -70px !important; }
  .pr-lg--80 {
    padding-right: -80px !important; }
  .pr-lg--90 {
    padding-right: -90px !important; }
  .pr-lg--100 {
    padding-right: -100px !important; }
  .pl-lg--0 {
    padding-left: 0px !important; }
  .pl-lg-10 {
    padding-left: 10px !important; }
  .pl-lg-15 {
    padding-left: 15px !important; }
  .pl-lg-20 {
    padding-left: 20px !important; }
  .pl-lg-25 {
    padding-left: 25px !important; }
  .pl-lg-30 {
    padding-left: 30px !important; }
  .pl-lg-40 {
    padding-left: 40px !important; }
  .pl-lg-50 {
    padding-left: 50px !important; }
  .pl-lg-60 {
    padding-left: 60px !important; }
  .pl-lg-70 {
    padding-left: 70px !important; }
  .pl-lg-80 {
    padding-left: 80px !important; }
  .pl-lg-90 {
    padding-left: 90px !important; }
  .pl-lg-100 {
    padding-left: 100px !important; }
  .pl-lg-110 {
    padding-left: 110px !important; }
  .pl-lg-120 {
    padding-left: 120px !important; }
  .pl-lg-130 {
    padding-left: 130px !important; }
  .pl-lg-140 {
    padding-left: 140px !important; }
  .pl-lg-150 {
    padding-left: 150px !important; }
  .pl-lg-200 {
    padding-left: 200px !important; }
  .pl-lg-250 {
    padding-left: 250px !important; }
  .pl-lg--10 {
    padding-left: -10px !important; }
  .pl-lg--15 {
    padding-left: -15px !important; }
  .pl-lg--20 {
    padding-left: -20px !important; }
  .pl-lg--25 {
    padding-left: -25px !important; }
  .pl-lg--30 {
    padding-left: -30px !important; }
  .pl-lg--40 {
    padding-left: -40px !important; }
  .pl-lg--50 {
    padding-left: -50px !important; }
  .pl-lg--60 {
    padding-left: -60px !important; }
  .pl-lg--70 {
    padding-left: -70px !important; }
  .pl-lg--80 {
    padding-left: -80px !important; }
  .pl-lg--90 {
    padding-left: -90px !important; }
  .pl-lg--100 {
    padding-left: -100px !important; } }

@media (max-width: 991.98px) {
  .p-md--0 {
    padding: 0px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .p-md-70 {
    padding: 70px !important; }
  .p-md-80 {
    padding: 80px !important; }
  .p-md-90 {
    padding: 90px !important; }
  .p-md-100 {
    padding: 100px !important; }
  .p-md-110 {
    padding: 110px !important; }
  .p-md-120 {
    padding: 120px !important; }
  .p-md-130 {
    padding: 130px !important; }
  .p-md-140 {
    padding: 140px !important; }
  .p-md-150 {
    padding: 150px !important; }
  .p-md-200 {
    padding: 200px !important; }
  .p-md-250 {
    padding: 250px !important; }
  .p-md--10 {
    padding: -10px !important; }
  .p-md--15 {
    padding: -15px !important; }
  .p-md--20 {
    padding: -20px !important; }
  .p-md--25 {
    padding: -25px !important; }
  .p-md--30 {
    padding: -30px !important; }
  .p-md--40 {
    padding: -40px !important; }
  .p-md--50 {
    padding: -50px !important; }
  .p-md--60 {
    padding: -60px !important; }
  .p-md--70 {
    padding: -70px !important; }
  .p-md--80 {
    padding: -80px !important; }
  .p-md--90 {
    padding: -90px !important; }
  .p-md--100 {
    padding: -100px !important; }
  .pt-md--0 {
    padding-top: 0px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pt-md-70 {
    padding-top: 70px !important; }
  .pt-md-80 {
    padding-top: 80px !important; }
  .pt-md-90 {
    padding-top: 90px !important; }
  .pt-md-100 {
    padding-top: 100px !important; }
  .pt-md-110 {
    padding-top: 110px !important; }
  .pt-md-120 {
    padding-top: 120px !important; }
  .pt-md-130 {
    padding-top: 130px !important; }
  .pt-md-140 {
    padding-top: 140px !important; }
  .pt-md-150 {
    padding-top: 150px !important; }
  .pt-md-200 {
    padding-top: 200px !important; }
  .pt-md-250 {
    padding-top: 250px !important; }
  .pt-md--10 {
    padding-top: -10px !important; }
  .pt-md--15 {
    padding-top: -15px !important; }
  .pt-md--20 {
    padding-top: -20px !important; }
  .pt-md--25 {
    padding-top: -25px !important; }
  .pt-md--30 {
    padding-top: -30px !important; }
  .pt-md--40 {
    padding-top: -40px !important; }
  .pt-md--50 {
    padding-top: -50px !important; }
  .pt-md--60 {
    padding-top: -60px !important; }
  .pt-md--70 {
    padding-top: -70px !important; }
  .pt-md--80 {
    padding-top: -80px !important; }
  .pt-md--90 {
    padding-top: -90px !important; }
  .pt-md--100 {
    padding-top: -100px !important; }
  .pb-md--0 {
    padding-bottom: 0px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pb-md-70 {
    padding-bottom: 70px !important; }
  .pb-md-80 {
    padding-bottom: 80px !important; }
  .pb-md-90 {
    padding-bottom: 90px !important; }
  .pb-md-100 {
    padding-bottom: 100px !important; }
  .pb-md-110 {
    padding-bottom: 110px !important; }
  .pb-md-120 {
    padding-bottom: 120px !important; }
  .pb-md-130 {
    padding-bottom: 130px !important; }
  .pb-md-140 {
    padding-bottom: 140px !important; }
  .pb-md-150 {
    padding-bottom: 150px !important; }
  .pb-md-200 {
    padding-bottom: 200px !important; }
  .pb-md-250 {
    padding-bottom: 250px !important; }
  .pb-md--10 {
    padding-bottom: -10px !important; }
  .pb-md--15 {
    padding-bottom: -15px !important; }
  .pb-md--20 {
    padding-bottom: -20px !important; }
  .pb-md--25 {
    padding-bottom: -25px !important; }
  .pb-md--30 {
    padding-bottom: -30px !important; }
  .pb-md--40 {
    padding-bottom: -40px !important; }
  .pb-md--50 {
    padding-bottom: -50px !important; }
  .pb-md--60 {
    padding-bottom: -60px !important; }
  .pb-md--70 {
    padding-bottom: -70px !important; }
  .pb-md--80 {
    padding-bottom: -80px !important; }
  .pb-md--90 {
    padding-bottom: -90px !important; }
  .pb-md--100 {
    padding-bottom: -100px !important; }
  .pr-md--0 {
    padding-right: 0px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pr-md-70 {
    padding-right: 70px !important; }
  .pr-md-80 {
    padding-right: 80px !important; }
  .pr-md-90 {
    padding-right: 90px !important; }
  .pr-md-100 {
    padding-right: 100px !important; }
  .pr-md-110 {
    padding-right: 110px !important; }
  .pr-md-120 {
    padding-right: 120px !important; }
  .pr-md-130 {
    padding-right: 130px !important; }
  .pr-md-140 {
    padding-right: 140px !important; }
  .pr-md-150 {
    padding-right: 150px !important; }
  .pr-md-200 {
    padding-right: 200px !important; }
  .pr-md-250 {
    padding-right: 250px !important; }
  .pr-md--10 {
    padding-right: -10px !important; }
  .pr-md--15 {
    padding-right: -15px !important; }
  .pr-md--20 {
    padding-right: -20px !important; }
  .pr-md--25 {
    padding-right: -25px !important; }
  .pr-md--30 {
    padding-right: -30px !important; }
  .pr-md--40 {
    padding-right: -40px !important; }
  .pr-md--50 {
    padding-right: -50px !important; }
  .pr-md--60 {
    padding-right: -60px !important; }
  .pr-md--70 {
    padding-right: -70px !important; }
  .pr-md--80 {
    padding-right: -80px !important; }
  .pr-md--90 {
    padding-right: -90px !important; }
  .pr-md--100 {
    padding-right: -100px !important; }
  .pl-md--0 {
    padding-left: 0px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .pl-md-70 {
    padding-left: 70px !important; }
  .pl-md-80 {
    padding-left: 80px !important; }
  .pl-md-90 {
    padding-left: 90px !important; }
  .pl-md-100 {
    padding-left: 100px !important; }
  .pl-md-110 {
    padding-left: 110px !important; }
  .pl-md-120 {
    padding-left: 120px !important; }
  .pl-md-130 {
    padding-left: 130px !important; }
  .pl-md-140 {
    padding-left: 140px !important; }
  .pl-md-150 {
    padding-left: 150px !important; }
  .pl-md-200 {
    padding-left: 200px !important; }
  .pl-md-250 {
    padding-left: 250px !important; }
  .pl-md--10 {
    padding-left: -10px !important; }
  .pl-md--15 {
    padding-left: -15px !important; }
  .pl-md--20 {
    padding-left: -20px !important; }
  .pl-md--25 {
    padding-left: -25px !important; }
  .pl-md--30 {
    padding-left: -30px !important; }
  .pl-md--40 {
    padding-left: -40px !important; }
  .pl-md--50 {
    padding-left: -50px !important; }
  .pl-md--60 {
    padding-left: -60px !important; }
  .pl-md--70 {
    padding-left: -70px !important; }
  .pl-md--80 {
    padding-left: -80px !important; }
  .pl-md--90 {
    padding-left: -90px !important; }
  .pl-md--100 {
    padding-left: -100px !important; } }

@media (max-width: 767.98px) {
  .p-sm--0 {
    padding: 0px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .p-sm-70 {
    padding: 70px !important; }
  .p-sm-80 {
    padding: 80px !important; }
  .p-sm-90 {
    padding: 90px !important; }
  .p-sm-100 {
    padding: 100px !important; }
  .p-sm-110 {
    padding: 110px !important; }
  .p-sm-120 {
    padding: 120px !important; }
  .p-sm-130 {
    padding: 130px !important; }
  .p-sm-140 {
    padding: 140px !important; }
  .p-sm-150 {
    padding: 150px !important; }
  .p-sm-200 {
    padding: 200px !important; }
  .p-sm-250 {
    padding: 250px !important; }
  .p-sm--10 {
    padding: -10px !important; }
  .p-sm--15 {
    padding: -15px !important; }
  .p-sm--20 {
    padding: -20px !important; }
  .p-sm--25 {
    padding: -25px !important; }
  .p-sm--30 {
    padding: -30px !important; }
  .p-sm--40 {
    padding: -40px !important; }
  .p-sm--50 {
    padding: -50px !important; }
  .p-sm--60 {
    padding: -60px !important; }
  .p-sm--70 {
    padding: -70px !important; }
  .p-sm--80 {
    padding: -80px !important; }
  .p-sm--90 {
    padding: -90px !important; }
  .p-sm--100 {
    padding: -100px !important; }
  .pt-sm--0 {
    padding-top: 0px !important; }
  .pt-sm-10 {
    padding-top: 10px !important; }
  .pt-sm-15 {
    padding-top: 15px !important; }
  .pt-sm-20 {
    padding-top: 20px !important; }
  .pt-sm-25 {
    padding-top: 25px !important; }
  .pt-sm-30 {
    padding-top: 30px !important; }
  .pt-sm-40 {
    padding-top: 40px !important; }
  .pt-sm-50 {
    padding-top: 50px !important; }
  .pt-sm-60 {
    padding-top: 60px !important; }
  .pt-sm-70 {
    padding-top: 70px !important; }
  .pt-sm-80 {
    padding-top: 80px !important; }
  .pt-sm-90 {
    padding-top: 90px !important; }
  .pt-sm-100 {
    padding-top: 100px !important; }
  .pt-sm-110 {
    padding-top: 110px !important; }
  .pt-sm-120 {
    padding-top: 120px !important; }
  .pt-sm-130 {
    padding-top: 130px !important; }
  .pt-sm-140 {
    padding-top: 140px !important; }
  .pt-sm-150 {
    padding-top: 150px !important; }
  .pt-sm-200 {
    padding-top: 200px !important; }
  .pt-sm-250 {
    padding-top: 250px !important; }
  .pt-sm--10 {
    padding-top: -10px !important; }
  .pt-sm--15 {
    padding-top: -15px !important; }
  .pt-sm--20 {
    padding-top: -20px !important; }
  .pt-sm--25 {
    padding-top: -25px !important; }
  .pt-sm--30 {
    padding-top: -30px !important; }
  .pt-sm--40 {
    padding-top: -40px !important; }
  .pt-sm--50 {
    padding-top: -50px !important; }
  .pt-sm--60 {
    padding-top: -60px !important; }
  .pt-sm--70 {
    padding-top: -70px !important; }
  .pt-sm--80 {
    padding-top: -80px !important; }
  .pt-sm--90 {
    padding-top: -90px !important; }
  .pt-sm--100 {
    padding-top: -100px !important; }
  .pb-sm--0 {
    padding-bottom: 0px !important; }
  .pb-sm-10 {
    padding-bottom: 10px !important; }
  .pb-sm-15 {
    padding-bottom: 15px !important; }
  .pb-sm-20 {
    padding-bottom: 20px !important; }
  .pb-sm-25 {
    padding-bottom: 25px !important; }
  .pb-sm-30 {
    padding-bottom: 30px !important; }
  .pb-sm-40 {
    padding-bottom: 40px !important; }
  .pb-sm-50 {
    padding-bottom: 50px !important; }
  .pb-sm-60 {
    padding-bottom: 60px !important; }
  .pb-sm-70 {
    padding-bottom: 70px !important; }
  .pb-sm-80 {
    padding-bottom: 80px !important; }
  .pb-sm-90 {
    padding-bottom: 90px !important; }
  .pb-sm-100 {
    padding-bottom: 100px !important; }
  .pb-sm-110 {
    padding-bottom: 110px !important; }
  .pb-sm-120 {
    padding-bottom: 120px !important; }
  .pb-sm-130 {
    padding-bottom: 130px !important; }
  .pb-sm-140 {
    padding-bottom: 140px !important; }
  .pb-sm-150 {
    padding-bottom: 150px !important; }
  .pb-sm-200 {
    padding-bottom: 200px !important; }
  .pb-sm-250 {
    padding-bottom: 250px !important; }
  .pb-sm--10 {
    padding-bottom: -10px !important; }
  .pb-sm--15 {
    padding-bottom: -15px !important; }
  .pb-sm--20 {
    padding-bottom: -20px !important; }
  .pb-sm--25 {
    padding-bottom: -25px !important; }
  .pb-sm--30 {
    padding-bottom: -30px !important; }
  .pb-sm--40 {
    padding-bottom: -40px !important; }
  .pb-sm--50 {
    padding-bottom: -50px !important; }
  .pb-sm--60 {
    padding-bottom: -60px !important; }
  .pb-sm--70 {
    padding-bottom: -70px !important; }
  .pb-sm--80 {
    padding-bottom: -80px !important; }
  .pb-sm--90 {
    padding-bottom: -90px !important; }
  .pb-sm--100 {
    padding-bottom: -100px !important; }
  .pr-sm--0 {
    padding-right: 0px !important; }
  .pr-sm-10 {
    padding-right: 10px !important; }
  .pr-sm-15 {
    padding-right: 15px !important; }
  .pr-sm-20 {
    padding-right: 20px !important; }
  .pr-sm-25 {
    padding-right: 25px !important; }
  .pr-sm-30 {
    padding-right: 30px !important; }
  .pr-sm-40 {
    padding-right: 40px !important; }
  .pr-sm-50 {
    padding-right: 50px !important; }
  .pr-sm-60 {
    padding-right: 60px !important; }
  .pr-sm-70 {
    padding-right: 70px !important; }
  .pr-sm-80 {
    padding-right: 80px !important; }
  .pr-sm-90 {
    padding-right: 90px !important; }
  .pr-sm-100 {
    padding-right: 100px !important; }
  .pr-sm-110 {
    padding-right: 110px !important; }
  .pr-sm-120 {
    padding-right: 120px !important; }
  .pr-sm-130 {
    padding-right: 130px !important; }
  .pr-sm-140 {
    padding-right: 140px !important; }
  .pr-sm-150 {
    padding-right: 150px !important; }
  .pr-sm-200 {
    padding-right: 200px !important; }
  .pr-sm-250 {
    padding-right: 250px !important; }
  .pr-sm--10 {
    padding-right: -10px !important; }
  .pr-sm--15 {
    padding-right: -15px !important; }
  .pr-sm--20 {
    padding-right: -20px !important; }
  .pr-sm--25 {
    padding-right: -25px !important; }
  .pr-sm--30 {
    padding-right: -30px !important; }
  .pr-sm--40 {
    padding-right: -40px !important; }
  .pr-sm--50 {
    padding-right: -50px !important; }
  .pr-sm--60 {
    padding-right: -60px !important; }
  .pr-sm--70 {
    padding-right: -70px !important; }
  .pr-sm--80 {
    padding-right: -80px !important; }
  .pr-sm--90 {
    padding-right: -90px !important; }
  .pr-sm--100 {
    padding-right: -100px !important; }
  .pl-sm--0 {
    padding-left: 0px !important; }
  .pl-sm-10 {
    padding-left: 10px !important; }
  .pl-sm-15 {
    padding-left: 15px !important; }
  .pl-sm-20 {
    padding-left: 20px !important; }
  .pl-sm-25 {
    padding-left: 25px !important; }
  .pl-sm-30 {
    padding-left: 30px !important; }
  .pl-sm-40 {
    padding-left: 40px !important; }
  .pl-sm-50 {
    padding-left: 50px !important; }
  .pl-sm-60 {
    padding-left: 60px !important; }
  .pl-sm-70 {
    padding-left: 70px !important; }
  .pl-sm-80 {
    padding-left: 80px !important; }
  .pl-sm-90 {
    padding-left: 90px !important; }
  .pl-sm-100 {
    padding-left: 100px !important; }
  .pl-sm-110 {
    padding-left: 110px !important; }
  .pl-sm-120 {
    padding-left: 120px !important; }
  .pl-sm-130 {
    padding-left: 130px !important; }
  .pl-sm-140 {
    padding-left: 140px !important; }
  .pl-sm-150 {
    padding-left: 150px !important; }
  .pl-sm-200 {
    padding-left: 200px !important; }
  .pl-sm-250 {
    padding-left: 250px !important; }
  .pl-sm--10 {
    padding-left: -10px !important; }
  .pl-sm--15 {
    padding-left: -15px !important; }
  .pl-sm--20 {
    padding-left: -20px !important; }
  .pl-sm--25 {
    padding-left: -25px !important; }
  .pl-sm--30 {
    padding-left: -30px !important; }
  .pl-sm--40 {
    padding-left: -40px !important; }
  .pl-sm--50 {
    padding-left: -50px !important; }
  .pl-sm--60 {
    padding-left: -60px !important; }
  .pl-sm--70 {
    padding-left: -70px !important; }
  .pl-sm--80 {
    padding-left: -80px !important; }
  .pl-sm--90 {
    padding-left: -90px !important; }
  .pl-sm--100 {
    padding-left: -100px !important; } }

@media (max-width: 575.98px) {
  .p-xs--0 {
    padding: 0px !important; }
  .p-xs-10 {
    padding: 10px !important; }
  .p-xs-15 {
    padding: 15px !important; }
  .p-xs-20 {
    padding: 20px !important; }
  .p-xs-25 {
    padding: 25px !important; }
  .p-xs-30 {
    padding: 30px !important; }
  .p-xs-40 {
    padding: 40px !important; }
  .p-xs-50 {
    padding: 50px !important; }
  .p-xs-60 {
    padding: 60px !important; }
  .p-xs-70 {
    padding: 70px !important; }
  .p-xs-80 {
    padding: 80px !important; }
  .p-xs-90 {
    padding: 90px !important; }
  .p-xs-100 {
    padding: 100px !important; }
  .p-xs-110 {
    padding: 110px !important; }
  .p-xs-120 {
    padding: 120px !important; }
  .p-xs-130 {
    padding: 130px !important; }
  .p-xs-140 {
    padding: 140px !important; }
  .p-xs-150 {
    padding: 150px !important; }
  .p-xs-200 {
    padding: 200px !important; }
  .p-xs-250 {
    padding: 250px !important; }
  .p-xs--10 {
    padding: -10px !important; }
  .p-xs--15 {
    padding: -15px !important; }
  .p-xs--20 {
    padding: -20px !important; }
  .p-xs--25 {
    padding: -25px !important; }
  .p-xs--30 {
    padding: -30px !important; }
  .p-xs--40 {
    padding: -40px !important; }
  .p-xs--50 {
    padding: -50px !important; }
  .p-xs--60 {
    padding: -60px !important; }
  .p-xs--70 {
    padding: -70px !important; }
  .p-xs--80 {
    padding: -80px !important; }
  .p-xs--90 {
    padding: -90px !important; }
  .p-xs--100 {
    padding: -100px !important; }
  .pt-xs--0 {
    padding-top: 0px !important; }
  .pt-xs-10 {
    padding-top: 10px !important; }
  .pt-xs-15 {
    padding-top: 15px !important; }
  .pt-xs-20 {
    padding-top: 20px !important; }
  .pt-xs-25 {
    padding-top: 25px !important; }
  .pt-xs-30 {
    padding-top: 30px !important; }
  .pt-xs-40 {
    padding-top: 40px !important; }
  .pt-xs-50 {
    padding-top: 50px !important; }
  .pt-xs-60 {
    padding-top: 60px !important; }
  .pt-xs-70 {
    padding-top: 70px !important; }
  .pt-xs-80 {
    padding-top: 80px !important; }
  .pt-xs-90 {
    padding-top: 90px !important; }
  .pt-xs-100 {
    padding-top: 100px !important; }
  .pt-xs-110 {
    padding-top: 110px !important; }
  .pt-xs-120 {
    padding-top: 120px !important; }
  .pt-xs-130 {
    padding-top: 130px !important; }
  .pt-xs-140 {
    padding-top: 140px !important; }
  .pt-xs-150 {
    padding-top: 150px !important; }
  .pt-xs-200 {
    padding-top: 200px !important; }
  .pt-xs-250 {
    padding-top: 250px !important; }
  .pt-xs--10 {
    padding-top: -10px !important; }
  .pt-xs--15 {
    padding-top: -15px !important; }
  .pt-xs--20 {
    padding-top: -20px !important; }
  .pt-xs--25 {
    padding-top: -25px !important; }
  .pt-xs--30 {
    padding-top: -30px !important; }
  .pt-xs--40 {
    padding-top: -40px !important; }
  .pt-xs--50 {
    padding-top: -50px !important; }
  .pt-xs--60 {
    padding-top: -60px !important; }
  .pt-xs--70 {
    padding-top: -70px !important; }
  .pt-xs--80 {
    padding-top: -80px !important; }
  .pt-xs--90 {
    padding-top: -90px !important; }
  .pt-xs--100 {
    padding-top: -100px !important; }
  .pb-xs--0 {
    padding-bottom: 0px !important; }
  .pb-xs-10 {
    padding-bottom: 10px !important; }
  .pb-xs-15 {
    padding-bottom: 15px !important; }
  .pb-xs-20 {
    padding-bottom: 20px !important; }
  .pb-xs-25 {
    padding-bottom: 25px !important; }
  .pb-xs-30 {
    padding-bottom: 30px !important; }
  .pb-xs-40 {
    padding-bottom: 40px !important; }
  .pb-xs-50 {
    padding-bottom: 50px !important; }
  .pb-xs-60 {
    padding-bottom: 60px !important; }
  .pb-xs-70 {
    padding-bottom: 70px !important; }
  .pb-xs-80 {
    padding-bottom: 80px !important; }
  .pb-xs-90 {
    padding-bottom: 90px !important; }
  .pb-xs-100 {
    padding-bottom: 100px !important; }
  .pb-xs-110 {
    padding-bottom: 110px !important; }
  .pb-xs-120 {
    padding-bottom: 120px !important; }
  .pb-xs-130 {
    padding-bottom: 130px !important; }
  .pb-xs-140 {
    padding-bottom: 140px !important; }
  .pb-xs-150 {
    padding-bottom: 150px !important; }
  .pb-xs-200 {
    padding-bottom: 200px !important; }
  .pb-xs-250 {
    padding-bottom: 250px !important; }
  .pb-xs--10 {
    padding-bottom: -10px !important; }
  .pb-xs--15 {
    padding-bottom: -15px !important; }
  .pb-xs--20 {
    padding-bottom: -20px !important; }
  .pb-xs--25 {
    padding-bottom: -25px !important; }
  .pb-xs--30 {
    padding-bottom: -30px !important; }
  .pb-xs--40 {
    padding-bottom: -40px !important; }
  .pb-xs--50 {
    padding-bottom: -50px !important; }
  .pb-xs--60 {
    padding-bottom: -60px !important; }
  .pb-xs--70 {
    padding-bottom: -70px !important; }
  .pb-xs--80 {
    padding-bottom: -80px !important; }
  .pb-xs--90 {
    padding-bottom: -90px !important; }
  .pb-xs--100 {
    padding-bottom: -100px !important; }
  .pr-xs--0 {
    padding-right: 0px !important; }
  .pr-xs-10 {
    padding-right: 10px !important; }
  .pr-xs-15 {
    padding-right: 15px !important; }
  .pr-xs-20 {
    padding-right: 20px !important; }
  .pr-xs-25 {
    padding-right: 25px !important; }
  .pr-xs-30 {
    padding-right: 30px !important; }
  .pr-xs-40 {
    padding-right: 40px !important; }
  .pr-xs-50 {
    padding-right: 50px !important; }
  .pr-xs-60 {
    padding-right: 60px !important; }
  .pr-xs-70 {
    padding-right: 70px !important; }
  .pr-xs-80 {
    padding-right: 80px !important; }
  .pr-xs-90 {
    padding-right: 90px !important; }
  .pr-xs-100 {
    padding-right: 100px !important; }
  .pr-xs-110 {
    padding-right: 110px !important; }
  .pr-xs-120 {
    padding-right: 120px !important; }
  .pr-xs-130 {
    padding-right: 130px !important; }
  .pr-xs-140 {
    padding-right: 140px !important; }
  .pr-xs-150 {
    padding-right: 150px !important; }
  .pr-xs-200 {
    padding-right: 200px !important; }
  .pr-xs-250 {
    padding-right: 250px !important; }
  .pr-xs--10 {
    padding-right: -10px !important; }
  .pr-xs--15 {
    padding-right: -15px !important; }
  .pr-xs--20 {
    padding-right: -20px !important; }
  .pr-xs--25 {
    padding-right: -25px !important; }
  .pr-xs--30 {
    padding-right: -30px !important; }
  .pr-xs--40 {
    padding-right: -40px !important; }
  .pr-xs--50 {
    padding-right: -50px !important; }
  .pr-xs--60 {
    padding-right: -60px !important; }
  .pr-xs--70 {
    padding-right: -70px !important; }
  .pr-xs--80 {
    padding-right: -80px !important; }
  .pr-xs--90 {
    padding-right: -90px !important; }
  .pr-xs--100 {
    padding-right: -100px !important; }
  .pl-xs--0 {
    padding-left: 0px !important; }
  .pl-xs-10 {
    padding-left: 10px !important; }
  .pl-xs-15 {
    padding-left: 15px !important; }
  .pl-xs-20 {
    padding-left: 20px !important; }
  .pl-xs-25 {
    padding-left: 25px !important; }
  .pl-xs-30 {
    padding-left: 30px !important; }
  .pl-xs-40 {
    padding-left: 40px !important; }
  .pl-xs-50 {
    padding-left: 50px !important; }
  .pl-xs-60 {
    padding-left: 60px !important; }
  .pl-xs-70 {
    padding-left: 70px !important; }
  .pl-xs-80 {
    padding-left: 80px !important; }
  .pl-xs-90 {
    padding-left: 90px !important; }
  .pl-xs-100 {
    padding-left: 100px !important; }
  .pl-xs-110 {
    padding-left: 110px !important; }
  .pl-xs-120 {
    padding-left: 120px !important; }
  .pl-xs-130 {
    padding-left: 130px !important; }
  .pl-xs-140 {
    padding-left: 140px !important; }
  .pl-xs-150 {
    padding-left: 150px !important; }
  .pl-xs-200 {
    padding-left: 200px !important; }
  .pl-xs-250 {
    padding-left: 250px !important; }
  .pl-xs--10 {
    padding-left: -10px !important; }
  .pl-xs--15 {
    padding-left: -15px !important; }
  .pl-xs--20 {
    padding-left: -20px !important; }
  .pl-xs--25 {
    padding-left: -25px !important; }
  .pl-xs--30 {
    padding-left: -30px !important; }
  .pl-xs--40 {
    padding-left: -40px !important; }
  .pl-xs--50 {
    padding-left: -50px !important; }
  .pl-xs--60 {
    padding-left: -60px !important; }
  .pl-xs--70 {
    padding-left: -70px !important; }
  .pl-xs--80 {
    padding-left: -80px !important; }
  .pl-xs--90 {
    padding-left: -90px !important; }
  .pl-xs--100 {
    padding-left: -100px !important; } }

.tm-responsive-pos-top-0 {
  top: 0px; }

.tm-responsive-pos-top-10 {
  top: 10px; }

.tm-responsive-pos-top-15 {
  top: 15px; }

.tm-responsive-pos-top-20 {
  top: 20px; }

.tm-responsive-pos-top-25 {
  top: 25px; }

.tm-responsive-pos-top-30 {
  top: 30px; }

.tm-responsive-pos-top-40 {
  top: 40px; }

.tm-responsive-pos-top-50 {
  top: 50px; }

.tm-responsive-pos-top-60 {
  top: 60px; }

.tm-responsive-pos-top-70 {
  top: 70px; }

.tm-responsive-pos-top-80 {
  top: 80px; }

.tm-responsive-pos-top-90 {
  top: 90px; }

.tm-responsive-pos-top-100 {
  top: 100px; }

@media (max-width: 991.98px) {
  [class^="tm-responsive-pos-top-"], [class*=" tm-responsive-pos-top-"] {
    top: 0; } }

.tm-responsive-pos-bottom-0 {
  bottom: 0px; }

.tm-responsive-pos-bottom-10 {
  bottom: 10px; }

.tm-responsive-pos-bottom-15 {
  bottom: 15px; }

.tm-responsive-pos-bottom-20 {
  bottom: 20px; }

.tm-responsive-pos-bottom-25 {
  bottom: 25px; }

.tm-responsive-pos-bottom-30 {
  bottom: 30px; }

.tm-responsive-pos-bottom-40 {
  bottom: 40px; }

.tm-responsive-pos-bottom-50 {
  bottom: 50px; }

.tm-responsive-pos-bottom-60 {
  bottom: 60px; }

.tm-responsive-pos-bottom-70 {
  bottom: 70px; }

.tm-responsive-pos-bottom-80 {
  bottom: 80px; }

.tm-responsive-pos-bottom-90 {
  bottom: 90px; }

.tm-responsive-pos-bottom-100 {
  bottom: 100px; }

@media (max-width: 991.98px) {
  [class^="tm-responsive-pos-bottom-"], [class*=" tm-responsive-pos-bottom-"] {
    bottom: 0; } }

.mt-responsive--150 {
  margin-top: -150px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--150 {
      margin-top: -100px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--150 {
      margin-top: -50px; } }

.mt-responsive--200 {
  margin-top: -200px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--200 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--200 {
      margin-top: -80px; } }

.mt-responsive--210 {
  margin-top: -210px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--210 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--210 {
      margin-top: -80px; } }

.mt-responsive--220 {
  margin-top: -220px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--220 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--220 {
      margin-top: -80px; } }

.mt-responsive--230 {
  margin-top: -230px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--230 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--230 {
      margin-top: -80px; } }

.mt-responsive--240 {
  margin-top: -240px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--240 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--240 {
      margin-top: -80px; } }

.mt-responsive--250 {
  margin-top: -250px; }
  @media (max-width: 1199.98px) {
    .mt-responsive--250 {
      margin-top: -150px; } }
  @media (max-width: 991.98px) {
    .mt-responsive--250 {
      margin-top: -80px; } }

@media (max-width: 767.98px) {
  [class^="mt-responsive-"], [class*=" mt-responsive-"] {
    margin-top: 0; } }
