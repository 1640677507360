.unique-section {
    background-color: #ffffff; /* Clean white background */
    padding: 60px 20px; /* Increased padding for spaciousness */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

.container {
    max-width: 1200px; /* Limit max width for readability */
    margin: 0 auto; /* Center container */
}

.row {
    display: flex; /* Flexbox layout */
    flex-wrap: wrap; /* Allow wrapping of elements */
    justify-content: center; /* Center content horizontally */
}

.info-item {
    text-align: center; /* Center text */
    margin-bottom: 40px; /* Space between items */
    transition: transform 0.3s; /* Animation effect on hover */
}

.info-item:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.info-title {
    font-size: 1.4em; /* Larger title font */
    color: #ffffff; /* Dark text color */
    display: flex; /* Flexbox for icon alignment */
    align-items: center; /* Center align icon and text */
}

.info-title i {
    color: #EE1623; /* Icon color */
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.5em; /* Larger icon size */
}

.info-description {
    padding-right: 15px; /* Padding for text */
    color: hsl(0, 0%, 100%); /* Subtle text color */
    font-size: 1em; /* Description font size */
    margin-top: 10px; /* Space above description */
}

.experience-image {
    width: 100%; /* Full width for responsive image */
    border-radius: 10px; /* Rounded image corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Image shadow */
}
