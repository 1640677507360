.gallery-container {
    padding: 20px;
    text-align: center;
  }
  
  .gallery-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .gallery-item {
    padding: 5px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.2s;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 50%; 
    max-height: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    width: 100%; 
    height: auto;
    border-radius: 10px;
    
    
  }
  
  .close {
    position: absolute;
    top: 0px;
    right: 30px;
    color: rgb(214, 16, 16);
    font-size: 2rem;
    cursor: pointer;
    z-index: 1100; 
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(180, 27, 27);
    font-size: 2rem;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 1100; /* Ensure navigation buttons are on top */
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
   