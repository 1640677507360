/* Citycar | Taxi Business HTML Templa

Template Name: Citycar
Version: 1.0.0
License: copyright commercial
/************ TABLE OF CONTENTS ***************
1.  Fonts
2.  Reset
3.  Global Settings
4.  Main Header / Two
5.  Sticky Header 
6.  Mobile Menu
7.  Section Title
8.  Main Slider
9.  Banner Section / Two
10. Pricing Section / Two / Three
11. Benefit Section
12. About Section / Two / Three
13. Services Section / Two / Three / Four
14. Fun Fact Section
15. Testimonial Section / Two
16. Team Section 
17. Apps Section / Two / Three
18. News Section / Two / Three
19. Work Section / Two
20. Booking Section / Two
21. Clients Section
22. Main Footer / Two / Three
**********************************************/
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&amp;family=Epilogue:wght@300;400;500;600;700;800;900&amp;family=Odibee+Sans&amp;display=swap");
@import url("animate.css");
@import url("owl.css");
@import url("swiper.min.css");
@import url("jquery.fancybox.min.css");
@import url("linear.css");
@import url("select2.min.css");
@import url("aos.css");
@import url("odometer-theme-default.css");
@import url("fontawesome.css");
@import url("flaticon_taxi.css");
@import url("tm-bs-mp.css");
@import url("tm-utility-classes.css");
:root {
  --theme-color1-rgb: 255, 238, 2;
  --theme-color2-rgb: 0, 0, 0;
  --theme-color-light-rgb: 255, 255, 255;
  --theme-color-dark-rgb: 0, 0, 0;
  /* --theme-color1: #ffee02; */
  --theme-color1: #EE1623;
  --theme-color2: #000000;
  --review-color: #fdc009;
  --theme-color-light: #FFFFFF;
  --theme-color-dark: #000000;
  --bg-theme-color1: var(--theme-color1);
  --bg-theme-color2: var(--theme-color2);
  --text-color-bg-theme-color1: #000;
  --text-color-bg-theme-color2: #fff;
  --text-color-bg-theme-color3: #fff;
  --text-color: #727272;
  --headings-color: var(--theme-color-dark);
  --link-color: var(--theme-color-dark);
  --link-hover-color: var(--theme-color-dark);
  --title-font: "Lexend", sans-serif;
  --text-font: "Epilogue", sans-serif;
  --style-font: "Archivo", sans-serif;
  --body-font-size: 16px;
  --body-line-height: 30px;
  --body-font-weight: 400;
  --line-height-heading-h1: 1em;
  --line-height-heading: 1.3em;
  --line-height-heading-small: 1.4em;
  --h1-font-size: 84px;
  --h2-font-size: 46px;
  --h3-font-size: 34px;
  --h4-font-size: 24px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;
  --h1-font-weight: 600;
  --h2-font-weight: 600;
  --h3-font-weight: 600;
  --h4-font-weight: 600;
  --h5-font-weight: 400;
  --h6-font-weight: 400;
  --sec-title-subtitle-color: #141414;
  --sec-title-subtitle-font-size: 16px;
  --sec-title-subtitle-font-family: var(--title-font);
  --sec-title-subtitle-font-weight: 500;
  --sec-title-subtitle-line-height: 24px;
  --sec-title-color: var(--theme-color-dark);
  --sec-title-font-size: 55px;
  --sec-title-font-family: var(--title-font);
  --sec-title-font-weight: var(--h2-font-weight);
  --theme-light-background: #f8f6f1;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black: #131313;
  --container-width: 1200px;
  --small-container-width: 1050px;
  --large-container-width: 1800px;
  --container-pt: 120px;
  --container-pb: 120px;
}


/*
 * typography.scss
 * -----------------------------------------------
*/
::-moz-selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}
::selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  color: #7c858c;
}

::-moz-input-placeholder {
  color: #7c858c;
}

::-ms-input-placeholder {
  color: #7c858c;
}

body {
  background-color: #fff;
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}
.case{
  color: white;
}
.bordee{
  border: 5px solid #ee1623;
}

@media (max-width:400px){
  .carousel-inner img{
      height: 180px;
  }
}
@media (max-width: 768px) {
  .navbar-nav .nav-item {
    border-bottom: 1px solid #ee1623; /* Bottom border for each item */
  }
}
p, .text {
  /* color: var(--text-color); */
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}


/* -------- Headings ---------- */
h1, h2, h3, h4, h5, h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position: relative;
  line-height: var(--line-height-heading-);
}
h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: var(--headings-color);
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--line-height-heading-h1);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--line-height-heading);
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--h5-font-weight);
  line-height: var(--line-height-heading);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--h6-font-weight);
  line-height: var(--line-height-heading-small);
}
*{
  /* border: 1px solid red !important;  */
}
/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}
.slick-next{
  right: 0px !important;
}
p {
  margin-bottom: 20px;
}
p a:not(.button):not(.btn):hover, p a:not(.button):not(.btn):focus {
  text-decoration: underline;
}
.darkcolor , .whycolor{
  color: #EE1623;
}
*{
  /* border: 1px solid red !important; */
}
/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a b, a strong {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol, ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b, strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe {
  border: none !important;
}

/*
 * container.scss
 * -----------------------------------------------
*/
.bluee {
  background-color: white;
}
.bg-infoy{
  background-color: #EE1623;
}
.container .container {
  width: 100%;
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.borderrr{
  border: 2px solid red;
}
.borderrr{
  border: 2px solid red;
}
section > .container,
section > .container-fluid {
  /* padding-top: var(--container-pt); */
  padding-bottom: var(--container-pt);
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: var(--container-width);
  }
}
/*=== Default Form ===*/
.form-control, .input-text {
  height: calc(2.25rem + 27px);
  padding: 14px 30px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}
.form-control::-webkit-input-placeholder, .input-text::-webkit-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-moz-placeholder, .input-text::-moz-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::placeholder, .input-text::placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* -------- Dark Theme Styling ---------- */
.dark-layout {
  background-color: var(--theme-color-dark) !important;
  --sec-title-color: var(--theme-color-light);
}
.dark-layout .sticky-header .main-menu .navigation > li > a,
.dark-layout h1, .dark-layout h2, .dark-layout h3, .dark-layout h4, .dark-layout h5, .dark-layout h6 {
  color: var(--theme-color-light);
}
.dark-layout .preloader {
  background-color: var(--theme-color-dark);
}
.dark-layout .preloader:after {
  background-image: url(../images/logo.png);
}
.dark-layout .sticky-header {
  background-color: var(--theme-color-dark);
}
.dark-layout .hidden-bar .upper-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links li {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/*** 

====================================================================
Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/
textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.title a {
  /* color: inherit; */
}

.color1 {
  color: var(--theme-color1);
}

.color2 {
  color: var(--theme-color2);
}

.color3 {
  color: var(--theme-color3);
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
}

.large-container {
  position: static;
  max-width: var(--large-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.auto-container {
  position: static;
  max-width: var(--container-width);
  /* padding: 0px 15px; */
  margin: 0 auto;
  width: 100%;
}

.small-container {
  position: static;
  max-width: var(--small-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}
/* *{
  border: 1px solid red;
} */

/*=======================
Scroll To Top style
=======================*/
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #000000;
  text-align: center;
  cursor: pointer;
  background-color: var(--theme-color1);
  z-index: 100;
  display: none;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--theme-color-dark);
  color: var(--theme-color-light);
}

.link-style-one {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}
.link-style-one:before {
  position: absolute;
  left: 0;
  right: 18px;
  bottom: 2px;
  height: 1px;
  background-color: var(--r1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.link-style-one i {
  position: relative;
  top: 1px;
  display: block;
  font-size: 14px;
  margin-left: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.link-style-one:hover {
  color: var(--theme-color1);
}
.link-style-one:hover:before {
  right: 100%;
}

/*=== List Style One ===*/
.list-style-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0;
}
.list-style-one li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 76px;
  padding-top: 5px;
  font-family: var(--text-font);
  color: var(--theme-color-dark);
}
.list-style-one li:last-child {
  margin-right: 0;
}
.list-style-one li i {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 24px;
  line-height: 1em;
  margin-right: 5px;
  margin-top: -5px;
}
.list-style-one li .icon-two {
  font-size: 19px;
  margin-right: 10px;
}
.list-style-one li a {
  display: block;
  font-weight: 500;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.list-style-one li a:hover {
  text-decoration: underline;
}
.list-style-one.light li,
.list-style-one.light a {
  color: var(--theme-color-light) !important;
}
.list-style-one.light a:hover {
  text-decoration: underline;
}
.list-style-one.light i {
  color: var(--theme-color1);
}

/*=== List Style Two ===*/
.list-style-two {
  position: relative;
}
.list-style-two li {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  padding-left: 28px;
  margin-bottom: 10px;
  font-family: var(--text-font);
  color: var(--theme-color-dark);
}
@media (max-width: 575.98px) {
  .list-style-two li {
    padding-left: 20px;
    font-size: 14px;
  }
}
.list-style-two li:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #b5b5b5;
  background-color: var(--theme-color-light);
}
.list-style-two.light li {
  color: var(--theme-color-light) !important;
}

/*Social Icon One*/
.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.social-icon-one li {
  margin-left: 22px;
  padding-left: 27px;
  border-left: 1px solid #424242;
  display: inline-block;
}
.social-icon-one li:first-child {
  margin-left: 0;
  border-left: none;
}
.social-icon-one li a {
  position: relative;
  display: block;
  color: var(--theme-color-light);
  font-size: 17px;
  line-height: 1em;
}
.social-icon-one li a:hover {
  color: var(--theme-color1);
}

/*Social Icon Two*/
.social-icon-two {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.social-icon-two li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
}
.social-icon-two li:last-child {
  margin-right: 0;
}
.social-icon-two li a {
  position: relative;
  display: block;
  width: 49px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  border: 1px solid #c1c1c1;
  color: var(--theme-color-dark);
  background: var(--theme-color-light);
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-two li a i {
  position: relative;
}
.social-icon-two li a::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  content: "";
}
.social-icon-two li a:hover {
  color: var(--theme-color-light);
}
.social-icon-two li a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-pattern-1 {
  background-image: url(../images/icons/pattern-1.jpg);
}

.bg-pattern-2 {
  background-image: url(../images/icons/pattern-2.jpg);
}

.bg-pattern-3 {
  background-image: url(../images/icons/pattern-3.jpg);
}

.bg-pattern-4 {
  background-image: url(../images/icons/pattern-4.jpg);
}

.bg-pattern-5 {
  background-image: url(../images/icons/pattern-5.jpg);
}

.bg-pattern-6 {
  background-image: url(../images/icons/pattern-6.png);
}

.bg-pattern-7 {
  background-image: url(../images/icons/pattern-7.jpg);
}

.bg-pattern-8 {
  background-image: url(../images/icons/pattern-8.jpg);
}

/*======================
    Tabs Box
======================*/
.tabs-box {
  position: relative;
}
.tabs-box .tab {
  display: none;
}
.tabs-box .tab.active-tab {
  display: block;
}

.play-btn {
  position: relative;
  display: inline-block;
}
.play-btn:hover .icon {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}
.play-btn .icon-triangle {
  background-color: var(--theme-color-light);
  border: 6px solid var(--theme-color1);
  border-radius: 50%;
  color: var(--theme-color-dark);
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 93px;
  width: 93px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*======================
    Media Play Button 
======================*/
.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-now .icon {
  position: relative;
  display: inline-block;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 85px;
  background-color: #ffffff;
  color: var(--theme-color2);
  z-index: 1;
  font-size: 18px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
          box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 102px;
  width: 102px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
          animation: ripple 3s infinite;
}
.play-now .ripple.light,
.play-now .ripple:before.light,
.play-now .ripple:after.light {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
          box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.play-now .ripple.light,
.play-now .ripple.light:before,
.play-now .ripple.light:after {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
          box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}
.play-now-two {
  height: 150px;
  width: 150px;
  background-color: rgba(21, 21, 21, 0.5);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 34px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-animation: zoom-one 3s infinite linear;
          animation: zoom-one 3s infinite linear;
}
.play-now-two:hover {
  color: #ff9205;
  background-color: #ffffff;
}

/*========================
  Select2 Dropdown Plugin
========================*/
.select2-dropdown {
  border: 1px solid #eee;
}

.select2-results__option {
  padding: 0 10px;
  color: #444444;
  border: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444444;
  padding-left: 0;
  line-height: 1;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--theme-color1);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  bottom: 0;
  height: auto;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  font-weight: 900;
  color: var(--theme-color-dark);
  background-color: #f5f5f5;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
}
.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  position: relative;
  right: -8px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  position: relative;
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
}

.default-navs .owl-nav {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.default-navs .owl-next,
.default-navs .owl-prev {
  position: relative;
  margin: 0 -70px;
  margin-top: -150px;
  display: block;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #7f7f7f;
  color: var(--theme-color-dark);
  background: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 0;
}
.default-navs .owl-next:hover,
.default-navs .owl-prev:hover {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}

.default-navs-two .owl-nav {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 3;
}
.default-navs-two .owl-next,
.default-navs-two .owl-prev {
  position: relative;
  margin-right: 15px;
  display: block;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #787878;
  color: var(--theme-color-dark);
  background: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: -1;
}
.default-navs-two .owl-next:hover,
.default-navs-two .owl-prev:hover {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}

.default-dots .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.default-dots .owl-dots .owl-dot {
  position: relative;
  width: 73px;
  height: 7px;
  border: 1px solid #c1c1c1;
  background-color: transparent;
  margin: 0 4.5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.default-dots .owl-dots .owl-dot.active {
  background-color: var(--theme-color-dark);
  border: 2px solid var(--theme-color-dark);
}

.default-dots-two .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.default-dots-two .owl-dots .owl-dot {
  border-radius: 50%;
  position: relative;
  width: 10px;
  height: 10px;
  border: 2px solid var(--theme-color1);
  background-color: transparent;
  margin: 0 4.5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.default-dots-two .owl-dots .owl-dot.active {
  background-color: var(--theme-color-dark);
  border-color: var(--theme-color-dark);
}

/*==========================
	Nav Style One
===========================*/
.nav-style-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}
.nav-style-one .next,
.nav-style-one .prev {
  margin-right: 38px;
  padding: 18px 25px;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.nav-style-one .next .icon,
.nav-style-one .prev .icon {
  position: relative;
  display: inline-block;
}
.nav-style-one .next .icon:before,
.nav-style-one .prev .icon:before {
  position: absolute;
  top: 0;
  margin-top: -20px;
  right: -25px;
  height: 52px;
  width: 52px;
  border: 1px dotted #fff;
  border-radius: 100px;
  content: "";
  z-index: -1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.nav-style-one .next:hover .icon:before,
.nav-style-one .prev:hover .icon:before {
  background-color: rgba(255, 255, 255, 0.15);
  width: 125px;
}
.nav-style-one .prev {
  margin-right: 0;
  padding-left: 25px;
  padding-right: 0;
}
.nav-style-one .prev .icon:before {
  left: -25px;
  right: auto;
}
.nav-style-one .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.nav-style-one.dark .next .icon:before,
.nav-style-one.dark .prev .icon:before {
  border: 1px solid #797979;
}

/*=======================
    Preloader
=======================*/
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.preloader h1 {
  position: relative;
  color: rgba(255, 255, 255, 0.3);
  font-size: 5em;
  letter-spacing: 0.01em;
  line-height: 1em;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  .preloader h1 {
    font-size: 4em;
  }
}
.preloader h1:before {
  content: attr(data-text);
  position: absolute;
  margin-top: 2px;
  max-width: 120%;
  white-space: nowrap;
  overflow: hidden;
  color: var(--theme-color1);
  -webkit-animation: loading 5s linear infinite;
          animation: loading 5s linear infinite;
  font-family: var(--title-font);
}
.preloader h1:after {
  position: absolute;
  right: 0;
  bottom: -10px;
  content: "Loading...";
  font-size: 18px;
  line-height: 1em;
  color: var(--theme-color-light);
  font-weight: 500;
  letter-spacing: 0.005em;
}
@media (max-width: 575.98px) {
  .preloader h1:after {
    font-size: 14px;
  }
}

@-webkit-keyframes loading {
  0% {
    max-width: 0;
  }
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}
/*==========================
	Default Tabs
===========================*/
.default-tabs {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.default-tabs .tab-buttons {
  position: relative;
  margin-bottom: 30px;
}

.default-tabs .tab-buttons li {
  position: relative;
  float: left;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 35px;
  color: var(--theme-color1);
  line-height: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  transition: all 300ms ease;
}
.default-tabs .tab-buttons li:last-child {
  margin-right: 0;
}

.default-tabs .tab-buttons li.active-btn {
  background: var(--gradient-1);
  color: #ffffff;
}

.default-tabs .tabs-content {
  position: relative;
  width: 100%;
}

.blockquote-style-one {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color-dark);
  padding: 13px 25px;
  background-color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-family: var(--title-font);
  font-weight: 800;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.blockquote-style-one:before {
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 10px;
  width: 4px;
  z-index: 2;
  background-color: var(--theme-color1);
  content: "";
}

.blockquote-style-two {
  color: var(--theme-color-dark);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
  font-family: var(--title-font);
}
.blockquote-style-two:before {
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 5px;
  width: 3px;
  z-index: 2;
  background-color: var(--theme-color-dark);
  content: "";
}

/*================================
    Progress Bar
=================================*/
.skills {
  position: relative;
  margin-bottom: 50px;
}
.skills .skill-item {
  position: relative;
  margin-bottom: 25px;
}
.skills .skill-item:last-child {
  margin-bottom: 0px;
}
.skills .skill-item .skill-header {
  position: absolute;
  top: 10px;
  left: 18px;
  margin-bottom: 0px;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .skills .skill-item .skill-header {
    margin-bottom: 30px;
  }
}
.skills .skill-item .skill-header .skill-title {
  font-size: 20px;
  font-weight: 800;
  color: var(--theme-color2);
  margin-bottom: 10px;
  letter-spacing: -0.04em;
}
.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
  height: 7px;
  border-radius: 10px;
}
.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 7px;
  background: var(--theme-color-light);
  border-radius: 10px;
}
.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 7px;
  width: 0px;
  -webkit-transition: all 3000ms ease;
  transition: all 3000ms ease;
  border-radius: 10px;
  background: #6511fa;
}
.skills .skill-item .skill-bar .bar-inner .skill-percentage {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 14px;
  color: var(--theme-color-light);
}
.skills.style-two .skill-header {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 0px;
  z-index: 3;
}
.skills.style-two .skill-percentage {
  position: absolute;
  right: -40px !important;
  bottom: 100%;
  width: 40px;
  height: 21px;
  line-height: 21px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1em;
  border-radius: 6px 6px 6px 0;
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
  margin-bottom: 10px;
  text-align: center;
}
.skills.style-two .skill-percentage:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 5px solid var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.theme-btn {
  position: relative;
  display: block;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.theme-btn .btn-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  padding: 24px 90px 20px 60px;
  font-weight: 700;
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
  clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-one:before {
  content: "";
  position: absolute;
  top: 0;
  right: 25px;
  width: 7px;
  height: 100%;
  -webkit-transform: skewX(-21deg);
          transform: skewX(-21deg);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}
.btn-style-one:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-one i {
  position: relative;
  top: 1px;
  display: block;
  margin-left: 10px;
}
.btn-style-one:hover {
  color: var(--theme-color-light);
}
.btn-style-one:hover:after {
  width: 100%;
  right: auto;
  left: 0;
  z-index: -1;
}
.btn-style-one:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.btn-style-one.dark-bg {
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
}
.btn-style-one.dark-bg:after {
  background-color: var(--theme-color1);
}
.btn-style-one.light-bg {
  color: var(--theme-color-dark);
  background: var(--theme-color2);
}
.btn-style-one.light-bg::before {
  background: var(--theme-color-light);
}
.btn-style-one.light-bg:hover {
  color: var(--theme-color-light);
}
.btn-style-one.hover-light:after {
  background-color: var(--theme-color-light);
}
.btn-style-one.hover-light:hover {
  color: var(--theme-color-dark);
}
.btn-style-one.hover-light:hover:after {
  background-color: var(--theme-color-light);
}
.btn-style-one.dark-line:before {
  background-color: var(--theme-color-dark);
}
.btn-style-one.dark-line:hover:before {
  background-color: var(--theme-color-light);
}
.btn-style-one.dark-line-two:before {
  background-color: var(--theme-color-dark);
}
.btn-style-one.dark-line-two:hover:before {
  background-color: var(--theme-color-dark);
}
.btn-style-one.light-line:before {
  background-color: var(--theme-color-light);
}
.btn-style-one.light-line:hover:before {
  background-color: var(--theme-color-dark);
}
.btn-style-one.yellow-line:before {
  background-color: var(--theme-color1);
}
.btn-style-one.yellow-line:hover:before {
  background-color: var(--theme-color-dark);
}

/*Btn Style Two*/
.btn-style-two {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  padding: 22px 74px;
  font-weight: 700;
  overflow: hidden;
  color: var(--theme-color-dark);
  border: 2px solid var(--theme-color1);
  /* background-color: var(--theme-color-light); */
  background-color: #EE1623;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-two:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-two i {
  position: relative;
  top: 1px;
  display: block;
  margin-left: 10px;
}
.btn-style-two:hover {
  color: var(--theme-color-light);
}
.btn-style-two:hover:before {
  width: 100%;
  left: 0;
}
.btn-style-two:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.btn-style-two.dark-bg {
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
}
.btn-style-two.dark-bg::before {
  background-color: var(--theme-color1);
}
.btn-style-two.light-bg {
  color: var(--theme-color-dark);
  background: var(--theme-color2);
}
.btn-style-two.light-bg::before {
  background: var(--theme-color-light);
}
.btn-style-two.light-bg:hover {
  color: var(--theme-color-light);
}
.btn-style-two.hover-light:hover {
  color: var(--theme-color-dark);
}
.btn-style-two.hover-light:hover:after {
  background-color: var(--theme-color-dark);
}
.btn-style-two.hover-light:before {
  background-color: var(--theme-color-light);
}

/*Btn Style three*/
.btn-style-three {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  padding: 18.5px 58px 18.5px 39px;
  font-weight: 700;
  color: var(--theme-color-dark);
  border-left: 1px solid #a7a7a7;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-three:before {
  content: "";
  position: absolute;
  top: 0;
  right: 11px;
  width: 100%;
  height: 100%;
  -webkit-transform: skewX(-19deg);
          transform: skewX(-19deg);
  border: 1px solid #a7a7a7;
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-style-three i {
  position: relative;
  top: 1px;
  display: block;
  margin-left: 10px;
}
.btn-style-three:hover {
  color: var(--theme-color-light);
}
.btn-style-three:hover:before {
  background-color: var(--theme-color-dark) !important;
}
.btn-style-three:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.btn-style-three.dark-bg {
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
}
.btn-style-three.dark-bg:after {
  background-color: var(--theme-color1);
}
.btn-style-three.light-bg {
  color: var(--theme-color-dark);
  background: var(--theme-color2);
}
.btn-style-three.light-bg::before {
  background: var(--theme-color-light);
}
.btn-style-three.light-bg:hover {
  color: var(--theme-color-light);
}
.btn-style-three.hover-light:hover {
  color: var(--theme-color-dark);
}
.btn-style-three.hover-light:hover:after {
  background-color: var(--theme-color-light);
}
.btn-style-three.dark-line:before {
  background-color: var(--theme-color-dark);
}
.btn-style-three.dark-line:hover:before {
  background-color: var(--theme-color-light);
}
.btn-style-three.dark-line-two:before {
  background-color: var(--theme-color-dark);
}
.btn-style-three.dark-line-two:hover:before {
  background-color: var(--theme-color-dark);
}
.btn-style-three.light-line:before {
  background-color: var(--theme-color-light);
}
.btn-style-three.light-line:hover:before {
  background-color: var(--theme-color-dark);
}
.btn-style-three.yellow-line:before {
  background-color: var(--theme-color1);
}
.btn-style-three.yellow-line:hover:before {
  background-color: var(--theme-color-dark);
}

/*** 

====================================================================
  Anim Icons
====================================================================

***/
.reveal {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* visibility: hidden; */
  overflow: hidden;
}
.reveal img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform-origin: left;
          transform-origin: left;
}

.anim-icons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

[text-split] {
  opacity: 0;
}

.word {
  overflow: hidden;
  margin-bottom: -0.1em;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.anim-icons.full-width {
  max-width: 100%;
}

.anim-icons .icon {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.icon-triangle {
  width: 8px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-quote {
  width: 28px;
  height: 22px;
  background-image: url(../images/icons/icon-quote.png);
}

.icon-tag {
  width: 28px;
  height: 28px;
  background-image: url(../images/icons/icon-tag.png);
}

.sub-icon {
  width: 26px;
  height: 54px;
  background-image: url(../images/icons/sub-icon.png);
}

.icon-car {
  width: 274px;
  height: 172px;
  background-image: url(../images/icons/icon-car.png);
}

.icon-car-2 {
  width: 597px;
  height: 300px;
  background-image: url(../images/icons/icon-car-2.png);
}

.icon-car-3 {
  width: 590px;
  height: 496px;
  background-image: url(../images/icons/icon-car-3.png);
}

.icon-car-4 {
  width: 581px;
  height: 449px;
  background-image: url(../images/icons/icon-car-4.png);
}

.icon-car-5 {
  width: 523px;
  height: 701px;
  background-image: url(../images/icons/icon-car-5.png);
}

.icon-car-6 {
  width: 507px;
  height: 228px;
  background-image: url(../images/icons/icon-car-6.png);
}

.icon-check {
  width: 47px;
  height: 47px;
  background-image: url(../images/icons/icon-check.png);
}

.bg-overlay {
  background-image: url(../images/icons/overlay.png);
  background-size: cover;
  height: 100%;
  width: 936px;
}

.bg-overlay-2 {
  width: 743px;
  height: 100%;
  background-image: url(../images/icons/overlay-2.png);
}

.bg-overlay-3 {
  width: 832px;
  height: 100%;
  background-image: url(../images/icons/overlay-3.png);
}

.bg-overlay-4 {
  width: 583px;
  height: 460px;
  background-image: url(../images/icons/overlay-4.png);
}

.bg-overlay-5 {
  width: 867px;
  height: 100%;
  background-image: url(../images/icons/overlay-5.png);
}

.bg-overlay-6 {
  width: 581px;
  height: 388px;
  background-image: url(../images/icons/overlay-6.png);
}

.bg-overlay-7 {
  width: 585px;
  height: 509px;
  background-image: url(../images/icons/overlay-7.png);
}

.bg-overlay-8 {
  width: 1173px;
  height: 381px;
  background-image: url(../images/icons/overlay-8.png);
}

.bg-shape {
  width: 564px;
  height: 606px;
  background-image: url(../images/banner/bg-shape.png);
}

.bounce-y {
  -webkit-animation: bounce-y 10s infinite linear;
          animation: bounce-y 10s infinite linear;
}

.bounce-x {
  -webkit-animation: bounce-x 10s infinite linear;
          animation: bounce-x 10s infinite linear;
}

.zoom-one {
  -webkit-animation: zoom-one 10s infinite linear;
          animation: zoom-one 10s infinite linear;
}

.zoom-two {
  -webkit-animation: zoom-two 5s infinite linear;
          animation: zoom-two 5s infinite linear;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@-webkit-keyframes zoom-two {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoom-two {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.overlay-anim {
  position: relative;
}
.overlay-anim:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.overlay-anim:hover:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.circle {
  position: fixed;
  width: 10px;
  height: 10px;
  left: -10px;
  top: -10px;
  border-radius: 100%;
  z-index: 1;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@supports (mix-blend-mode: difference) {
  .circle {
    background-color: white;
    mix-blend-mode: difference;
  }
}
@media only screen and (max-width: 1023px) {
  .circle {
    display: none !important;
  }
}

.circle-follow {
  position: fixed;
  mix-blend-mode: difference;
  width: 30px;
  height: 30px;
  left: -21px;
  top: -21px;
  border-radius: 100%;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@supports (mix-blend-mode: difference) {
  .circle-follow {
    border: 1px solid #fff;
    mix-blend-mode: difference;
  }
}
@media only screen and (max-width: 1023px) {
  .circle-follow {
    display: none !important;
  }
}




.news-details-section {
  background-color: #4c5b6b; 
  padding: 60px 0; /* Padding around the section */
}

.news-details-section h3 {
  color: #ecf0f1; /* Light color for heading */
  margin-bottom: 30px; /* Spacing below heading */
  text-align: center; /* Center align heading */
  font-size: 24px; /* Font size for heading */
}

.form-border {
  background-color: #EE1623; 
  color: white;
  border-radius: 10px; /* Rounded corners */
  padding: 30px; /* Inner spacing */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.form-group {
  margin-bottom: 20px; /* Spacing between form elements */
}

label {
  color: #ecf0f1; /* Light color for labels */
  font-weight: 600; /* Bold labels */
}

.form-control {
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  background-color: #ecf0f1; /* Light background for inputs */
  padding: 12px; /* Inner spacing */
  transition: all 0.3s; /* Smooth transition */
}

.form-control:focus {
  outline: none; /* Remove outline */
  box-shadow: 0 0 5px rgba(46, 204, 113, 0.5); /* Green shadow on focus */
}

textarea {
  resize: none; /* Disable resizing */
}

.btn-main {
  background-color: black; /* Primary button color */
  color: #fff; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 12px 30px; /* Inner spacing */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth background transition */
}

.btn-main:hover {
  background-color: black; /* Darker shade on hover */
}

.success, .error {
  display: none; /* Hidden by default */
  color: #fff; /* Text color */
  text-align: center; /* Center align messages */
  margin-top: 20px; /* Spacing above messages */
}

.success {
  background-color: #2aabdf; /* Green for success */
  padding: 10px; /* Inner spacing */
  border-radius: 5px; /* Rounded corners */
}

.error {
  background-color: #e74c3c; /* Red for error */
  padding: 10px; /* Inner spacing */
  border-radius: 5px; /* Rounded corners */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .col-8 {
      width: 100%; /* Full width on smaller screens */
  }
  
  .form-border {
      padding: 20px; /* Less padding on smaller screens */
  }
  
  h3 {
      font-size: 20px; /* Smaller heading on mobile */
  }
}

.he a{
  display: block;
  color: white;
  text-decoration: none;
}
/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.search-popup .search-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color-dark);
  opacity: 0.95;
}
.search-popup .close-search {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: var(--theme-color-light);
  z-index: 3;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .search-inner {
  position: relative;
  display: block;
  top: 40%;
  height: auto;
  z-index: 1;
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.search-popup .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.search-popup .form-group input[type=search],
.search-popup .form-group input[type=text] {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e6dc;
  padding: 15px 20px;
  color: #707070;
  background: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group input[type=search]:focus,
.search-popup .form-group input[type=text]:focus {
  border-color: var(--border-theme-color2);
}
.search-popup .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  color: var(--theme-color-dark);
  line-height: 40px;
  border-radius: 5px;
  font-weight: normal;
  background: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group button:hover {
  color: var(--theme-color-dark);
}
.search-popup textarea::-webkit-input-placeholder, .search-popup input::-webkit-input-placeholder {
  color: inherit;
}
.search-popup textarea::-moz-placeholder, .search-popup input::-moz-placeholder {
  color: inherit;
}
.search-popup textarea:-ms-input-placeholder, .search-popup input:-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::-ms-input-placeholder, .search-popup input::-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::placeholder,
.search-popup input::placeholder {
  color: inherit;
}

.moblie-search-active .search-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 0%;
}
.moblie-search-active .search-popup .search-inner {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

/*** 

====================================================================
Main Header
====================================================================

***/
.header-span {
  position: relative;
  height: 110px;
  display: block;
  width: 100%;
}

.main-header {
  position: relative;
  width: 100%;
  z-index: 999;
}

.header-top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding: 0 80px;
}
@media (max-width: 1399.98px) {
  .header-top .inner-container {
    padding: 0 40px;
  }
}
.header-top .top-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-top .top-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-top .top-right .social-icon-one {
  margin-left: 41px;
}
.header-top .useful-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0;
}
.header-top .useful-links li {
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
  font-size: 12px;
  color: #75767a;
  line-height: 20px;
}
.header-top .useful-links li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-top .useful-links li:first-child:before {
  display: none;
}
.header-top .useful-links li:before {
  position: absolute;
  left: -3px;
  top: 0;
  content: "/";
}
.header-top .useful-links li a {
  color: #808287;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.header-top .useful-links li a:hover {
  color: var(--theme-color-light);
}

.main-header .header-lower {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .header-lower .logo-box {
  position: relative;
  padding: 20px 0;
}

.main-header {
  width: 100%;
  z-index: 999;
}
.main-header .logo {
  position: relative;
  display: block;
}
.main-header .logo img {
  max-width: 100%;
  height: auto;
}
.main-header .main-box {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .main-box .nav-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.main-menu {
  position: relative;
}
@media (max-width: 991.98px) {
  .main-menu {
    display: none;
  }
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  float: left;
  padding: 14px 0px;
  margin-right: 48px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li:last-child {
  margin-right: 0;
}
.main-menu .navigation > li:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0%;
  height: 2px;
  margin-top: 10px;
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 3;
}
.main-menu .navigation > li:hover:before, .main-menu .navigation > li.current:before {
  left: 0;
  width: 100%;
}
.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  color: var(--theme-color-light);
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li > a .icon {
  position: relative;
  font-size: 22px;
  line-height: 24px;
  margin-left: 10px;
}
.main-menu .navigation > li > a:hover {
  color: var(--theme-color1);
}
.main-menu .navigation > li.current > a {
  color: var(--theme-color1);
}
.main-menu .navigation > li.dropdown > a {
  padding-right: 14px;
  margin-right: -14px;
}
.main-menu .navigation > li.dropdown > a:after {
  content: "\f107";
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 24px;
  font-size: 12px;
  z-index: 5;
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  margin-top: -2px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
  margin-top: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  padding: 10px 0 0;
  background-color: #ffffff;
  margin-top: 30px;
  opacity: 0;
  display: none;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}
.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ebf1f5;
}
.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0px;
  line-height: 29px;
  font-weight: 400;
  font-size: 16px;
  color: var(--theme-color-dark);
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.main-menu .navigation > li > ul > li:hover > a {
  color: var(--theme-colo-dark);
}
.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: "Font Awesome 6 Pro";
  content: "\f105";
  position: absolute;
  right: 0;
  top: 11px;
  display: block;
  line-height: 24px;
  font-size: 14px;
  font-weight: 900;
  z-index: 5;
}
.main-menu .navigation > li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0px;
  margin-top: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0px;
  width: 220px;
  z-index: 100;
  display: none;
  background-color: #ffffff;
  opacity: 0;
  padding: 10px 0 0;
  margin-top: 10px;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  border-bottom: 1px solid #ebf1f5;
  width: 100%;
}
.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  color: var(--theme-color-dark);
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li > ul > li > ul > li > a:hover {
  color: var(--theme-colo-dark);
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.main-header .outer-box .theme-btn {
  margin-left: 40px;
}

.main-header .ui-btn {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: none;
  font-size: 24px;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .ui-btn:hover {
  color: var(--theme-color1);
}
.main-header .ui-btn.light {
  color: var(--theme-color-light);
}
.main-header .ui-btn.light:hover {
  color: var(--theme-color1);
}

.main-header .info-btn {
  position: relative;
  font-size: 16px;
  padding-left: 44px;
  color: #ffffff;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  line-height: 20px;
  margin-left: 40px;
}
.main-header .info-btn small {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--theme-color1);
}
.main-header .info-btn .icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  line-height: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 1em;
  margin-right: 20px;
  padding-right: 20px;
}
.main-header .info-btn:hover {
  color: var(--theme-color-light);
}

.header-search {
  position: relative;
  width: 110px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.header-search .form-group {
  position: relative;
}
.header-search .form-group input:not([type=submit]) {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  line-height: 20px;
  padding: 5px 0;
  padding-left: 30px;
  color: var(--text-color);
  background: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-bottom: 1px solid transparent;
}
.header-search .form-group input:not([type=submit])::-webkit-input-placeholder {
  color: var(--text-color);
}
.header-search .form-group input:not([type=submit])::-moz-placeholder {
  color: var(--text-color);
}
.header-search .form-group input:not([type=submit]):-ms-input-placeholder {
  color: var(--text-color);
}
.header-search .form-group input:not([type=submit])::-ms-input-placeholder {
  color: var(--text-color);
}
.header-search .form-group input:not([type=submit])::placeholder {
  color: var(--text-color);
}
.header-search .form-group input:not([type=submit]):focus {
  border-bottom: 1px solid var(--theme-color-light);
}
.header-search .form-group .icon {
  position: absolute;
  top: 0px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 20px;
  font-size: 20px;
  padding: 5px 0;
  color: var(--theme-color-dark);
  background: none;
}

/*** 

====================================================================
    Header Style One
====================================================================

***/
.header-style-one {
  position: relative;
}
.header-style-one .header-top {
  position: relative;
  background-color: var(--theme-color-dark);
}
.header-style-one .header-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: var(--theme-color1);
}
.header-style-one .header-top:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 22px;
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}
@media (max-width: 991.98px) {
  .header-style-one .header-top {
    display: none;
  }
}
.header-style-one .header-top .inner-container {
  padding: 0;
}
.header-style-one .header-lower {
  position: relative;
  background-color: var(--theme-color-dark);
}
.header-style-one .header-lower .main-box .logo-box {
  position: relative;
  margin-right: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 991.98px) {
  .header-style-one .header-lower .main-box .logo-box {
    padding: 10px 0;
  }
}
.header-style-one .header-lower .main-box .nav-outer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-style-one .header-lower .main-box .outer-box {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1199.98px) {
  .header-style-one .header-lower .main-box .outer-box .btn-box {
    display: none;
  }
}
.header-style-one .header-lower .main-box .outer-box .btn-box .theme-btn {
  padding: 22px 92px 22px 47px;
}

/*** 

====================================================================
    Header Style Two
====================================================================

***/
.header-style-two {
  position: relative;
}
.header-style-two.style-two {
  background-color: var(--theme-color-dark);
}
.header-style-two.style-two::before {
  background-image: url(../images/background/bg-header3.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.header-style-two.style-two .header-top {
  background-color: transparent;
}
.header-style-two.style-two .header-lower {
  background-color: transparent;
}
.header-style-two.style-two .header-lower .main-box .logo-box {
  padding: 18px 0;
  margin-right: 90px;
}
.header-style-two.style-two .header-lower .main-box .logo-box::before {
  background-color: var(--theme-color1);
  content: "";
  position: absolute;
  left: -55px;
  top: 0;
  height: 100%;
  width: 242px;
}
@media (max-width: 1399.98px) {
  .header-style-two.style-two .header-lower .main-box .logo-box::before {
    left: -15px;
    width: 180px;
  }
}
@media (max-width: 1399.98px) {
  .header-style-two.style-two .header-lower .main-box .logo-box .logo {
    padding-left: 15px;
  }
}
.header-style-two.style-two .outer-box {
  padding-right: 15px;
}
.header-style-two.style-two .outer-box .btn-box {
  position: relative;
}
.header-style-two.style-two .outer-box .btn-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 10px;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.header-style-two.style-two .outer-box .theme-btn {
  border-color: var(--theme-color1);
  color: var(--theme-color-light);
  padding: 24px 68px 20px 48px;
}
.header-style-two.style-two .outer-box .theme-btn:before {
  border-color: var(--theme-color1);
  background-color: transparent;
}
.header-style-two.style-two .outer-box .theme-btn:hover {
  color: var(--theme-color-dark);
}
.header-style-two.style-two .outer-box .theme-btn:hover:before {
  background-color: var(--theme-color1) !important;
}
.header-style-two .header-top {
  position: relative;
  background-color: #141414;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 991.98px) {
  .header-style-two .header-top {
    display: none;
  }
}
.header-style-two .header-top .inner-container {
  padding: 0;
}
.header-style-two .header-lower {
  position: relative;
  background-color: #141414;
}
.header-style-two .header-lower .main-box .logo-box {
  position: relative;
  padding: 22px 0;
  margin-right: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.header-style-two .header-lower .main-box .nav-outer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-style-two .header-lower .main-box .outer-box {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1199.98px) {
  .header-style-two .header-lower .main-box .outer-box .theme-btn {
    display: none;
  }
}

/*** 

====================================================================
    Header Style Three
====================================================================

***/
.header-style-three {
  position: relative;
}
.header-style-three .header-top {
  position: relative;
  background-color: var(--theme-color-light);
}
@media (max-width: 991.98px) {
  .header-style-three .header-top {
    display: none;
  }
}
.header-style-three .header-top .inner-container {
  padding: 0;
  padding-bottom: 2px;
}
.header-style-three .header-top .inner-container .social-icon-one a {
  color: var(--theme-color-dark);
}
.header-style-three .header-top .inner-container .social-icon-one a:hover {
  color: var(--theme-color1);
}
.header-style-three .header-lower {
  position: relative;
  background-color: var(--theme-color-light);
}
.header-style-three .header-lower .main-box .logo-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 18px 0;
  padding-right: 95px;
  margin-right: 17px;
}
.header-style-three .header-lower .main-box .logo-box .logo img {
  position: relative;
  z-index: 3;
}
.header-style-three .header-lower .main-box .logo-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 26px;
  width: 1000%;
  height: 100%;
  -webkit-transform: skewX(33deg);
          transform: skewX(33deg);
  background-color: var(--theme-color-dark);
  border-right: 10px solid var(--theme-color1);
}
.header-style-three .header-lower .main-box .nav-outer .navigation > li > a {
  color: var(--theme-color-dark);
}
.header-style-three .header-lower .main-box .outer-box {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1199.98px) {
  .header-style-three .header-lower .main-box .outer-box .btn-box {
    display: none;
  }
}
.header-style-three .header-lower .main-box .outer-box .btn-box .theme-btn {
  padding: 22px 92px 22px 47px;
}

/*** 

====================================================================
    Sticky Header
====================================================================

***/
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 99999;
  background: #ffffff;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
@media (max-width: 991.98px) {
  .sticky-header {
    padding: 5px 0;
  }
}

.sticky-header.fixed-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

.sticky-header .logo {
  padding: 5px 0;
}
.sticky-header .logo img {
  max-height: 40px;
}
.sticky-header .nav-outer {
  position: relative;
  background: none;
}
.sticky-header .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sticky-header .main-menu .navigation > li {
  margin: 0;
  margin-left: 60px;
  padding: 20px 0;
}

.sticky-header .main-menu .navigation > li > a {
  color: var(--theme-color-dark);
}

.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color-dark);
}

.sticky-header .outer-box,
.sticky-header .navbar-header {
  display: none;
}

.sticky-header .mobile-nav-toggler {
  color: var(--theme-color-dark);
}

@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
}
/*** 

====================================================================
      Mobile Menu
====================================================================

***/
.mobile-nav-toggler {
  position: relative;
  font-size: 28px;
  line-height: 20px;
  cursor: pointer;
  color: var(--theme-color-dark);
  display: none;
  margin-left: 20px;
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}
@media (max-width: 991.98px) {
  .mobile-nav-toggler {
    display: block;
  }
}
.mobile-nav-toggler.light {
  color: var(--theme-color-light);
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}
.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
}
.mobile-menu .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}
.mobile-menu .close-btn {
  position: relative;
  text-align: center;
  font-size: 18px;
  color: var(--theme-color1);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
}
.mobile-menu .close-btn:hover {
  opacity: 0.5;
}
.mobile-menu .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}
.mobile-menu .nav-logo img {
  max-height: 40px;
}
.mobile-menu .menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: #000;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
          transform: translateX(101%);
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease 200ms;
  transition: all 0.4s ease 200ms;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.mobile-menu-visible .scroll-to-top {
  opacity: 0;
  visibility: hidden;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > ul > li:last-child {
  border-bottom: none;
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
}
.mobile-menu .navigation li:hover > a, .mobile-menu .navigation li.current > a {
  color: var(--theme-color1);
}
.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
}
.mobile-menu .navigation li.dropdown .dropdown-btn:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 10px;
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li.dropdown .dropdown-btn.active i:before {
  content: "\f106";
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}

.mobile-menu .navigation li > ul > li {
  padding-left: 20px;
}

.contact-list-one {
  position: relative;
  padding: 30px 20px 20px;
  width: 100%;
}
.contact-list-one li {
  position: relative;
  padding-left: 54px;
  min-height: 50px;
  margin-bottom: 20px;
}
.contact-list-one li .icon {
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 34px;
  line-height: 40px;
  color: var(--theme-color1);
}
.contact-list-one li .title {
  display: block;
  font-size: 14px;
  color: var(--theme-color-dark);
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.contact-list-one li .text {
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color-dark);
}
.contact-list-one li .text a {
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-list-one li .text a:hover {
  color: var(--theme-color1);
}
.contact-list-one.light li .text,
.contact-list-one.light li .title {
  color: var(--theme-color-light);
}
.contact-list-one.light li .text a,
.contact-list-one.light li .title a {
  color: var(--theme-color-light);
}

.mobile-menu .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: var(--theme-color-dark);
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}
.mobile-menu .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.mobile-menu .social-links li a:hover {
  color: var(--theme-color2);
}

/*** 

====================================================================
    Section Title
====================================================================

***/
.sec-title {
  position: relative;
  margin-bottom: 50px;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .sec-title br {
    display: none;
  }
}
.sec-title .sub-icon {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 5px;
}
.sec-title .sub-title {
  position: relative;
  display: block;
  font-size: var(--sec-title-subtitle-font-size);
  line-height: var(--sec-title-subtitle-line-height);
  font-weight: var(--sec-title-subtitle-font-weight);
  font-family: var(--sec-title-subtitle-font-family);
  color: var(--sec-title-subtitle-color);
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sec-title .sub-title.line-style {
  display: inline-block;
  top: -2px;
  padding-left: 25px;
}
.sec-title .sub-title.line-style:before {
  background-color: var(--theme-color1);
  content: "";
  width: 6px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 6px;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.sec-title .sub-title.line-style:after {
  background-color: var(--theme-color1);
  content: "";
  width: 2px;
  height: 13px;
  left: 9px;
  position: absolute;
  top: 6px;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.sec-title h1 {
  position: relative;
  font-size: var(--h1-font-size);
  line-height: 1.18;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .sec-title h1 {
    font-size: 84px;
  }
}
@media (max-width: 991.98px) {
  .sec-title h1 {
    font-size: 72px;
  }
}
@media (max-width: 767.98px) {
  .sec-title h1 {
    font-size: 68px;
  }
}
@media (max-width: 575.98px) {
  .sec-title h1 {
    font-size: 36px;
  }
}
.sec-title h2 {
  position: relative;
  font-size: var(--sec-title-font-size);
  color: var(--sec-title-color);
  font-family: var(--sec-title-font-family);
  font-weight: var(--sec-title-font-weight);
  margin-bottom: 0;
  letter-spacing: -0.03em;
  line-height: 1.09;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .sec-title h2 {
    line-height: 1em;
    font-size: 48px;
  }
}
@media (max-width: 575.98px) {
  .sec-title h2 {
    font-size: 40px;
  }
  .sec-title h2 br {
    display: none;
  }
}
.sec-title .text {
  position: relative;
  color: var(--theme-color-dark);
  margin-top: 20px;
}
@media (max-width: 575.98px) {
  .sec-title .text {
    font-size: 16px;
  }
}
.sec-title.light .sub-title {
  color: var(--theme-color-light);
}
.sec-title.light .sub-title:before {
  background: var(--theme-color1);
}
.sec-title.light .text {
  color: var(--theme-color-light);
}
.sec-title.light h2,
.sec-title.light h1 {
  color: var(--theme-color-light);
}

.text-line-style {
  display: inline-block;
  top: -2px;
  padding-left: 25px;
  position: relative;
}
.text-line-style:before {
  background-color: var(--theme-color1);
  content: "";
  width: 6px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.text-line-style:after {
  background-color: var(--theme-color1);
  content: "";
  width: 2px;
  height: 13px;
  left: 9px;
  position: absolute;
  top: 50%;
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
    Main Slider
====================================================================

***/
.main-slider {
  position: relative;
  min-height: 715px;
}
@media (max-width: 1199.98px) {
  .main-slider {
    min-height: auto;
  }
}
.main-slider h1 {
  position: relative;
  display: block;
  color: var(--theme-color-light);
  font-size: 74px;
  line-height: 1.2em;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .main-slider h1 {
    font-size: 60px;
  }
}
@media (max-width: 767.98px) {
  .main-slider h1 {
    font-size: 44px;
  }
  .main-slider h1 br {
    display: none;
  }
}
.main-slider .text {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 34px;
  color: var(--theme-color-light);
  z-index: 1;
}
@media (max-width: 991.98px) {
  .main-slider .text {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .main-slider .text {
    font-size: 18px;
  }
}
@media (max-width: 575.98px) {
  .main-slider .text {
    font-size: 16px;
  }
}

.main-slider .tp-dottedoverlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: var(--theme-color-dark);
}
.main-slider .tp-dottedoverlay:after {
  content: "";
  position: absolute;
  top: -127px;
  right: 50px;
  width: 837px;
  height: 842px;
  background-image: url(../images/main-slider/overlay.png);
}
@media only screen and (max-width: 1699px) {
  .main-slider .tp-dottedoverlay:after {
    right: 0;
  }
}
@media (max-width: 1399.98px) {
  .main-slider .tp-dottedoverlay:after {
    right: -100px;
  }
}
@media (max-width: 1199.98px) {
  .main-slider .tp-dottedoverlay:after {
    display: none;
  }
}

/*** 

====================================================================
    Faqs Section
====================================================================

***/
.faqs-section {
  padding: 0;
}
@media (max-width: 1199.98px) {
  .faqs-section .auto-container {
    max-width: 960px;
  }
}
.faqs-section .faq-column .inner-column {
  padding: 120px 0 74px;
}
@media (max-width: 1199.98px) {
  .faqs-section .faq-column .inner-column {
    padding: 90px 0 64px;
  }
}
.faqs-section .faq-column .inner-column .sec-title {
  margin-bottom: 20px;
}
.faqs-section .image-column {
  position: relative;
}
@media (max-width: 1199.98px) {
  .faqs-section .image-column {
    display: none;
  }
}

.faqs-section .image-column .inner-column::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 85px;
  height: 100%;
  width: 123px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-18deg);
          transform: skew(-18deg);
  pointer-events: none;
}
.faqs-section .image-column .inner-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 172px;
  height: 238.5px;
  width: 121px;
  background-color: var(--theme-color-dark);
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  pointer-events: none;
}
.faqs-section .image-column .image {
  clip-path: polygon(49% 0%, 94% 0px, 49% 100%, 0% 100%);
  margin-bottom: 0;
  margin-left: 55px;
}
.faqs-section .accordion-box .block .acc-btn.active .icon::before {
  content: "\f078";
}

.accordion-box {
  position: relative;
}
.accordion-box .block {
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
          box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  position: relative;
}
.accordion-box .block .acc-btn {
  border-bottom: 1px solid red;
  color: var(--headings-color);
  cursor: pointer;
  font-size: 20px;
  font-family: var(--title-font);
  font-weight: var(--h4-font-weight);
  padding: 20px 30px;
  padding-right: 70px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn .icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: red;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  height: 35px;
  line-height: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn.active {
  background-color: var(--bg-theme-color2);
  color: var(--theme-color-light);
}
.accordion-box .block .acc-btn.active .icon {
  color: var(--text-color-bg-theme-color2);
}
.accordion-box .block .acc-btn.active .icon:before {
  content: "\f068";
}
.accordion-box .block .acc-content {
  display: none;
  position: relative;
}
.accordion-box .block .acc-content .content {
  padding: 25px 30px;
  position: relative;
}
.accordion-box .block .acc-content .content .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}
.accordion-box .block .acc-content.current {
  display: block;
}
.accordion-box.style-two .block {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 1px solid rgba(var(--theme-color-dark-rgb), 0.1);
  margin-bottom: 0;
}
.accordion-box.style-two .block:last-child {
  border-bottom: none;
}
.accordion-box.style-two .block:last-child .acc-content .content {
  padding-bottom: 0;
}
.accordion-box.style-two .block .acc-btn {
  padding: 23px 30px 23px 0;
}
.accordion-box.style-two .block .acc-btn.active {
  background-color: transparent;
  border-bottom: 1px solid rgba(var(--theme-color-dark-rgb), 0.1);
  color: var(--headings-color);
}
.accordion-box.style-two .block .acc-btn.active .icon {
  color: var(--headings-color);
}
.accordion-box.style-two .block .acc-btn .icon {
  right: 0;
}
.accordion-box.style-two .block .acc-content .content {
  padding: 25px 0;
}

/*** 

====================================================================
    Faqs Section Two
====================================================================

***/
.faqs-section-two {
  padding: 274px 0 120px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .faqs-section-two {
    padding: 274px 0 90px;
  }
}
@media (max-width: 991.98px) {
  .faqs-section-two {
    padding: 0 0 90px;
  }
}
.faqs-section-two .booking-form-two {
  margin: 0 auto;
}
.faqs-section-two .bg-image {
  height: 370px;
}
@media (max-width: 991.98px) {
  .faqs-section-two .bg-image {
    display: none;
  }
}
.faqs-section-two .bg-image::before {
  background-color: rgba(var(--theme-color-dark-rgb), 0.8);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.faqs-section-two .bg-image .bg-shape2::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 495px);
  width: 229px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-26deg);
          transform: skew(-26deg);
  pointer-events: none;
  z-index: 1;
}
.faqs-section-two .bg-image .bg-shape2::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(50% - 565px);
  width: 77px;
  height: 151.5px;
  background-color: var(--theme-color-light);
  -webkit-transform: skew(-26deg) translateY(-50%);
          transform: skew(-26deg) translateY(-50%);
  pointer-events: none;
  z-index: 1;
}
.faqs-section-two .faq-column .inner-column {
  padding: 210px 30px 0 0;
}
@media (max-width: 991.98px) {
  .faqs-section-two .faq-column .inner-column {
    padding: 0 0 50px 0;
  }
}
.faqs-section-two .faq-column .inner-column .sec-title {
  margin-bottom: 20px;
}
.faqs-section-two .accordion-box .block .acc-btn.active .icon::before {
  content: "\f078";
}

/*** 

====================================================================
    Faqs Section Three
====================================================================

***/
.faqs-section-three {
  background-color: var(--theme-color-dark);
  padding: 0;
}
.faqs-section-three .faq-column .inner-column {
  padding: 124px 0 74px;
  margin-left: -50px;
}
@media (max-width: 1399.98px) {
  .faqs-section-three .faq-column .inner-column {
    margin-left: -200px;
    margin-right: 50px;
  }
}
@media (max-width: 991.98px) {
  .faqs-section-three .faq-column .inner-column {
    margin-left: 0;
    margin-right: 0;
    padding: 84px 0 80px;
  }
}
.faqs-section-three .faq-column .inner-column .sec-title {
  margin-bottom: 20px;
}
.faqs-section-three .faq-column .inner-column .accordion-box .block {
  background-color: transparent;
}
.faqs-section-three .faq-column .inner-column .accordion-box .block .acc-btn {
  border-bottom-color: rgba(var(--theme-color-light-rgb), 0.1);
  color: #fff;
}
.faqs-section-three .faq-column .inner-column .accordion-box .block .acc-btn .icon {
  color: #fff;
}
.faqs-section-three .faq-column .inner-column .accordion-box .block .acc-btn.active {
  color: #fff;
}
.faqs-section-three .faq-column .inner-column .accordion-box .block .acc-content .content .text {
  color: #B8B8B8;
}
.faqs-section-three .image-column {
  position: relative;
}
@media (max-width: 991.98px) {
  .faqs-section-three .image-column {
    display: none;
  }
}
.faqs-section-three .image-column .inner-column::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -150px;
  height: calc(100% + 1px);
  width: 153px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(18deg);
          transform: skew(18deg);
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .faqs-section-three .image-column .inner-column::before {
    display: none;
  }
}
.faqs-section-three .image-column .inner-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 275px;
  height: 238.5px;
  width: 121px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(18deg);
          transform: skew(18deg);
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .faqs-section-three .image-column .inner-column::after {
    display: none;
  }
}
.faqs-section-three .image-column .image {
  clip-path: polygon(0% 0%, 38% 0%, 75% 100%, 36% 100%);
  margin-bottom: 0;
  margin-left: -100px;
}
.faqs-section-three .accordion-box .block .acc-btn.active .icon::before {
  content: "\f078";
}

/* Animate 1 */
.owl-carousel .animate-7,
.owl-carousel .animate-6,
.owl-carousel .animate-5,
.owl-carousel .animate-4,
.owl-carousel .animate-3,
.owl-carousel .animate-2,
.owl-carousel .animate-1 {
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.owl-carousel .animate-x {
  opacity: 0;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.owl-carousel .active .animate-7,
.owl-carousel .active .animate-6,
.owl-carousel .active .animate-5,
.owl-carousel .active .animate-4,
.owl-carousel .active .animate-3,
.owl-carousel .active .animate-2,
.owl-carousel .active .animate-1 {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.owl-carousel .active .animate-2 {
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}
.owl-carousel .active .animate-3 {
  -webkit-transition-delay: 600ms;
          transition-delay: 600ms;
}
.owl-carousel .active .animate-4 {
  -webkit-transition-delay: 900ms;
          transition-delay: 900ms;
}
.owl-carousel .active .animate-5 {
  -webkit-transition-delay: 1200ms;
          transition-delay: 1200ms;
}
.owl-carousel .active .animate-6 {
  -webkit-transition-delay: 1500ms;
          transition-delay: 1500ms;
}
.owl-carousel .active .animate-7 {
  -webkit-transition-delay: 1800ms;
          transition-delay: 1800ms;
}

.banner-carousel .owl-nav {
  position: relative;
  padding: 0 60px;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-pack: unset;
      -ms-flex-pack: unset;
          justify-content: unset;
  z-index: 3;
}
@media (max-width: 1199.98px) {
  .banner-carousel .owl-nav {
    display: none !important;
  }
}
.banner-carousel .owl-nav .owl-next,
.banner-carousel .owl-nav .owl-prev {
  margin-bottom: -20px;
  display: block;
  height: 54px;
  width: 54px;
  line-height: 54px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  margin-right: 60px;
  color: var(--theme-color-light);
  background-color: transparent;
  border: 1px solid var(--theme-color-light);
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.banner-carousel .owl-nav .owl-next:hover,
.banner-carousel .owl-nav .owl-prev:hover {
  background-color: var(--theme-color1);
  border-color: var(--theme-color1);
  color: var(--theme-color-dark);
}

/*** 

====================================================================
    Banner Section
====================================================================

***/
.banner-section {
  position: relative;
  overflow: hidden;
}
.banner-section .image-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 1399.98px) {
  .banner-section .image-box {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
.banner-section .image-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  width: 160px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
}
@media (max-width: 1399.98px) {
  .banner-section .image-box:before {
    display: none;
  }
}
.banner-section .image-box .image {
  position: relative;
  display: block;
  clip-path: polygon(42% 0, 100% 0, 100% 100%, 0% 100%);
  margin-bottom: 0;
  z-index: 9;
}
@media (max-width: 1399.98px) {
  .banner-section .image-box .image {
    clip-path: none;
    width: 600px;
  }
}
@media (max-width: 1199.98px) {
  .banner-section .image-box .image {
    display: none;
  }
}
.banner-section .content-box {
  position: relative;
  padding: 137px 0 320px;
}
@media (max-width: 1199.98px) {
  .banner-section .content-box {
    padding: 105px 0 120px;
    text-align: center;
  }
}
.banner-section .content-box .title {
  position: relative;
  font-size: 74px;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--theme-color-light);
  margin-bottom: 25px;
  text-transform: capitalize;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner-section .content-box .title {
    line-height: 1em;
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .banner-section .content-box .title {
    font-size: 60px;
  }
}
@media (max-width: 767.98px) {
  .banner-section .content-box .title {
    font-size: 46px;
  }
  .banner-section .content-box .title br {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .banner-section .content-box .title {
    font-size: 40px;
  }
}
.banner-section .content-box .text {
  position: relative;
  color: var(--theme-color-light);
  margin-bottom: 42px;
}
@media (max-width: 767.98px) {
  .banner-section .content-box .text br {
    display: none;
  }
}
.banner-section .content-box .theme-btn {
  padding: 22px 92px 22px 52px;
}
.banner-section .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
.banner-section .slide-item .banner-shape-1 {
  background-image: url(../images/banner/banner-shape-1.png);
  width: 295px;
  height: 170px;
  position: absolute;
  top: 79px;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1399.98px) {
  .banner-section .slide-item .banner-shape-1 {
    display: none;
  }
}
.banner-section .slide-item .banner-shape-curve1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 193px;
  height: 100px;
  background-color: var(--theme-color1);
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 18% 100%);
  pointer-events: none;
}
@media (max-width: 1399.98px) {
  .banner-section .slide-item .banner-shape-curve1 {
    display: none;
  }
}
.banner-section .slide-item .banner-shape-curve2 {
  position: absolute;
  right: 0;
  bottom: 100px;
  width: 136px;
  height: 100px;
  background-color: var(--theme-color-light);
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 18% 100%);
  pointer-events: none;
}
@media (max-width: 1399.98px) {
  .banner-section .slide-item .banner-shape-curve2 {
    display: none;
  }
}
.banner-section .banner-carousel .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: absolute;
  top: calc(50% - 172px);
  left: 0;
  right: 0;
  padding: 0 20px;
}
.banner-section .banner-carousel .owl-nav .owl-next,
.banner-section .banner-carousel .owl-nav .owl-prev {
  background-color: rgba(var(--theme-color-light-rgb), 1);
  border: none;
  border-radius: 0;
  color: var(--theme-color-dark);
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  margin-right: 0;
  text-align: center;
  opacity: 0.3;
}
.banner-section .banner-carousel .owl-nav .owl-next:hover,
.banner-section .banner-carousel .owl-nav .owl-prev:hover {
  background-color: var(--theme-color-light);
  opacity: 1;
}

/*** 

====================================================================
    Banner Section Two
====================================================================

***/
.banner-section-two {
  position: relative;
  overflow: hidden;
  padding: 135px 0 55px;
  background-color: var(--theme-color-dark);
}
@media (max-width: 575.98px) {
  .banner-section-two {
    padding-top: 100px;
  }
}
.banner-section-two .bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media only screen and (max-width: 1699px) {
  .banner-section-two .bg-shape {
    display: none;
  }
}
.banner-section-two .title-column .title {
  position: relative;
  font-size: 55px;
  font-weight: 500;
  line-height: 1.2em;
  color: var(--theme-color-light);
  margin-bottom: 0;
  text-transform: capitalize;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner-section-two .title-column .title {
    font-size: 50px;
  }
  .banner-section-two .title-column .title br {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .banner-section-two .title-column .title {
    text-align: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-two .title-column .title {
    font-size: 46px;
  }
}
.banner-section-two .button-column .inner-column {
  position: relative;
  margin: 7px auto 0;
  max-width: 400px;
  padding-left: 60px;
}
@media (max-width: 1199.98px) {
  .banner-section-two .button-column .inner-column {
    padding-left: 0;
    max-width: 100%;
    margin: 0;
  }
}
@media (max-width: 991.98px) {
  .banner-section-two .button-column .inner-column {
    text-align: center;
  }
}
.banner-section-two .button-column .inner-column .text {
  position: relative;
  font-size: 22px;
  line-height: 36px;
  color: var(--theme-color-light);
  margin-bottom: 35px;
}
@media (max-width: 991.98px) {
  .banner-section-two .button-column .inner-column .text {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .banner-section-two .button-column .inner-column .text br {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .banner-section-two .button-column .inner-column .text {
    font-size: 18px;
  }
}
.banner-section-two .image-column .inner-column {
  position: relative;
  margin-top: 45px;
}
.banner-section-two .image-column .inner-column .image {
  position: relative;
  display: block;
  margin-right: -366px;
  margin-bottom: 42px;
}
@media (max-width: 991.98px) {
  .banner-section-two .image-column .inner-column .image {
    margin-right: 0;
  }
}
.banner-section-two .image-column .inner-column .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner-section-two .image-column .inner-column .title {
  position: relative;
  color: var(--theme-color-light);
  font-size: 26px;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  .banner-section-two .image-column .inner-column .title {
    font-size: 20px;
  }
}
.banner-section-two .image-column .inner-column .title .phone-no {
  position: relative;
  color: var(--theme-color1);
  text-decoration: underline;
  margin-left: 20px;
}
@media (max-width: 575.98px) {
  .banner-section-two .image-column .inner-column .title .phone-no {
    margin-left: 0;
    display: block;
    margin-top: 5px;
  }
}

/*** 

====================================================================
    Banner Section Three
====================================================================

***/
.banner-section-three {
  position: relative;
  overflow: hidden;
}
.banner-section-three .image-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 1399.98px) {
  .banner-section-three .image-box {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
.banner-section-three .image-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  width: 160px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
}
@media (max-width: 1399.98px) {
  .banner-section-three .image-box:before {
    display: none;
  }
}
.banner-section-three .image-box .image {
  position: relative;
  display: block;
  clip-path: polygon(42% 0, 100% 0, 100% 100%, 0% 100%);
  margin-bottom: 0;
  z-index: 9;
}
@media (max-width: 1399.98px) {
  .banner-section-three .image-box .image {
    clip-path: none;
    width: 600px;
  }
}
@media (max-width: 1199.98px) {
  .banner-section-three .image-box .image {
    display: none;
  }
}
.banner-section-three .content-box {
  position: relative;
  padding: 84px 0 0;
  text-align: center;
}
@media (max-width: 1199.98px) {
  .banner-section-three .content-box {
    text-align: center;
  }
}
.banner-section-three .content-box .text {
  position: relative;
  color: var(--theme-color1);
  font-weight: 600;
  margin-bottom: 3px;
  letter-spacing: 0.7px;
}
.banner-section-three .content-box .text.line-style {
  display: inline-block;
  top: -2px;
  padding-left: 25px;
}
@media (max-width: 419.98px) {
  .banner-section-three .content-box .text.line-style {
    padding-left: 0;
  }
}
.banner-section-three .content-box .text.line-style:before {
  background-color: var(--theme-color1);
  content: "";
  width: 6px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 6px;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 419.98px) {
  .banner-section-three .content-box .text.line-style:before {
    display: none;
  }
}
.banner-section-three .content-box .text.line-style:after {
  background-color: var(--theme-color1);
  content: "";
  width: 2px;
  height: 13px;
  left: 9px;
  position: absolute;
  top: 6px;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 419.98px) {
  .banner-section-three .content-box .text.line-style:after {
    display: none;
  }
}
.banner-section-three .content-box .title {
  position: relative;
  font-size: 70px;
  font-weight: 900;
  line-height: 90px;
  color: var(--theme-color-light);
  margin-bottom: 0;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner-section-three .content-box .title {
    font-size: 54px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .title {
    font-size: 44px;
    font-weight: 700;
  }
}
@media (max-width: 767.98px) {
  .banner-section-three .content-box .title {
    font-size: 34px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-three .content-box .title br {
    display: none;
  }
}
.banner-section-three .content-box .title-stroke {
  color: rgba(var(--theme-color-light-rgb), 0.2);
  font-size: 200px;
  font-weight: 900;
  line-height: 1;
  font-family: var(--title-font);
  position: absolute;
  left: 50%;
  top: 329px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.15);
}
@media (max-width: 1199.98px) {
  .banner-section-three .content-box .title-stroke {
    top: 270px;
  }
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .title-stroke {
    font-size: 120px;
    top: 230px;
  }
}
@media (max-width: 767.98px) {
  .banner-section-three .content-box .title-stroke {
    top: 200px;
  }
}
.banner-section-three .content-box .info-box {
  background-color: rgba(var(--theme-color-light-rgb), 0.1);
  border: 1px solid rgba(var(--theme-color-light-rgb), 0.2);
  border-radius: 30px;
  padding: 38px 20px 25px;
  max-width: 190px;
  position: absolute;
  right: 9px;
  top: 104px;
}
@media (max-width: 1399.98px) {
  .banner-section-three .content-box .info-box {
    right: 120px;
    top: 0px;
  }
}
@media (max-width: 1199.98px) {
  .banner-section-three .content-box .info-box {
    right: 90px;
    top: 60px;
  }
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .info-box {
    max-width: 160px;
    padding: 28px 10px 15px;
    right: 20px;
  }
}
@media (max-width: 767.98px) {
  .banner-section-three .content-box .info-box {
    right: 0;
  }
}
@media (max-width: 575.98px) {
  .banner-section-three .content-box .info-box {
    display: none;
  }
}
.banner-section-three .content-box .info-box .taxi-count {
  color: var(--theme-color1);
  font-size: 48px;
  font-weight: 600;
  font-family: var(--title-font);
  margin-bottom: 23px;
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .info-box .taxi-count {
    font-size: 30px;
    margin-bottom: 5px;
  }
}
.banner-section-three .content-box .info-box .info-text {
  color: var(--theme-color-light);
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .info-box .info-text {
    font-size: 14px;
  }
}
.banner-section-three .content-box .banner-car1 {
  background-image: url(../images/banner/banner-car1.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 1187px;
  height: 690px;
  margin-top: 102px;
  margin-left: -9px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .banner-section-three .content-box .banner-car1 {
    background-size: contain;
    width: 100%;
    max-width: 960px;
    margin: 85px auto 0;
  }
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .banner-car1 {
    margin: 0 auto 0;
    height: 530px;
  }
}
@media (max-width: 767.98px) {
  .banner-section-three .content-box .banner-car1 {
    height: 395px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-three .content-box .banner-car1 {
    height: 250px;
  }
}
@media (max-width: 419.98px) {
  .banner-section-three .content-box .banner-car1 {
    margin-top: -50px;
  }
}
.banner-section-three .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  max-height: 877px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-section-three .banner-carousel-two .owl-nav {
  display: none;
}
.banner-section-three .banner-carousel-two .owl-dots {
  background-color: rgba(var(--theme-color-light-rgb), 0.1);
  border-radius: 50px;
  text-align: center;
  position: absolute;
  top: 369px;
  left: 56px;
  max-width: 40px;
  padding: 20px 3px 16px;
  line-height: 1;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .banner-section-three .banner-carousel-two .owl-dots {
    left: 15px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-three .banner-carousel-two .owl-dots {
    display: none;
  }
}
.banner-section-three .banner-carousel-two .owl-dots .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 5px;
}
.banner-section-three .banner-carousel-two .owl-dots .owl-dot:last-child {
  margin-bottom: 0;
}
.banner-section-three .banner-carousel-two .owl-dots .owl-dot span {
  display: inline-block;
  background-color: var(--theme-color-light);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.banner-section-three .banner-carousel-two .owl-dots .owl-dot.active {
  margin-bottom: 3px;
}
.banner-section-three .banner-carousel-two .owl-dots .owl-dot.active span {
  background-color: var(--theme-color1);
  width: 10px;
  height: 10px;
}
.banner-section-three .social-icon-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 45px;
  top: 326px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner-section-three .social-icon-two {
    right: 15px;
  }
}
@media (max-width: 991.98px) {
  .banner-section-three .social-icon-two {
    display: none;
  }
}
.banner-section-three .social-icon-two li {
  margin-bottom: 10px;
}
.banner-section-three .social-icon-two li:last-child {
  margin-bottom: 0;
}
.banner-section-three .social-icon-two li a {
  border: 1px solid #5A5A5B;
  color: var(--theme-color-light);
  background-color: transparent;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.banner-section-three .social-icon-two li a:before {
  background-color: var(--theme-color-light);
}
.banner-section-three .social-icon-two li a:hover {
  color: var(--theme-color-dark);
}

/*** 

====================================================================
    Banner Section Four
====================================================================

***/
.banner-section-four {
  position: relative;
  overflow: hidden;
}
.banner-section-four .image-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 1399.98px) {
  .banner-section-four .image-box {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
.banner-section-four .image-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  width: 160px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
}
@media (max-width: 1399.98px) {
  .banner-section-four .image-box:before {
    display: none;
  }
}
.banner-section-four .image-box .image {
  position: relative;
  display: block;
  clip-path: polygon(42% 0, 100% 0, 100% 100%, 0% 100%);
  margin-bottom: 0;
  z-index: 9;
}
@media (max-width: 1399.98px) {
  .banner-section-four .image-box .image {
    clip-path: none;
    width: 600px;
  }
}
@media (max-width: 1199.98px) {
  .banner-section-four .image-box .image {
    display: none;
  }
}
.banner-section-four .content-box {
  position: relative;
  padding: 211px 0 291px;
}
@media (max-width: 1199.98px) {
  .banner-section-four .content-box {
    padding: 120px 0;
  }
}
@media (max-width: 767.98px) {
  .banner-section-four .content-box {
    text-align: center;
  }
}
.banner-section-four .content-box .title {
  position: relative;
  font-size: 74px;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--theme-color-light);
  margin-bottom: 25px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner-section-four .content-box .title {
    line-height: 1em;
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .banner-section-four .content-box .title {
    font-size: 54px;
  }
  .banner-section-four .content-box .title br {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .banner-section-four .content-box .title {
    font-size: 46px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-four .content-box .title {
    font-size: 40px;
  }
}
.banner-section-four .content-box .text {
  position: relative;
  color: var(--theme-color-light);
  margin-bottom: 42px;
}
@media (max-width: 1199.98px) {
  .banner-section-four .content-box .text br {
    display: none;
  }
}
.banner-section-four .content-box .theme-btn {
  padding: 22px 92px 22px 52px;
}
.banner-section-four .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .banner-section-four .slide-item .bg-image:before {
    background-color: rgba(var(--theme-color-dark-rgb), 0.54);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
.banner-section-four .slide-item .banner-shape-1 {
  background-image: url(../images/banner/verified.png);
  width: 160px;
  height: 145px;
  position: absolute;
  bottom: 199px;
  left: 86px;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .banner-section-four .slide-item .banner-shape-1 {
    bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  .banner-section-four .slide-item .banner-shape-1 {
    display: none;
  }
}
.banner-section-four .slide-item .banner-shape-curve1 {
  background-image: url(../images/banner/bg3-shape1.png);
  position: absolute;
  right: 0;
  top: 0;
  width: 477px;
  height: 903px;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .banner-section-four .slide-item .banner-shape-curve1 {
    display: none;
  }
}
.banner-section-four .slide-item .banner-shape-curve2 {
  background-image: url(../images/banner/bg3-shape2.png);
  position: absolute;
  left: 0;
  top: 0;
  width: 142px;
  height: 134px;
  pointer-events: none;
}
@media (max-width: 1399.98px) {
  .banner-section-four .slide-item .banner-shape-curve2 {
    display: none;
  }
}

/*** 

====================================================================
    Pricing Section
====================================================================

***/
.pricing-section {
  position: relative;
  padding: 120px 0 120px;
}
.pricing-section .default-dots .owl-dots {
  margin-top: 40px;
}

.pricing-block {
  margin-bottom: 30px;
}
.pricing-block .inner-box {
  position: relative;
  border-top: 0;
  overflow: hidden;
  background-color: transparent;
}
.pricing-block .inner-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background-color: #f5f5f5;
  clip-path: polygon(100% 0, 0 100%, 0 0);
  z-index: 1;
}
.pricing-block .inner-box:hover .content:before {
  height: 100%;
}
.pricing-block .inner-box:hover .feature-list li {
  color: var(--theme-color-light);
}
.pricing-block .inner-box:hover .feature-list li.colored {
  background-color: #0f0f0f;
}
.pricing-block .inner-box:hover .car-name,
.pricing-block .inner-box:hover .city {
  color: var(--theme-color-light);
}
.pricing-block .inner-box:hover .theme-btn {
  background-color: var(--theme-color1);
}
.pricing-block .inner-box .image-box {
  position: relative;
  z-index: 2;
}
.pricing-block .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  clip-path: polygon(0 27.5%, 100% 0, 100% 74%, 0 99%);
}
.pricing-block .inner-box .image-box .image:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background-color: var(--theme-color1);
  z-index: 1;
}
.pricing-block .inner-box .image-box .icon-car {
  position: absolute;
  bottom: 16px;
  right: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}
.pricing-block .inner-box .content {
  position: relative;
  padding: 120px 30px 23px 25px;
  margin-top: -100px;
  border: 1px solid #cbcbcb;
  border-top: none;
  background-color: var(--theme-color-light);
}
@media (max-width: 1199.98px) {
  .pricing-block .inner-box .content {
    padding: 120px 15px 20px;
  }
}
@media (max-width: 575.98px) {
  .pricing-block .inner-box .content {
    padding: 120px 10px 15px;
  }
}
.pricing-block .inner-box .content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block .inner-box .car-detail {
  padding-top: 10px;
  padding-left: 27px;
}
@media (max-width: 991.98px) {
  .pricing-block .inner-box .car-detail {
    padding-left: 15px;
  }
}
.pricing-block .inner-box .car-name {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 32px;
  margin-bottom: 2px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block .inner-box .city {
  position: relative;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  font-size: 21px;
  letter-spacing: 0.12em;
  margin-bottom: 26px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block .inner-box .feature-list {
  position: relative;
  margin-bottom: 16px;
}
.pricing-block .inner-box .feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 30px 8px 30px;
  margin-bottom: 2px;
  color: #444444;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .pricing-block .inner-box .feature-list li {
    padding: 8px 15px;
  }
}
.pricing-block .inner-box .feature-list li.colored {
  background-color: #f3f3f3;
}
.pricing-block .inner-box .feature-list li .price {
  position: relative;
  font-weight: 700;
  width: 75px;
  text-align: left;
}
.pricing-block .inner-box .btn-box {
  position: relative;
  margin: 0 auto;
}
.pricing-block .inner-box .theme-btn {
  margin-top: 15px;
  width: 100%;
  padding: 17px 30px 12px;
}

/*** 

====================================================================
    Pricing Section Two
====================================================================

***/
.pricing-section-two {
  position: relative;
  padding: 120px 0 120px;
}
.pricing-section-two .sec-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 65px;
}
@media (max-width: 991.98px) {
  .pricing-section-two .sec-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.pricing-section-two .sec-title .text {
  max-width: 400px;
}
@media (max-width: 991.98px) {
  .pricing-section-two .sec-title .text {
    max-width: 100%;
  }
}
.pricing-section-two .default-dots .owl-dots {
  margin-top: 40px;
}

.pricing-block-two {
  margin-bottom: 30px;
}
.pricing-block-two .inner-box {
  position: relative;
  overflow: hidden;
  background-color: var(--theme-color-light);
}
.pricing-block-two .inner-box:hover .image-box:before {
  background-color: var(--theme-color-dark);
}
.pricing-block-two .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.pricing-block-two .inner-box:hover .content .price {
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
}
.pricing-block-two .inner-box:hover .content .price:before {
  background-color: var(--theme-color-dark);
}
.pricing-block-two .inner-box .image-box {
  position: relative;
  padding-top: 14px;
}
.pricing-block-two .inner-box .image-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 80px;
  width: 190px;
  height: 257px;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .pricing-block-two .inner-box .image-box:before {
    top: -30px;
  }
}
.pricing-block-two .inner-box .image-box .image {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
}
.pricing-block-two .inner-box .image-box .image img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-two .inner-box .content {
  position: relative;
  padding: 36px 4px 0;
}
.pricing-block-two .inner-box .content .title {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 30px;
  line-height: 1.2em;
  margin-bottom: 0;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-two .inner-box .content .city {
  position: relative;
  font-family: var(--title-font);
  color: #444444;
  font-size: 21px;
  line-height: 1em;
  margin-bottom: 22px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
.pricing-block-two .inner-box .content .price {
  position: relative;
  display: inline-block;
  padding: 12px 36px 5px 20px;
  font-size: 34px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-two .inner-box .content .price small {
  font-size: 20px;
}
.pricing-block-two .inner-box .content .price:before {
  content: "";
  position: absolute;
  top: 0;
  right: -34px;
  width: 35px;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
    Pricing Section Three
====================================================================

***/
.pricing-section-three {
  position: relative;
  padding: 120px 0 90px;
}

.pricing-block-three {
  margin-bottom: 30px;
}
.pricing-block-three .inner-box {
  position: relative;
  border: 1px solid #cbcbcb;
  background-color: var(--theme-color-light);
}
.pricing-block-three .inner-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-image: url(../images/icons/bg-pricing.jpg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-three .inner-box:hover:before {
  height: 100%;
}
.pricing-block-three .inner-box:hover .content .feature-list li {
  color: #e5e5e5;
  border-color: #3f4140;
}
.pricing-block-three .inner-box:hover .content .theme-btn:before {
  border-color: transparent;
  background-color: var(--theme-color1);
}
.pricing-block-three .inner-box:hover .content .theme-btn:hover:before {
  border: 1px solid #a7a7a7;
}
.pricing-block-three .inner-box .title-box {
  position: relative;
  padding: 55px 67px 53px;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .pricing-block-three .inner-box .title-box {
    padding: 30px 30px;
  }
}
.pricing-block-three .inner-box .title-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  clip-path: polygon(13% 0, 100% 0, 86% 100%, 0% 100%);
  background-color: var(--theme-color1);
}
@media (max-width: 575.98px) {
  .pricing-block-three .inner-box .title-box:before {
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
  }
}
.pricing-block-three .inner-box .title-box .title {
  position: relative;
  font-size: 18px;
  letter-spacing: 0.13em;
  color: #444444;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.pricing-block-three .inner-box .title-box .price {
  position: relative;
  font-size: 70px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.pricing-block-three .inner-box .title-box .price sub {
  font-size: 19px;
}
.pricing-block-three .inner-box .title-box .icon-tag {
  position: absolute;
  bottom: -26px;
  right: 30px;
  width: 69px;
  height: 69px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  color: var(--theme-color1);
  background-color: var(--theme-color-dark);
  border: 2px solid var(--theme-color1);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .pricing-block-three .inner-box .title-box .icon-tag {
    right: 10px;
  }
}
.pricing-block-three .inner-box .title-box .icon {
  position: absolute;
  bottom: -26px;
  right: 30px;
  width: 69px;
  height: 69px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 28px;
  color: var(--theme-color1);
  background-color: var(--theme-color-dark);
  border: 2px solid var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .pricing-block-three .inner-box .title-box .icon {
    right: 10px;
  }
}
.pricing-block-three .inner-box .content {
  position: relative;
  padding: 45px 46px 35px;
}
@media (max-width: 1199.98px) {
  .pricing-block-three .inner-box .content {
    padding: 40px 30px;
  }
}
@media (max-width: 575.98px) {
  .pricing-block-three .inner-box .content {
    padding: 40px 15px 20px;
  }
}
.pricing-block-three .inner-box .content .feature-list {
  position: relative;
  margin-bottom: 20px;
}
.pricing-block-three .inner-box .content .feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 14px;
  margin-bottom: 15px;
  color: #444444;
  border-bottom: 1px solid #c1c1c1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-three .inner-box .content .feature-list li:last-child {
  border-bottom: none;
}
.pricing-block-three .inner-box .content .feature-list li i {
  position: relative;
  color: #4ede30;
  font-size: 20px;
  margin-right: 15px;
}
.pricing-block-three .inner-box .content .btn-box {
  position: relative;
}
.pricing-block-three .inner-box .content .btn-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 58px;
  width: 10px;
  height: 100%;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-three .inner-box .content .btn-box:hover:before {
  border: 1px solid #a7a7a7;
  background-color: var(--theme-color-dark);
}

/*** 

====================================================================
    Pricing Section Four
====================================================================

***/
.pricing-section-four {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 1199.98px) {
  .pricing-section-four {
    padding: 90px 0;
  }
}
.pricing-section-four .default-dots .owl-dots {
  margin-top: 40px;
}

.pricing-block-four {
  margin-bottom: 30px;
}
.pricing-block-four .inner-box {
  position: relative;
  border-top: 0;
  overflow: hidden;
  background-color: transparent;
}
.pricing-block-four .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.pricing-block-four .inner-box:hover .content:before {
  height: 100%;
}
.pricing-block-four .inner-box:hover .feature-list li {
  color: var(--theme-color-light);
}
.pricing-block-four .inner-box:hover .feature-list li.colored {
  background-color: #0f0f0f;
}
.pricing-block-four .inner-box:hover .car-name,
.pricing-block-four .inner-box:hover .city {
  color: var(--theme-color-light);
}
.pricing-block-four .inner-box:hover .theme-btn {
  background-color: var(--theme-color1);
}
.pricing-block-four .inner-box .image-box {
  position: relative;
  z-index: 2;
}
.pricing-block-four .inner-box .image-box:before {
  content: "";
  position: absolute;
  left: 17px;
  bottom: -18px;
  height: 100%;
  width: calc(100% - 34px);
  background-color: var(--theme-color1);
  clip-path: polygon(0 27.5%, 100% 0, 100% 74%, 0 99%);
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}
.pricing-block-four .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  clip-path: polygon(0 17.5%, 100% 0, 100% 74%, 0 99%);
}
.pricing-block-four .inner-box .image-box .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-four .inner-box .content {
  position: relative;
  padding: 120px 30px 23px;
  margin-top: -100px;
  border: 1px solid #EDEDED;
  border-top: none;
  background-color: var(--theme-color-light);
}
@media (max-width: 1199.98px) {
  .pricing-block-four .inner-box .content {
    padding: 120px 15px 20px;
  }
}
@media (max-width: 575.98px) {
  .pricing-block-four .inner-box .content {
    padding: 160px 10px 15px;
    margin-top: -141px;
  }
}
.pricing-block-four .inner-box .content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-four .inner-box .car-detail {
  padding-top: 10px;
  padding-left: 27px;
}
@media (max-width: 1199.98px) {
  .pricing-block-four .inner-box .car-detail {
    padding-left: 15px;
  }
}
.pricing-block-four .inner-box .car-name {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 32px;
  margin-bottom: 2px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .pricing-block-four .inner-box .car-name {
    font-size: 30px;
  }
}
.pricing-block-four .inner-box .city {
  position: relative;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  font-size: 21px;
  letter-spacing: 0.12em;
  margin-bottom: 26px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-four .inner-box .feature-list {
  position: relative;
  margin-bottom: 16px;
}
.pricing-block-four .inner-box .feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 30px 8px 30px;
  margin-bottom: 2px;
  color: #444444;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .pricing-block-four .inner-box .feature-list li {
    padding: 8px 15px;
  }
}
.pricing-block-four .inner-box .feature-list li.colored {
  background-color: #f3f3f3;
}
.pricing-block-four .inner-box .feature-list li .price {
  position: relative;
  font-weight: 700;
  width: 75px;
  text-align: left;
}
@media (max-width: 767.98px) {
  .pricing-block-four .inner-box .feature-list li .price {
    text-align: right;
  }
}
.pricing-block-four .inner-box .btn-box {
  position: relative;
  margin: 0 auto;
  padding: 0 15px 17px 15px;
}
.pricing-block-four .inner-box .theme-btn {
  border: 1px solid #040404;
  margin-top: 15px;
  padding: 20.5px 88px;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .pricing-block-four .inner-box .theme-btn {
    padding: 20px 25px;
  }
}

/*** 

====================================================================
    Pricing Section Five
====================================================================

***/
.pricing-section-five {
  position: relative;
  padding: 120px 0 110px;
}
@media (max-width: 1199.98px) {
  .pricing-section-five {
    padding: 100px 0 70px;
  }
}
@media (max-width: 991.98px) {
  .pricing-section-five {
    padding: 84px 0 60px;
  }
}
.pricing-section-five .sec-title {
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .pricing-section-five .sec-title {
    margin-bottom: 20px;
  }
}
.pricing-section-five .sec-title h2 {
  font-size: 48px;
  line-height: 1.45;
}
@media (max-width: 1199.98px) {
  .pricing-section-five .sec-title h2 {
    font-size: 47px;
  }
}
@media (max-width: 575.98px) {
  .pricing-section-five .sec-title h2 {
    font-size: 36px;
  }
}
.pricing-section-five .outer-box {
  padding-left: 60px;
  position: relative;
}
@media (max-width: 991.98px) {
  .pricing-section-five .outer-box {
    padding-left: 0;
    margin-top: 40px;
  }
}
.pricing-section-five .outer-box .image {
  position: absolute;
  left: -300px;
  bottom: 64px;
}
@media (max-width: 991.98px) {
  .pricing-section-five .outer-box .image {
    display: none;
  }
}

.pricing-block-five {
  margin-bottom: 30px;
}
.pricing-block-five .inner-box {
  position: relative;
  border-top: 0;
  background-color: transparent;
}
.pricing-block-five .inner-box::before {
  background-color: var(--theme-color1);
  content: "";
  width: 133px;
  height: calc(100% + 40px);
  right: 135px;
  position: absolute;
  top: -20px;
  -webkit-transform: skewX(-11deg);
          transform: skewX(-11deg);
  z-index: -1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .pricing-block-five .inner-box::before {
    right: 74px;
    -webkit-transform: skewX(-7deg);
            transform: skewX(-7deg);
  }
}
@media (max-width: 991.98px) {
  .pricing-block-five .inner-box::before {
    display: none;
  }
}
.pricing-block-five .inner-box.active::before {
  display: none;
}
.pricing-block-five .inner-box:hover::before, .pricing-block-five .inner-box.active::before {
  background-color: var(--theme-color-dark);
}
.pricing-block-five .inner-box:hover .content:before, .pricing-block-five .inner-box.active .content:before {
  height: 0;
}
.pricing-block-five .inner-box:hover .content:after, .pricing-block-five .inner-box.active .content:after {
  top: -7px;
}
.pricing-block-five .inner-box:hover .feature-list li, .pricing-block-five .inner-box.active .feature-list li {
  color: var(--theme-color-dark);
}
.pricing-block-five .inner-box:hover .feature-list li:before, .pricing-block-five .inner-box:hover .feature-list li:after, .pricing-block-five .inner-box.active .feature-list li:before, .pricing-block-five .inner-box.active .feature-list li:after {
  background-color: var(--theme-color-dark);
}
.pricing-block-five .inner-box:hover .feature-list li.colored, .pricing-block-five .inner-box.active .feature-list li.colored {
  background-color: #0f0f0f;
}
.pricing-block-five .inner-box:hover .car-name,
.pricing-block-five .inner-box:hover .city, .pricing-block-five .inner-box.active .car-name,
.pricing-block-five .inner-box.active .city {
  color: var(--theme-color-dark);
}
.pricing-block-five .inner-box:hover .theme-btn, .pricing-block-five .inner-box.active .theme-btn {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}
.pricing-block-five .inner-box:hover .theme-btn.hover-light:hover, .pricing-block-five .inner-box.active .theme-btn.hover-light:hover {
  color: var(--theme-color-dark);
}
.pricing-block-five .inner-box:hover .theme-btn.dark-line-two:before, .pricing-block-five .inner-box.active .theme-btn.dark-line-two:before {
  background-color: var(--theme-color1);
}
.pricing-block-five .inner-box .content {
  position: relative;
  padding: 34px 30px 50px;
  background-color: var(--theme-color1);
  overflow: hidden;
}
.pricing-block-five .inner-box .content:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-five .inner-box .content::after {
  background-image: url(../images/icons/shape-3.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 7px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-five .inner-box .content .pricing-tag {
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
  font-family: var(--title-font);
  font-weight: 500;
  display: inline-block;
  padding: 4px 10px 4px 30px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 12% 100%);
  position: absolute;
  top: 53px;
  right: -18px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  pointer-events: none;
  z-index: 1;
}
.pricing-block-five .inner-box .car-detail {
  padding-top: 10px;
  margin-bottom: 58px;
}
.pricing-block-five .inner-box .car-name {
  position: relative;
  color: var(--theme-color-light);
  font-size: 32px;
  margin-bottom: 2px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .pricing-block-five .inner-box .car-name {
    font-size: 24px;
    padding-right: 45px;
  }
}
.pricing-block-five .inner-box .city {
  position: relative;
  font-family: var(--title-font);
  color: var(--theme-color-light);
  font-size: 21px;
  margin-bottom: 26px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-five .inner-box .feature-list {
  position: relative;
  margin-bottom: 76px;
}
.pricing-block-five .inner-box .feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 0 8px 30px;
  margin-bottom: 2px;
  color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .pricing-block-five .inner-box .feature-list li {
    padding: 8px 0 8px 30px;
  }
}
.pricing-block-five .inner-box .feature-list li:before {
  background-color: var(--theme-color1);
  content: "";
  width: 6px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-five .inner-box .feature-list li:after {
  background-color: var(--theme-color1);
  content: "";
  width: 2px;
  height: 13px;
  left: 9px;
  position: absolute;
  top: 50%;
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-block-five .inner-box .feature-list li.colored {
  background-color: #f3f3f3;
}
.pricing-block-five .inner-box .feature-list li .price {
  position: relative;
  font-weight: 700;
  min-width: 75px;
  text-align: right;
}
.pricing-block-five .inner-box .btn-box {
  position: relative;
  margin: 0 auto;
}
.pricing-block-five .inner-box .theme-btn {
  position: relative;
}
.pricing-block-five .inner-box .theme-btn.dark-line-two:before {
  top: 0;
  height: calc(100% + 1px);
}

/*** 

====================================================================
    Benefit Section
====================================================================

***/
.benefit-section {
  position: relative;
  padding: 120px 0 80px;
}
.benefit-section .sec-title .sub-title {
  margin-bottom: 5px;
}
.benefit-section:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1100px;
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(18% 0, 100% 0, 80% 100%, 0% 100%);
  z-index: 1;
}
@media (max-width: 991.98px) {
  .benefit-section:before {
    display: none;
  }
}
.benefit-section .image-column {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .benefit-section .image-column {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
.benefit-section .image-column .inner-column {
  position: relative;
  margin-top: -85px;
  margin-left: -35px;
  margin-right: -45px;
}
@media (max-width: 991.98px) {
  .benefit-section .image-column .inner-column {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.benefit-section .image-column .inner-column .image {
  position: relative;
  display: block;
  margin-bottom: 0;
  clip-path: polygon(31% 0, 100% 0, 69% 100%, 0% 100%);
}
@media (max-width: 991.98px) {
  .benefit-section .image-column .inner-column .image {
    clip-path: none;
    margin: 0 auto;
  }
}
.benefit-section .blocks-column {
  position: relative;
  z-index: 2;
}
.benefit-section .blocks-column.style-two .inner-column {
  margin-left: 20px;
}
@media (max-width: 991.98px) {
  .benefit-section .blocks-column.style-two .inner-column {
    margin-left: 0;
  }
}

.benefit-block {
  margin-bottom: 65px;
}
@media (max-width: 767.98px) {
  .benefit-block {
    margin-bottom: 30px;
  }
}
.benefit-block .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.benefit-block .inner-box .icon-check {
  position: relative;
  margin-right: 15px;
}
.benefit-block .inner-box .icon {
  position: relative;
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 28px;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 2px;
  color: var(--theme-color1);
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-block .inner-box .title {
  position: relative;
  font-size: 30px;
  margin-bottom: 5px;
}
.benefit-block .inner-box .text {
  position: relative;
  max-width: 265px;
  margin-bottom: 0;
}

/*** 

====================================================================
    Benefit Section Two
====================================================================

***/
.benefit-section-two {
  position: relative;
  padding: 120px 0 54px;
}
@media (max-width: 1199.98px) {
  .benefit-section-two {
    padding: 84px 0 15px;
  }
}
@media (max-width: 767.98px) {
  .benefit-section-two {
    padding: 84px 0 50px;
  }
}
.benefit-section-two .sec-title .sub-title {
  margin-bottom: 5px;
}
.benefit-section-two .image-column {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .benefit-section-two .image-column {
    display: none;
  }
}
.benefit-section-two .image-column .inner-column {
  position: relative;
  margin-top: 0;
}
.benefit-section-two .image-column .inner-column:before {
  background-color: var(--theme-color-dark);
  content: "";
  width: 255px;
  height: 100%;
  max-height: 498px;
  right: 135px;
  position: absolute;
  top: 50%;
  -webkit-transform: skewX(-18deg) translateY(-50%);
          transform: skewX(-18deg) translateY(-50%);
  z-index: -1;
}
.benefit-section-two .image-column .inner-column .image {
  position: relative;
  display: block;
  margin-bottom: 0;
  text-align: center;
}
.benefit-section-two .image-column .inner-column .image img {
  width: auto;
}
.benefit-section-two .blocks-column {
  position: relative;
  z-index: 2;
}
.benefit-section-two .blocks-column.style-two .inner-column {
  margin-left: 90px;
}
@media (max-width: 1199.98px) {
  .benefit-section-two .blocks-column.style-two .inner-column {
    margin-left: 60px;
  }
}
@media (max-width: 991.98px) {
  .benefit-section-two .blocks-column.style-two .inner-column {
    margin-left: 0;
  }
}

.benefit-block-two {
  margin-bottom: 65px;
}
@media (max-width: 767.98px) {
  .benefit-block-two {
    margin-bottom: 30px;
  }
}
.benefit-block-two .inner-box {
  position: relative;
}
@media (max-width: 575.98px) {
  .benefit-block-two .inner-box {
    text-align: center;
  }
}
.benefit-block-two .inner-box:hover .icon:after {
  left: 0px;
  width: 55px;
}
.benefit-block-two .inner-box .icon-check {
  position: relative;
  margin-right: 15px;
}
.benefit-block-two .inner-box .icon {
  color: var(--theme-color-dark);
  display: inline-block;
  position: relative;
  font-size: 54px;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-block-two .inner-box .icon:after {
  background-color: var(--theme-color1);
  content: "";
  width: 20px;
  height: calc(100% + 10px);
  left: 34px;
  position: absolute;
  top: -5px;
  -webkit-transform: skewX(-14deg);
  transform: skewX(-14deg);
  z-index: -1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-block-two .inner-box .title {
  position: relative;
  margin-bottom: 15px;
}
.benefit-block-two .inner-box .text {
  position: relative;
  max-width: 285px;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .benefit-block-two .inner-box .text {
    margin: 0 auto;
  }
}

/*** 

====================================================================
    Benefit Section Three
====================================================================

***/
.benefit-section-three {
  background-color: #f5f5f5;
  position: relative;
  padding: 120px 0 105px;
}
@media (max-width: 1199.98px) {
  .benefit-section-three {
    padding: 90px 0 75px;
  }
}
@media (max-width: 991.98px) {
  .benefit-section-three {
    padding: 84px 0 75px;
  }
}
.benefit-section-three .bg-image {
  pointer-events: none;
}
.benefit-section-three .bg-image:before {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
@media (max-width: 1399.98px) {
  .benefit-section-three .bg-image:before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .benefit-section-three .image-column {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .benefit-section-three .image-column .inner-column {
    max-width: 555px;
    margin: 0 auto;
  }
}
.benefit-section-three .image-column .inner-column .image {
  margin-left: -100px;
  position: relative;
}
.benefit-section-three .image-column .inner-column .image:after {
  background-color: var(--theme-color1);
  content: "";
  width: 120px;
  height: 237px;
  right: 40px;
  position: absolute;
  top: 0;
  -webkit-transform: skewX(19.7deg);
          transform: skewX(19.7deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-section-three .content-column .inner-column {
  padding-left: 30px;
  margin-top: -7px;
}
@media (max-width: 1199.98px) {
  .benefit-section-three .content-column .inner-column {
    margin-bottom: 15px;
  }
}
@media (max-width: 991.98px) {
  .benefit-section-three .content-column .inner-column {
    padding-left: 0;
    margin-top: 0;
  }
}
.benefit-section-three .content-column .inner-column .sec-title {
  margin-bottom: 38px;
}
.benefit-section-three .content-column .inner-column .benefit-box {
  margin-bottom: 30px;
}

.benefit-block-three .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 21px;
}
@media (max-width: 575.98px) {
  .benefit-block-three .inner-box {
    display: block;
  }
}
.benefit-block-three .inner-box .icon-check {
  position: relative;
  margin-right: 15px;
}
.benefit-block-three .inner-box .number {
  color: rgba(var(--theme-color-light-rgb), 0.6);
  position: relative;
  font-size: 70px;
  line-height: 1;
  border-radius: 50%;
  padding-left: 65px;
  margin-right: 35px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-block-three .inner-box .number:before {
  background-color: var(--theme-color1);
  border: 1px solid var(--theme-color-dark);
  content: "";
  width: 20px;
  height: 38px;
  left: 0;
  position: absolute;
  top: calc(50% - 6px);
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}
.benefit-block-three .inner-box .number:after {
  background-color: var(--theme-color-light);
  content: "";
  width: 26px;
  height: 47px;
  left: 9px;
  position: absolute;
  top: calc(50% - 6px);
  -webkit-transform: skewX(-22deg) translateY(-50%);
          transform: skewX(-22deg) translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.benefit-block-three .inner-box .title {
  color: var(--theme-color-light);
  margin-bottom: 8px;
}
.benefit-block-three .inner-box .text {
  color: #B8B8B8;
  position: relative;
  margin-bottom: 0;
}

/*** 

====================================================================
    Video Section One
====================================================================

***/
.video-section-one {
  position: relative;
  padding: 100px 0 113px;
}
.video-section-one.pull-bottom {
  padding-bottom: 309px;
}
.video-section-one.pull-bottom .bg-image {
  background-attachment: fixed;
}
.video-section-one .bg-image::before {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
@media (max-width: 767.98px) {
  .video-section-one .bg-image::before {
    display: none;
  }
}
.video-section-one .bg-image::after {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
@media (max-width: 767.98px) {
  .video-section-one .bg-image::after {
    display: none;
  }
}
.video-section-one .content-column .inner-column {
  position: relative;
  text-align: center;
}
.video-section-one .content-column .inner-column .sec-title {
  margin-bottom: 0;
}
.video-section-one .content-column .inner-column .sec-title h2 {
  line-height: 1.2;
}
.video-section-one .content-column .inner-column .video-box {
  display: inline-block;
  position: relative;
  margin-bottom: 35px;
}
.video-section-one .content-column .inner-column .video-box .image {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: calc(50% + 12px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}
.video-section-one .content-column .inner-column .video-box .play-btn .icon-triangle {
  background-color: var(--theme-color1);
  border: none;
  font-size: 24px;
  height: 147px;
  width: 147px;
}

/*** 

====================================================================
    Video Section Two
====================================================================

***/
.video-section-two {
  position: relative;
  padding: 308px 0 273px;
}
@media (max-width: 1199.98px) {
  .video-section-two {
    padding: 200px 0 165px;
  }
}
@media (max-width: 575.98px) {
  .video-section-two {
    padding: 120px 0 90px;
  }
}
.video-section-two::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -33px;
  height: 100%;
  width: 254px;
  background-color: rgba(var(--theme-color1-rgb), 0.2);
  -webkit-transform: skew(-19deg);
          transform: skew(-19deg);
  pointer-events: none;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .video-section-two::before {
    display: none;
  }
}
.video-section-two::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -33px;
  height: 100%;
  width: 254px;
  background-color: rgba(var(--theme-color1-rgb), 0.2);
  -webkit-transform: skew(-19deg);
          transform: skew(-19deg);
  pointer-events: none;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .video-section-two::after {
    display: none;
  }
}
.video-section-two .bg-image {
  background-attachment: fixed;
}
.video-section-two .bg-image::before {
  background-color: rgba(var(--theme-color-dark-rgb), 0.6);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.video-section-two .bg-image::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 200px;
  height: 199px;
  width: 112px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-19deg) translateY(-50%);
          transform: skew(-19deg) translateY(-50%);
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .video-section-two .bg-image::after {
    display: none;
  }
}
.video-section-two .content {
  position: relative;
  text-align: center;
}
.video-section-two .content .video-box {
  display: inline-block;
  position: relative;
  margin-bottom: 35px;
}
.video-section-two .content .video-box .image {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: calc(50% + 12px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}
.video-section-two .content .video-box .play-btn .icon-triangle {
  background-color: var(--theme-color1);
  outline: 1px solid var(--theme-color1);
  outline-offset: 30px;
  border: none;
  font-size: 24px;
  font-family: var(--title-font);
  text-transform: uppercase;
  height: 160px;
  width: 160px;
}

/*** 
====================================================================
  About Section
====================================================================
***/
.about-section {
  padding: 120px 0 22px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section {
    padding: 120px 0 29px;
  }
}
.about-section .content-column {
  margin-bottom: 50px;
}
.about-section .content-column .inner-column {
  padding-left: 65px;
  padding-top: 44px;
  position: relative;
}
@media only screen and (max-width: 1699px) {
  .about-section .content-column .inner-column {
    padding-left: 30px;
  }
}
@media (max-width: 1199.98px) {
  .about-section .content-column .inner-column {
    padding-left: 0;
    padding-top: 0;
  }
}
.about-section .content-column .inner-column .sec-title {
  margin-bottom: 30px;
}
.about-section .content-column .inner-column .blockquote-style-two {
  margin-bottom: 0;
}
.about-section .content-column .inner-column .bottom-box {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 40px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section .content-column .inner-column .bottom-box {
    margin-left: 0;
    padding-top: 30px;
  }
}
@media (max-width: 991.98px) {
  .about-section .content-column .inner-column .bottom-box {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (max-width: 575.98px) {
  .about-section .content-column .inner-column .bottom-box {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.about-section .content-column .inner-column .bottom-box .video-box::before {
  background-color: var(--theme-color1);
  content: "";
  height: calc(100% + 30px);
  left: 62px;
  position: absolute;
  top: -15px;
  -webkit-transform: skewX(-15deg);
          transform: skewX(-15deg);
  width: 75px;
  z-index: -1;
}
.about-section .content-column .inner-column .bottom-box .video-box .image {
  margin-bottom: 0;
}
.about-section .content-column .inner-column .bottom-box .video-box .play-btn {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.about-section .content-column .inner-column .bottom-box .video-box .play-btn .icon-triangle {
  border-width: 3px;
  height: 70px;
  width: 70px;
  font-size: 16px;
}
.about-section .content-column .inner-column .bottom-box .features-outer {
  padding-top: 25px;
  margin-right: 30px;
}
@media (max-width: 575.98px) {
  .about-section .content-column .inner-column .bottom-box .features-outer {
    margin-bottom: 50px;
  }
}
.about-section .content-column .inner-column .bottom-box .features-outer .theme-btn {
  margin-top: 16px;
}
.about-section .content-column .inner-column .feature-block {
  color: var(--theme-color-dark);
  font-size: 24px;
  font-family: var(--title-font);
  margin-bottom: 30px;
}
.about-section .content-column .inner-column .feature-block .icon {
  font-size: 30px;
  margin-right: 10px;
}
.about-section .image-column {
  margin-bottom: 50px;
}
.about-section .image-column .inner-column {
  margin-bottom: 82px;
  margin-top: 50px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section .image-column .inner-column {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.about-section .image-column .inner-column:before {
  background-color: var(--theme-color1);
  content: "";
  height: calc(100% + 78px);
  left: 115px;
  position: absolute;
  top: -44px;
  -webkit-transform: skewX(-19deg);
          transform: skewX(-19deg);
  width: 273px;
}
@media (max-width: 1199.98px) {
  .about-section .image-column .inner-column:before {
    display: none;
  }
}
.about-section .image-column .inner-column .image,
.about-section .image-column .inner-column .image-2 {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
}
.about-section .image-column .inner-column .image img,
.about-section .image-column .inner-column .image-2 img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .about-section .image-column .inner-column .image {
    height: 600px;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .about-section .image-column .inner-column .image {
    height: auto;
  }
}
.about-section .image-column .inner-column .image-2 {
  position: absolute;
  right: 38px;
  top: 33px;
}
@media (max-width: 1199.98px) {
  .about-section .image-column .inner-column .image-2 {
    display: none;
  }
}
.about-section .image-column .inner-column .image-3 {
  bottom: -79px;
  position: absolute;
  right: 19px;
}
@media (max-width: 1199.98px) {
  .about-section .image-column .inner-column .image-3 {
    display: none;
  }
}

/*** 
====================================================================
  About Section Two
====================================================================
***/
.about-section-two {
  padding: 120px 0 70px;
  position: relative;
}
.about-section-two .sec-title {
  margin-bottom: 63px;
}
.about-section-two .content-column {
  margin-bottom: 50px;
}
.about-section-two .content-column .inner-column {
  padding-left: 10px;
  padding-top: 25px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-two .content-column .inner-column {
    padding-left: 0;
    padding-top: 0;
  }
}
.about-section-two .content-column .inner-column .title {
  border-bottom: 5px solid var(--theme-color1);
  display: inline-block;
  line-height: 0.7em;
  position: relative;
  text-transform: uppercase;
}
.about-section-two .content-column .inner-column .text {
  color: #444444;
  padding-right: 70px;
  position: relative;
}
@media (max-width: 991.98px) {
  .about-section-two .content-column .inner-column .text {
    padding-right: 0;
  }
}
.about-section-two .content-column .inner-column .title.two {
  margin-top: 55px;
  margin-bottom: 35px;
}
.about-section-two .content-column .inner-column .theme-btn {
  margin-top: 30px;
}
.about-section-two .image-column {
  margin-bottom: 50px;
}
.about-section-two .image-column .inner-column {
  margin-bottom: 83px;
  position: relative;
}
@media (max-width: 991.98px) {
  .about-section-two .image-column .inner-column {
    margin-bottom: 0;
  }
}
.about-section-two .image-column .inner-column:before {
  background-color: var(--theme-color1);
  clip-path: polygon(45% 0, 100% 0, 55% 100%, 0% 100%);
  content: "";
  left: 85px;
  height: 528px;
  position: absolute;
  top: -21px;
  width: 492px;
}
@media (max-width: 1199.98px) {
  .about-section-two .image-column .inner-column:before {
    display: none;
  }
}
.about-section-two .image-column .inner-column .image,
.about-section-two .image-column .inner-column .image-2 {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-two .image-column .inner-column .image,
  .about-section-two .image-column .inner-column .image-2 {
    height: 500px;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .about-section-two .image-column .inner-column .image,
  .about-section-two .image-column .inner-column .image-2 {
    height: 600px;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .about-section-two .image-column .inner-column .image,
  .about-section-two .image-column .inner-column .image-2 {
    height: auto;
  }
}
.about-section-two .image-column .inner-column .image img,
.about-section-two .image-column .inner-column .image-2 img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  width: 100%;
}
.about-section-two .image-column .inner-column .image-2 {
  position: absolute;
  right: 23px;
  top: 93px;
}
@media (max-width: 1199.98px) {
  .about-section-two .image-column .inner-column .image-2 {
    display: none;
  }
}
.about-section-two .image-column .inner-column .exp-box {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--theme-color-light);
  border: 2px solid var(--theme-color1);
  bottom: -40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 45px;
  padding: 25px 40px 22px;
  position: absolute;
}
@media (max-width: 1199.98px) {
  .about-section-two .image-column .inner-column .exp-box {
    bottom: 20px;
    left: 20px;
  }
}
@media (max-width: 575.98px) {
  .about-section-two .image-column .inner-column .exp-box {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    left: 15px;
    padding: 20px 20px;
  }
}
.about-section-two .image-column .inner-column .exp-box .count {
  color: var(--theme-color-dark);
  font-size: 83px;
  margin-bottom: 0;
  margin-right: 15px;
  position: relative;
}
@media (max-width: 575.98px) {
  .about-section-two .image-column .inner-column .exp-box .count {
    font-size: 60px;
    margin-right: 0;
  }
}
.about-section-two .image-column .inner-column .exp-box .count small {
  font-size: 86px;
}
@media (max-width: 575.98px) {
  .about-section-two .image-column .inner-column .exp-box .count small {
    font-size: 70px;
    margin-right: 0;
  }
}
.about-section-two .image-column .inner-column .exp-box .title {
  color: var(--theme-color-dark);
  margin-bottom: 0;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  .about-section-two .image-column .inner-column .exp-box .title {
    font-size: 20px;
  }
}

/*** 

====================================================================
    About Section Three
====================================================================

***/
.about-section-three {
  position: relative;
  padding: 120px 0 0;
  margin-bottom: 20px;
}
.about-section-three:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 195px;
  width: 480px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .about-section-three:before {
    display: none;
  }
}
.about-section-three .outer-box {
  position: relative;
  z-index: 2;
}
.about-section-three .outer-box .content-column {
  margin-bottom: 50px;
}
.about-section-three .outer-box .content-column .inner-column {
  position: relative;
  padding-top: 35px;
  padding-left: 38px;
}
@media (max-width: 1399.98px) {
  .about-section-three .outer-box .content-column .inner-column {
    padding-left: 0;
  }
}
@media (max-width: 1199.98px) {
  .about-section-three .outer-box .content-column .inner-column {
    padding-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .about-section-three .outer-box .content-column .inner-column .sec-title {
    margin-bottom: 30px;
  }
}
.about-section-three .outer-box .content-column .inner-column .sec-title .sub-title {
  margin-bottom: 10px;
}
.about-section-three .outer-box .content-column .inner-column .sec-title h2 {
  margin-right: -30px;
  line-height: 1em;
}
@media (max-width: 1399.98px) {
  .about-section-three .outer-box .content-column .inner-column .sec-title h2 {
    margin-right: 0;
  }
}
.about-section-three .outer-box .content-column .inner-column .sec-title .text {
  margin-top: 20px;
}
@media (max-width: 1199.98px) {
  .about-section-three .outer-box .content-column .inner-column .sec-title .text {
    margin-top: 10px;
  }
}
.about-section-three .outer-box .content-column .inner-column .bottom-box {
  padding-top: 18px;
  padding-left: 149px;
}
@media (max-width: 1199.98px) {
  .about-section-three .outer-box .content-column .inner-column .bottom-box {
    padding-top: 0;
    padding-left: 0;
  }
}
.about-section-three .outer-box .content-column .inner-column .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 28px;
}
@media (max-width: 575.98px) {
  .about-section-three .outer-box .content-column .inner-column .author-box {
    margin-bottom: 15px;
  }
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-image {
  position: relative;
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-image img {
  position: relative;
  z-index: 1;
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-image:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -2px;
  width: 100px;
  height: 108px;
  clip-path: polygon(45% 0, 100% 0, 55% 100%, 0% 100%);
  background-color: var(--theme-color1);
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-info {
  position: relative;
  padding-top: 5px;
  padding-left: 16px;
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-info .name {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1px;
  font-family: var(--text-font);
}
.about-section-three .outer-box .content-column .inner-column .author-box .author-info .designation {
  position: relative;
  color: var(--theme-color-dark);
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.about-section-three .outer-box .content-column .inner-column .author-box .sign.hide-sm {
  position: relative;
  margin-left: 23px;
}
@media (max-width: 575.98px) {
  .about-section-three .outer-box .content-column .inner-column .author-box .sign.hide-sm {
    margin-left: 0;
    display: none;
  }
}
.about-section-three .outer-box .content-column .inner-column .sign.hide-lg {
  display: none;
}
@media (max-width: 575.98px) {
  .about-section-three .outer-box .content-column .inner-column .sign.hide-lg {
    display: block;
    margin-bottom: 10px;
  }
}
.about-section-three .outer-box .image-column {
  margin-bottom: 50px;
}
.about-section-three .outer-box .image-column .inner-column {
  position: relative;
}
.about-section-three .outer-box .image-column .inner-column .image {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .about-section-three .outer-box .image-column .inner-column .image {
    width: 100%;
    height: 700px;
  }
}
@media (max-width: 575.98px) {
  .about-section-three .outer-box .image-column .inner-column .image {
    height: auto;
  }
}
.about-section-three .outer-box .image-column .inner-column .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-section-three .outer-box .image-column .inner-column .video-box {
  position: absolute;
  right: -155px;
  bottom: 65px;
}
@media (max-width: 1199.98px) {
  .about-section-three .outer-box .image-column .inner-column .video-box {
    display: none;
  }
}
.about-section-three .outer-box .image-column .inner-column .video-box .image {
  border: 1px solid #cecece;
}
.about-section-three .outer-box .image-column .inner-column .video-box .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.about-section-three .outer-box .image-column .inner-column .video-box .play-btn .icon-triangle {
  height: 72px;
  width: 72px;
}
.about-section-three .fun-fact-two {
  position: relative;
  bottom: -20px;
  margin-top: 20px;
}
.about-section-three .fun-fact-two .fact-counter {
  position: relative;
  border: 1px solid #cecece;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  background-color: var(--theme-color-light);
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .about-section-three .fun-fact-two .fact-counter {
    padding: 0px 15px;
  }
}
@media (max-width: 991.98px) {
  .about-section-three .fun-fact-two .fact-counter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
  }
}

.counter-block-two:first-child .inner-box:before {
  left: -100px;
}
.counter-block-two:last-child .inner-box:before {
  right: -100px;
}
.counter-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 53px 42px 53px 42px;
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .counter-block-two .inner-box {
    padding: 50px 15px;
  }
}
@media (max-width: 575.98px) {
  .counter-block-two .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.counter-block-two .inner-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  right: -30px;
  height: 100%;
  opacity: 0;
  background-color: var(--theme-color-dark);
  clip-path: polygon(18% 0, 100% 0, 83% 100%, 0% 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .counter-block-two .inner-box:before {
    clip-path: none;
  }
}
.counter-block-two .inner-box:hover:before {
  opacity: 1;
}
.counter-block-two .inner-box:hover .counter-one,
.counter-block-two .inner-box:hover .counter-text {
  color: var(--theme-color-light);
}
.counter-block-two .inner-box:hover .icon-box:before {
  opacity: 0.2;
}
.counter-block-two .inner-box:hover .icon-box .icon {
  color: var(--theme-color-light);
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.counter-block-two .inner-box .icon-box {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 20px;
}
@media (max-width: 575.98px) {
  .counter-block-two .inner-box .icon-box {
    margin-right: 0;
  }
}
.counter-block-two .inner-box .icon-box.two:before {
  top: 3px;
  right: auto;
  left: -17px;
}
.counter-block-two .inner-box .icon-box.three:before {
  right: 50%;
  -webkit-transform: translate(50%);
          transform: translate(50%);
}
.counter-block-two .inner-box .icon-box:before {
  content: "";
  position: absolute;
  top: 5px;
  right: -5px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-two .inner-box .icon-box .icon {
  position: relative;
  display: inline-block;
  color: var(--theme-color-dark);
  font-size: 85px;
  line-height: 1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .counter-block-two .inner-box .icon-box .icon {
    font-size: 70px;
  }
}
.counter-block-two .inner-box .counter-one {
  position: relative;
  font-size: 50px;
  line-height: 1em;
  letter-spacing: 0.04em;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-two .inner-box .counter-text {
  position: relative;
  font-size: 20px;
  line-height: 1em;
  color: var(--theme-color-dark);
  margin-bottom: 0;
  margin-top: -5px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 
====================================================================
  About Section Four
====================================================================
***/
.about-section-four {
  padding: 104px 0 101px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-four {
    padding: 60px 0 100px;
  }
}
@media (max-width: 991.98px) {
  .about-section-four {
    padding: 0 0 70px;
  }
}
@media (max-width: 767.98px) {
  .about-section-four {
    padding: 84px 0 70px;
  }
}
@media (max-width: 1199.98px) {
  .about-section-four .content-column {
    margin-bottom: 50px;
  }
}
.about-section-four .content-column .inner-column {
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-four .content-column .inner-column {
    padding-top: 0;
  }
}
.about-section-four .content-column .inner-column .sec-title {
  margin-bottom: 40px;
}
.about-section-four .content-column .inner-column .sec-title .text {
  color: var(--text-color);
  padding-right: 135px;
  margin-top: 28px;
}
@media (max-width: 1199.98px) {
  .about-section-four .content-column .inner-column .sec-title .text {
    padding-right: 0;
  }
}
.about-section-four .fun-fact-column .inner-column {
  padding-top: 18px;
  padding-left: 23px;
  margin-right: 30px;
}
@media (max-width: 1199.98px) {
  .about-section-four .fun-fact-column .inner-column {
    padding-top: 0;
  }
}
@media (max-width: 991.98px) {
  .about-section-four .fun-fact-column .inner-column {
    padding-left: 0;
  }
}
.about-section-four .fun-fact-column .counter-outer-box {
  position: relative;
}
@media (max-width: 991.98px) {
  .about-section-four .fun-fact-column .counter-outer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 767.98px) {
  .about-section-four .fun-fact-column .counter-outer-box {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .about-section-four .fun-fact-column .counter-outer-box .counter-block-three {
    width: 100%;
    margin-right: 30px;
  }
}
@media (max-width: 991.98px) {
  .about-section-four .fun-fact-column .counter-outer-box .counter-block-three:last-child {
    margin-right: 0;
  }
}
.about-section-four .fun-fact-column .counter-outer-box .image {
  margin-bottom: 0;
  position: absolute;
  left: -195px;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .about-section-four .fun-fact-column .counter-outer-box .image {
    display: none;
  }
}
.about-section-four .fun-fact-column .counter-outer-box .image img {
  width: auto;
}

.counter-block-three .inner-box {
  position: relative;
  margin-bottom: 20px;
}
.counter-block-three .inner-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 17px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-19deg);
          transform: skewX(-19deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .counter-block-three .inner-box::before {
    display: none;
  }
}
.counter-block-three .inner-box:hover::before {
  background-color: var(--theme-color-dark);
}
.counter-block-three .inner-box:hover .content {
  background-color: var(--theme-color1);
}
.counter-block-three .inner-box:hover .content::before {
  width: 100%;
}
.counter-block-three .inner-box:hover .content .counter-text {
  color: var(--text-color-bg-theme-color1);
}
.counter-block-three .inner-box:hover .content .counter-text:after, .counter-block-three .inner-box:hover .content .counter-text:before {
  background-color: var(--text-color-bg-theme-color1);
}
.counter-block-three .inner-box:hover .content .counter-one {
  color: var(--text-color-bg-theme-color1);
}
.counter-block-three .inner-box .content {
  background-color: #040404;
  padding: 25px 40px 16px 210px;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .counter-block-three .inner-box .content {
    padding: 25px 30px 16px;
  }
}
.counter-block-three .inner-box .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .counter-block-three .inner-box .content::before {
    display: none;
  }
}
.counter-block-three .inner-box .content .counter-text {
  color: var(--theme-color1);
  font-weight: 600;
  margin-bottom: 7px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-three .inner-box .content .counter-one {
  color: var(--theme-color-light);
  font-size: 48px;
  font-weight: 600;
  font-family: var(--style-font);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-three .inner-box .content .counter-one .counter-after {
  font-size: 50px;
  font-family: var(--title-font);
  position: relative;
  top: -3px;
  margin-left: 2px;
}

/*** 
====================================================================
  About Section Five
====================================================================
***/
.about-section-five {
  padding: 114px 0 85px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-five {
    padding: 84px 0 55px;
  }
}
.about-section-five .icon-car-7 {
  background-image: url(../images/icons/icon-car-7.png);
  width: 783px;
  height: 293px;
  position: absolute;
  bottom: -33px;
  right: 235px;
  pointer-events: none;
  z-index: 1;
}
@media (max-width: 1599.98px) {
  .about-section-five .icon-car-7 {
    background-size: contain;
    background-repeat: no-repeat;
    width: 553px;
    height: 221px;
    position: absolute;
    bottom: -35px;
  }
}
@media (max-width: 1199.98px) {
  .about-section-five .icon-car-7 {
    right: 35px;
  }
}
@media (max-width: 991.98px) {
  .about-section-five .icon-car-7 {
    width: 300px;
    height: 137px;
  }
}
@media (max-width: 767.98px) {
  .about-section-five .icon-car-7 {
    width: 250px;
    height: 120px;
  }
}
.about-section-five .icon-car-8 {
  background-image: url(../images/icons/icon-car-8.png);
  width: 720px;
  height: 391px;
  position: absolute;
  bottom: -33px;
  right: 140px;
  pointer-events: none;
  z-index: 1;
}
@media (max-width: 1599.98px) {
  .about-section-five .icon-car-8 {
    background-size: contain;
    background-repeat: no-repeat;
    width: 553px;
    height: 221px;
    position: absolute;
    bottom: -35px;
  }
}
@media (max-width: 1199.98px) {
  .about-section-five .icon-car-8 {
    right: 35px;
  }
}
@media (max-width: 991.98px) {
  .about-section-five .icon-car-8 {
    width: 300px;
    height: 137px;
  }
}
@media (max-width: 767.98px) {
  .about-section-five .icon-car-8 {
    width: 250px;
    height: 120px;
  }
}
.about-section-five .content-column {
  margin-bottom: 50px;
}
.about-section-five .content-column .inner-column {
  position: relative;
}
@media only screen and (max-width: 1699px) {
  .about-section-five .content-column .inner-column {
    padding-left: 30px;
  }
}
@media (max-width: 1199.98px) {
  .about-section-five .content-column .inner-column {
    padding-left: 0;
    padding-top: 0;
  }
}
.about-section-five .content-column .inner-column .sec-title {
  margin-bottom: 30px;
}
.about-section-five .content-column .inner-column .bottom-box {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 40px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about-section-five .content-column .inner-column .bottom-box {
    margin-left: 0;
    padding-top: 30px;
  }
}
@media (max-width: 575.98px) {
  .about-section-five .content-column .inner-column .bottom-box {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.about-section-five .content-column .inner-column .bottom-box .box-right-align {
  margin-left: 40px;
}
@media (max-width: 575.98px) {
  .about-section-five .content-column .inner-column .bottom-box .box-right-align {
    margin-left: 0;
    margin-top: 50px;
  }
}
.about-section-five .content-column .inner-column .bottom-box .video-box {
  min-width: 200px;
}
.about-section-five .content-column .inner-column .bottom-box .video-box::before {
  background-color: var(--theme-color-dark);
  content: "";
  height: calc(100% + 30px);
  left: 62px;
  position: absolute;
  top: -15px;
  -webkit-transform: skewX(-15deg);
          transform: skewX(-15deg);
  width: 75px;
  z-index: -1;
}
.about-section-five .content-column .inner-column .bottom-box .video-box .image {
  margin-bottom: 0;
}
.about-section-five .content-column .inner-column .bottom-box .video-box .play-btn {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.about-section-five .content-column .inner-column .bottom-box .video-box .play-btn .icon-triangle {
  border-width: 3px;
  height: 70px;
  width: 70px;
  font-size: 16px;
}
.about-section-five .content-column .inner-column .bottom-box .blockquote-style-two {
  font-size: 20px;
  margin-bottom: 35px;
  margin-right: -50px;
}
@media (max-width: 991.98px) {
  .about-section-five .content-column .inner-column .bottom-box .blockquote-style-two {
    margin-right: 0;
  }
}
.about-section-five .content-column .inner-column .bottom-box .blockquote-style-two::before {
  top: 7px;
}
.about-section-five .image-column {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 1199.98px) {
  .about-section-five .image-column {
    right: -150px;
  }
}
@media (max-width: 991.98px) {
  .about-section-five .image-column {
    display: none;
  }
}
.about-section-five .image-column .inner-column::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -23px;
  height: 100%;
  width: 153px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-18.8deg);
          transform: skew(-18.8deg);
  pointer-events: none;
}
.about-section-five .image-column .inner-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 350px;
  height: 238.5px;
  width: 121px;
  background-color: var(--theme-color-dark);
  -webkit-transform: skew(-20.5deg);
          transform: skew(-20.5deg);
  pointer-events: none;
}
.about-section-five .image-column .image {
  clip-path: polygon(49% 0%, 94% 0px, 49% 100%, 0% 100%);
  margin-bottom: 0;
}

/***

====================================================================
    Marquee Section
====================================================================

***/
.marquee-section {
  background-color: var(--theme-color1);
  position: relative;
  padding: 5px 0;
}

.marquee {
  border: 1px solid var(--theme-color-dark);
  border-left: none;
  border-right: none;
  position: relative;
  padding: 17px 0;
  --duration: 30s;
  --gap: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  gap: var(--gap);
}
.marquee .marquee-group {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  -webkit-animation: scroll var(--duration) linear infinite;
          animation: scroll var(--duration) linear infinite;
}
.marquee .text {
  color: var(--theme-color-dark);
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  padding-left: 68px;
  margin-left: 125px;
  font-family: var(--title-font);
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.marquee .text:after {
  background-image: url(../images/icons/icon-star.png);
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 44px;
  width: 44px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.marquee .text:hover:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
@media (prefers-reduced-motion: reduce) {
  .marquee .marquee-group {
    -webkit-animation-play-state: play;
            animation-play-state: play;
  }
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

/***

====================================================================
    Marquee Section Two
====================================================================

***/
.marquee-section-two {
  padding: 97px 0 120px;
}
@media (max-width: 991.98px) {
  .marquee-section-two {
    padding: 128px 0;
  }
}
@media (max-width: 767.98px) {
  .marquee-section-two {
    padding: 0;
  }
}
.marquee-section-two .marquee-two {
  -webkit-transform: rotate(-4.2deg);
          transform: rotate(-4.2deg);
  margin: 0 -25px;
}
@media (max-width: 767.98px) {
  .marquee-section-two .marquee-two {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin: 0 -25px;
  }
}
.marquee-section-two .marquee-three {
  -webkit-transform: rotate(4.2deg);
          transform: rotate(4.2deg);
  margin: 0 -25px -75px;
}
@media (max-width: 767.98px) {
  .marquee-section-two .marquee-three {
    display: none;
  }
}

.marquee-two {
  background-color: var(--theme-color1);
  position: relative;
  padding: 5px 0;
}
.marquee-two .inner-box {
  border: 1px solid var(--theme-color-dark);
  border-left: none;
  border-right: none;
  position: relative;
  padding: 17px 0;
  --duration: 30s;
  --gap: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  gap: var(--gap);
}
.marquee-two .marquee-group {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  -webkit-animation: scroll var(--duration) linear infinite;
          animation: scroll var(--duration) linear infinite;
}
.marquee-two .text {
  color: var(--theme-color-dark);
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  padding-left: 68px;
  margin-left: 125px;
  font-family: var(--title-font);
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.marquee-two .text:after {
  background-image: url(../images/icons/icon-star-2.png);
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 29px;
  width: 29px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.marquee-two .text:hover:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
@media (prefers-reduced-motion: reduce) {
  .marquee-two .marquee-group {
    -webkit-animation-play-state: play;
            animation-play-state: play;
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

.marquee-three {
  background-color: var(--theme-color-dark);
  position: relative;
  padding: 5px 0;
}
.marquee-three .inner-box {
  border: 1px solid var(--theme-color-light);
  border-left: none;
  border-right: none;
  position: relative;
  padding: 17px 0;
  --duration: 30s;
  --gap: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  gap: var(--gap);
}
.marquee-three .marquee-group {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  -webkit-animation: scroll var(--duration) linear infinite;
          animation: scroll var(--duration) linear infinite;
}
.marquee-three .marquee-group.slide-right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.marquee-three .text {
  color: var(--theme-color-light);
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  padding-left: 68px;
  margin-left: 125px;
  font-family: var(--title-font);
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.marquee-three .text:after {
  background-image: url(../images/icons/icon-star-3.png);
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 29px;
  width: 29px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.marquee-three .text:hover:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
@media (prefers-reduced-motion: reduce) {
  .marquee-three .marquee-group {
    -webkit-animation-play-state: play;
            animation-play-state: play;
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

/*** 
====================================================================
  Services Section
====================================================================
***/
.service-section {
  position: relative;
  padding: 120px 0 65px;
}
@media (max-width: 991.98px) {
  .service-section {
    padding-bottom: 90px;
  }
}
.service-section .outer-box {
  position: relative;
  padding-bottom: 20px;
}
@media (max-width: 991.98px) {
  .service-section .outer-box {
    padding-bottom: 0;
  }
}
.service-section .outer-box .bg-overlay-8 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .service-section .outer-box .bg-overlay-8 {
    display: none;
  }
}

.service-block {
  margin-bottom: 30px;
}
.service-block .inner-box {
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-bottom: 55px;
}
.service-block .inner-box::before {
  position: absolute;
  left: 80px;
  bottom: 0;
  width: 200px;
  height: 381px;
  -webkit-transform: skewX(-25deg);
  transform: skewX(-20deg);
  content: "";
  background-color: #f7f6f2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box:hover:before {
  background-color: var(--theme-color1);
  opacity: 0.3;
}
.service-block .inner-box:hover .image-box:after {
  height: 100%;
}
.service-block .inner-box:hover .image-box .image {
  border-color: var(--theme-color1);
}
.service-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.service-block .inner-box .image-box {
  position: relative;
  display: inline-block;
  padding: 14px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .service-block .inner-box .image-box {
    margin-bottom: 10px;
  }
}
.service-block .inner-box .image-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid var(--theme-color1);
}
.service-block .inner-box .image-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  border-radius: 50%;
  background: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box .image-box .image {
  position: relative;
  border-radius: 50%;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box .image-box .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 3;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box .content {
  position: relative;
  text-align: center;
}
.service-block .inner-box .content .title {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 32px;
  margin-bottom: 12px;
}
.service-block .inner-box .content .text {
  position: relative;
  margin-bottom: 0;
  max-width: 320px;
  margin: 0 auto;
}

/*** 
====================================================================
  Services Section Two
====================================================================
***/
.service-section-two {
  position: relative;
  padding: 120px 0 65px;
}
.service-section-two .btn-column .inner-column {
  margin-right: -30px;
}
@media (max-width: 1199.98px) {
  .service-section-two .btn-column .inner-column {
    margin-right: -90px;
  }
}
@media (max-width: 991.98px) {
  .service-section-two .btn-column .inner-column {
    margin-right: 0;
  }
}
.service-section-two .btn-column .inner-column .sec-title {
  margin-bottom: 42px;
}
.service-section-two .btn-column .inner-column .sec-title h2 {
  line-height: 1em;
}
.service-section-two .btn-column .inner-column .tab-btns {
  position: relative;
  display: inline-block;
  padding-left: 4px;
}
@media (max-width: 767.98px) {
  .service-section-two .btn-column .inner-column .tab-btns {
    margin-bottom: 30px;
  }
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn {
  position: relative;
  font-family: var(--title-font);
  font-size: 30px;
  line-height: 1em;
  cursor: pointer;
  margin-bottom: 55px;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .service-section-two .btn-column .inner-column .tab-btns .tab-btn {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn sup {
  position: relative;
  font-size: 20px;
  margin-right: 24px;
  color: rgba(154, 154, 154, 0.2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .service-section-two .btn-column .inner-column .tab-btns .tab-btn sup {
    margin-right: 5px;
  }
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn .icon {
  position: relative;
  opacity: 0;
  font-size: 12px;
  line-height: 1em;
  margin-left: 25px;
  color: var(--theme-color-light);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn:hover, .service-section-two .btn-column .inner-column .tab-btns .tab-btn.active-btn {
  color: var(--theme-color-light);
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn:hover sup, .service-section-two .btn-column .inner-column .tab-btns .tab-btn.active-btn sup {
  color: var(--theme-color1);
}
.service-section-two .btn-column .inner-column .tab-btns .tab-btn:hover .icon, .service-section-two .btn-column .inner-column .tab-btns .tab-btn.active-btn .icon {
  opacity: 1;
}
.service-section-two .image-box {
  margin-bottom: 50px;
}
.service-section-two .image-box .inner-box {
  position: relative;
  text-align: right;
  padding-top: 54px;
  margin-right: -13px;
}
@media (max-width: 991.98px) {
  .service-section-two .image-box .inner-box {
    margin-right: 0;
    padding-top: 0;
  }
}
.service-section-two .image-box .inner-box:before {
  content: "";
  position: absolute;
  top: 13px;
  right: 164px;
  width: 330px;
  height: 637px;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-23deg);
          transform: skewX(-23deg);
}
@media (max-width: 991.98px) {
  .service-section-two .image-box .inner-box:before {
    display: none;
  }
}
.service-section-two .image-box .inner-box .image {
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .service-section-two .image-box .inner-box .image {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .service-section-two .image-box .inner-box .image img {
    width: 100%;
  }
}
.service-section-two .image-box .inner-box .exp-box {
  position: absolute;
  bottom: 46px;
  left: 85px;
  padding: 35px 30px 44px 50px;
  max-width: 364px;
  display: inline-block;
  background-color: var(--theme-color-dark);
  border: 2px solid var(--theme-color1);
  text-align: left;
}
@media (max-width: 991.98px) {
  .service-section-two .image-box .inner-box .exp-box {
    left: 30px;
    bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .service-section-two .image-box .inner-box .exp-box {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 20px;
    margin-top: 10px;
    max-width: auto;
  }
}
.service-section-two .image-box .inner-box .exp-box .text {
  position: relative;
  color: #a4a4a4;
  margin-bottom: 26px;
}
.service-section-two .image-box .inner-box .exp-box .read-more {
  position: relative;
  font-family: var(--title-font);
  font-size: 18px;
  letter-spacing: 0.04em;
  color: var(--theme-color-light);
  border-bottom: 2px solid var(--theme-color1);
  text-transform: uppercase;
}

/*** 
====================================================================
  Services Section Three
====================================================================
***/
.service-section-three {
  position: relative;
  /* padding: 120px 0 120px; */
}
.service-section-three .sec-title {
  margin-bottom: 30px;
}
.service-section-three .default-dots .owl-dots {
  margin-top: 40px;
}

.service-block-three {
  margin-bottom: 30px;
}
.service-block-three .inner-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box:hover {
  -webkit-box-shadow: 0 10px 10px rgba(4, 4, 4, 0.08);
          box-shadow: 0 10px 10px rgba(4, 4, 4, 0.08);
}
.service-block-three .inner-box:hover .image-box .image {
  border-color: var(--theme-color1);
}
.service-block-three .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.service-block-three .inner-box:hover .image-box .icon {
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
}
.service-block-three .inner-box .image-box {
  position: relative;
}
.service-block-three .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .image-box .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .image-box .icon {
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 150px;
  height: 113px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 60px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
  clip-path: polygon(30% 0, 100% 0, 70% 100%, 0% 100%);
  z-index: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .content {
  position: relative;
  padding: 40px 40px 26px;
  border: 1px solid #cecece;
  border-top: none;
}
@media (max-width: 1199.98px) {
  .service-block-three .inner-box .content {
    padding: 30px 30px;
  }
}
@media (max-width: 575.98px) {
  .service-block-three .inner-box .content {
    padding: 30px 15px;
  }
}
.service-block-three .inner-box .content .title {
  position: relative;
  color: var(--theme-color-dark);
  margin-bottom: 17px;
}
.service-block-three .inner-box .content .title:hover {
  color: var(--theme-color2);
}
.service-block-three .inner-box .content .text {
  position: relative;
  color: #444444;
  line-height: 26px;
  margin-bottom: 20px;
}
.service-block-three .inner-box .content .icon {
  position: relative;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-dark);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .content .icon:hover {
  background-color: var(--theme-color1);
  border-color: var(--theme-color1);
}

/*** 
====================================================================
  Services Section Four
====================================================================
***/
.service-section-four {
  position: relative;
  padding: 120px 0 120px;
}
.service-section-four .service-tab .tab-btns-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1199.98px) {
  .service-section-four .service-tab .tab-btns-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}
.service-section-four .service-tab .tabs-header {
  position: relative;
  margin-top: -20px;
}
@media (max-width: 1199.98px) {
  .service-section-four .service-tab .tabs-header {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }
}
.service-section-four .service-tab .tabs-header .service-tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1199.98px) {
  .service-section-four .service-tab .tabs-header .service-tab-btns {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 50px;
  }
}
@media (max-width: 575.98px) {
  .service-section-four .service-tab .tabs-header .service-tab-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 30px;
  }
}
.service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn {
  position: relative;
  margin-left: 15px;
  font-size: 20px;
  line-height: 24px;
  color: #161616;
  font-family: var(--title-font);
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn {
    margin-left: 0;
    margin: 0 7px;
  }
}
@media (max-width: 575.98px) {
  .service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn {
    margin: 0;
    margin-bottom: 20px;
  }
}
.service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 0;
  border-bottom: 6px solid var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn.active-btn:before, .service-section-four .service-tab .tabs-header .service-tab-btns .tab-btn:hover:before {
  left: 0;
  right: auto;
  width: 100%;
}
.service-section-four .service-tab .tabs-content .default-navs-two .owl-nav {
  margin-top: -70px;
}
@media (max-width: 1199.98px) {
  .service-section-four .service-tab .tabs-content .default-navs-two .owl-nav {
    display: none !important;
  }
}
.service-section-four .service-tab .tabs-content .tab {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  visibility: hidden;
}
.service-section-four .service-tab .tabs-content .tab.active-tab {
  position: relative;
  visibility: visible;
  z-index: 5;
}

.service-block-four .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 991.98px) {
  .service-block-four .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.service-block-four .inner-box .image-box {
  position: relative;
  margin-top: 10px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.service-block-four .inner-box .image-box .bg-overlay-4 {
  position: absolute;
  top: 0;
  left: -162px;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .service-block-four .inner-box .image-box .bg-overlay-4 {
    display: none;
  }
}
.service-block-four .inner-box .image-box .icon-car-6 {
  position: absolute;
  bottom: 75px;
  left: -185px;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .service-block-four .inner-box .image-box .icon-car-6 {
    display: none;
  }
}
.service-block-four .inner-box .image-box .image {
  position: relative;
}
@media (max-width: 1199.98px) {
  .service-block-four .inner-box .image-box .image {
    margin-left: 50px;
  }
}
@media (max-width: 991.98px) {
  .service-block-four .inner-box .image-box .image {
    margin-left: 0;
    margin-top: 30px;
  }
}
.service-block-four .inner-box .content {
  position: relative;
  padding-top: 10px;
}
.service-block-four .inner-box .content .car-detail {
  position: relative;
}
.service-block-four .inner-box .content .car-name {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 38px;
  margin-bottom: 0;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-four .inner-box .content .city {
  position: relative;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  margin-top: -4px;
  font-size: 21px;
  letter-spacing: 0.12em;
  margin-bottom: 25px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-four .inner-box .content .feature-list {
  position: relative;
  margin-bottom: 16px;
}
.service-block-four .inner-box .content .feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 30px;
  margin-bottom: 2px;
  color: #444444;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .service-block-four .inner-box .content .feature-list li {
    padding: 8px 15px;
  }
}
.service-block-four .inner-box .content .feature-list li.colored {
  background-color: #f3f3f3;
}
.service-block-four .inner-box .content .feature-list li .price {
  position: relative;
  font-weight: 700;
  width: 105px;
  text-align: left;
  padding-left: 30px;
}
.service-block-four .inner-box .content .theme-btn {
  margin-top: 5px;
}

/*** 
====================================================================
  Services Section Five
====================================================================
***/
.service-section-five {
  background-color: #f5f5f5;
  position: relative;
  padding: 120px 0 60px;
}
@media (max-width: 1199.98px) {
  .service-section-five {
    padding: 90px 0 30px;
  }
}
.service-section-five .bg-image {
  background-image: url(../images/background/bg-services5.png);
  pointer-events: none;
  background-position: top center;
}
@media (max-width: 991.98px) {
  .service-section-five .bg-image {
    display: none;
  }
}
.service-section-five .bg-image:before {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
@media (max-width: 1399.98px) {
  .service-section-five .bg-image:before {
    display: none;
  }
}
.service-section-five .bg-image:after {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
@media (max-width: 1399.98px) {
  .service-section-five .bg-image:after {
    display: none;
  }
}
.service-section-five .sec-title {
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .service-section-five .sec-title {
    margin-bottom: 60px;
  }
}
.service-section-five .default-dots .owl-dots {
  margin-top: 40px;
}

.service-block-five {
  margin-bottom: 30px;
}
.service-block-five .inner-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-five .inner-box:hover .image-box:before {
  background-color: var(--theme-color-dark);
}
.service-block-five .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.service-block-five .inner-box .image-box {
  position: relative;
}
.service-block-five .inner-box .image-box:before {
  background-color: var(--theme-color1);
  clip-path: polygon(0 27.5%, 100% 0, 100% 74%, 0 99%);
  content: "";
  position: absolute;
  width: calc(100% - 50px);
  height: 100%;
  left: 30px;
  bottom: -26px;
  -webkit-transform: rotate(0.5deg);
          transform: rotate(0.5deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-five .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  clip-path: polygon(0 17.5%, 100% 0, 100% 84%, 0 99%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-five .inner-box .image-box .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-five .inner-box .content {
  position: relative;
  text-align: center;
  padding: 46px 30px 0;
}
@media (max-width: 1199.98px) {
  .service-block-five .inner-box .content {
    padding: 30px 15px 0;
  }
}
.service-block-five .inner-box .content .title {
  position: relative;
  color: var(--theme-color-dark);
  margin-bottom: 17px;
}
.service-block-five .inner-box .content .title:hover {
  color: var(--theme-color-dark);
}
.service-block-five .inner-box .content .text {
  position: relative;
  line-height: 26px;
  margin-bottom: 20px;
}

/***

====================================================================
    Service Section Six
====================================================================

***/
.service-section-six {
  background-image: url(../images/background/bg-features1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  padding: 145px 0 148px;
  position: relative;
}
@media (max-width: 991.98px) {
  .service-section-six {
    padding: 115px 0 90px;
  }
}
.service-section-six::before {
  background: var(--theme-color-dark);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.93;
  position: absolute;
  top: 0;
  width: 100%;
}
.service-section-six::after {
  background-image: url(../images/icons/shape-2.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 31px;
}
.service-section-six .sec-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 95px;
  padding-bottom: 50px;
}
@media (max-width: 991.98px) {
  .service-section-six .sec-title {
    margin-bottom: 32px;
  }
}
@media (max-width: 991.98px) {
  .service-section-six .sec-title h2 br {
    display: none;
  }
}
.service-section-six .sec-title .text {
  margin: 0;
}
@media (max-width: 991.98px) {
  .service-section-six .sec-title .text {
    margin-top: 30px;
  }
}
.service-section-six .icon-lines-3-bottom {
  right: 108px;
  bottom: 50px;
}

.service-block-six {
  position: relative;
}
.service-block-six .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 31px;
}
@media (max-width: 575.98px) {
  .service-block-six .inner-box {
    margin-bottom: 24px;
  }
}
.service-block-six .inner-box:last-child {
  margin-bottom: 0;
}
.service-block-six .inner-box:hover:before, .service-block-six .inner-box.active:before {
  color: var(--theme-color1);
}
.service-block-six .inner-box:hover .image, .service-block-six .inner-box.active .image {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  top: -38px;
}
.service-block-six .inner-box:hover .content .info-box .share-icon .icon, .service-block-six .inner-box.active .content .info-box .share-icon .icon {
  -webkit-transform: none;
          transform: none;
}
.service-block-six .inner-box:hover .title-box .name, .service-block-six .inner-box.active .title-box .name {
  color: var(--theme-color1);
}
.service-block-six .inner-box .content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0 29px 0;
  position: relative;
  max-width: 646px;
  width: 100%;
}
@media (max-width: 1109.98px) {
  .service-block-six .inner-box .content {
    max-width: 540px;
  }
}
@media (max-width: 991.98px) {
  .service-block-six .inner-box .content {
    max-width: none;
  }
}
.service-block-six .inner-box .content .info-box .share-icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 12px);
  right: 30px;
  height: 40px;
  width: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: var(--theme-color-dark);
  background-color: var(--bg-theme-color1);
  border-radius: 3px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 3;
}
@media (max-width: 575.98px) {
  .service-block-six .inner-box .content .info-box .share-icon {
    right: 5px;
  }
}
.service-block-six .inner-box .content .info-box .share-icon .icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-six .inner-box .title-box {
  position: relative;
}
@media (max-width: 1199.98px) {
  .service-block-six .inner-box .title-box {
    width: 50%;
  }
}
@media (max-width: 991.98px) {
  .service-block-six .inner-box .title-box {
    width: 100%;
  }
}
.service-block-six .inner-box .title-box .name {
  color: var(--theme-color-light);
  margin-bottom: 16px;
}
.service-block-six .inner-box .title-box .text {
  color: #B8B8B8;
  position: relative;
}
@media (max-width: 991.98px) {
  .service-block-six .inner-box .image-box {
    display: none;
  }
}
.service-block-six .inner-box .image {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  width: 430px;
  height: 546px;
  z-index: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-six .inner-box .image::before {
  background-color: var(--theme-color1);
  content: "";
  width: 156px;
  height: calc(100% + 40px);
  right: 135px;
  position: absolute;
  top: -20px;
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  z-index: -1;
}

/*** 
====================================================================
  Services Section Seven
====================================================================
***/
.service-section-seven {
  position: relative;
  padding: 162px 0 151px;
}
@media (max-width: 1199.98px) {
  .service-section-seven {
    padding: 90px 0 80px;
  }
}
.service-section-seven .btn-column .inner-column .sec-title {
  margin-bottom: 42px;
}
.service-section-seven .btn-column .inner-column .sec-title h2 {
  line-height: 1em;
}
.service-section-seven .btn-column .inner-column .tab-btns {
  position: relative;
  display: block;
}
@media (max-width: 767.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns {
    margin-bottom: 30px;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-family: var(--title-font);
  font-size: 30px;
  line-height: 1em;
  cursor: pointer;
  margin-bottom: 13px;
  color: var(--theme-color2);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
@media (max-width: 575.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn {
    font-size: 18px;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn .title {
  border: 1px solid #eee;
  display: block;
  padding: 30px 66px;
  position: relative;
  width: 100%;
  clip-path: polygon(6.8% 0, 100% 0, 100% 100%, 0% 100%);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
@media (max-width: 1199.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .title {
    padding: 30px 20px 30px 45px;
    clip-path: none;
  }
}
@media (max-width: 575.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .title {
    padding: 20px 20px 20px 15px;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn .title:after {
  border-left: 1px solid #eee;
  content: "";
  position: absolute;
  left: 16px;
  top: 0;
  width: 1px;
  height: 100%;
  -webkit-transform: skewX(-21deg);
          transform: skewX(-21deg);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
@media (max-width: 1199.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .title:after {
    display: none;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn .icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 28px 25px;
  position: relative;
  font-size: 30px;
  margin-right: -8px;
  color: var(--theme-color2);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transform: skewX(-21deg);
          transform: skewX(-21deg);
}
@media (max-width: 1199.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .icon {
    -webkit-transform: none;
            transform: none;
    margin-right: 7px;
  }
}
@media (max-width: 575.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .icon {
    padding: 14px 12px;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn .icon i {
  -webkit-transform: skewX(21deg);
          transform: skewX(21deg);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
@media (max-width: 1199.98px) {
  .service-section-seven .btn-column .inner-column .tab-btns .tab-btn .icon i {
    -webkit-transform: none;
            transform: none;
  }
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn:hover, .service-section-seven .btn-column .inner-column .tab-btns .tab-btn.active-btn {
  color: var(--theme-color1);
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn:hover .title, .service-section-seven .btn-column .inner-column .tab-btns .tab-btn.active-btn .title {
  background-color: var(--theme-color-dark);
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn:hover .title:after, .service-section-seven .btn-column .inner-column .tab-btns .tab-btn.active-btn .title:after {
  border-color: var(--theme-color-dark);
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn:hover .icon, .service-section-seven .btn-column .inner-column .tab-btns .tab-btn.active-btn .icon {
  background-color: var(--theme-color-dark);
  border-color: var(--theme-color-dark);
  color: var(--theme-color1);
}
.service-section-seven .btn-column .inner-column .tab-btns .tab-btn:hover .icon:after, .service-section-seven .btn-column .inner-column .tab-btns .tab-btn.active-btn .icon:after {
  border-color: var(--theme-color-dark);
}
.service-section-seven .image-box .inner-box {
  position: relative;
  text-align: right;
}
@media (max-width: 991.98px) {
  .service-section-seven .image-box .inner-box {
    margin-right: 0;
    padding-top: 0;
  }
}
.service-section-seven .image-box .inner-box:before {
  content: "";
  position: absolute;
  top: -42px;
  right: 164px;
  width: 214px;
  height: 650px;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-13deg);
          transform: skewX(-13deg);
}
@media (max-width: 1199.98px) {
  .service-section-seven .image-box .inner-box:before {
    display: none;
  }
}
.service-section-seven .image-box .inner-box .image {
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .service-section-seven .image-box .inner-box .image {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .service-section-seven .image-box .inner-box .image img {
    width: 100%;
  }
}
.service-section-seven .image-box .inner-box .exp-box {
  position: absolute;
  bottom: 46px;
  left: 85px;
  padding: 35px 30px 44px 50px;
  max-width: 364px;
  display: inline-block;
  background-color: var(--theme-color-dark);
  border: 2px solid var(--theme-color1);
  text-align: left;
}
@media (max-width: 991.98px) {
  .service-section-seven .image-box .inner-box .exp-box {
    left: 30px;
    bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .service-section-seven .image-box .inner-box .exp-box {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 20px;
    margin-top: 10px;
    max-width: auto;
  }
}
.service-section-seven .image-box .inner-box .exp-box .text {
  position: relative;
  color: #a4a4a4;
  margin-bottom: 26px;
}
.service-section-seven .image-box .inner-box .exp-box .read-more {
  position: relative;
  font-family: var(--title-font);
  font-size: 18px;
  letter-spacing: 0.04em;
  color: var(--theme-color-light);
  border-bottom: 2px solid var(--theme-color1);
  text-transform: uppercase;
}

/*** 

====================================================================
    Funfacts Section
====================================================================

***/
.funfact-section {
  position: relative;
  padding: 10px 0 30px;
}

.counter-block {
  margin-bottom: 90px;
}
.counter-block:last-child .inner-box:before {
  display: none;
}
.counter-block .inner-box {
  position: relative;
  text-align: center;
}
.counter-block .inner-box:before {
  content: "";
  position: absolute;
  bottom: 35px;
  right: -40px;
  width: 26px;
  height: 54px;
  background-image: url(../images/icons/icon-line.png);
}
.counter-block .inner-box:hover .icon-box .icon {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.counter-block .inner-box .icon-box {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}
.counter-block .inner-box .icon-box.two:before {
  right: auto;
  left: -17px;
}
.counter-block .inner-box .icon-box.three:before {
  right: 50%;
  -webkit-transform: translate(50%);
          transform: translate(50%);
}
.counter-block .inner-box .icon-box:before {
  content: "";
  position: absolute;
  top: 5px;
  right: -7px;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: var(--theme-color1);
}
.counter-block .inner-box .icon-box .icon {
  position: relative;
  display: inline-block;
  color: var(--theme-color-dark);
  font-size: 105px;
  line-height: 1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .counter-block .inner-box .icon-box .icon {
    font-size: 80px;
  }
}
.counter-block .inner-box .counter-one {
  position: relative;
  font-size: 90px;
  line-height: 1em;
  letter-spacing: 0.04em;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .counter-block .inner-box .counter-one {
    font-size: 60px;
  }
}
.counter-block .inner-box .counter-text {
  position: relative;
  font-size: 20px;
  line-height: 1em;
  color: var(--theme-color-dark);
  margin-bottom: 0;
  margin-top: -10px;
  text-transform: uppercase;
}

/*** 

====================================================================
    Funfacts Section Two
====================================================================

***/
.funfact-section-four {
  padding: 115px 0;
  position: relative;
}
@media (max-width: 1199.98px) {
  .funfact-section-four {
    padding: 85px 0 40px;
  }
}
@media (max-width: 991.98px) {
  .funfact-section-four {
    padding: 85px 0 50px;
  }
}
.funfact-section-four .bg-image {
  background-position: center center;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .content-column {
    margin-bottom: 50px;
  }
}
.funfact-section-four .content-column .inner-column {
  position: relative;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .content-column .inner-column {
    padding-top: 0;
  }
}
@media (max-width: 991.98px) {
  .funfact-section-four .content-column .inner-column {
    text-align: center;
  }
}
.funfact-section-four .content-column .inner-column .sec-title {
  margin-bottom: 40px;
}
.funfact-section-four .content-column .inner-column .sec-title .text {
  color: var(--text-color);
  padding-right: 135px;
  margin-top: 28px;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .content-column .inner-column .sec-title .text {
    padding-right: 0;
  }
}
.funfact-section-four .fun-fact-column .inner-column {
  padding-top: 18px;
  padding-left: 23px;
  margin-right: 30px;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .fun-fact-column .inner-column {
    padding-left: 0;
  }
}
@media (max-width: 575.98px) {
  .funfact-section-four .fun-fact-column .inner-column {
    margin-right: 0;
  }
}
.funfact-section-four .fun-fact-column .counter-outer-box {
  position: relative;
}
.funfact-section-four .fun-fact-column .counter-outer-box .line-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 110px;
  margin-top: 35px;
  margin-bottom: 31px;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .line-top {
    padding-left: 70px;
  }
}
@media (max-width: 991.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .line-top {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .line-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-left: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.funfact-section-four .fun-fact-column .counter-outer-box .line-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .line-bottom {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .line-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.funfact-section-four .fun-fact-column .counter-outer-box .counter-block-four:nth-child(2) .inner-box {
  margin-left: 140px;
}
@media (max-width: 1199.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .counter-block-four:nth-child(2) .inner-box {
    margin-left: 70px;
  }
}
@media (max-width: 575.98px) {
  .funfact-section-four .fun-fact-column .counter-outer-box .counter-block-four:nth-child(2) .inner-box {
    margin-left: 0;
    margin-top: 50px;
  }
}

.counter-block-four .inner-box {
  position: relative;
  margin-bottom: 50px;
}
.counter-block-four .inner-box::before {
  content: "";
  position: absolute;
  top: -13px;
  left: -28px;
  width: 64px;
  height: 100%;
  min-height: 107px;
  background-color: #2b2b2b;
  -webkit-transform: skewX(-19deg);
          transform: skewX(-19deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-four .inner-box:hover::before {
  width: calc(100% + 48px);
}
.counter-block-four .inner-box .content {
  position: relative;
}
.counter-block-four .inner-box .content .counter-text {
  color: var(--theme-color-light);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
}
.counter-block-four .inner-box .content .counter-one {
  color: var(--theme-color1);
  font-size: 48px;
  font-weight: 600;
  font-family: var(--style-font);
  margin-bottom: -5px;
}
.counter-block-four .inner-box .content .counter-one .counter-after {
  font-size: 50px;
  font-family: var(--title-font);
  position: relative;
  top: -3px;
  margin-left: 2px;
}

/*** 

====================================================================
    Testimonials Section
====================================================================

***/
.testimonial-section {
  position: relative;
  padding: 120px 0 120px;
  z-index: 3;
}
.testimonial-section:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 800px;
  background-color: var(--theme-color1);
  clip-path: polygon(42% 0, 100% 0, 100% 100%, 2% 100%);
  z-index: 1;
}
@media (max-width: 991.98px) {
  .testimonial-section:before {
    display: none;
  }
}
.testimonial-section .sec-title {
  margin-bottom: -141px;
}
@media (max-width: 1199.98px) {
  .testimonial-section .sec-title {
    margin-bottom: 23px;
  }
}
.testimonial-section .owl-nav {
  display: none;
}
.testimonial-section .default-dots .owl-dots {
  margin-top: 38px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-left: -5px;
}

.testimonial-block {
  position: relative;
  z-index: 3;
}
.testimonial-block .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991.98px) {
  .testimonial-block .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--theme-color-light);
    padding: 10px 10px;
  }
}
.testimonial-block .inner-box .image {
  position: absolute;
  top: 20px;
  right: -5px;
  display: block;
  margin-bottom: 46px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 477px;
  clip-path: polygon(35% 0, 100% 0, 65% 100%, 0% 100%);
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .testimonial-block .inner-box .image {
    right: 0;
    height: auto;
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .testimonial-block .inner-box .image {
    position: relative;
    clip-path: none;
    -webkit-box-ordinal-group: inherit;
        -ms-flex-order: inherit;
            order: inherit;
  }
}
.testimonial-block .inner-box .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block .inner-box .content {
  position: relative;
  margin-top: 162px;
  width: 93%;
  padding: 45px 500px 40px 80px;
  background-color: var(--theme-color-light);
}
@media (max-width: 1399.98px) {
  .testimonial-block .inner-box .content {
    padding-left: 50px;
  }
}
@media (max-width: 1199.98px) {
  .testimonial-block .inner-box .content {
    margin-top: 0;
    padding-left: 30px;
    padding-right: 430px;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .testimonial-block .inner-box .content {
    padding: 30px 20px;
  }
}
@media (max-width: 575.98px) {
  .testimonial-block .inner-box .content {
    padding: 30px 5px 10px;
  }
}
.testimonial-block .inner-box .content .text {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 33px;
}
@media (max-width: 575.98px) {
  .testimonial-block .inner-box .content .text {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.testimonial-block .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575.98px) {
  .testimonial-block .inner-box .content .author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.testimonial-block .inner-box .content .author-box .icon {
  position: relative;
  width: 73px;
  height: 73px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 30px;
  border-radius: 50%;
  margin-right: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
}
.testimonial-block .inner-box .content .author-box .author-info {
  position: relative;
  padding-top: 19px;
}
.testimonial-block .inner-box .content .author-box .author-info .name {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1px;
  font-family: var(--text-font);
}
.testimonial-block .inner-box .content .author-box .author-info .designation {
  position: relative;
  display: block;
  color: #161616;
}
.testimonial-block .inner-box .content .author-box .author-info .rating {
  position: relative;
  padding-top: 11px;
  margin-left: -2px;
}
@media (max-width: 575.98px) {
  .testimonial-block .inner-box .content .author-box .author-info .rating {
    margin-left: 0;
    padding-top: 5px;
  }
}
.testimonial-block .inner-box .content .author-box .author-info .rating i {
  position: relative;
  color: #101010;
  font-size: 12px;
  margin-right: 2px;
}

/*** 

====================================================================
    Testimonials Section Two
====================================================================

***/
.testimonial-section-two {
  position: relative;
  padding: 120px 0 110px;
  z-index: 3;
}
@media (max-width: 1199.98px) {
  .testimonial-section-two {
    padding-top: 0;
  }
}
.testimonial-section-two:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 1020px;
  background-color: var(--theme-color1);
  clip-path: polygon(30% 0, 100% 0, 70% 100%, 0% 100%);
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .testimonial-section-two:before {
    display: none;
  }
}
.testimonial-section-two .sec-title {
  margin-bottom: 20px;
}
@media (max-width: 1199.98px) {
  .testimonial-section-two .sec-title {
    margin-bottom: 30px;
  }
}
.testimonial-section-two .owl-carousel {
  z-index: 99;
}
.testimonial-section-two .owl-nav {
  position: absolute;
  right: -20px;
  top: -95px;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .testimonial-section-two .owl-nav {
    position: relative;
    top: 0;
    right: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 30px;
  }
}

.testimonial-block-two {
  position: relative;
  z-index: 3;
}
.testimonial-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767.98px) {
  .testimonial-block-two .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.testimonial-block-two .inner-box .image-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1199.98px) {
  .testimonial-block-two .inner-box .image-box {
    display: block;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 30px;
  }
}
@media (max-width: 767.98px) {
  .testimonial-block-two .inner-box .image-box {
    margin-right: 0;
  }
}
.testimonial-block-two .inner-box .image-box .image {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  clip-path: polygon(31% 0, 100% 0, 69% 100%, 0% 100%);
}
@media (max-width: 1199.98px) {
  .testimonial-block-two .inner-box .image-box .image {
    clip-path: none;
  }
}
.testimonial-block-two .inner-box .image-box .image img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-two .inner-box .image-box .image.two {
  margin-left: -80px;
}
@media (max-width: 1199.98px) {
  .testimonial-block-two .inner-box .image-box .image.two {
    display: none;
  }
}
.testimonial-block-two .inner-box .image-box .icon-quote {
  position: absolute;
  top: 70px;
  right: 0;
  width: 73px;
  height: 73px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 30px;
  border-radius: 50%;
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1199.98px) {
  .testimonial-block-two .inner-box .image-box .icon-quote {
    top: 10px;
    right: 10px;
  }
}
.testimonial-block-two .inner-box .content {
  position: relative;
  max-width: 540px;
  padding-right: 30px;
}
@media (max-width: 1399.98px) {
  .testimonial-block-two .inner-box .content {
    max-width: 550px;
  }
}
@media (max-width: 1199.98px) {
  .testimonial-block-two .inner-box .content {
    max-width: 100%;
    padding-right: 0;
  }
}
.testimonial-block-two .inner-box .content .text {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 12px;
}
@media (max-width: 575.98px) {
  .testimonial-block-two .inner-box .content .text {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }
}
.testimonial-block-two .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial-block-two .inner-box .content .author-box .author-info {
  position: relative;
  padding-top: 19px;
}
.testimonial-block-two .inner-box .content .author-box .author-info .name {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1px;
  font-family: var(--text-font);
}
.testimonial-block-two .inner-box .content .author-box .author-info .rating-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.testimonial-block-two .inner-box .content .author-box .author-info .rating-box .designation {
  position: relative;
  display: block;
  color: #161616;
  margin-bottom: 0;
}
.testimonial-block-two .inner-box .content .author-box .author-info .rating-box .rating {
  position: relative;
  padding-left: 15px;
}
.testimonial-block-two .inner-box .content .author-box .author-info .rating-box .rating i {
  position: relative;
  color: #101010;
  font-size: 12px;
  margin-right: 2px;
}

/*** 

====================================================================
    Testimonials Section Three
====================================================================

***/
.testimonial-section-three {
  background-color: #f5f5f5;
  position: relative;
  padding: 101px 0 20px;
  z-index: 3;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three {
    padding: 83px 0 90px;
  }
}
.testimonial-section-three .bg-image {
  background-image: url(../images/background/bg-testi1.png);
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three .bg-image {
    display: none;
  }
}
.testimonial-section-three .bg-image::after {
  background-image: url(../images/icons/shape-1.png);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 18px;
}
.testimonial-section-three .image-man {
  background-image: url(../images/resource/man1.png);
  width: 496px;
  /* height: 669px; */
  height: 469px;
  position: absolute;
  bottom: 0;
  right: calc(50% + 134px);
  z-index: 2;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three .image-man {
    display: none;
  }
}
.testimonial-section-three:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(50% + 95px);
  height: 100%;
  width: 585px;
  background-color: var(--theme-color1);
  clip-path: polygon(35% 0, 100% 0, 65% 100%, 0% 100%);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three:before {
    display: none;
  }
}
.testimonial-section-three:after {
  content: "";
  position: absolute;
  top: 267px;
  right: calc(50% + 572px);
  height: 238px;
  width: 190px;
  background-color: var(--theme-color-dark);
  clip-path: polygon(35% 0, 100% 0, 67% 100%, 0% 100%);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three:after {
    display: none;
  }
}
.testimonial-section-three .sec-title {
  margin-bottom: 27px;
  padding-left: 80px;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three .sec-title {
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .testimonial-section-three .sec-title {
    text-align: center;
  }
}
.testimonial-section-three .sec-title h2 {
  font-size: 40px;
}
.testimonial-section-three .owl-nav {
  display: none;
}
.testimonial-section-three .default-dots-two .owl-dots {
  margin-top: 48px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 80px;
}
@media (max-width: 1199.98px) {
  .testimonial-section-three .default-dots-two .owl-dots {
    padding-left: 0;
  }
}

.testimonial-block-three {
  position: relative;
  z-index: 3;
}
.testimonial-block-three .inner-box {
  position: relative;
  padding-left: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1199.98px) {
  .testimonial-block-three .inner-box {
    padding-left: 0;
  }
}
@media (max-width: 991.98px) {
  .testimonial-block-three .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--theme-color-light);
    padding: 30px 25px;
  }
}
.testimonial-block-three .inner-box .content {
  position: relative;
}
.testimonial-block-three .inner-box .content .text {
  border-bottom: 1px solid rgba(22, 23, 26, 0.15);
  position: relative;
  color: var(--theme-color-dark);
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 45px;
  padding-bottom: 37px;
  padding-left: 25px;
}
@media (max-width: 575.98px) {
  .testimonial-block-three .inner-box .content .text {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-left: 0;
  }
}
.testimonial-block-three .inner-box .content .text:before {
  background-color: var(--theme-color1);
  content: "";
  position: absolute;
  height: calc(100% - 52px);
  width: 3px;
  left: 0;
  top: 7px;
}
@media (max-width: 575.98px) {
  .testimonial-block-three .inner-box .content .text:before {
    display: none;
  }
}
.testimonial-block-three .inner-box .content .box-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767.98px) {
  .testimonial-block-three .inner-box .content .box-info {
    display: block;
  }
}
.testimonial-block-three .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575.98px) {
  .testimonial-block-three .inner-box .content .author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.testimonial-block-three .inner-box .content .author-box .author-image {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 30px;
}
.testimonial-block-three .inner-box .content .author-box .author-info {
  position: relative;
  padding-top: 6px;
}
.testimonial-block-three .inner-box .content .author-box .author-info .name {
  position: relative;
  letter-spacing: -0.2px;
  margin-bottom: 1px;
}
@media (max-width: 575.98px) {
  .testimonial-block-three .inner-box .content .author-box .author-info .name {
    font-size: 16px;
  }
}
.testimonial-block-three .inner-box .content .author-box .author-info .designation {
  position: relative;
  display: block;
}
.testimonial-block-three .inner-box .content .author-rating {
  margin-left: 20px;
  padding-top: 13px;
}
@media (max-width: 767.98px) {
  .testimonial-block-three .inner-box .content .author-rating {
    display: none;
  }
}
.testimonial-block-three .inner-box .content .author-rating .rating-title {
  color: #16171A;
  font-size: 20px;
  font-family: var(--title-font);
  line-height: 1;
}
.testimonial-block-three .inner-box .content .author-rating .rating {
  position: relative;
  padding-top: 3px;
  margin-left: -2px;
}
@media (max-width: 575.98px) {
  .testimonial-block-three .inner-box .content .author-rating .rating {
    margin-left: 0;
    padding-top: 5px;
  }
}
.testimonial-block-three .inner-box .content .author-rating .rating i {
  position: relative;
  color: #FFEE02;
  font-size: 12px;
  margin-right: 2px;
}

/*** 

====================================================================
    Testimonials Section Four
====================================================================

***/
.testimonial-section-four {
  position: relative;
  padding: 114px 0 120px;
  z-index: 3;
}
@media (max-width: 1199.98px) {
  .testimonial-section-four {
    padding: 84px 0 90px;
  }
}
.testimonial-section-four .bg-image {
  background-image: url(../images/background/bg-testi1.png);
  pointer-events: none;
  height: 50%;
  z-index: -1;
}
.testimonial-section-four .sec-title {
  margin-bottom: 0;
}
.testimonial-section-four .outer-box {
  position: relative;
  padding-top: 51px;
}
.testimonial-section-four .outer-box::before {
  border: 1px solid rgba(193, 193, 193, 0.14);
  content: "";
  width: 154px;
  height: 400px;
  left: calc(50% - 164px);
  position: absolute;
  top: 0;
  pointer-events: none;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-section-four .outer-box::after {
  border: 1px solid rgba(193, 193, 193, 0.14);
  content: "";
  width: 154px;
  height: 400px;
  left: calc(50% + 11px);
  position: absolute;
  top: 0;
  pointer-events: none;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-section-four .owl-nav {
  display: none;
}
.testimonial-section-four .default-dots .owl-dots {
  margin-top: 65px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.testimonial-block-four {
  position: relative;
}
.testimonial-block-four .inner-box {
  position: relative;
  padding: 39px 25px 0;
}
.testimonial-block-four .inner-box:before {
  background-color: var(--theme-color2);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 105px);
}
.testimonial-block-four .inner-box:hover .content .text {
  color: var(--theme-color1);
}
.testimonial-block-four .inner-box:hover .content .author-box .author-image {
  overflow: hidden;
}
.testimonial-block-four .inner-box:hover .content .author-box .author-image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.testimonial-block-four .inner-box:hover .content .author-box .author-info .designation {
  color: var(--theme-color1);
}
.testimonial-block-four .inner-box .content {
  position: relative;
  text-align: center;
  padding-top: 45px;
}
.testimonial-block-four .inner-box .content::before {
  background-color: var(--theme-color1);
  content: "";
  width: 13px;
  height: 27px;
  left: calc(50% - 11px);
  position: absolute;
  top: 0;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-four .inner-box .content::after {
  background-color: var(--theme-color1);
  content: "";
  width: 13px;
  height: 27px;
  left: calc(50% + 11px);
  position: absolute;
  top: 0;
  -webkit-transform: skewX(-22deg);
          transform: skewX(-22deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-four .inner-box .content .text {
  color: var(--theme-color-light);
  position: relative;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-four .inner-box .content .author-box {
  position: relative;
  text-align: center;
}
.testimonial-block-four .inner-box .content .author-box .author-image {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 15px;
  overflow: hidden;
}
.testimonial-block-four .inner-box .content .author-box .author-image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-four .inner-box .content .author-box .author-info {
  position: relative;
}
.testimonial-block-four .inner-box .content .author-box .author-info .name {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin-bottom: 1px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-four .inner-box .content .author-box .author-info .designation {
  font-size: 16px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/***

====================================================================
    Team Section
====================================================================

***/
.team-section {
  position: relative;
  padding: 120px 0 90px;
}

.team-block {
  position: relative;
  margin-bottom: 30px;
}
.team-block .inner-box {
  position: relative;
  padding: 52px 0 24px;
  overflow: hidden;
  border: 1px solid #c1c1c1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .team-block .inner-box {
    padding: 20px 0;
  }
}
.team-block .inner-box:hover:before {
  background-color: var(--theme-color-dark);
}
.team-block .inner-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 314px;
  background-color: var(--theme-color1);
  clip-path: polygon(44% 0, 100% 0, 56% 100%, 0% 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .team-block .inner-box:before {
    clip-path: polygon(44% 0, 100% 0, 70% 100%, 0% 100%);
  }
}
@media (max-width: 575.98px) {
  .team-block .inner-box:before {
    display: none;
  }
}
.team-block .inner-box:hover {
  -webkit-box-shadow: 10px 21px 10px rgba(4, 4, 4, 0.05);
          box-shadow: 10px 21px 10px rgba(4, 4, 4, 0.05);
}
.team-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.team-block .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  clip-path: polygon(35% 0, 100% 0, 64.5% 100%, 0% 100%);
}
@media (max-width: 575.98px) {
  .team-block .inner-box .image {
    clip-path: none;
  }
}
.team-block .inner-box .image img {
  position: relative;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block .inner-box .content {
  position: relative;
  padding: 0 55px 30px;
}
@media (max-width: 1199.98px) {
  .team-block .inner-box .content {
    padding: 0 30px 20px;
  }
}
@media (max-width: 767.98px) {
  .team-block .inner-box .content {
    padding: 0 15px 20px;
  }
}
.team-block .inner-box .content .name {
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
}
.team-block .inner-box .content .name a:hover {
  color: var(--theme-color-dark);
}
.team-block .inner-box .content .designation {
  position: relative;
  display: block;
  color: var(--text-color);
  font-family: var(--title-font);
  margin-bottom: 12px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .team-block .inner-box .content .designation {
    font-size: 18px;
  }
}
.team-block .inner-box .content .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.team-block .inner-box .content .info-btn .icon {
  position: relative;
  width: 51px;
  height: 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  margin-right: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
}
.team-block .inner-box .content .info-btn strong {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}
.team-block .inner-box .content .info-btn strong:hover {
  text-decoration: underline;
}
.team-block .inner-box .social-icon-two {
  position: relative;
  padding-top: 31px;
  padding-left: 55px;
}
@media (max-width: 1199.98px) {
  .team-block .inner-box .social-icon-two {
    padding-top: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 767.98px) {
  .team-block .inner-box .social-icon-two {
    padding-top: 30px;
    padding-left: 15px;
  }
}
.team-block .inner-box .social-icon-two li a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-shadow: 0 10px 10px rgba(4, 4, 4, 0.05);
          box-shadow: 0 10px 10px rgba(4, 4, 4, 0.05);
}
.team-block .inner-box .social-icon-two li a:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/***

====================================================================
    Team Section Two
====================================================================

***/
.team-section-two {
  position: relative;
  padding: 120px 0 66px;
}
@media (max-width: 1199.98px) {
  .team-section-two {
    padding: 90px 0 37px;
  }
}
.team-section-two::before {
  background-image: url(../images/icons/icon-map-2.png);
  content: "";
  position: absolute;
  right: calc(100% - 285px);
  bottom: 121px;
  height: 329px;
  width: 219px;
  pointer-events: none;
  z-index: -1;
}
@media (max-width: 1699.98px) {
  .team-section-two::before {
    display: none;
  }
}
.team-section-two .outer-box {
  position: relative;
}
.team-section-two .carousel-outer {
  margin-right: -395px;
}
@media (max-width: 1399.98px) {
  .team-section-two .carousel-outer {
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  .team-section-two .carousel-outer {
    margin-right: 0;
  }
}
.team-section-two .swiper-button-next, .team-section-two .swiper-button-prev {
  border: 1px solid var(--theme-color1);
  display: block;
  color: var(--theme-color-dark);
  top: 47px;
  right: 0;
  width: 52px;
  height: 52px;
  line-height: 54px;
  font-size: 18px;
  background-color: var(--theme-color-light);
  padding-left: 5px;
  text-align: center;
  opacity: 1;
  -webkit-transform: skewX(-21deg);
          transform: skewX(-21deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .team-section-two .swiper-button-next, .team-section-two .swiper-button-prev {
    display: none;
  }
}
.team-section-two .swiper-button-next:hover, .team-section-two .swiper-button-prev:hover {
  color: var(--theme-color-dark);
  background-color: var(--theme-color1);
}
.team-section-two .swiper-button-next:after, .team-section-two .swiper-button-prev:after {
  display: none;
}
.team-section-two .swiper-button-next .icon, .team-section-two .swiper-button-prev .icon {
  -webkit-transform: skewX(21deg);
          transform: skewX(21deg);
}
.team-section-two .swiper-button-prev {
  left: auto;
  right: 60px;
  padding-left: 0;
  padding-right: 2px;
}

.team-block-two {
  position: relative;
  margin-bottom: 30px;
}
.team-block-two .inner-box {
  position: relative;
  padding: 0 0 24px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 767.98px) {
  .team-block-two .inner-box {
    padding: 20px 0;
  }
}
.team-block-two .inner-box:hover .content .info-btn .text {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}
.team-block-two .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.team-block-two .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.team-block-two .inner-box .image img {
  position: relative;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block-two .inner-box .content {
  position: relative;
  padding: 23px 0 0;
}
.team-block-two .inner-box .content .name {
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
}
.team-block-two .inner-box .content .name a:hover {
  color: var(--theme-color-dark);
}
.team-block-two .inner-box .content .designation {
  position: relative;
  display: block;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block-two .inner-box .content .info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 23px;
}
.team-block-two .inner-box .content .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.team-block-two .inner-box .content .info-btn .icon {
  position: relative;
  width: 48px;
  min-width: 48px;
  height: 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 24px;
  color: var(--theme-color1);
  background-color: var(--theme-color-dark);
  -webkit-transform: skewX(-12deg);
          transform: skewX(-12deg);
}
@media (max-width: 991.98px) {
  .team-block-two .inner-box .content .info-btn .icon {
    -webkit-transform: none;
            transform: none;
    margin-right: 5px;
  }
}
.team-block-two .inner-box .content .info-btn .icon i {
  -webkit-transform: skewX(12deg) rotate(-38deg);
          transform: skewX(12deg) rotate(-38deg);
}
.team-block-two .inner-box .content .info-btn .text {
  border: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  color: var(--theme-color-dark);
  font-size: 16px;
  font-weight: 700;
  padding: 11px 25px 8px;
  position: relative;
  width: 100%;
  clip-path: polygon(6.8% 0, 100% 0, 100% 100%, 0% 100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .team-block-two .inner-box .content .info-btn .text {
    padding: 11px 5px 8px 18px;
  }
}
@media (max-width: 991.98px) {
  .team-block-two .inner-box .content .info-btn .text {
    clip-path: none;
  }
}
@media (max-width: 339.98px) {
  .team-block-two .inner-box .content .info-btn .text {
    padding: 11px 5px 8px 8px;
    font-size: 15px;
  }
}
.team-block-two .inner-box .content .info-btn .text::before {
  border-left: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  content: "";
  position: absolute;
  left: 6px;
  top: 0;
  width: 1px;
  height: 100%;
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 991.98px) {
  .team-block-two .inner-box .content .info-btn .text::before {
    display: none;
  }
}
.team-block-two .inner-box .content .info-btn .text:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}
.team-block-two .inner-box .content .social-icon-two {
  border-top: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  border-bottom: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  position: relative;
}
.team-block-two .inner-box .content .social-icon-two li {
  margin-right: 0;
}
.team-block-two .inner-box .content .social-icon-two li a {
  border: none;
  border-right: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  height: 49px;
  width: 68px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .team-block-two .inner-box .content .social-icon-two li a {
    width: 52px;
  }
}

/*** 

====================================================================
    Apps Section
====================================================================

***/
.app-section {
  position: relative;
  padding: 90px 0 90px;
}
@media (max-width: 991.98px) {
  .app-section {
    padding-top: 0;
  }
}
.app-section .outer-box {
  position: relative;
  margin-right: -367px;
}
@media (max-width: 1199.98px) {
  .app-section .outer-box {
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  .app-section .outer-box {
    text-align: center;
  }
}
.app-section .outer-box .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.85;
  background-color: var(--theme-color-dark);
}
.app-section .outer-box .icon-car-3 {
  position: absolute;
  top: -90px;
  left: 586px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .app-section .outer-box .icon-car-3 {
    left: auto;
    right: -80px;
    top: -70px;
  }
}
@media (max-width: 991.98px) {
  .app-section .outer-box .icon-car-3 {
    display: none;
  }
}
.app-section .outer-box .bg-overlay-2 {
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .app-section .outer-box .bg-overlay-2 {
    display: none;
  }
}
.app-section .outer-box .content {
  padding: 70px 108px 74px;
}
@media (max-width: 1199.98px) {
  .app-section .outer-box .content {
    padding: 60px 50px;
  }
}
@media (max-width: 991.98px) {
  .app-section .outer-box .content {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .app-section .outer-box .content {
    padding: 50px 15px;
  }
}
.app-section .outer-box .content .title {
  position: relative;
  color: var(--theme-color-light);
  font-size: 70px;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  margin-bottom: 0px;
}
@media (max-width: 575.98px) {
  .app-section .outer-box .content .title {
    font-size: 46px;
  }
  .app-section .outer-box .content .title br {
    display: none;
  }
}
.app-section .outer-box .content .title .colored {
  color: var(--theme-color1);
}
.app-section .outer-box .content .text {
  position: relative;
  margin-top: -5px;
  color: var(--theme-color-light);
  margin-bottom: 25px;
}
@media (max-width: 575.98px) {
  .app-section .outer-box .content .text {
    margin-top: 0;
  }
}
.app-section .outer-box .content .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
  .app-section .outer-box .content .btn-box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .app-section .outer-box .content .btn-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.app-section .outer-box .content .btn-box .image {
  margin-right: 18px;
}
@media (max-width: 991.98px) {
  .app-section .outer-box .content .btn-box .image {
    margin-right: 0;
    margin: 0 9px;
  }
}
@media (max-width: 575.98px) {
  .app-section .outer-box .content .btn-box .image {
    margin: 0 0 10px;
  }
}

/*** 

====================================================================
    Apps Section Two
====================================================================

***/
.app-section-two {
  position: relative;
  margin-bottom: 70px;
}
.app-section-two .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background-color: var(--theme-color-dark);
}
.app-section-two .icon-car-4 {
  position: absolute;
  bottom: -80px;
  right: 360px;
  z-index: 1;
}
@media only screen and (max-width: 1699px) {
  .app-section-two .icon-car-4 {
    right: 0px;
  }
}
@media (max-width: 1199.98px) {
  .app-section-two .icon-car-4 {
    bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .app-section-two .icon-car-4 {
    display: none;
  }
}
.app-section-two .bg-overlay-3 {
  position: absolute;
  top: 0;
  right: 120px;
  bottom: 0;
}
@media only screen and (max-width: 1699px) {
  .app-section-two .bg-overlay-3 {
    right: 0px;
  }
}
@media (max-width: 1199.98px) {
  .app-section-two .bg-overlay-3 {
    display: none;
  }
}
.app-section-two .content {
  position: relative;
  padding: 68px 0 85px;
}
@media (max-width: 991.98px) {
  .app-section-two .content {
    padding: 60px 0;
    text-align: center;
  }
}
.app-section-two .content .title {
  position: relative;
  color: var(--theme-color-light);
  font-size: 60px;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  margin-bottom: 10px;
}
@media (max-width: 575.98px) {
  .app-section-two .content .title {
    font-size: 46px;
  }
  .app-section-two .content .title br {
    display: none;
  }
}
.app-section-two .content .title .colored {
  color: var(--theme-color1);
}
.app-section-two .content .text {
  position: relative;
  color: var(--theme-color-light);
  margin-top: -7px;
  margin-bottom: 25px;
}
@media (max-width: 575.98px) {
  .app-section-two .content .text {
    margin-top: 0;
  }
}
.app-section-two .content .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
  .app-section-two .content .btn-box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .app-section-two .content .btn-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.app-section-two .content .btn-box .image {
  margin-right: 18px;
}
@media (max-width: 991.98px) {
  .app-section-two .content .btn-box .image {
    margin-right: 0;
    margin: 0 9px;
  }
}
@media (max-width: 575.98px) {
  .app-section-two .content .btn-box .image {
    margin: 0 0 10px;
  }
}

/*** 

====================================================================
    Apps Section Three
====================================================================

***/
.app-section-three {
  position: relative;
  overflow: hidden;
}
.app-section-three .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.88;
  background-color: var(--theme-color-dark);
}
.app-section-three .bg-overlay-5 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 1399.98px) {
  .app-section-three .bg-overlay-5 {
    right: -120px;
  }
}
@media (max-width: 1199.98px) {
  .app-section-three .bg-overlay-5 {
    display: none;
  }
}
.app-section-three .content-column .inner-column {
  position: relative;
  padding: 120px 0 113px;
  margin-left: -30px;
}
@media (max-width: 1199.98px) {
  .app-section-three .content-column .inner-column {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .app-section-three .content-column .inner-column {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .app-section-three .content-column .inner-column {
    padding: 100px 0;
  }
}
.app-section-three .content-column .inner-column .sec-title {
  margin-left: -60px;
  margin-bottom: 44px;
}
@media (max-width: 1199.98px) {
  .app-section-three .content-column .inner-column .sec-title {
    margin-left: 0;
  }
}
.app-section-three .content-column .inner-column .sec-title .sub-title {
  margin-bottom: 15px;
}
.app-section-three .content-column .inner-column .sec-title h2 {
  letter-spacing: -0.01em;
  line-height: 1em;
}
.app-section-three .content-column .inner-column .blocks-outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991.98px) {
  .app-section-three .content-column .inner-column .blocks-outer-box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .app-section-three .content-column .inner-column .blocks-outer-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.app-section-three .content-column .inner-column .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 35px;
  margin-left: -42px;
}
@media (max-width: 1199.98px) {
  .app-section-three .content-column .inner-column .btn-box {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .app-section-three .content-column .inner-column .btn-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.app-section-three .content-column .inner-column .btn-box .image {
  margin-right: 18px;
}
@media (max-width: 991.98px) {
  .app-section-three .content-column .inner-column .btn-box .image {
    margin-right: 0;
    margin: 0 9px;
  }
}
@media (max-width: 575.98px) {
  .app-section-three .content-column .inner-column .btn-box .image {
    margin: 0 0 10px;
  }
}
.app-section-three .image-column .inner-column {
  position: relative;
}
@media (max-width: 991.98px) {
  .app-section-three .image-column .inner-column {
    display: none;
  }
}
.app-section-three .image-column .inner-column .image {
  position: absolute;
  top: 75px;
  left: 58px;
  margin-bottom: 0;
  z-index: 1;
}

.app-block.style-two .inner-box {
  padding: 30px 42px 25px;
  margin-left: -2px;
}
.app-block .inner-box {
  position: relative;
  padding: 30px 48px 24px;
  border: 2px solid #323232;
  text-align: center;
}
.app-block .inner-box:hover .icon {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.app-block .inner-box .icon {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 1em;
  color: var(--theme-color1);
  margin-bottom: 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.app-block .inner-box .title {
  position: relative;
  color: var(--theme-color-light);
  font-size: 22px;
  line-height: 1.2em;
  margin-bottom: 0;
}

/*** 

====================================================================
    News Section
====================================================================

***/
.news-section {
  position: relative;
  padding: 120px 0 120px;
}
.news-section .sec-title {
  margin-bottom: 30px;
}
.news-section .btn-box {
  margin-top: 25px;
}

.news-block {
  margin-bottom: 30px;
}
.news-block .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
}
.news-block .inner-box:hover .image img:first-child {
  -webkit-transform: translateX(0) scaleX(1);
          transform: translateX(0) scaleX(1);
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.news-block .inner-box:hover .image img:nth-child(2) {
  -webkit-transform: translateX(-50%) scaleX(2);
          transform: translateX(-50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block .inner-box .image img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-block .inner-box .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transform: translateX(50%) scaleX(2);
          transform: translateX(50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block .inner-box .content {
  position: relative;
}
.news-block .inner-box .content .post-meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 13px 52px;
  margin-bottom: 30px;
  background-color: var(--theme-color-light);
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .news-block .inner-box .content .post-meta {
    padding: 13px 30px;
  }
}
.news-block .inner-box .content .post-meta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 235px;
  height: 56px;
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0% 100%);
  background-color: var(--theme-color1);
}
@media (max-width: 575.98px) {
  .news-block .inner-box .content .post-meta::before {
    clip-path: none;
    width: 100%;
  }
}
.news-block .inner-box .content .post-meta li {
  position: relative;
  color: var(--theme-color-dark);
  margin-right: 90px;
  font-family: var(--title-font);
  text-transform: uppercase;
}
.news-block .inner-box .content .post-meta li:last-child {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  .news-block .inner-box .content .post-meta li {
    margin-right: 70px;
  }
}
@media (max-width: 649.98px) {
  .news-block .inner-box .content .post-meta li {
    margin-right: 40px;
  }
}
.news-block .inner-box .content .post-meta li i {
  position: relative;
  color: var(--theme-color-dark);
  line-height: 1em;
  margin-right: 5px;
}
.news-block .inner-box .content .title {
  color: var(--theme-color-dark);
  margin-bottom: 25px;
  position: relative;
}
.news-block .inner-box .content .title a:hover {
  color: var(--theme-color-dark);
}
.news-block .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 2px;
}
.news-block .inner-box .content .author-box img {
  position: relative;
  border-radius: 50%;
  outline: 1px solid var(--theme-color-dark) !important;
  margin-right: 13px;
}
.news-block .inner-box .content .author-box .name {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--style-font);
  margin-bottom: 0;
}
.news-block .inner-box .content .author-box .name span {
  color: #444444;
  font-weight: 800;
  text-decoration: underline;
}

/*** 

====================================================================
    News Section Two
====================================================================

***/
.news-section-two {
  position: relative;
  padding: 120px 0 90px;
}
.news-section-two .sec-title {
  margin-bottom: 30px;
}

.news-block-two {
  margin-bottom: 30px;
}
.news-block-two .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
}
.news-block-two .inner-box:hover .image img:first-child {
  -webkit-transform: translateX(0) scaleX(1);
          transform: translateX(0) scaleX(1);
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.news-block-two .inner-box:hover .image img:nth-child(2) {
  -webkit-transform: translateX(-50%) scaleX(2);
          transform: translateX(-50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-two .inner-box:hover .content .author-box {
  background-color: var(--theme-color1);
}
.news-block-two .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block-two .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-block-two .inner-box .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transform: translateX(50%) scaleX(2);
          transform: translateX(50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-two .inner-box .content {
  position: relative;
  padding: 0 14px;
}
@media (max-width: 575.98px) {
  .news-block-two .inner-box .content {
    padding: 0;
  }
}
.news-block-two .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 26px;
  margin-top: -30px;
  margin-bottom: 34px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
  background-color: var(--theme-color-light);
  border: 2px solid var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 3;
}
@media (max-width: 575.98px) {
  .news-block-two .inner-box .content .author-box {
    margin-top: 0;
  }
}
.news-block-two .inner-box .content .author-box img {
  position: relative;
  border-radius: 50%;
  outline: 2px solid var(--theme-color-dark) !important;
}
.news-block-two .inner-box .content .author-box .author-info {
  position: relative;
  padding-left: 15px;
}
.news-block-two .inner-box .content .author-box .author-info .date {
  position: relative;
  font-size: 20px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.news-block-two .inner-box .content .author-box .author-info .name {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--style-font);
  margin-bottom: 0;
}
.news-block-two .inner-box .content .author-box .author-info .name span {
  color: #444444;
  font-weight: 800;
  text-decoration: underline;
}
.news-block-two .inner-box .content .title {
  position: relative;
  font-weight: 700;
  font-family: var(--text-font);
  color: var(--theme-color-dark);
  margin-bottom: 8px;
}
.news-block-two .inner-box .content .title a:hover {
  color: var(--theme-color2);
}
.news-block-two .inner-box .content .text {
  position: relative;
  margin-bottom: 24px;
}
.news-block-two .inner-box .content .read-more {
  position: relative;
  font-size: 18px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  border-bottom: 1px solid var(--theme-color1);
  text-transform: uppercase;
}
.news-block-two .inner-box .content .read-more:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
    News Section Three
====================================================================

***/
.news-section-three {
  position: relative;
  padding: 120px 0 120px;
}
.news-section-three .btn-box {
  margin-top: 30px;
}

.news-block-three {
  margin-bottom: 115px;
}
@media (max-width: 991.98px) {
  .news-block-three {
    margin-bottom: 50px;
  }
}
.news-block-three.style-two {
  margin-bottom: 50px;
}
.news-block-three.style-two .inner-box .content-box {
  padding-left: 0;
  padding-right: 92px;
}
@media (max-width: 1199.98px) {
  .news-block-three.style-two .inner-box .content-box {
    padding-right: 15px;
  }
}
@media (max-width: 991.98px) {
  .news-block-three.style-two .inner-box .content-box {
    padding-right: 0;
  }
}
.news-block-three .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991.98px) {
  .news-block-three .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.news-block-three .inner-box:hover .image-box .image a:after {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.news-block-three .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.news-block-three .inner-box .image-box {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.news-block-three .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  z-index: 3;
}
@media (max-width: 991.98px) {
  .news-block-three .inner-box .image-box .image {
    width: 100%;
    height: 100%;
  }
}
.news-block-three .inner-box .image-box .image img {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 991.98px) {
  .news-block-three .inner-box .image-box .image img {
    width: 100%;
    height: 100%;
  }
}
.news-block-three .inner-box .image-box .bg-overlay-6 {
  position: absolute;
  top: -18px;
  left: 5px;
}
@media (max-width: 991.98px) {
  .news-block-three .inner-box .image-box .bg-overlay-6 {
    display: none;
  }
}
.news-block-three .inner-box .content-box {
  position: relative;
  padding-left: 72px;
  padding-top: 18px;
}
@media (max-width: 1199.98px) {
  .news-block-three .inner-box .content-box {
    padding-left: 15px;
  }
}
@media (max-width: 991.98px) {
  .news-block-three .inner-box .content-box {
    padding-left: 0;
  }
}
.news-block-three .inner-box .content-box .sub-title {
  position: relative;
  display: inline-block;
  letter-spacing: 0.12em;
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.news-block-three .inner-box .content-box .sub-title:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  border-bottom: 4px solid var(--theme-color1);
}
.news-block-three .inner-box .content-box .title {
  position: relative;
  color: var(--theme-color-dark);
  font-family: var(--text-font);
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
}
@media (max-width: 575.98px) {
  .news-block-three .inner-box .content-box .title {
    font-size: 24px;
  }
}
.news-block-three .inner-box .content-box .title a:hover {
  color: var(--theme-color1);
}
.news-block-three .inner-box .content-box .text {
  position: relative;
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .news-block-three .inner-box .content-box .text {
    margin-bottom: 20px;
  }
}
.news-block-three .inner-box .content-box .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.news-block-three .inner-box .content-box .author-box img {
  position: relative;
  border-radius: 50%;
  outline: 1px solid var(--theme-color-dark) !important;
  margin-right: 13px;
}
.news-block-three .inner-box .content-box .author-box .date {
  position: relative;
  color: var(--theme-color-dark);
  font-family: var(--title-font);
  font-size: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.news-block-three .inner-box .content-box .author-box .name {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--style-font);
  margin-bottom: 0;
}
.news-block-three .inner-box .content-box .author-box .name span {
  color: #444444;
  font-weight: 800;
  text-decoration: underline;
}
.news-block-three.style-two .content-box {
  padding-left: 20px;
  padding-right: 50px;
}

/*** 

====================================================================
    News Section
====================================================================

***/
.news-section-four {
  position: relative;
  padding: 120px 0 90px;
}
@media (max-width: 1199.98px) {
  .news-section-four {
    padding: 84px 0 60px;
  }
}
@media (max-width: 991.98px) {
  .news-section-four {
    padding: 84px 0 30px;
  }
}
.news-section-four .sec-title {
  margin-bottom: 46px;
}
.news-section-four .btn-box {
  margin-top: 25px;
}

.news-block-four {
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .news-block-four {
    margin-bottom: 60px;
  }
}
@media (max-width: 575.98px) {
  .news-block-four {
    margin-bottom: 30px;
  }
}
.news-block-four .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
}
.news-block-four .inner-box:hover .image img:first-child {
  -webkit-transform: translateX(0) scaleX(1);
          transform: translateX(0) scaleX(1);
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.news-block-four .inner-box:hover .image img:nth-child(2) {
  -webkit-transform: translateX(-50%) scaleX(2);
          transform: translateX(-50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-four .inner-box:hover .content .post-meta::before {
  background-color: var(--theme-color-dark);
}
.news-block-four .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block-four .inner-box .image img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-block-four .inner-box .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transform: translateX(50%) scaleX(2);
          transform: translateX(50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-four .inner-box .content {
  position: relative;
  margin-top: -25px;
}
.news-block-four .inner-box .content .post-meta {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  margin-left: 5px;
  z-index: 1;
}
.news-block-four .inner-box .content .post-meta::before {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 11px;
  height: 100%;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-28deg);
          transform: skewX(-28deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .news-block-four .inner-box .content .post-meta::before {
    display: none;
  }
}
.news-block-four .inner-box .content .post-meta li {
  position: relative;
  color: var(--theme-color-dark);
  font-family: var(--title-font);
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0% 100%);
  background-color: var(--theme-color1);
  padding: 10px 45px;
}
.news-block-four .inner-box .content .post-meta li i {
  position: relative;
  color: var(--theme-color-dark);
  line-height: 1em;
  margin-right: 5px;
}
.news-block-four .inner-box .content .title {
  margin-bottom: 14px;
  position: relative;
}
.news-block-four .inner-box .content .title a:hover {
  color: var(--theme-color-dark);
}
.news-block-four .inner-box .content .text {
  margin-bottom: 15px;
}
.news-block-four .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 2px;
}
.news-block-four .inner-box .content .author-box img {
  position: relative;
  border-radius: 50%;
  margin-right: 13px;
}
.news-block-four .inner-box .content .author-box .name {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--style-font);
  margin-bottom: 0;
}
.news-block-four .inner-box .content .author-box .name span {
  color: #444444;
  font-weight: 800;
  text-decoration: underline;
}

/*** 

====================================================================
    News Section Five
====================================================================

***/
.news-section-five {
  background-color: var(--theme-color-dark);
  position: relative;
  padding: 114px 0 82px;
}
@media (max-width: 1199.98px) {
  .news-section-five {
    padding: 84px 0 52px;
  }
}
@media (max-width: 991.98px) {
  .news-section-five {
    padding: 84px 0 60px;
  }
}
.news-section-five .sec-title {
  margin-bottom: 70px;
}
@media (max-width: 991.98px) {
  .news-section-five .sec-title {
    margin-bottom: 40px;
  }
}

.news-block-five .inner-box {
  margin-bottom: 38px;
  position: relative;
}
.news-block-five .inner-box .content {
  border: 1px solid rgba(var(--theme-color-light-rgb), 0.2);
  position: relative;
  padding: 24px 30px 31px;
}
@media (max-width: 575.98px) {
  .news-block-five .inner-box .content {
    padding: 24px 15px 31px;
  }
}
.news-block-five .inner-box .content .post-meta {
  border-bottom: 1px solid rgba(var(--theme-color-light-rgb), 0.2);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 11px;
  margin-bottom: 23px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .news-block-five .inner-box .content .post-meta {
    padding: 13px 0;
  }
}
@media (max-width: 575.98px) {
  .news-block-five .inner-box .content .post-meta {
    display: block;
  }
}
.news-block-five .inner-box .content .post-meta li {
  position: relative;
  color: #B8B8B8;
  margin-right: 20px;
}
.news-block-five .inner-box .content .post-meta li:last-child {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  .news-block-five .inner-box .content .post-meta li {
    margin-right: 20px;
  }
}
.news-block-five .inner-box .content .post-meta li i {
  position: relative;
  color: var(--theme-color1);
  line-height: 1em;
  margin-right: 5px;
}
.news-block-five .inner-box .content .title {
  color: var(--theme-color-light);
  margin-bottom: 0;
  position: relative;
}
.news-block-five .inner-box .content .title a:hover {
  color: var(--theme-color1);
}

.news-block-six {
  margin-bottom: 30px;
}
.news-block-six .inner-box {
  position: relative;
}
.news-block-six .inner-box:hover .image img:first-child {
  -webkit-transform: translateX(0) scaleX(1);
          transform: translateX(0) scaleX(1);
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.news-block-six .inner-box:hover .image img:nth-child(2) {
  -webkit-transform: translateX(-50%) scaleX(2);
          transform: translateX(-50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-six .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block-six .inner-box .image img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-block-six .inner-box .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transform: translateX(50%) scaleX(2);
          transform: translateX(50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-six .inner-box .content {
  background-color: var(--theme-color-light);
  border: 1px solid rgba(var(--theme-color-light-rgb), 0.2);
  padding: 24px 30px;
  max-width: 470px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .news-block-six .inner-box .content {
    position: relative;
    max-width: none;
    padding: 24px 12px;
  }
}
.news-block-six .inner-box .content .post-meta {
  border-bottom: 1px solid rgba(22, 23, 26, 0.1);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 11px;
  margin-bottom: 23px;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .news-block-six .inner-box .content .post-meta {
    display: block;
  }
}
.news-block-six .inner-box .content .post-meta li {
  position: relative;
  color: var(--text-color);
  margin-right: 20px;
}
.news-block-six .inner-box .content .post-meta li:last-child {
  margin-right: 0;
}
@media (max-width: 575.98px) {
  .news-block-six .inner-box .content .post-meta li {
    margin-right: 10px;
  }
}
@media (max-width: 649.98px) {
  .news-block-six .inner-box .content .post-meta li {
    margin-right: 40px;
  }
}
.news-block-six .inner-box .content .post-meta li i {
  position: relative;
  color: var(--theme-color1);
  line-height: 1em;
  margin-right: 5px;
}
.news-block-six .inner-box .content .title {
  margin-bottom: 15px;
  position: relative;
}
.news-block-six .inner-box .content .title a:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
    News Section Six
====================================================================

***/
.news-section-six {
  position: relative;
  padding: 120px 0 90px;
}
@media (max-width: 1199.98px) {
  .news-section-six {
    padding: 84px 0 60px;
  }
}
.news-section-six .sec-title {
  margin-bottom: 46px;
}
.news-section-six .btn-box {
  margin-top: 25px;
}

.news-block-seven {
  margin-bottom: 30px;
}
.news-block-seven .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
}
.news-block-seven .inner-box:hover .image img:first-child {
  -webkit-transform: translateX(0) scaleX(1);
          transform: translateX(0) scaleX(1);
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.news-block-seven .inner-box:hover .image img:nth-child(2) {
  -webkit-transform: translateX(-50%) scaleX(2);
          transform: translateX(-50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-seven .inner-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block-seven .inner-box .image img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-block-seven .inner-box .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transform: translateX(50%) scaleX(2);
          transform: translateX(50%) scaleX(2);
  opacity: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.news-block-seven .inner-box .content {
  position: relative;
  margin-top: -52px;
}
.news-block-seven .inner-box .content .post-meta {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  padding: 13px 30px 9px 20px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .news-block-seven .inner-box .content .post-meta {
    padding: 13px 10px 9px;
  }
}
.news-block-seven .inner-box .content .post-meta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 5px);
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(0% 0, 100% 0, 91% 100%, 0% 100%);
  background-color: var(--theme-color1);
}
.news-block-seven .inner-box .content .post-meta li {
  position: relative;
  color: var(--text-color-bg-theme-color1);
  margin-right: 15px;
}
@media (max-width: 1199.98px) {
  .news-block-seven .inner-box .content .post-meta li {
    font-size: 15px;
  }
}
.news-block-seven .inner-box .content .post-meta li i {
  position: relative;
  color: var(--text-color-bg-theme-color1);
  line-height: 1em;
  margin-right: 5px;
}
.news-block-seven .inner-box .content .title {
  margin-bottom: 14px;
  position: relative;
}
.news-block-seven .inner-box .content .title a:hover {
  color: var(--theme-color-dark);
}
.news-block-seven .inner-box .content .text {
  margin-bottom: 15px;
}
.news-block-seven .inner-box .content .author-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 2px;
}
.news-block-seven .inner-box .content .author-box img {
  position: relative;
  border-radius: 50%;
  margin-right: 13px;
}
.news-block-seven .inner-box .content .author-box .name {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--style-font);
  margin-bottom: 0;
}
.news-block-seven .inner-box .content .author-box .name span {
  color: #444444;
  font-weight: 800;
  text-decoration: underline;
}

/*** 
====================================================================
  Work Section
====================================================================
***/
.work-section {
  padding: 120px 0 120px;
  position: relative;
}
@media (max-width: 991.98px) {
  .work-section {
    padding-bottom: 90px;
  }
}
.work-section .sec-title {
  margin-bottom: 85px;
}

.work-block {
  margin-bottom: 30px;
}
.work-block .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  position: relative;
}
@media (max-width: 991.98px) {
  .work-block .inner-box {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .work-block .inner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
.work-block .inner-box::before {
  content: "";
  position: absolute;
  top: -55px;
  left: 65px;
  width: 155px;
  height: 304px;
  opacity: 0.3;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-24deg);
          transform: skewX(-24deg);
}
@media (max-width: 991.98px) {
  .work-block .inner-box::before {
    display: none;
  }
}
.work-block .inner-box:hover .icon {
  background-color: var(--theme-color1);
  border-color: var(--theme-color1);
}
.work-block .inner-box .icon {
  position: relative;
  width: 110px;
  height: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 60px;
  border-radius: 50%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  border: 1px solid var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .work-block .inner-box .icon {
    margin-right: 0;
    margin: 0 auto 10px;
  }
}
.work-block .inner-box .content {
  position: relative;
  padding-top: 5px;
}
.work-block .inner-box .content .title {
  position: relative;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.work-block .inner-box .content .text {
  position: relative;
  padding-right: 10px;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .work-block .inner-box .content .text {
    padding-right: 0;
  }
}

/*** 
====================================================================
  Work Section Two
====================================================================
***/
.work-section-two {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 991.98px) {
  .work-section-two {
    padding: 20px 0 120px;
  }
}
.work-section-two .bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-color: var(--theme-color-dark);
}
.work-section-two .sec-title {
  margin-bottom: 15px;
}
.work-section-two .map-box {
  position: relative;
}
@media (max-width: 575.98px) {
  .work-section-two .map-box {
    max-width: 320px;
    margin: 0 auto;
  }
}
.work-section-two .map-box .image {
  position: relative;
  margin-bottom: 0;
  display: block;
  z-index: 3;
}
@media (max-width: 991.98px) {
  .work-section-two .map-box .image {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .work-section-two .map-box .image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.map-info-block {
  position: absolute;
  bottom: 380px;
  left: 90px;
}
@media (max-width: 767.98px) {
  .map-info-block {
    bottom: 320px;
    left: -30px;
  }
}
@media (max-width: 575.98px) {
  .map-info-block {
    bottom: 180px;
    left: -100px;
  }
}
.map-info-block .inner-box {
  position: relative;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 3;
}
.map-info-block .inner-box:hover .image-box {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .map-info-block .inner-box:hover .image-box {
    opacity: 0;
  }
}
.map-info-block .inner-box .icon {
  content: "";
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  width: 68px;
  height: 68px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 42px;
  border-radius: 50%;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  border: 1px solid var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.map-info-block .inner-box .icon:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  background-color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: -1;
}
@media (max-width: 575.98px) {
  .map-info-block .inner-box .icon:after {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}
.map-info-block .inner-box .image-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 10px;
  padding: 17px 19px;
  background-color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .map-info-block .inner-box .image-box {
    padding: 10px;
    margin-bottom: 0;
  }
}
.map-info-block .inner-box .image-box:before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 15px solid #ffee02;
}
.map-info-block .inner-box .image {
  position: relative;
  display: block;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .map-info-block .inner-box .image {
    width: 50%;
  }
}
.map-info-block .inner-box .content {
  position: relative;
  padding-left: 20px;
}
.map-info-block .inner-box .title {
  position: relative;
  color: #b7b7b7;
  font-family: var(--text-font);
  font-size: 16px;
  max-width: 140px;
  margin-bottom: 0;
}
.map-info-block.style-two {
  position: absolute;
  top: -30px;
  right: 45px;
  left: auto;
  bottom: auto;
}
@media (max-width: 991.98px) {
  .map-info-block.style-two {
    top: -120px;
    right: 0;
  }
}
@media (max-width: 575.98px) {
  .map-info-block.style-two {
    top: -30px;
    right: -90px;
  }
}
.map-info-block.style-three {
  position: absolute;
  bottom: 360px;
  left: 390px;
}
@media (max-width: 991.98px) {
  .map-info-block.style-three {
    bottom: 150px;
    left: 20px;
  }
}
@media (max-width: 991.98px) {
  .map-info-block.style-three {
    bottom: 80px;
    left: -70px;
  }
}
.map-info-block.style-four {
  position: absolute;
  bottom: 200px;
  right: 60px;
  left: auto;
}
@media (max-width: 991.98px) {
  .map-info-block.style-four {
    bottom: 130px;
    right: -20px;
  }
}
@media (max-width: 991.98px) {
  .map-info-block.style-four {
    bottom: 200px;
    right: -80px;
  }
}

/*** 

====================================================================
    Booking Section
====================================================================

***/
.booking-section {
  position: relative;
  padding: 155px 0;
}
.booking-section.pull-up {
  margin-top: -183px;
  padding: 0 0 114px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .booking-section.pull-up {
    margin-top: 0;
    padding: 90px 0;
  }
}
@media (max-width: 1199.98px) {
  .booking-section.pull-up .auto-container {
    padding: 0;
  }
}
.booking-section .outer-box {
  background-color: #1F1D1D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0 45px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .booking-section .outer-box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .booking-section .outer-box {
    padding: 0 15px;
  }
}
.booking-section .outer-box::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 95px;
  height: 100%;
  width: 235px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-19deg);
          transform: skew(-19deg);
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .booking-section .outer-box::before {
    display: none;
  }
}
.booking-section .outer-box::after {
  background-image: url(../images/icons/icon-car-5.png);
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 80px);
  height: 238px;
  width: 425px;
}
@media (max-width: 1199.98px) {
  .booking-section .outer-box::after {
    display: none;
  }
}
.booking-section .outer-box .sec-title {
  margin-bottom: 32px;
}
.booking-section .outer-box .sec-title .sub-title {
  color: var(--theme-color1);
}
.booking-section .outer-box .sec-title h2 {
  font-size: 48px;
}
@media (max-width: 767.98px) {
  .booking-section .outer-box .sec-title h2 {
    font-size: 40px;
  }
}
.booking-section .outer-box .booking-form {
  background-color: transparent;
  max-width: 675px;
  padding: 64px 0 54px;
  position: relative;
  margin-bottom: 0;
}
.booking-section .outer-box .booking-form::after {
  background-image: url(../images/icons/shape-4.png);
  content: "";
  position: absolute;
  right: -45px;
  top: 0;
  height: 100%;
  width: 9.6px;
}
@media (max-width: 1199.98px) {
  .booking-section .outer-box .booking-form::after {
    display: none;
  }
}
.booking-section .outer-box .booking-form .form-group {
  margin-bottom: 16px;
}
.booking-section .outer-box .booking-form .select2-container--default .select2-selection--single,
.booking-section .outer-box .booking-form input:not([type=submit]),
.booking-section .outer-box .booking-form textarea,
.booking-section .outer-box .booking-form select {
  color: var(--theme-color-light);
  background-color: transparent;
  border: 1px solid rgba(115, 115, 115, 0.5);
}
.booking-section .outer-box .booking-form .select2-container--default .select2-selection--single ::-webkit-input-placeholder,
.booking-section .outer-box .booking-form input:not([type=submit]) ::-webkit-input-placeholder,
.booking-section .outer-box .booking-form textarea ::-webkit-input-placeholder,
.booking-section .outer-box .booking-form select ::-webkit-input-placeholder {
  color: var(--theme-color-light);
}
.booking-section .outer-box .booking-form .input-outer .icon {
  color: #B8B8B8;
}
@media (max-width: 991.98px) {
  .booking-section .outer-box .booking-form .theme-btn {
    margin-top: 0;
  }
}

.booking-form {
  position: relative;
  max-width: 758px;
  padding: 77px 50px 0px 65px;
  margin-bottom: 70px;
  background-color: var(--theme-color-light);
}
@media (max-width: 991.98px) {
  .booking-form {
    padding: 50px 30px;
  }
}
@media (max-width: 575.98px) {
  .booking-form {
    padding: 50px 15px;
    max-width: 100%;
  }
}
.booking-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.booking-form .form-group:last-child {
  margin-bottom: 0;
}
.booking-form .boxed-outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.booking-form .boxed-outer-box .boxed {
  width: 100%;
}
.booking-form .boxed-outer-box label {
  display: inline-block;
  width: 100%;
  height: 66px;
  padding: 20px 25px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-dark);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-form .boxed-outer-box input[type=radio] {
  display: none;
}
.booking-form .boxed-outer-box input[type=radio]:checked + label {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}
.booking-form .input-outer {
  position: relative;
}
.booking-form .input-outer .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 16px;
  line-height: 1em;
  color: var(--theme-color-dark);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.booking-form .select2-container--default .select2-selection--single,
.booking-form input:not([type=submit]),
.booking-form textarea,
.booking-form select {
  position: relative;
  display: block;
  height: 53px;
  width: 100%;
  padding: 20px 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #444444;
  background-color: var(--theme-color-light);
  border: 1px solid #e3e3e3;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-form ::-webkit-input-placeholder {
  color: #444444;
}
.booking-form input:focus,
.booking-form select:focus,
.booking-form textarea:focus {
  border-color: var(--theme-color-dark);
}
.booking-form .theme-btn {
  margin-top: 24px;
  padding: 22px 95px 22px 60px;
}

.booking-tabs {
  position: relative;
  counter-reset: count;
}
.booking-tabs .tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
  .booking-tabs .tab-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.booking-tabs .tab-btns .tab-btn {
  position: relative;
  width: 100%;
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  padding: 20px 25px;
  color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-dark);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
  z-index: 1;
}
.booking-tabs .tab-btns .tab-btn:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  counter-increment: count;
}
.booking-tabs .tab-btns .tab-btn.active-btn {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}

/*** 

====================================================================
    Booking Section Two
====================================================================

***/
.booking-section-two {
  position: relative;
  padding: 120px 0 70px;
}
.booking-section-two .sec-title {
  margin-bottom: 85px;
}
@media (max-width: 767.98px) {
  .booking-section-two .sec-title {
    margin-bottom: 40px;
  }
}
.booking-section-two .booking-tab {
  margin-bottom: 50px;
  margin-right: 35px;
}
@media (max-width: 1199.98px) {
  .booking-section-two .booking-tab {
    margin-right: 0;
  }
}
.booking-section-two .booking-tab .tab-btns {
  position: relative;
}
.booking-section-two .booking-tab .tab-btns .tab-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 13px 7px 28px;
  width: 100%;
  cursor: pointer;
  font-size: 22px;
  letter-spacing: 0.04em;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  border: 1px solid #c5c5c5;
  margin-bottom: 11px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-section-two .booking-tab .tab-btns .tab-btn i {
  font-size: 20px;
  color: #c5c5c5;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-section-two .booking-tab .tab-btns .tab-btn.active-btn, .booking-section-two .booking-tab .tab-btns .tab-btn:hover {
  border-color: var(--theme-color1);
}
.booking-section-two .booking-tab .tab-btns .tab-btn.active-btn i, .booking-section-two .booking-tab .tab-btns .tab-btn:hover i {
  color: var(--theme-color1);
}
.booking-section-two .form-column {
  margin-bottom: 50px;
}
.booking-section-two .form-column .inner-column {
  margin-left: -5px;
  margin-right: 9px;
}
@media (max-width: 1199.98px) {
  .booking-section-two .form-column .inner-column {
    margin-right: 0;
    margin-left: 0;
  }
}
.booking-section-two .form-column .booking-form.style-two {
  position: relative;
  padding: 48px 52px 44px;
  border: none;
  margin-bottom: 0;
  background-color: var(--theme-color-dark);
  z-index: 1;
}
.booking-section-two .form-column .booking-form.style-two:before {
  display: none;
}
@media (max-width: 1199.98px) {
  .booking-section-two .form-column .booking-form.style-two {
    padding: 30px 30px;
  }
}
@media (max-width: 575.98px) {
  .booking-section-two .form-column .booking-form.style-two {
    padding: 30px 15px;
  }
}
.booking-section-two .form-column .booking-form.style-two .input-outer .icon {
  color: #b0b0b0;
}
.booking-section-two .form-column .booking-form.style-two .select2-container--default .select2-selection--single,
.booking-section-two .form-column .booking-form.style-two input:not([type=submit]),
.booking-section-two .form-column .booking-form.style-two textarea,
.booking-section-two .form-column .booking-form.style-two select {
  height: 66px;
  color: #b0b0b0;
  border: 1px solid #858585;
  background-color: var(--theme-color-dark);
}
.booking-section-two .form-column .booking-form.style-two ::-webkit-input-placeholder {
  color: #b0b0b0;
}
.booking-section-two .form-column .booking-form.style-two input:focus,
.booking-section-two .form-column .booking-form.style-two select:focus,
.booking-section-two .form-column .booking-form.style-two textarea:focus {
  border-color: var(--theme-color1);
}
.booking-section-two .form-column .booking-form.style-two .theme-btn {
  margin-top: 8px;
}
.booking-section-two .video-column {
  margin-bottom: 50px;
}
.booking-section-two .video-column .inner-column {
  position: relative;
  margin-left: -34px;
  margin-right: -20px;
  margin-bottom: 14px;
}
@media (max-width: 1199.98px) {
  .booking-section-two .video-column .inner-column {
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  .booking-section-two .video-column .inner-column {
    margin-bottom: 0;
    margin-left: 0;
  }
}
.booking-section-two .video-column .inner-column:before {
  content: "";
  position: absolute;
  top: -50px;
  right: 230px;
  width: 455px;
  height: 621px;
  background-color: var(--theme-color1);
  -webkit-transform: skewX(-30deg);
          transform: skewX(-30deg);
}
@media (max-width: 991.98px) {
  .booking-section-two .video-column .inner-column:before {
    display: none;
  }
}
.booking-section-two .video-column .inner-column .image {
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .booking-section-two .video-column .inner-column .image {
    width: 550px;
    height: 650px;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .booking-section-two .video-column .inner-column .image {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .booking-section-two .video-column .inner-column .image {
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .booking-section-two .video-column .inner-column .image img {
    width: 100%;
    height: 100%;
  }
}
.booking-section-two .video-column .inner-column .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.booking-form-two {
  position: relative;
  max-width: 758px;
  padding: 70px 50px 45px;
  margin-bottom: 70px;
  background-color: var(--theme-color-dark);
}
@media (max-width: 991.98px) {
  .booking-form-two {
    padding: 50px 30px;
  }
}
@media (max-width: 575.98px) {
  .booking-form-two {
    padding: 50px 15px;
    max-width: 100%;
  }
}
.booking-form-two .sec-title {
  margin-bottom: 42px;
}
.booking-form-two .bg-image2::after {
  background-image: url(../images/icons/shape-6.png);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
}
.booking-form-two .form-group {
  position: relative;
  margin-bottom: 18px;
}
.booking-form-two .form-group:last-child {
  margin-bottom: 0;
}
.booking-form-two .form-group label {
  color: #B8B8B8;
  font-size: 14px;
  margin-bottom: 8px;
}
.booking-form-two .boxed-outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.booking-form-two .boxed-outer-box .boxed {
  width: 100%;
}
.booking-form-two .boxed-outer-box label {
  display: inline-block;
  width: 100%;
  height: 66px;
  padding: 20px 25px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-dark);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-form-two .boxed-outer-box input[type=radio] {
  display: none;
}
.booking-form-two .boxed-outer-box input[type=radio]:checked + label {
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
}
.booking-form-two .input-outer {
  position: relative;
}
.booking-form-two .input-outer .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 16px;
  line-height: 1em;
  color: var(--theme-color-dark);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.booking-form-two .select2-container--default .select2-selection--single,
.booking-form-two input:not([type=submit]),
.booking-form-two textarea,
.booking-form-two select {
  position: relative;
  display: block;
  height: 53px;
  width: 100%;
  padding: 20px 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #444444;
  background-color: var(--theme-color-light);
  border: 1px solid #e3e3e3;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-form-two ::-webkit-input-placeholder {
  color: #444444;
}
.booking-form-two input:focus,
.booking-form-two select:focus,
.booking-form-two textarea:focus {
  border-color: var(--theme-color-dark);
}
.booking-form-two .theme-btn {
  margin-top: 39px;
  padding: 16.5px 85px 16.5px 50px;
}
@media (max-width: 1199.98px) {
  .booking-form-two .theme-btn {
    padding: 16.5px 10px 16.5px 15px;
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .booking-form-two .theme-btn .btn-title {
    margin-left: -30px;
  }
}

/*** 

====================================================================
    Booking Section Three
====================================================================

***/
.booking-section-three {
  position: relative;
  padding: 100px 0 113px;
}
.booking-section-three.pull-top {
  margin-top: -328px;
  padding-bottom: 0;
}
.booking-section-three .outer-box {
  background-color: var(--theme-color1);
  position: relative;
}
.booking-section-three .outer-box:before {
  content: "";
  background-image: url("../images/icons/shape-5.png");
  width: 455px;
  height: 425px;
  position: absolute;
  bottom: -2px;
  left: calc(50% + 39px);
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .booking-section-three .outer-box:before {
    display: none;
  }
}
.booking-section-three .outer-box:after {
  content: "";
  background-image: url("../images/resource/man3.png");
  width: 379px;
  height: 523px;
  position: absolute;
  bottom: -2px;
  left: calc(50% + 102px);
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .booking-section-three .outer-box:after {
    display: none;
  }
}
.booking-section-three .content-column .inner-column {
  position: relative;
  padding: 72px 50px 79px;
}
@media (max-width: 575.98px) {
  .booking-section-three .content-column .inner-column {
    padding: 72px 15px 79px;
  }
}
.booking-section-three .content-column .inner-column .sec-title {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .booking-section-three .content-column .inner-column .sec-title {
    text-align: center;
  }
}
.booking-section-three .content-column .inner-column .sec-title h2 {
  line-height: 1.2;
}
.booking-section-three .content-column .inner-column .sec-title .theme-btn {
  margin-top: 42px;
}

/*** 

====================================================================
    Booking Section Four
====================================================================

***/
.booking-section-four {
  position: relative;
  padding: 120px 0 78px;
}
@media (max-width: 1199.98px) {
  .booking-section-four {
    padding: 90px 0 58px;
  }
}
.booking-section-four .outer-box {
  background-color: var(--theme-color1);
  position: relative;
}
.booking-section-four .outer-box:before {
  content: "";
  background-image: url("../images/resource/image-1.jpg");
  width: 470px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .booking-section-four .outer-box:before {
    display: none;
  }
}
.booking-section-four .outer-box:after {
  content: "";
  background-color: var(--theme-color1);
  width: calc(100% - 310px);
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 310px;
  clip-path: polygon(0 0, 100% 0, 82% 100%, 0 100%);
  pointer-events: none;
}
.booking-section-four .content-column .inner-column {
  position: relative;
  padding: 58px 50px 61px;
}
@media (max-width: 1199.98px) {
  .booking-section-four .content-column .inner-column {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .booking-section-four .content-column .inner-column {
    padding: 58px 15px 61px;
  }
}
.booking-section-four .content-column .inner-column::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 350px;
  height: 179px;
  width: 82px;
  background-color: var(--theme-color-dark);
  -webkit-transform: skew(-20deg) translateY(-50%);
          transform: skew(-20deg) translateY(-50%);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .booking-section-four .content-column .inner-column::after {
    display: none;
  }
}
.booking-section-four .content-column .inner-column .sec-title {
  margin-bottom: 0;
}
.booking-section-four .content-column .inner-column .sec-title h2 {
  line-height: 1.2;
}
.booking-section-four .content-column .inner-column .sec-title .theme-btn {
  margin-top: 42px;
}

/*** 

====================================================================
    Booking Section Five
====================================================================

***/
.booking-section-five {
  padding: 120px 0;
  position: relative;
}
@media (max-width: 1199.98px) {
  .booking-section-five {
    padding: 84px 0 90px;
  }
}
.booking-section-five .outer-box {
  position: relative;
}
.booking-section-five .bg-shape2 {
  height: 324px;
  position: absolute;
  right: 0;
  top: -120px;
  width: 100%;
  z-index: 0;
}
@media (max-width: 1199.98px) {
  .booking-section-five .bg-shape2 {
    height: 264px;
    top: -84px;
  }
}
@media (max-width: 767.98px) {
  .booking-section-five .bg-shape2 {
    display: none;
  }
}
.booking-section-five .bg-shape2::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% + 214px);
  width: 229px;
  background-color: var(--theme-color1);
  -webkit-transform: skew(-26deg);
          transform: skew(-26deg);
  pointer-events: none;
  z-index: 1;
}
.booking-section-five .bg-shape2::after {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% + 508px);
  width: 117px;
  height: 155px;
  background-color: var(--theme-color-dark);
  -webkit-transform: skew(-26deg);
          transform: skew(-26deg);
  pointer-events: none;
  z-index: 1;
}
.booking-section-five .content-column .inner-column {
  border: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
}
.booking-section-five .content-column .inner-column .image-column {
  position: absolute;
  right: 13px;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .booking-section-five .content-column .inner-column .image-column {
    width: 45%;
  }
}
@media (max-width: 991.98px) {
  .booking-section-five .content-column .inner-column .image-column {
    display: none;
  }
}
.booking-section-five .content-column .inner-column .image-column .image {
  margin-bottom: 0;
}

.booking-form-three {
  position: relative;
  max-width: 541px;
  padding: 46px 30px 32px;
}
@media (max-width: 991.98px) {
  .booking-form-three {
    padding: 50px 30px;
    max-width: none;
  }
}
@media (max-width: 575.98px) {
  .booking-form-three {
    padding: 50px 15px;
    max-width: 100%;
  }
}
.booking-form-three .form-group {
  position: relative;
  margin-bottom: 18px;
}
.booking-form-three .form-group:last-child {
  margin-bottom: 0;
}
.booking-form-three .form-group label {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 8px;
}
.booking-form-three .input-outer {
  position: relative;
}
.booking-form-three .input-outer .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 16px;
  line-height: 1em;
  color: var(--theme-color-dark);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.booking-form-three .select2-container--default .select2-selection--single,
.booking-form-three input:not([type=submit]),
.booking-form-three textarea,
.booking-form-three select {
  position: relative;
  display: block;
  height: 53px;
  width: 100%;
  padding: 20px 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #444444;
  background-color: var(--theme-color-light);
  border: 1px solid rgba(var(--theme-color-dark-rgb), 0.05);
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.booking-form-three ::-webkit-input-placeholder {
  color: #444444;
}
.booking-form-three input:focus,
.booking-form-three select:focus,
.booking-form-three textarea:focus {
  border-color: var(--theme-color-dark);
}
.booking-form-three .theme-btn {
  margin-top: 39px;
  padding: 16.5px 95px 16.5px 60px;
}
@media (max-width: 767.98px) {
  .booking-form-three .theme-btn {
    margin-top: 10px;
  }
}

/*** 

====================================================================
    Map Section
====================================================================

***/
.map-section {
  position: relative;
  min-height: 482px;
}
.map-section .map {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: block;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

/*** 

====================================================================
    Clients Section
====================================================================

***/
.clients-section {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 1199.98px) {
  .clients-section {
    padding: 120px 0 90px;
  }
}
@media (max-width: 991.98px) {
  .clients-section .default-navs .owl-nav {
    display: none !important;
  }
}
.clients-section .sponsors-outer {
  position: relative;
  margin-right: -20px;
}
@media (max-width: 1199.98px) {
  .clients-section .sponsors-outer {
    margin-right: 0;
  }
}

.client-block {
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 3;
}
.client-block .image {
  position: relative;
  overflow: hidden;
  text-align: center;
  min-width: 181px;
  min-height: 160px;
  background-color: #f4f4f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.client-block .image:hover img {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.client-block img {
  position: relative;
  width: auto !important;
  display: inline-block !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*** 

====================================================================
    Clients Section Two
====================================================================

***/
.clients-section-two {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 991.98px) {
  .clients-section-two .default-navs .owl-nav {
    display: none !important;
  }
}
.clients-section-two .sponsors-outer {
  background-color: #dcdcdc;
  position: relative;
}

/***
==================================================================
	Main Footer
==================================================================
***/
.main-footer {
  position: relative;
}
.main-footer .widgets-section {
  padding: 100px 0 60px;
  position: relative;
}
.main-footer .footer-column {
  margin-bottom: 50px;
  position: relative;
}
.main-footer .footer-widget {
  position: relative;
}
.main-footer .widget-title {
  color: var(--theme-color-light);
  display: block;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 37px;
  margin-bottom: 40px;
  padding-bottom: 2px;
  position: relative;
  text-transform: uppercase;
}
.main-footer .widget-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #212121;
}
.main-footer .widget-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 2px;
  background-color: var(--theme-color1);
}
.main-footer .about-widget .logo {
  margin-bottom: 28px;
}
.main-footer .about-widget .text {
  color: #b7b7b7;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}

/*=== User LInks ===*/
.user-links {
  position: relative;
}
.user-links li {
  color: #b7b7b7;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative;
}
.user-links li:last-child {
  margin-bottom: 0;
}
.user-links li a {
  color: inherit;
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover {
  color: var(--theme-color-light);
}
.user-links li a:before {
  background-color: var(--theme-color1);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover:before {
  width: 100%;
}
.user-links li i {
  font-size: 12px;
  font-weight: 300;
  margin-right: 2px;
}
.user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.user-links.two-column li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  padding-right: 15px;
  width: 50%;
}
.user-links.two-column li:last-child {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  width: 100%;
}

.contact-list-two {
  padding: 0;
  position: relative;
}
.contact-list-two li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
@media (max-width: 575.98px) {
  .contact-list-two li br {
    display: none;
  }
}
.contact-list-two li i {
  background-color: var(--theme-color1);
  color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 22px;
  height: 44px;
  margin-right: 45px;
  min-width: 44px;
  position: relative;
  width: 44px;
}
.contact-list-two li i:after {
  background-color: var(--theme-color1);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: -21px;
  width: 21px;
}
.contact-list-two li:last-child {
  margin-bottom: 0;
}
.contact-list-two li a {
  color: var(--theme-color-dark);
  display: inline-block;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-list-two.light li {
  color: #b7b7b7;
}
.contact-list-two.light a {
  color: #b7b7b7;
}
.contact-list-two.light a:hover {
  color: var(--theme-color1);
}

.insta-gallery {
  margin: 0px -7px 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .insta-gallery {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .insta-gallery {
    max-width: 100%;
  }
}
.insta-gallery .image {
  float: left;
  margin-bottom: 14px;
  padding: 0px 8px;
  position: relative;
  width: 33.3333%;
}
.insta-gallery img {
  display: block;
  width: 100%;
}
.insta-gallery a {
  position: relative;
  display: block;
}
.insta-gallery a:hover:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.insta-gallery a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.insta-gallery a:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--theme-color1);
  opacity: 0.8;
  content: "";
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.insta-gallery a:after {
  color: var(--theme-color-dark);
  content: "\f0b2";
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  height: 20px;
  left: 50%;
  line-height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 20px;
}

/* Newsletter Form */
.newsletter-form {
  position: relative;
}
.newsletter-form .form-group {
  position: relative;
}
.newsletter-form .form-group .input-outer {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group .input-outer:before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 50px;
  height: 100%;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
  border: 1px solid #565656;
  border-left: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group .input-outer:hover:before {
  border-color: var(--theme-color1);
}
.newsletter-form .form-group input[type=text],
.newsletter-form .form-group input[type=email] {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  padding: 15px 32px;
  color: var(--theme-color-light);
  background: transparent;
  border: 1px solid #565656;
  border-right: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group input[type=text]::-webkit-input-placeholder, .newsletter-form .form-group input[type=email]::-webkit-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::-moz-placeholder, .newsletter-form .form-group input[type=email]::-moz-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]:-ms-input-placeholder, .newsletter-form .form-group input[type=email]:-ms-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::-ms-input-placeholder, .newsletter-form .form-group input[type=email]::-ms-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::placeholder,
.newsletter-form .form-group input[type=email]::placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input:hover {
  border-color: var(--theme-color1);
}
.newsletter-form .form-group .theme-btn {
  margin-top: 20px;
  padding: 18px 100px 18px 46px;
}

/*=== Footer Bottom ===*/
.footer-bottom {
  position: relative;
  width: 100%;
}
.footer-bottom .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 2px solid #212121;
  padding: 35px 0;
}
@media (max-width: 767.98px) {
  .footer-bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .footer-bottom .inner-container {
    padding: 15px 0;
  }
}
.footer-bottom .copyright-text {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: var(--theme-color-light);
  margin-bottom: 0px;
}
@media (max-width: 575.98px) {
  .footer-bottom .copyright-text {
    font-size: 12px;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
.footer-bottom .copyright-text a {
  color: inherit;
}
.footer-bottom .copyright-text a:hover {
  color: var(--theme-color-light);
}
.footer-bottom .footer-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 575.98px) {
  .footer-bottom .footer-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.footer-bottom .footer-nav li {
  margin-left: 45px;
}
@media (max-width: 767.98px) {
  .footer-bottom .footer-nav li {
    margin-left: 0;
    margin: 0 20px;
  }
}
.footer-bottom .footer-nav li a {
  position: relative;
  color: #b7b7b7;
}
.footer-bottom .footer-nav li a:hover {
  text-decoration: none;
  color: var(--theme-color1);
}
.footer-bottom .footer-nav li i {
  margin-right: 5px;
}

/***

==================================================================
	Main Footer
==================================================================

***/
.footer-style-one {
  position: relative;
}
.footer-style-one .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background-color: var(--theme-color-dark);
}
.footer-style-one .widgets-section {
  padding: 120px 0 55px;
}
@media (max-width: 575.98px) {
  .footer-style-one .widgets-section {
    padding-bottom: 20px;
  }
}
.footer-style-one .about-widget .text {
  padding-right: 10px;
}
.footer-style-one .newsletter-widget {
  padding-left: 8px;
}
.footer-style-one .newsletter-widget .text {
  color: #b7b7b7;
  margin-bottom: 18px;
}

/***

==================================================================
	Main Footer Two
==================================================================

***/
.footer-style-two {
  position: relative;
  background-color: transparent;
}
.footer-style-two:before {
  display: none;
}
.footer-style-two .footer-top {
  position: relative;
}
.footer-style-two .footer-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 64px 0 0;
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
.footer-style-two .footer-top .inner-container .top-center .text {
  position: relative;
  margin-bottom: 0;
  padding-top: 12px;
  margin-left: -70px;
  max-width: 330px;
  color: #b7b7b7;
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container .top-center .text {
    padding-top: 0;
    margin: 20px 0;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 95px 30px 75px;
  background-color: var(--theme-color1);
  text-align: left;
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn {
    padding: 30px 20px;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -65px;
  width: 65px;
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn:before {
    left: -64px;
  }
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn:before {
    display: none;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .icon {
  position: relative;
  font-size: 55px;
  line-height: 1em;
  margin-right: 10px;
  color: var(--theme-color-dark);
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .title {
  position: relative;
  font-size: 20px;
  margin-bottom: 2px;
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .phone-no {
  position: relative;
  font-size: 36px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}
.footer-style-two .widgets-section {
  position: relative;
  padding-top: 90px;
  padding-bottom: 50px;
}
@media (max-width: 575.98px) {
  .footer-style-two .widgets-section {
    padding-bottom: 20px;
  }
}
.footer-style-two .widgets-section .about-widget .text {
  padding-right: 20px;
}
@media (max-width: 991.98px) {
  .footer-style-two .widgets-section .about-widget .text {
    padding-right: 0;
  }
}
.footer-style-two .widgets-section .about-widget .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.footer-style-two .widgets-section .about-widget .btn-box .image {
  margin-right: 15px;
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget {
    margin-right: -15px;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget .user-links li {
    padding-right: 3px;
  }
}
.footer-style-two .widgets-section .links-widget .user-links li i {
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget .user-links li i {
    margin-right: 3px;
  }
}
.footer-style-two .widgets-section .newsletter-widget {
  padding-left: 108px;
  margin-right: -85px;
}
@media (max-width: 991.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    margin-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    padding-right: 60px;
  }
}
@media (max-width: 575.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    padding-right: 15px;
  }
}
.footer-style-two .widgets-section .newsletter-widget .text {
  color: #b7b7b7;
  margin-bottom: 18px;
  margin-right: -10px;
}
.footer-style-two .footer-bottom {
  background-color: #0b0b0b;
}
.footer-style-two .footer-bottom .inner-container {
  border-top: none;
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
@media (max-width: 399.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li {
    margin: 0 10px;
  }
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li:first-child {
    margin-left: 0;
  }
}
@media (max-width: 399.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li a {
    font-size: 14px;
  }
}

/***

==================================================================
	Main Footer Three
==================================================================

***/
.footer-style-three {
  position: relative;
  background-color: transparent;
}
.footer-style-three:before {
  display: none;
}
.footer-style-three .footer-top {
  position: relative;
  padding-top: 90px;
}
.footer-style-three .footer-top .bg-overlay-7 {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 1399.98px) {
  .footer-style-three .footer-top .bg-overlay-7 {
    display: none;
  }
}
.footer-style-three .footer-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 55px 55px 40px;
  background-color: var(--theme-color1);
}
@media (max-width: 1199.98px) {
  .footer-style-three .footer-top .inner-container {
    padding: 50px 30px;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container {
    padding: 40px 15px;
  }
}
.footer-style-three .footer-top .inner-container .top-left .sub-title {
  position: relative;
  letter-spacing: 0.12em;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.footer-style-three .footer-top .inner-container .top-left .title {
  position: relative;
  font-size: 38px;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (max-width: 1199.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 32px;
  }
  .footer-style-three .footer-top .inner-container .top-left .title br {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 30px;
  }
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 24px;
  }
}
.footer-style-three .footer-top .inner-container .top-right {
  position: relative;
  padding-left: 82px;
  padding-right: 34px;
  border-left: 2px solid var(--theme-color-dark);
}
@media (max-width: 991.98px) {
  .footer-style-three .footer-top .inner-container .top-right {
    padding-left: 30px;
    padding-top: 0;
    padding-right: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container .top-right {
    padding-left: 0;
    border-left: none;
    padding-top: 20px;
  }
}
.footer-style-three .footer-top .inner-container .top-right .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .icon {
  position: relative;
  font-size: 55px;
  line-height: 1em;
  margin-right: 15px;
  color: var(--theme-color-dark);
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .title {
  position: relative;
  font-size: 20px;
  margin-bottom: 2px;
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .phone-no {
  position: relative;
  font-size: 36px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container .top-right .info-btn .phone-no {
    font-size: 24px;
  }
}
.footer-style-three .footer-top .inner-container .top-right .btn-box {
  position: relative;
  margin-top: 25px;
}
.footer-style-three .footer-top .inner-container .top-right .btn-box .theme-btn {
  padding: 18px 82px 18px 37px;
}
.footer-style-three .widgets-section {
  position: relative;
  padding: 100px 0 60px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section {
    padding: 85px 0 26px;
  }
}
.footer-style-three .widgets-section .about-widget .text {
  padding-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .about-widget .text br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget {
    margin-right: -15px;
  }
}
.footer-style-three .widgets-section .links-widget .user-links {
  margin-right: -90px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links {
    margin-right: 0;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links li {
    padding-right: 3px;
  }
}
.footer-style-three .widgets-section .links-widget .user-links li i {
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links li i {
    margin-right: 3px;
  }
}
.footer-style-three .widgets-section .gallery-widget {
  padding-top: 5px;
  padding-left: 105px;
  margin-right: -100px;
}
@media (max-width: 1399.98px) {
  .footer-style-three .widgets-section .gallery-widget {
    padding-left: 0;
    margin-right: 0;
  }
}

/***
====================================================================
  Products details
====================================================================
***/
.product-details .bxslider .image-box {
  display: block;
  margin-right: 30px;
  margin-bottom: 10px;
  position: relative;
}
.product-details .bxslider .image-box img {
  width: 100%;
}
.product-details .bxslider .thumb-box li {
  display: inline-block;
  float: left;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 15px;
  position: relative;
  width: 100px;
}
.product-details .bxslider .thumb-box li:last-child {
  margin: 0px !important;
}
.product-details .bxslider .thumb-box li a {
  display: inline-block;
  position: relative;
}
.product-details .bxslider .thumb-box li a:before {
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 100%;
}
.product-details .bxslider .thumb-box li a.active:before {
  opacity: 1;
}
.product-details .bx-wrapper {
  margin-bottom: 30px;
}
.product-details .product-info .product-details__top {
  display: block;
  margin-top: -8px;
  position: relative;
}
.product-details .product-info .product-details__title {
  font-size: 40px;
  line-height: 44px;
  margin: 0;
}
.product-details .product-info .product-details__title span {
  color: var(--theme-color2);
  display: inline-block;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  margin-left: 20px;
  position: relative;
}
.product-details .product-info .product-details__reveiw {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #e0e4e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 31px;
  margin-top: 22px;
  padding-bottom: 20px;
}
.product-details .product-info .product-details__reveiw i {
  font-size: 16px;
  color: var(--review-color);
}
.product-details .product-info .product-details__reveiw i + i {
  margin-left: 4px;
}
.product-details .product-info .product-details__reveiw span {
  color: var(--theme-color2);
  font-size: 16px;
  line-height: 1;
  margin-left: 18px;
  position: relative;
  top: 1px;
}
.product-details .product-info .product-details__quantity-title {
  color: #222;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-right: 20px;
}
.product-details .product-info .product-details__buttons {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons {
    display: block;
  }
}
.product-details .product-info .product-details__buttons-1 {
  display: block;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-1 {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.product-details .product-info .product-details__buttons-2 {
  display: block;
  margin-left: 10px;
  position: relative;
}
.product-details .product-info .product-details__buttons-2 .thm-btn {
  background-color: var(--theme-color2);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:before {
  background-color: var(--theme-color1);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:after {
  background-color: var(--theme-color1);
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 0;
  }
}
.product-details .product-info .product-details__social {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.product-details .product-info .product-details__social .title {
  display: block;
  position: relative;
}
.product-details .product-info .product-details__social .title h3 {
  color: #222;
  font-size: 20px;
  line-height: 20px;
}
.product-details .product-info .product-details__social .social-icon-one {
  margin-left: 30px;
}
.product-details .product-info .product-details__social .social-icon-one a {
  color: var(--theme-color2);
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__social {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  .product-details .product-info .product-details__social .social-icon-one {
    margin-left: 0;
  }
}

.product-details__quantity {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  position: relative;
}
.product-details__quantity .quantity-box {
  border-radius: 10px;
  height: 60px;
  position: relative;
  width: 98px;
}
.product-details__quantity .quantity-box input {
  border: 1px solid #e0e4e8;
  border-radius: 10px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  color: var(--theme-color2);
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  outline: none;
  padding-left: 30px;
  width: 98px;
}
.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--text-color-bg-theme-color1);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--theme-color1);
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-details__quantity .quantity-box button:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}
.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.product-discription {
  position: relative;
  display: block;
}
.product-discription .product-description__title {
  font-size: 30px;
  margin-bottom: 27px;
}
.product-discription .product-description__text1 {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.product-discription .product-description__list {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.product-discription .product-description__list ul {
  position: relative;
  display: block;
}
.product-discription .product-description__list ul li {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .product-description__list ul li:last-child {
  margin-bottom: 0px;
}
.product-discription .product-description__list ul li p {
  margin: 0;
  font-weight: 600;
  color: var(--headings-color);
}
.product-discription .product-description__list ul li p span:before {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 17px;
  line-height: 17px;
  margin-right: 11px;
  top: 2px;
}
.product-discription .tabs-content .text p {
  margin-bottom: 17px;
}
.product-discription .tabs-content .text p:last-child {
  margin-bottom: 0px;
}
.product-discription .tab-btn-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 60px;
}
.product-discription .tab-btn-box:before {
  position: absolute;
  content: "";
  background-color: #e1e8e4;
  width: 100%;
  height: 1px;
  left: 0px;
  top: 28px;
}
.product-discription .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  color: #1e2434;
  text-align: center;
  padding: 14px 30px;
  background-color: #fff;
  border: 1px solid #e1e8e4;
  cursor: pointer;
  margin: 0px 8.5px;
  margin-bottom: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-discription .tab-btn-box .tab-btns li.active-btn {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.product-discription .tab-btn-box .tab-btns li:last-child {
  margin-bottom: 0;
}
.product-discription .single-comment-box .inner-box {
  position: relative;
  display: block;
  background-color: #f4f5f4;
  padding: 34px 30px 34px 125px;
}
.product-discription .single-comment-box .inner-box .comment-thumb {
  position: absolute;
  left: 30px;
  top: 40px;
  border-radius: 50%;
  width: 80px;
}
.product-discription .single-comment-box .inner-box .comment-thumb img {
  width: 100%;
  border-radius: 50%;
}
.product-discription .single-comment-box .inner-box .rating {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .single-comment-box .inner-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .single-comment-box .inner-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .single-comment-box .inner-box h5 {
  display: block;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.product-discription .single-comment-box .inner-box h5 span {
  font-weight: 400;
  text-transform: capitalize;
}
.product-discription .customer-comment {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.product-discription .comment-box {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 51px 60px 60px 60px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.product-discription .comment-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 23px;
}
.product-discription .comment-box .form-group {
  position: relative;
  margin-bottom: 15px;
}
.product-discription .comment-box .form-group label {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #707582;
  margin-bottom: 8px;
}
.product-discription .comment-box .column:last-child .form-group {
  margin-bottom: 0px;
}
.product-discription .comment-box .review-box {
  position: relative;
  display: block;
  margin-top: 8px;
}
.product-discription .comment-box .review-box p {
  position: relative;
  float: left;
  margin-right: 10px;
}
.product-discription .comment-box .review-box .rating {
  position: relative;
  float: left;
}
.product-discription .comment-box .review-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .comment-box .review-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .comment-box .custom-controls-stacked {
  position: relative;
  float: left;
}

.related-product h3 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-details__img {
    margin-bottom: 50px;
  }
  .product-details__title span {
    margin-left: 0;
    display: block;
  }
  .product-details__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 10px;
  }
  .product-details__social-link {
    margin-left: 0;
    margin-top: 20px;
  }
}
/***

====================================================================
 Categories Section
====================================================================

***/
.categories-section {
  position: relative;
  padding: 100px 0 70px;
}
.categories-section .bg-pattern {
  position: absolute;
  left: 0;
  top: -220px;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url(../images/icons/pattern-7.html);
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
}
.categories-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  background: var(--theme-color3);
  content: "";
  z-index: 1;
}
.categories-section:after {
  position: absolute;
  left: 0;
  bottom: -50px;
  height: 70px;
  width: 100%;
  z-index: 2;
  content: "";
  background-image: url(../images/icons/pattern-3.html);
  background-repeat: no-repeat;
  background-position: center bottom;
}

.category-block {
  position: relative;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  padding: 40px 30px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block .inner-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center bottom;
  content: "";
}
.category-block .inner-box:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.category-block .inner-box:hover .image img {
  -webkit-transform: rotate(10deg) scale(1.2);
  transform: rotate(10deg) scale(1.2);
}
.category-block .inner-box.cat-bg-1:before {
  background-image: url(../images/icons/cat-1-bg.html);
}
.category-block .inner-box.cat-bg-2:before {
  background-image: url(../images/icons/cat-2-bg.html);
}
.category-block .inner-box.cat-bg-3:before {
  background-image: url(../images/icons/cat-3-bg.html);
}
.category-block .inner-box.cat-bg-4:before {
  background-image: url(../images/icons/cat-4-bg.html);
}
.category-block .image {
  position: relative;
  display: inline-block;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.category-block .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 {
  font-size: 20px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 10px;
}
.category-block h4 a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 a:hover {
  color: var(--theme-color1);
}
.category-block p {
  font-size: 14px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}

/***

====================================================================
    Categories Section Two
====================================================================

***/
.categories-section-two {
  position: relative;
  padding: 120px 0 90px;
}

.category-block-two {
  position: relative;
  padding-top: 70px;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block-two .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 18px;
}
.category-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 60px;
  width: 100%;
  border-radius: 10px;
  background: #e3eee5;
  content: "";
}
.category-block-two .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.category-block-two .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.category-block-two .inner-box:hover h4 a {
  color: var(--theme-color1);
}
.category-block-two .content {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 40px 40px;
  z-index: 1;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
}
.category-block-two .content:before {
  position: absolute;
  top: -88px;
  left: 0;
  width: 180px;
  height: 180px;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  background: #e3eee5;
  content: "";
}
.category-block-two .content:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  font-size: 30px;
  line-height: 1em;
  color: #e8f3ea;
  height: 15px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  content: attr(data-text);
}
.category-block-two .image {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: -70px;
}
.category-block-two .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 120%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}
.category-block-two h4 {
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 15px;
}
.category-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two p {
  font-size: 16px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}
.category-block-two .link {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #e8f3ea;
  border-radius: 50%;
  line-height: 50px;
  margin-top: 25px;
  color: #608174;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .link:hover {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.category-block-two.child-two .link {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:before {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:hover h4 a {
  color: #e69da2;
}
.category-block-two.child-two .content:before {
  background: #f0e2e3;
}
.category-block-two.child-two .content:after {
  color: #f0e2e3;
}
.category-block-two.child-three .link {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:before {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:hover h4 a {
  color: #c9b579;
}
.category-block-two.child-three .content:before {
  background: #f1ede1;
}
.category-block-two.child-three .content:after {
  color: #f1ede1;
}

/***

====================================================================
    Products Section
====================================================================

***/
.products-section {
  position: relative;
  padding: 120px 0;
}

.products-section .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 670px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.products-section .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #113629;
  opacity: 0.7;
  content: "";
}

.products-section .bg-image:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-image: url(../images/icons/pattern-8.html);
  background-position: center bottom;
  content: "";
}

.products-section .sec-title h2 {
  font-size: 60px;
}

.products-section .sec-title .theme-btn {
  margin-top: 30px;
}

.products-box {
  max-width: 1530px;
  position: relative;
  padding: 120px 60px 90px;
  margin: 120px auto 0;
  background-color: #f7f5ee;
  overflow: hidden;
  border-radius: 10px;
}

.products-box:before {
  position: absolute;
  left: -90px;
  bottom: 0;
  height: 70%;
  width: 100%;
  background: url(../images/icons/shape-7.html) top left no-repeat;
  content: "";
}

.products-box .sec-title {
  margin-bottom: 30px;
}

.products-box .outer-box {
  position: relative;
  padding-right: 400px;
}

.products-box .outer-box .banner-box-two {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-box-two {
  position: relative;
}

.banner-box-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(22, 67, 51)), to(rgba(229, 229, 229, 0)));
  background: linear-gradient(to bottom, rgb(22, 67, 51) 0%, rgba(229, 229, 229, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--theme-color3)", endColorstr="#00e5e5e5",GradientType=0 );
  content: "";
}

.banner-box-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 370px;
  background: var(--theme-color3);
  border-radius: 10px;
  min-height: 440px;
  text-align: center;
  overflow: hidden;
  padding: 20px 20px;
}

.banner-box-two .title {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner-box-two .title strong {
  font-size: 60px;
  line-height: 1em;
  color: var(--theme-color2);
  font-weight: 400;
}

.banner-box-two h4 {
  font-size: 30px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.product-block-two {
  position: relative;
  margin-bottom: 30px;
}

.product-block-two .inner-box {
  position: relative;
  border: 2px solid transparent;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px 20px;
  padding-left: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 150px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover {
  border: 2px solid var(--theme-color1);
}

.product-block-two .image {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 50%;
  overflow: hidden;
  height: 110px;
  width: 110px;
  border: 1px solid #e4e1d6;
  margin-bottom: 0px;
}

.product-block-two .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover .image img {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.product-block-two h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 5px;
}

.product-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--theme-color1);
  font-weight: 600;
}

.product-block-two .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block-two .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #ffc737;
}

.products-carousel .owl-nav {
  display: none;
}

.products-carousel .owl-dots {
  position: absolute;
  right: 0;
  top: -80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 8;
}

.products-carousel .owl-dot {
  position: relative;
  height: 7px;
  width: 7px;
  display: block;
  background: #879d91;
  margin-right: 5px;
  border-radius: 5px;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.products-carousel .owl-dot.active {
  background: var(--theme-color3);
}

/***

====================================================================
    Featured Products
====================================================================

***/
.featured-products {
  position: relative;
  padding: 120px 0 90px;
}

.featured-products .bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/icons/pattern-7.html) left bottom no-repeat;
  background-size: 700px;
}

/*=== Mixitup Gallery ===*/
.featured-products .filters {
  margin-bottom: 40px;
  text-align: center;
}

.featured-products .filters .filter-tabs {
  position: relative;
  display: inline-block;
}

.featured-products .filters li {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 0px 2px 10px;
  cursor: pointer;
  color: #797f7d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 12px 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.featured-products .filters li:last-child {
  margin-right: 0;
}

.featured-products .filters .filter.active,
.featured-products .filters .filter:hover {
  color: var(--theme-color2);
}

.featured-products .filters li:before {
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 2px;
  width: 100%;
  content: "";
  background-color: #ffc737;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
}

.featured-products .filters li.active:before,
.featured-products .filters li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block.mix {
  display: none;
}

.product-block .inner-box {
  position: relative;
  border: 2px solid #e4e1d5;
  border-radius: 10px;
  text-align: center;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover {
  border: 2px solid var(--theme-color2);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.product-block .image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0px;
}

.product-block .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}

.product-block .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.product-block .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.product-block .content {
  position: relative;
  padding: 30px 30px 30px;
}

.product-block h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color1);
  margin-bottom: 5px;
}

.product-block h4 a {
  color: var(--theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block h4 a:hover {
  color: var(--theme-color2);
}

.product-block .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-gray-silver);
  font-weight: 600;
}

.product-block .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #ffc737;
}

.product-block .tag {
  position: absolute;
  top: 30px;
  left: 20px;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  background: #FD5F5C;
  font-weight: 400;
  padding: 0 12px;
  border-radius: 3px;
  z-index: 9;
  font-style: italic;
  text-transform: uppercase;
}

.product-block .icon-box {
  position: absolute;
  right: 20px;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .icon-box {
  top: 20px;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

.product-block .ui-btn {
  background-color: var(--theme-color1);
  border-radius: 50px;
  color: var(--theme-color2);
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .ui-btn:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}

.product-block .cat {
  color: var(--text-color);
  display: block;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 5px;
}

/*
 * shop-catalog-layouts.scss
 * -----------------------------------------------
*/
table.tbl-shopping-cart .product-thumbnail, table.cart-total .product-thumbnail {
  min-width: 64px;
}
table.tbl-shopping-cart img, table.cart-total img {
  width: 64px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
table.tbl-shopping-cart th,
table.tbl-shopping-cart td, table.cart-total th,
table.cart-total td {
  vertical-align: middle;
  border-left: 1px solid #e3e3e3;
  padding: 20px 30px;
}
table.tbl-shopping-cart .product-name a, table.cart-total .product-name a {
  color: var(--headings-color);
}
table.tbl-shopping-cart .product-name .variation, table.cart-total .product-name .variation {
  font-size: 0.9rem;
  list-style: none;
}
table.tbl-shopping-cart .product-remove a, table.cart-total .product-remove a {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background-color: #757575;
  color: #ffffff;
  text-align: center;
}
table.tbl-shopping-cart .coupon-form .apply-button, table.cart-total .coupon-form .apply-button {
  position: relative;
  display: inline-block;
  color: #1e2434;
  background: #f4f5f4;
  padding: 15px 29px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
table.tbl-shopping-cart .coupon-form .apply-button:hover, table.cart-total .coupon-form .apply-button:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}

table.tbl-shopping-cart > thead > tr > th,
table.tbl-shopping-cart > tbody > tr > th,
table.tbl-shopping-cart > tfoot > tr > th {
  color: #444;
}

.payment-method .accordion-box .block {
  background: #f4f5f4;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 20px;
}
.payment-method .accordion-box .block .acc-content .payment-info {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px 30px 10px 30px;
}
.payment-method .accordion-box .block:last-child {
  margin-bottom: 0px;
}
.payment-method .accordion-box .block .acc-btn {
  padding: 19px 30px 22px 30px;
}
.payment-method .accordion-box .block .acc-btn .icon-outer {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 30px;
  height: auto;
  /* line-height: 65px; */
  font-size: 28px;
  color: #1e2434;
}
.payment-method .accordion-box .block .acc-btn.active .icon-outer {
  color: var(--text-color-bg-theme-color1);
}
.payment-method .payment-method h3 {
  margin-bottom: 32px;
}

.shop-sidebar {
  position: relative;
  display: block;
}
.shop-sidebar .sidebar-search {
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-search .search-form .form-group {
  position: relative;
  margin: 0px;
}
.shop-sidebar .sidebar-search .search-form .form-group input[type=search] {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: var(--theme-light-background);
  border: 1px solid var(--theme-light-background);
  border-radius: 5px;
  color: #646578;
  padding: 10px 60px 10px 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button {
  position: absolute;
  display: inline-block;
  top: 5px;
  right: 5px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color2);
}
.shop-sidebar .sidebar-widget {
  position: relative;
  display: block;
  background-color: var(--theme-light-background);
  padding: 35px 30px 37px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .sidebar-widget .widget-title {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.shop-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}
.shop-sidebar .category-widget .category-list li:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  color: #646578;
  font-weight: 400;
  padding-left: 20px;
}
.shop-sidebar .category-widget .category-list li a:before {
  position: absolute;
  content: "\f0da";
  font-family: "Font Awesome 6 Pro";
  left: 0px;
  top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color2);
}
.shop-sidebar .category-widget .category-list li a:hover {
  color: var(--theme-color2);
}
.shop-sidebar .price-filters .widget-title {
  margin-bottom: 28px;
}
.shop-sidebar .post-widget {
  padding-bottom: 9px;
}
.shop-sidebar .post-widget .post {
  position: relative;
  padding-left: 90px;
  padding-bottom: 24px;
  margin-bottom: 23px;
  min-height: 108px;
  border-bottom: 1px solid #e1e1e1;
}
.shop-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.shop-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 7px;
  width: 70px;
  height: 70px;
  border: 1px solid #d0d4dd;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 5px;
}
.shop-sidebar .post-widget .post a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #646578;
  margin-bottom: 7px;
}
.shop-sidebar .post-widget .post .price {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0a267a;
}
.shop-sidebar .post-widget .post:hover .post-thumb {
  border-color: var(--theme-color1);
}
.shop-sidebar .post-widget .post:hover a {
  color: var(--theme-color1);
}

.range-slider {
  position: relative;
}
.range-slider .title {
  line-height: 26px;
  position: relative;
  display: inline-block;
  margin-right: 4px;
}
.range-slider .title:before {
  position: absolute;
  content: "$";
  left: -5px;
  top: -19px;
  color: #646578;
  font-size: 18px;
}
.range-slider p {
  color: #646578;
  display: inline-block;
  letter-spacing: -1px;
  margin-right: 10px !important;
  position: relative;
}
.range-slider .input {
  color: #646578;
  display: inline-block;
  font-size: 18px;
  margin-top: 5px;
  max-width: 75px;
  position: relative;
}
.range-slider .input input {
  background: none;
  color: #646578;
  font-size: 15px;
  text-align: left;
}
.range-slider .ui-widget.ui-widget-content {
  background-color: #d0d4dd;
  border-radius: 2px;
  border: none;
  height: 4px;
  margin-bottom: 14px;
}
.range-slider .ui-slider .ui-slider-range {
  top: 0px;
  height: 4px;
  background-color: var(--theme-color2);
}
.range-slider .ui-state-default {
  background-color: var(--theme-color2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  margin-left: 0px;
  position: absolute;
  top: -5px;
  width: 14px;
}
.range-slider .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: var(--theme-color1);
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider .ui-widget-content .ui-state-default {
  background-color: var(--theme-color2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  margin-left: 0px;
  top: -5px;
  width: 14px;
}
.range-slider .ui-widget-content .ui-state-default:before {
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  height: 6px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 6px;
}
.range-slider input[type=submit] {
  background: var(--theme-color1);
  border: none;
  border-radius: 10px;
  color: var(--theme-color2);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  float: right;
  margin-top: 0;
  padding: 7px 20px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.range-slider input[type=submit]:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color1);
}

/*** 
====================================================================
Page Title
====================================================================
***/
/* @-webkit-keyframes "ripple" {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes "ripple" {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
} */
.page-title {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  padding: 150px 0 160px;
  position: relative;
}
.page-title:before {
  background: var(--theme-color-dark);
  /* background: linear-gradient( var(--theme-color-dark), var(--theme-color-light)); */

  content: "";
  height: 100%;
  left: 0;
  opacity: 0.72;
  position: absolute;
  top: 0;
  width: 100%;
}
.page-title .title {
  color: var(--theme-color-light);
  /* font-size: 64px; */
  margin-bottom: 17px;
}
.page-title .text {
  color: var(--theme-color-light);
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 26px;
  max-width: 520px;
  position: relative;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}
.page-breadcrumb li {
  color: var(--theme-color-light);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-right: 12px;
  padding-right: 13px;
  position: relative;
  text-transform: capitalize;
}
.page-breadcrumb li:after {
  color: var(--theme-color-light);
  content: "\f105";
  font-family: "Font Awesome 6 Pro";
  font-size: 14px;
  font-weight: 900;
  position: absolute;
  right: -6px;
  top: 1px;
}
.page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a {
  color: var(--theme-color-light);
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.page-breadcrumb li a:hover {
  color: var(--theme-color-light);
}

.page-title.style-two {
  background-position: center center;
}
.page-title.style-two .page-breadcrumb-outer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 8;
}
.page-title.style-two .page-breadcrumb li {
  color: rgb(7, 7, 16);
  font-weight: 600;
}
.page-title.style-two .page-breadcrumb li:after {
  color: rgb(7, 7, 16);
}
.page-title.style-two .page-breadcrumb li a {
  color: rgba(7, 7, 16, 0.6);
}
.page-title.style-two .page-breadcrumb li a:hover {
  color: rgb(7, 7, 16);
}

.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: var(--theme-color-light);
  color: #ff6d2e;
  z-index: 1;
  padding-left: 5px;
  font-size: 14px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}
.play-now .ripple:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}
.error-page__inner .error-page__title-box {
  position: relative;
  display: block;
}
.error-page__inner .error-page__title {
  position: relative;
  display: inline-block;
  font-size: 280px;
  line-height: 280px;
  margin-bottom: 0;
  color: var(--theme-color1);
}
.error-page__inner .error-page__sub-title {
  font-size: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -16px;
}
.error-page__inner .error-page__text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.error-page__inner .error-page__form {
  position: relative;
  display: block;
  margin: 42px auto 20px;
}
.error-page__inner .error-page__form input[type=search] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  padding-left: 50px;
  padding-right: 75px;
  border-radius: 7px;
}
.error-page__inner .error-page__form button[type=submit] {
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.error-page__inner .error-page__form-input {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

/***
=============================================
    Services Details
=============================================
***/
.service-sidebar {
  position: relative;
  display: block;
  max-width: 365px;
  width: 100%;
}
.service-sidebar .service-sidebar-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.service-sidebar .service-sidebar-single-services {
  position: relative;
  display: block;
  background: #f6f4ec;
  border-radius: 10px;
  padding: 35px 30px 25px;
}
.service-sidebar .service-sidebar-single-services .title {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
}
.service-sidebar .service-sidebar-single-services .title h3 {
  color: var(--headings-color);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.service-sidebar .service-sidebar-single-services ul {
  position: relative;
  display: block;
  margin-top: 10px;
}
.service-sidebar .service-sidebar-single-services ul li {
  position: relative;
  display: block;
  margin-bottom: 5px;
  margin-top: -10px;
}
.service-sidebar .service-sidebar-single-services ul li:last-child {
  margin-bottom: 0;
}
.service-sidebar .service-sidebar-single-services ul li a {
  position: relative;
  display: block;
  color: var(--agriox-color-1, #687469);
  font-size: 18px;
  padding: 22px 20px 22px;
  border-radius: 10px;
  background: transparent;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.service-sidebar .service-sidebar-single-services ul li a:hover {
  color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-services ul li a:hover::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li a:hover i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(90deg);
          transform: perspective(400px) rotateX(90deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.service-sidebar .service-sidebar-single-services ul li a i {
  font-size: 16px;
}
.service-sidebar .service-sidebar-single-services ul li.current a::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li.current a i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li.current:first-child {
  margin-top: 20px;
}
.service-sidebar .service-sidebar-single-services ul li.current:last-child {
  margin-bottom: 35px;
}
.service-sidebar .service-sidebar-single-contact-box {
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 50px 0px 44px;
  z-index: 1;
}
.service-sidebar .service-sidebar-single-contact-box::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(109, 140, 84, 0.93);
  border-radius: 10px;
  content: "";
  z-index: -1;
}
.service-sidebar .service-sidebar-single-contact-box .icon {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  background: var(--theme-color2);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover {
  background-color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover span::before {
  color: #fff;
}
.service-sidebar .service-sidebar-single-contact-box .icon span::before {
  position: relative;
  display: inline-block;
  color: var(--headings-color);
  font-size: 30px;
  line-height: 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .title {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 42px;
}
.service-sidebar .service-sidebar-single-contact-box .title h2 {
  color: #ffffff;
  font-size: 36px;
}
.service-sidebar .service-sidebar-single-contact-box .phone {
  font-size: 24px;
  line-height: 34px;
}
.service-sidebar .service-sidebar-single-contact-box .phone a {
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .phone a:hover {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-contact-box p {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}
.service-sidebar .service-sidebar-single-btn {
  position: relative;
  display: block;
}
.service-sidebar .service-sidebar-single-btn .thm-btn {
  font-size: 16px;
  padding: 13px 50px 28px;
}
.service-sidebar .service-sidebar-single-btn .thm-btn span::before {
  position: relative;
  display: inline-block;
  top: 13px;
  color: #334b35;
  font-size: 40px;
  padding-right: 25px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}
.service-sidebar .service-sidebar-single-btn .thm-btn:hover span::before {
  color: #ffffff;
}
.service-sidebar .banner-widget {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.service-sidebar .banner-widget .widget-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 45px 30px 40px 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .widget-content .shape {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 278px;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-sidebar .banner-widget .content-box {
  position: relative;
  max-width: 200px;
  width: 100%;
}
.service-sidebar .banner-widget .content-box .icon-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  text-align: center;
  border-radius: 5px;
  margin-bottom: 23px;
}
.service-sidebar .banner-widget .content-box .icon-box .icon-shape {
  position: absolute;
  top: -15px;
  right: -38px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .content-box h3 {
  display: block;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.service-sidebar .banner-widget .content-box .theme-btn-two:hover {
  background: #0a267a;
}
.service-sidebar .service-sidebar-single-btn .theme-btn {
  padding: 20px 50px;
}
.service-sidebar .service-sidebar-single-btn .theme-btn .btn-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.service-sidebar .service-sidebar-single-btn .theme-btn span::before {
  position: relative;
  display: inline-block;
  font-size: 36px;
  padding-right: 25px;
  margin-top: 7px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}

.services-details__content .feature-list .single-item {
  position: relative;
  display: block;
  border: 1px solid #e1e8e4;
  padding: 16px 30px 16px 53px;
  margin-bottom: 20px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .icon-box {
  color: var(--theme-color1);
  position: absolute;
  left: 20px;
  top: 16px;
  font-size: 18px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .title {
  display: block;
  margin: 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}
.services-details__content .feature-list .single-item:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.services-details__content .feature-list .single-item:hover .icon-box {
  color: var(--text-color-bg-theme-color2);
}

.service-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.service-list li:last-child {
  margin-bottom: 0px;
}
.service-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--headings-color);
  font-weight: 600;
  background-color: #fff;
  padding: 17px 20px 17px 50px;
  -webkit-box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.service-list li a:hover, .service-list li a.current {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
  padding-left: 80px;
}
.service-list li a:hover i, .service-list li a.current i {
  width: 60px;
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}
.service-list li i {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  background-color: #f6f4ec;
  text-align: center;
  font-size: 16px;
  color: #707582;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar-service-list {
  margin: 0;
}
.sidebar-service-list li + li {
  margin-top: 10px;
}
.sidebar-service-list li a {
  background-color: #f5faff;
  border-radius: 15px;
  color: var(--theme-color2);
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding: 19px 40px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar-service-list li a:hover {
  color: var(--theme-color2);
}
.sidebar-service-list li a:hover i {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}
.sidebar-service-list li a i {
  height: 32px;
  width: 45px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #191825;
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  border-radius: 15px;
}
.sidebar-service-list li.current a {
  color: var(--theme-color2);
}
.sidebar-service-list li.current a i {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}

.service-details-help {
  position: relative;
  display: block;
  padding: 57px 55px 50px;
  margin-top: 30px;
  text-align: center;
  z-index: 1;
  background-color: var(--theme-color1);
  border-radius: 15px;
  overflow: hidden;
}

.help-shape-1 {
  position: absolute;
  bottom: -215px;
  left: -95px;
  width: 220px;
  height: 500px;
  background-color: #303030;
  mix-blend-mode: soft-light;
  border-radius: 150px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: -1;
}

.help-shape-2 {
  position: absolute;
  top: -118px;
  right: -130px;
  width: 180px;
  height: 350px;
  background-color: #fff;
  mix-blend-mode: soft-light;
  border-radius: 186px;
  -webkit-transform: rotate(48deg);
          transform: rotate(48deg);
}

.help-icon {
  height: 73px;
  width: 73px;
  background-color: #fff;
  color: #191825;
  font-size: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  margin: 0 auto 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-icon:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color1);
}

.help-title {
  font-size: 38px;
  color: var(--theme-color2);
  line-height: 40px;
  margin-bottom: 21px;
}

.help-contact {
  position: relative;
  display: block;
  margin-top: 21px;
}
.help-contact p {
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  line-height: 32px;
  font-weight: 600;
  opacity: 0.7;
}
.help-contact a {
  font-size: 30px;
  color: var(--theme-color2);
  font-weight: 600;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-contact a:hover {
  color: var(--theme-color2);
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details__img {
  position: relative;
  display: block;
}
.project-details__img img {
  width: 100%;
  border-radius: 10px;
}

.project-details__content {
  position: relative;
  display: block;
}

.project-details__content-left {
  position: relative;
  display: block;
  margin-top: 31px;
}

.project-details__content-right {
  position: relative;
  display: block;
  margin-top: 40px;
}

.project-details__details-box {
  position: relative;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 43px 50px 50px;
  z-index: 1;
}

.project-details__details-list {
  position: relative;
  display: block;
}
.project-details__details-list li {
  position: relative;
  display: block;
}
.project-details__details-list li + li {
  margin-top: 24px;
}

.project-details__client {
  font-size: 16px;
  color: #838d9e;
  line-height: 24px;
  margin: 0;
}

.project-details__name {
  font-size: 16px;
  line-height: 24px;
}

.project-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.project-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.project-details__social a:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.project-details__social a + a {
  margin-left: 10px;
}

.project-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #ece9e0;
  border-bottom: 1px solid #ece9e0;
  padding: 30px 0;
  margin-top: 117px;
}

.project-details__pagination {
  position: relative;
  display: block;
}
.project-details__pagination li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-details__pagination li a {
  font-size: 14px;
  color: #757873;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.project-details__pagination li a:hover {
  color: var(--theme-color1);
}
.project-details__pagination li a:hover i {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  border: 2px solid var(--theme-color2);
}
.project-details__pagination li .content {
  position: relative;
  display: block;
}
.project-details__pagination li.next {
  float: left;
  position: relative;
}
.project-details__pagination li.next i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}
.project-details__pagination li.next .content {
  text-align: left;
}
.project-details__pagination li.previous {
  position: relative;
  float: right;
}
.project-details__pagination li.previous i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}
.project-details__pagination li.previous .content {
  text-align: right;
}

/***

====================================================================
    Blog Details
====================================================================

***/
.blog-details {
  position: relative;
  display: block;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
  border-radius: 10px;
}
.blog-details__img img {
  width: 100%;
  border-radius: 10px;
}

.blog-details__date {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color1);
  text-align: center;
  padding: 21px 24px 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.blog-details__date .day {
  font-size: 16px;
  color: var(--theme-color2);
  font-weight: var(--body-font-weight-bold);
  line-height: 16px;
}
.blog-details__date .month {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: var(--body-font-weight-bold);
  color: var(--theme-color2);
  line-height: 12px;
  text-transform: uppercase;
}

.blog-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.blog-details__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-details__meta li + li {
  margin-left: 18px;
}
.blog-details__meta li a {
  font-size: 15px;
  color: #777;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.blog-details__meta li a:hover {
  color: var(--theme-color2);
}
.blog-details__meta li a i {
  color: var(--theme-color1);
  margin-right: 6px;
}

.blog-details__title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 21px;
  font-weight: var(--body-font-weight-bold);
}

.blog-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 49px;
  border-top: 1px solid #ece9e0;
}
@media only screen and (max-width: 767px) {
  .blog-details__bottom {
    gap: 30px;
  }
}
.blog-details__bottom p {
  margin: 0;
}

.blog-details__tags span {
  color: #0e2207;
  font-size: 20px;
  margin-right: 14px;
  font-weight: var(--body-font-weight-bold);
}
.blog-details__tags a {
  position: relative;
  font-size: 12px;
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
  display: inline-block;
  padding: 5px 30px 5px;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 35px;
}
.blog-details__tags a:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  text-decoration: none;
}
.blog-details__tags a + a {
  margin-left: 6px;
}

.blog-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.blog-details__social-list a:hover {
  color: var(--theme-color-light);
}
.blog-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.blog-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--theme-color2);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}
.blog-details__social-list a + a {
  margin-left: 10px;
}

.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}

.blog-details__pagenation {
  position: relative;
  display: block;
}
.blog-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: #0e2207;
  font-weight: var(--body-font-weight-bold);
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 52px;
  padding-bottom: 52px;
  border-radius: 10px;
}
.blog-details__pagenation li:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.blog-details__pagenation li + li {
  margin-left: 30px;
}

/* Nav Links */
.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 53px;
}
@media only screen and (max-width: 767px) {
  .nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
  }
}
.nav-links .prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: calc(50% - 15px);
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .nav-links .prev {
    width: 100%;
  }
}
.nav-links .prev .thumb {
  margin-right: 20px;
}
.nav-links .next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: calc(50% - 15px);
}
@media only screen and (max-width: 767px) {
  .nav-links .next {
    width: 100%;
  }
}
.nav-links .next .thumb {
  margin-left: 20px;
}
.nav-links > div {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.nav-links > div .thumb {
  display: inline-block;
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.nav-links > div .thumb a {
  display: inline-block;
}
.nav-links > div > a {
  display: inline-block;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 1.637;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 52px 50px;
  border-radius: 10px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .nav-links > div > a {
    padding: 30px;
  }
}
.nav-links > div > a:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}

/*** 

====================================================================
Sidebar
====================================================================

***/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: var(--h4-font-weight);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}
.sidebar__search-form input[type=search] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  font-size: 16px;
  font-weight: 500;
  padding-left: 50px;
  height: 74px;
  width: 100%;
  padding-right: 80px;
  border-radius: 10px;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder {
  color: var(--theme-color-dark);
  opacity: 1;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder, .sidebar__search-form input[type=search]:-ms-input-placeholder, .sidebar__search-form input[type=search]::-ms-input-placeholder, .sidebar__search-form input[type=search]::placeholder {
  color: var(--theme-color-dark);
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form input[type=search] {
    padding-left: 30px;
  }
}
.sidebar__search-form button[type=submit] {
  background-color: transparent;
  color: var(--theme-color-dark);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form button[type=submit] {
    width: 42px;
  }
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__post {
    padding: 30px;
  }
}
.sidebar__post .sidebar__title {
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__title {
    margin-left: 0;
  }
}
.sidebar__post .sidebar__post-list {
  margin: 0;
}
.sidebar__post .sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__post-list li {
    padding: 16px 0;
  }
}
.sidebar__post .sidebar__post-list li:hover {
  background-color: #ffffff;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list li + li {
  margin-top: 11px;
}
.sidebar__post .sidebar__post-list .sidebar__post-image {
  margin-right: 20px;
  -webkit-box-flex: 70px;
      -ms-flex: 70px 0 0px;
          flex: 70px 0 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-image > img {
  width: 80px;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content {
  position: relative;
  top: -3px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 a {
  color: #0e2207;
  display: block;
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta {
  font-size: 14px;
  font-weight: 500;
  color: #757873 !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta i {
  color: var(--theme-color2);
  font-size: 14px;
  padding-right: 3px;
}

.sidebar__category {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  padding: 45px 30px 38px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__category {
    padding: 30px 15px 30px;
  }
}
.sidebar__category .sidebar__title {
  padding-left: 20px;
  margin-bottom: 9px;
}
.sidebar__category-list {
  margin: 0;
}
.sidebar__category-list li + li {
  margin-top: 4px;
}
.sidebar__category-list li a {
  color: #757873;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 12px 20px;
  font-weight: 500;
  border-radius: 10px;
}
.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li a:hover span {
  color: #ffcd1e;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-color2);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}
.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  border-radius: 10px;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: #ffcd1e;
}

.sidebar__tags {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 45px 50px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__tags {
    padding: 30px;
  }
}
.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}
.sidebar__tags-list a {
  font-size: 14px;
  color: #0e2207;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #ffffff;
  display: inline-block;
  padding: 5px 28px 5px;
  margin-left: 5px;
  border-radius: 30px;
}
.sidebar__tags-list a:hover {
  color: var(--text-color-bg-theme-color1);
  background: var(--theme-color1);
}
.sidebar__tags-list a + a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__comments {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 50px 43px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__comments {
    padding: 30px;
  }
}
.sidebar__comments .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}
.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}
.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}
.sidebar__comments-list li + li {
  margin-top: 23px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  font-size: 15px;
  color: var(--text-color-bg-theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar__comments-text-box p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.sidebar__comments-text-box p span {
  color: #0e2207;
}
.sidebar__comments-text-box h5 {
  font-size: 20px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 0;
}

/*** 

====================================================================
    Comments
====================================================================

***/
.comment-one .comment-one__title {
  margin-bottom: 30px;
}
.comment-one .comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ece9e0;
  padding-bottom: 60px;
  margin-bottom: 60px;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.comment-one .comment-one__content {
  position: relative;
  margin-left: 45px;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__content {
    margin-top: 20px;
    margin-left: 0;
  }
}
.comment-one .comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  color: var(--theme-black);
  margin-bottom: 24px;
}
.comment-one .comment-one__content p {
  font-size: 16px;
  font-weight: 500;
}
.comment-one .comment-one__btn {
  padding: 5px 60px 5px 30px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
}
.comment-one .comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
  -webkit-box-flex: 100px;
      -ms-flex: 100px 0 0px;
          flex: 100px 0 0;
}
.comment-one .comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
}

.team-details__top {
  padding: 0 0 120px;
}

.team-details-shape-1 {
  position: absolute;
  bottom: -270px;
  right: 0;
  opacity: 0.5;
  z-index: 2;
}
.team-details-shape-1 img {
  width: auto;
}

.team-details__top-left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.team-details__top-img {
  position: relative;
  display: block;
  border-radius: 30px;
}
.team-details__top-img img {
  width: 100%;
  border-radius: 30px;
}

.team-details__big-text {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: #eef0f6;
  letter-spacing: 0.35em;
  font-weight: 400;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 209px;
  left: -325px;
}

.team-details__top-right {
  position: relative;
  display: block;
  margin-left: 50px;
}
@media only screen and (max-width: 991px) {
  .team-details__top-right {
    margin-top: 70px;
    margin-left: 0;
  }
}

.team-details__top-content {
  position: relative;
  display: block;
  margin-top: -11px;
}

.team-details__top-name {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 3px;
}

.team-details__top-title {
  font-size: 16px;
  color: var(--text-color);
}

.team-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
}
.team-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  font-size: 15px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__social a:hover {
  background-color: var(--theme-color-dark);
  color: var(--theme-color1);
}
.team-details__social a + a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 30px;
  color: var(--text-color);
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 30px;
}

.team-details__top-text-2 {
  padding-top: 23px;
  padding-bottom: 35px;
}

.team-details__bottom {
  position: relative;
  display: block;
  border-top: 1px solid #e4e5ea;
  padding-top: 110px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 70px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  line-height: 46px;
}

.team-details__bottom-left-text {
  padding-top: 30px;
}

.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 1px;
}
@media only screen and (max-width: 991px) {
  .team-details__bottom-right {
    margin-left: 0;
  }
}

.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
}
.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: #eef0f6;
  border-radius: 7px;
  margin-bottom: 22px;
}
.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 7px;
  background-color: var(--theme-color1);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}
.team-details__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--theme-color2);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}
.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}

.team-details__progress-single {
  position: relative;
  display: block;
}

.team-details__progress-title {
  font-size: 20px;
  line-height: 28px;
  color: var(--text-color);
  margin-bottom: 6px;
}

.team-contact-form {
  background-color: #eef0f6;
}
.team-contact-form input[type=text],
.team-contact-form input[type=email] {
  background-color: #fff;
}
.team-contact-form textarea {
  background-color: #fff;
  height: 180px;
}

/***

====================================================================
        Contact
====================================================================

***/
.contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
}
.contact-details__info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-details__info li .icon {
  height: 80px;
  width: 80px;
  background-color: var(--theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .icon span {
  color: var(--text-color-bg-theme-color1);
  font-size: 25px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li:hover .icon {
  background-color: var(--theme-color2);
}
.contact-details__info li:hover .icon span {
  color: var(--theme-color-light);
}
.contact-details__info li:hover .text a {
  color: var(--notech-base);
}
.contact-details__info li:hover .text a span {
  color: var(--notech-gray);
}
.contact-details__info li .text {
  margin-left: 30px;
}
.contact-details__info li .text p {
  font-size: 14px;
  line-height: 24px;
}
.contact-details__info li .text a {
  font-size: 18px;
  color: var(--notech-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .text span {
  font-size: 20px;
  color: var(--notech-black);
}
.contact-details__info li + li {
  margin-top: 19px;
}

/***
==================================================================
	Main Footer
==================================================================
***/
.main-footer {
  position: relative;
}
.main-footer .widgets-section {
  padding: 100px 0 60px;
  position: relative;
}
.main-footer .footer-column {
  margin-bottom: 50px;
  position: relative;
}
.main-footer .footer-widget {
  position: relative;
}
.main-footer .widget-title {
  color: var(--theme-color-light);
  display: block;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 37px;
  margin-bottom: 40px;
  padding-bottom: 2px;
  position: relative;
  text-transform: uppercase;
}
.main-footer .widget-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #212121;
}
.main-footer .widget-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 2px;
  background-color: var(--theme-color1);
}
.main-footer .about-widget .logo {
  margin-bottom: 28px;
}
.main-footer .about-widget .text {
  color: #b7b7b7;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}

/*=== User LInks ===*/
.user-links {
  position: relative;
}
.user-links li {
  color: #b7b7b7;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative;
}
.user-links li:last-child {
  margin-bottom: 0;
}
.user-links li a {
  color: inherit;
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover {
  color: var(--theme-color-light);
}
.user-links li a:before {
  background-color: var(--theme-color1);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover:before {
  width: 100%;
}
.user-links li i {
  font-size: 12px;
  font-weight: 300;
  margin-right: 2px;
}
.user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.user-links.two-column li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  padding-right: 15px;
  width: 50%;
}
.user-links.two-column li:last-child {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  width: 100%;
}

.contact-list-two {
  padding: 0;
  position: relative;
}
.contact-list-two li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
@media (max-width: 575.98px) {
  .contact-list-two li br {
    display: none;
  }
}
.contact-list-two li i {
  background-color: var(--theme-color1);
  color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 22px;
  height: 44px;
  margin-right: 45px;
  min-width: 44px;
  position: relative;
  width: 44px;
}
.contact-list-two li i:after {
  background-color: var(--theme-color1);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: -21px;
  width: 21px;
}
.contact-list-two li:last-child {
  margin-bottom: 0;
}
.contact-list-two li a {
  color: var(--theme-color-dark);
  display: inline-block;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-list-two.light li {
  color: #b7b7b7;
}
.contact-list-two.light a {
  color: #b7b7b7;
}
.contact-list-two.light a:hover {
  color: var(--theme-color1);
}

.insta-gallery {
  margin: 0px -7px 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .insta-gallery {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .insta-gallery {
    max-width: 100%;
  }
}
.insta-gallery .image {
  float: left;
  margin-bottom: 14px;
  padding: 0px 8px;
  position: relative;
  width: 33.3333%;
}
.insta-gallery img {
  display: block;
  width: 100%;
}
.insta-gallery a {
  position: relative;
  display: block;
}
.insta-gallery a:hover:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.insta-gallery a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.insta-gallery a:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--theme-color1);
  opacity: 0.8;
  content: "";
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.insta-gallery a:after {
  color: var(--theme-color-dark);
  content: "\f0b2";
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  height: 20px;
  left: 50%;
  line-height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 20px;
}

/* Newsletter Form */
.newsletter-form {
  position: relative;
}
.newsletter-form .form-group {
  position: relative;
}
.newsletter-form .form-group .input-outer {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group .input-outer:before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 50px;
  height: 100%;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
  border: 1px solid #565656;
  border-left: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group .input-outer:hover:before {
  border-color: var(--theme-color1);
}
.newsletter-form .form-group input[type=text],
.newsletter-form .form-group input[type=email] {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  padding: 15px 32px;
  color: var(--theme-color-light);
  background: transparent;
  border: 1px solid #565656;
  border-right: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.newsletter-form .form-group input[type=text]::-webkit-input-placeholder, .newsletter-form .form-group input[type=email]::-webkit-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::-moz-placeholder, .newsletter-form .form-group input[type=email]::-moz-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]:-ms-input-placeholder, .newsletter-form .form-group input[type=email]:-ms-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::-ms-input-placeholder, .newsletter-form .form-group input[type=email]::-ms-input-placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input[type=text]::placeholder,
.newsletter-form .form-group input[type=email]::placeholder {
  color: #b7b7b7;
}
.newsletter-form .form-group input:hover {
  border-color: var(--theme-color1);
}
.newsletter-form .form-group .theme-btn {
  margin-top: 20px;
  padding: 18px 100px 18px 46px;
}

/*=== Footer Bottom ===*/
.footer-bottom {
  position: relative;
  width: 100%;
}
.footer-bottom .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 2px solid #212121;
  padding: 35px 0;
}
@media (max-width: 767.98px) {
  .footer-bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .footer-bottom .inner-container {
    padding: 15px 0;
  }
}
.footer-bottom .copyright-text {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: var(--theme-color-light);
  margin-bottom: 0px;
}
@media (max-width: 575.98px) {
  .footer-bottom .copyright-text {
    font-size: 12px;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
.footer-bottom .copyright-text a {
  color: inherit;
}
.footer-bottom .copyright-text a:hover {
  color: var(--theme-color-light);
}
.footer-bottom .footer-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 575.98px) {
  .footer-bottom .footer-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.footer-bottom .footer-nav li {
  margin-left: 45px;
}
@media (max-width: 767.98px) {
  .footer-bottom .footer-nav li {
    margin-left: 0;
    margin: 0 20px;
  }
}
.footer-bottom .footer-nav li a {
  position: relative;
  color: #b7b7b7;
}
.footer-bottom .footer-nav li a:hover {
  text-decoration: none;
  color: var(--theme-color1);
}
.footer-bottom .footer-nav li i {
  margin-right: 5px;
}

/***

==================================================================
	Main Footer
==================================================================

***/
.footer-style-one {
  position: relative;
}
.footer-style-one .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background-color: var(--theme-color-dark);
}
.footer-style-one .widgets-section {
  padding: 120px 0 55px;
}
@media (max-width: 575.98px) {
  .footer-style-one .widgets-section {
    padding-bottom: 20px;
  }
}
.footer-style-one .about-widget .text {
  padding-right: 10px;
}
.footer-style-one .newsletter-widget {
  padding-left: 8px;
}
.footer-style-one .newsletter-widget .text {
  color: #b7b7b7;
  margin-bottom: 18px;
}

/***

==================================================================
	Main Footer Two
==================================================================

***/
.footer-style-two {
  position: relative;
  background-color: transparent;
}
.footer-style-two:before {
  display: none;
}
.footer-style-two .footer-top {
  position: relative;
}
.footer-style-two .footer-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 64px 0 0;
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
.footer-style-two .footer-top .inner-container .top-center .text {
  position: relative;
  margin-bottom: 0;
  padding-top: 12px;
  margin-left: -70px;
  max-width: 330px;
  color: #b7b7b7;
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container .top-center .text {
    padding-top: 0;
    margin: 20px 0;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 95px 30px 75px;
  background-color: var(--theme-color1);
  text-align: left;
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn {
    padding: 30px 20px;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -65px;
  width: 65px;
  height: 100%;
  background-color: var(--theme-color1);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
@media (max-width: 1199.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn:before {
    left: -64px;
  }
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-top .inner-container .top-right .info-btn:before {
    display: none;
  }
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .icon {
  position: relative;
  font-size: 55px;
  line-height: 1em;
  margin-right: 10px;
  color: var(--theme-color-dark);
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .title {
  position: relative;
  font-size: 20px;
  margin-bottom: 2px;
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.footer-style-two .footer-top .inner-container .top-right .info-btn .phone-no {
  position: relative;
  font-size: 36px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}
.footer-style-two .widgets-section {
  position: relative;
  padding-top: 90px;
  padding-bottom: 50px;
}
@media (max-width: 575.98px) {
  .footer-style-two .widgets-section {
    padding-bottom: 20px;
  }
}
.footer-style-two .widgets-section .about-widget .text {
  padding-right: 20px;
}
@media (max-width: 991.98px) {
  .footer-style-two .widgets-section .about-widget .text {
    padding-right: 0;
  }
}
.footer-style-two .widgets-section .about-widget .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.footer-style-two .widgets-section .about-widget .btn-box .image {
  margin-right: 15px;
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget {
    margin-right: -15px;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget .user-links li {
    padding-right: 3px;
  }
}
.footer-style-two .widgets-section .links-widget .user-links li i {
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-two .widgets-section .links-widget .user-links li i {
    margin-right: 3px;
  }
}
.footer-style-two .widgets-section .newsletter-widget {
  padding-left: 108px;
  margin-right: -85px;
}
@media (max-width: 991.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    margin-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    padding-right: 60px;
  }
}
@media (max-width: 575.98px) {
  .footer-style-two .widgets-section .newsletter-widget {
    padding-right: 15px;
  }
}
.footer-style-two .widgets-section .newsletter-widget .text {
  color: #b7b7b7;
  margin-bottom: 18px;
  margin-right: -10px;
}
.footer-style-two .footer-bottom {
  background-color: #0b0b0b;
}
.footer-style-two .footer-bottom .inner-container {
  border-top: none;
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
@media (max-width: 399.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li {
    margin: 0 10px;
  }
}
@media (max-width: 991.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li:first-child {
    margin-left: 0;
  }
}
@media (max-width: 399.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li a {
    font-size: 14px;
  }
}

/***

==================================================================
	Main Footer Three
==================================================================

***/
.footer-style-three {
  position: relative;
  background-color: transparent;
}
.footer-style-three:before {
  display: none;
}
.footer-style-three .footer-top {
  position: relative;
  padding-top: 90px;
}
.footer-style-three .footer-top .bg-overlay-7 {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 1399.98px) {
  .footer-style-three .footer-top .bg-overlay-7 {
    display: none;
  }
}
.footer-style-three .footer-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 55px 55px 40px;
  background-color: var(--theme-color1);
}
@media (max-width: 1199.98px) {
  .footer-style-three .footer-top .inner-container {
    padding: 50px 30px;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container {
    padding: 40px 15px;
  }
}
.footer-style-three .footer-top .inner-container .top-left .sub-title {
  position: relative;
  letter-spacing: 0.12em;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.footer-style-three .footer-top .inner-container .top-left .title {
  position: relative;
  font-size: 38px;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (max-width: 1199.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 32px;
  }
  .footer-style-three .footer-top .inner-container .top-left .title br {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 30px;
  }
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container .top-left .title {
    font-size: 24px;
  }
}
.footer-style-three .footer-top .inner-container .top-right {
  position: relative;
  padding-left: 82px;
  padding-right: 34px;
  border-left: 2px solid var(--theme-color-dark);
}
@media (max-width: 991.98px) {
  .footer-style-three .footer-top .inner-container .top-right {
    padding-left: 30px;
    padding-top: 0;
    padding-right: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-style-three .footer-top .inner-container .top-right {
    padding-left: 0;
    border-left: none;
    padding-top: 20px;
  }
}
.footer-style-three .footer-top .inner-container .top-right .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .icon {
  position: relative;
  font-size: 55px;
  line-height: 1em;
  margin-right: 15px;
  color: var(--theme-color-dark);
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .title {
  position: relative;
  font-size: 20px;
  margin-bottom: 2px;
  color: var(--theme-color-dark);
  text-transform: uppercase;
}
.footer-style-three .footer-top .inner-container .top-right .info-btn .phone-no {
  position: relative;
  font-size: 36px;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}
@media (max-width: 575.98px) {
  .footer-style-three .footer-top .inner-container .top-right .info-btn .phone-no {
    font-size: 24px;
  }
}
.footer-style-three .footer-top .inner-container .top-right .btn-box {
  position: relative;
  margin-top: 25px;
}
.footer-style-three .footer-top .inner-container .top-right .btn-box .theme-btn {
  padding: 18px 82px 18px 37px;
}
.footer-style-three .widgets-section {
  position: relative;
  padding: 100px 0 60px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section {
    padding: 85px 0 26px;
  }
}
.footer-style-three .widgets-section .about-widget .text {
  padding-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .about-widget .text br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget {
    margin-right: -15px;
  }
}
.footer-style-three .widgets-section .links-widget .user-links {
  margin-right: -90px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links {
    margin-right: 0;
  }
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links li {
    padding-right: 3px;
  }
}
.footer-style-three .widgets-section .links-widget .user-links li i {
  margin-right: 10px;
}
@media (max-width: 1199.98px) {
  .footer-style-three .widgets-section .links-widget .user-links li i {
    margin-right: 3px;
  }
}
.footer-style-three .widgets-section .gallery-widget {
  padding-top: 5px;
  padding-left: 105px;
  margin-right: -100px;
}
@media (max-width: 1399.98px) {
  .footer-style-three .widgets-section .gallery-widget {
    padding-left: 0;
    margin-right: 0;
  }
}