.slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1200px; /* Adjust the max-width as per your design */
    margin: auto;
    border-radius: 10px; /* Rounded corners for modern look */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}
.Borderr{
    border: 3px dotted red;
    padding: 15px;
}

.bg-redd{
    background-color: #EE1623;
}
.slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%; /* Full width for each slide */
    position: relative; /* Position for overlay */
}

.slide img {
    width: 100%; /* Make images responsive */
    height: auto;
    border-radius: 10px; /* Rounded corners for images */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px; /* Match image corners */
    opacity: 0; /* Start with no opacity */
    transition: opacity 0.5s ease; /* Fade effect */
}

.slide:hover .overlay {
    opacity: 1; /* Show overlay on hover */
}

.indicators {
    position: absolute;
    bottom: 20px; /* Positioning indicators */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 1; /* Ensure indicators are above other elements */
}

.indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5); /* Default background for inactive indicators */
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s; /* Transition effects */
}

.indicator.active {
    background: #007bff; /* Color for active indicator */
    transform: scale(1.2); /* Slightly increase size of active indicator */
}
