.booking-card {
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 10px;
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-image {
    max-width: 100%; /* Ensure the image fits within the column */
    /* height: auto; */
    border-radius: 10px;
  }
  
  .book-now-btn {
    background-color: #ee1623;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }
  
  .book-now-btn:hover {
    background-color: black;
    color: white;
  }
  
  h3 {
    color: #f36;
    margin-bottom: 10px;
  }
  
  p {
    margin: 5px 0;
    font-size: 16px;
    /* color: #555; */
  }
  
  .vh-100 {
    height: 100vh;
  }
  
  .pricing-details p {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  