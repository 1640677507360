#why-choose-section {
    max-width: 800px; 
    margin: auto; 
    padding: 20px; 
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
    overflow: hidden; 
  }
  
  .slider-container {
    max-width: 1200px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
    padding: 20px ; /* Padding around the slider */
    /* background-color: #EE1623; */
}

.slider-item {
    padding: 40px; 
    background-color: #f0f4ff; 
    border-radius: 12px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Softer shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    position: relative; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.slider-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3); 
}

.slider-heading {
    font-size: 28px; /* Font size for headings */
    color: #333; /* Dark gray for text */
    margin-bottom: 10px; 
    text-align: center; /* Center the heading */
}

.slider-description {
    font-size: 18px; 
    color: #666; /* Medium gray for description */
    text-align: center; 
    padding: 0 10px; 
}

.slick-dots {
    bottom: -40px; /* Position of dots */
}

.slick-dots li button:before {
    color: #EE1623; /* Dot color */
}

.slick-dots li.slick-active button:before {
    color: #EE1623; /* Active dot color */
}



.slider-item {
  position: relative; /* Allow absolute positioning of the icon */
  padding: 40px; 
  background-color: #f0f4ff; 
  border-radius: 12px; 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}

.icon-container {
  position: absolute; /* Absolute positioning */
  top: 30px; /* Adjust based on your layout */
  left: 10px; /* Adjust based on your layout */
}

.slider-icon {
  padding-top: 20px;
  font-size: 60px; /* Size of the icon */
  font-weight: bolder;
  
  color: #EE1623; /* Color of the icon */
 
}
