/* OurFleet.css */

/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

body {
    background-color: #f4f7fa;
    color: #333;
}

/* Page Title Section */
.page-title {
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    color: #fff;
}

.page-title .auto-container {
    text-align: center;
}

.page-title .title-outer h1 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

/* Fleet Section */
.our-fleet-section {
    padding: 80px 0;
}

.our-fleet-section .sec-title {
    margin-bottom: 40px;
}

.our-fleet-section .sec-title .sub-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    /* color: #333; */
}

.our-fleet-section .sec-title h2 {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #1c1c1c;
}

.letters-slide-up {
    position: relative;
    animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
    from {
        transform: translateY(10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Fleet Items Grid */
.row {
    display: flex;
    flex-wrap: wrap;
    /* gap: 30px; */
    justify-content: center;
}

/* Fleet Item Block */
.fleet-block {
    width: 100%;
    max-width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fleet-block:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
}

.fleet-block .inner-box {
    background-color: #c71524;
    color: rgb(240, 239, 239);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.fleet-block .inner-box:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.fleet-block .image a {
    display: block;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
}

.fleet-block .image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.fleet-block .image a:hover img {
    transform: scale(1.05);
}

.fleet-block .content {
    padding: 20px;
}

.fleet-block .title {
    font-size: 22px;
    font-weight: 600;
    color: #f1f0f0;
    margin-bottom: 12px;
    transition: color 0.3s ease;
}

.fleet-block .title a {
    color: inherit;
    text-decoration: none;
}

.fleet-block .title a:hover {
    color: #ff6f61; /* Accent color on hover */
}

.fleet-block .text {
    font-size: 16px;
    /* color: #666; */
    line-height: 1.6;
    margin-bottom: 12px;
}

.fleet-block .capacity {
    font-size: 14px;
    /* color: #999; */
}

/* Responsive Design */
@media (max-width: 992px) {
    .fleet-block {
        max-width: 45%;
    }
}

@media (max-width: 768px) {
    .fleet-block {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .page-title .title-outer h1 {
        font-size: 36px;
    }

    .our-fleet-section .sec-title h2 {
        font-size: 28px;
    }
}

/* Add a smooth scroll effect */
html {
    scroll-behavior: smooth;
}
