/*!
*    ______ _       _   _
*    |  ____| |     | | (_)
*    | |__  | | __ _| |_ _  ___ ___  _ __
*    |  __| | |/ _` | __| |/ __/ _ \| '_ \
*    | |    | | (_| | |_| | (_| (_) | | | |
*    |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*         https://www.flaticon.com
*/

@font-face {
    font-family: "flaticon_taxi";
    src: url("../fonts/flaticon_taxicaa5.woff2?cce18cf58e19f1d4b082c7f6eaa387a5") format("woff2"),
url("../fonts/flaticon_taxicaa5.woff?cce18cf58e19f1d4b082c7f6eaa387a5") format("woff"),
url("../fonts/flaticon_taxicaa5.eot?cce18cf58e19f1d4b082c7f6eaa387a5#iefix") format("embedded-opentype"),
url("../fonts/flaticon_taxicaa5.ttf?cce18cf58e19f1d4b082c7f6eaa387a5") format("truetype"),
url("../fonts/flaticon_taxicaa5.svg?cce18cf58e19f1d4b082c7f6eaa387a5#flaticon_taxi") format("svg")
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_taxi !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-connected-car:before {
    content: "\f101";
}
.flaticon-destination:before {
    content: "\f102";
}
.flaticon-location:before {
    content: "\f103";
}
.flaticon-taxi:before {
    content: "\f104";
}
.flaticon-mobile-app:before {
    content: "\f105";
}
.flaticon-taxi-1:before {
    content: "\f106";
}
.flaticon-taxi-2:before {
    content: "\f107";
}
.flaticon-booking:before {
    content: "\f108";
}
.flaticon-taxi-3:before {
    content: "\f109";
}
.flaticon-car-service:before {
    content: "\f10a";
}
.flaticon-mobile-app-1:before {
    content: "\f10b";
}
.flaticon-mobile-app-2:before {
    content: "\f10c";
}
.flaticon-location-1:before {
    content: "\f10d";
}
.flaticon-app:before {
    content: "\f10e";
}
.flaticon-app-1:before {
    content: "\f10f";
}
.flaticon-taxi-4:before {
    content: "\f110";
}
.flaticon-app-2:before {
    content: "\f111";
}
.flaticon-taxi-app:before {
    content: "\f112";
}
.flaticon-mobile-app-3:before {
    content: "\f113";
}
.flaticon-transportation:before {
    content: "\f114";
}
.flaticon-taxi-5:before {
    content: "\f115";
}
.flaticon-mobile-app-4:before {
    content: "\f116";
}
.flaticon-mobile-app-5:before {
    content: "\f117";
}
.flaticon-mobile-app-6:before {
    content: "\f118";
}
.flaticon-taxi-6:before {
    content: "\f119";
}
.flaticon-route:before {
    content: "\f11a";
}
.flaticon-location-2:before {
    content: "\f11b";
}
.flaticon-route-1:before {
    content: "\f11c";
}
.flaticon-car-service-1:before {
    content: "\f11d";
}
.flaticon-taxi-7:before {
    content: "\f11e";
}
.flaticon-taxi-8:before {
    content: "\f11f";
}
.flaticon-taxi-9:before {
    content: "\f120";
}
.flaticon-check-circle:before {
    content: "\f121";
}
.flaticon-accept:before {
    content: "\f122";
}
.flaticon-driver:before {
    content: "\f123";
}
.flaticon-driver-1:before {
    content: "\f124";
}
.flaticon-driver-2:before {
    content: "\f125";
}
.flaticon-taxi-driver:before {
    content: "\f126";
}
.flaticon-driver-3:before {
    content: "\f127";
}
.flaticon-price-tag:before {
    content: "\f128";
}
.flaticon-search:before {
    content: "\f129";
}
.flaticon-searching:before {
    content: "\f12a";
}
.flaticon-search-1:before {
    content: "\f12b";
}
.flaticon-clock:before {
    content: "\f12c";
}
.flaticon-stop-watch:before {
    content: "\f12d";
}

