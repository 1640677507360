.vision-section {
    padding: 60px 20px;
    background-color: #ddd0d1; /* Light background for contrast */
  }
  
  .vision-title {
    text-align: center;
    font-size: 2.5rem;
    color: rgb(31, 14, 14);
    margin-bottom: 40px;
  }
  
  .vision-content,
  .mission-content {
    background: black; /* White background for content boxes */
    border-radius: 8px; /* Rounded corners */
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Box shadow effect */
    padding: 30px;
    margin: 20px 0; /* Margin for spacing between boxes */
    border: 2px solid red;
  }
  
  .vision-header,
  .mission-header {
    font-size: 1.8rem;
    color: #ee1623; /* Primary color for headers */
    margin-bottom: 20px;
  }
  
  .vision-description,
  .mission-description {
    font-size: 1.1rem;
    color: white; /* Darker text color for better readability */
    line-height: 1.6; /* Increased line height for spacing */
  }
  
  .cta {
    text-align: center;
    margin-top: 40px;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    background-color: #007bff; /* Primary button color */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  