.our-network {
    padding: 60px 0;
  }
  
  .network-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #e0d7d7;
  }
  
  .network-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .network-card {
    position: relative;
    width: 300px;
    margin: 15px;
    border-radius: 15px;
    border: 2px solid #ee1623;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .network-card:hover {
    transform: scale(1.05);
  }
  
  .network-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .network-card-content {
    padding: 20px;
    text-align: center;
    background-color: black;
  }
  
  .network-card-content h4 {
    color: #ee1623;
    margin: 10px 0;
  }
  
  .network-card-content p {
    color: #dad3d3;
    
  }
  
  .network-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .network-card:hover .network-overlay {
    opacity: 1;
    color: white;
  }
  