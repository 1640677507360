@font-face {
  font-family: 'linearicons-free';
  src:  url('../fonts/linearicons-free8429.eot?4l2k0u');
  src:  url('../fonts/linearicons-free8429.eot?4l2k0u#iefix') format('embedded-opentype'),
    url('../fonts/linearicons-free8429.ttf?4l2k0u') format('truetype'),
    url('../fonts/linearicons-free8429.woff?4l2k0u') format('woff'),
    url('../fonts/linearicons-free8429.svg?4l2k0u#linearicons-free') format('svg');
	font-weight: normal;
	font-style: normal;
}

.lnr, [class^="lnr-"], [class*=" lnr-"] {
	font-family: 'linearicons-free';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lnr-icon-home:before {
  content: "\e800";
}
.lnr-icon-apartment:before {
  content: "\e801";
}
.lnr-icon-pencil:before {
  content: "\e802";
}
.lnr-icon-magic-wand:before {
  content: "\e803";
}
.lnr-icon-drop:before {
  content: "\e804";
}
.lnr-icon-lighter:before {
  content: "\e805";
}
.lnr-icon-poop:before {
  content: "\e806";
}
.lnr-icon-sun:before {
  content: "\e807";
}
.lnr-icon-moon:before {
  content: "\e808";
}
.lnr-icon-cloud:before {
  content: "\e809";
}
.lnr-icon-cloud-upload:before {
  content: "\e80a";
}
.lnr-icon-cloud-download:before {
  content: "\e80b";
}
.lnr-icon-cloud-sync:before {
  content: "\e80c";
}
.lnr-icon-cloud-check:before {
  content: "\e80d";
}
.lnr-icon-database:before {
  content: "\e80e";
}
.lnr-icon-lock:before {
  content: "\e80f";
}
.lnr-icon-cog:before {
  content: "\e810";
}
.lnr-icon-trash:before {
  content: "\e811";
}
.lnr-icon-dice:before {
  content: "\e812";
}
.lnr-icon-heart:before {
  content: "\e813";
}
.lnr-icon-star:before {
  content: "\e814";
}
.lnr-icon-star-half:before {
  content: "\e815";
}
.lnr-icon-star-empty:before {
  content: "\e816";
}
.lnr-icon-flag:before {
  content: "\e817";
}
.lnr-icon-envelope:before {
  content: "\e818";
}
.lnr-icon-paperclip:before {
  content: "\e819";
}
.lnr-icon-inbox:before {
  content: "\e81a";
}
.lnr-icon-eye:before {
  content: "\e81b";
}
.lnr-icon-printer:before {
  content: "\e81c";
}
.lnr-icon-file-empty:before {
  content: "\e81d";
}
.lnr-icon-file-add:before {
  content: "\e81e";
}
.lnr-icon-enter:before {
  content: "\e81f";
}
.lnr-icon-exit:before {
  content: "\e820";
}
.lnr-icon-graduation-hat:before {
  content: "\e821";
}
.lnr-icon-license:before {
  content: "\e822";
}
.lnr-icon-music-note:before {
  content: "\e823";
}
.lnr-icon-film-play:before {
  content: "\e824";
}
.lnr-icon-camera-video:before {
  content: "\e825";
}
.lnr-icon-camera:before {
  content: "\e826";
}
.lnr-icon-picture:before {
  content: "\e827";
}
.lnr-icon-book:before {
  content: "\e828";
}
.lnr-icon-bookmark:before {
  content: "\e829";
}
.lnr-icon-user:before {
  content: "\e82a";
}
.lnr-icon-users:before {
  content: "\e82b";
}
.lnr-icon-shirt:before {
  content: "\e82c";
}
.lnr-icon-store:before {
  content: "\e82d";
}
.lnr-icon-cart:before {
  content: "\e82e";
}
.lnr-icon-tag:before {
  content: "\e82f";
}
.lnr-icon-phone-handset:before {
  content: "\e830";
}
.lnr-icon-phone:before {
  content: "\e831";
}
.lnr-icon-pushpin:before {
  content: "\e832";
}
.lnr-icon-map-marker:before {
  content: "\e833";
}
.lnr-icon-map:before {
  content: "\e834";
}
.lnr-icon-location:before {
  content: "\e835";
}
.lnr-icon-calendar-full:before {
  content: "\e836";
}
.lnr-icon-keyboard:before {
  content: "\e837";
}
.lnr-icon-spell-check:before {
  content: "\e838";
}
.lnr-icon-screen:before {
  content: "\e839";
}
.lnr-icon-smartphone:before {
  content: "\e83a";
}
.lnr-icon-tablet:before {
  content: "\e83b";
}
.lnr-icon-laptop:before {
  content: "\e83c";
}
.lnr-icon-laptop-phone:before {
  content: "\e83d";
}
.lnr-icon-power-switch:before {
  content: "\e83e";
}
.lnr-icon-bubble:before {
  content: "\e83f";
}
.lnr-icon-heart-pulse:before {
  content: "\e840";
}
.lnr-icon-construction:before {
  content: "\e841";
}
.lnr-icon-pie-chart:before {
  content: "\e842";
}
.lnr-icon-chart-bars:before {
  content: "\e843";
}
.lnr-icon-gift:before {
  content: "\e844";
}
.lnr-icon-diamond:before {
  content: "\e845";
}
.lnr-icon-linearicons:before {
  content: "\e846";
}
.lnr-icon-dinner:before {
  content: "\e847";
}
.lnr-icon-coffee-cup:before {
  content: "\e848";
}
.lnr-icon-leaf:before {
  content: "\e849";
}
.lnr-icon-paw:before {
  content: "\e84a";
}
.lnr-icon-rocket:before {
  content: "\e84b";
}
.lnr-icon-briefcase:before {
  content: "\e84c";
}
.lnr-icon-bus:before {
  content: "\e84d";
}
.lnr-icon-car:before {
  content: "\e84e";
}
.lnr-icon-train:before {
  content: "\e84f";
}
.lnr-icon-bicycle:before {
  content: "\e850";
}
.lnr-icon-wheelchair:before {
  content: "\e851";
}
.lnr-icon-select:before {
  content: "\e852";
}
.lnr-icon-earth:before {
  content: "\e853";
}
.lnr-icon-smile:before {
  content: "\e854";
}
.lnr-icon-sad:before {
  content: "\e855";
}
.lnr-icon-neutral:before {
  content: "\e856";
}
.lnr-icon-mustache:before {
  content: "\e857";
}
.lnr-icon-alarm:before {
  content: "\e858";
}
.lnr-icon-bullhorn:before {
  content: "\e859";
}
.lnr-icon-volume-high:before {
  content: "\e85a";
}
.lnr-icon-volume-medium:before {
  content: "\e85b";
}
.lnr-icon-volume-low:before {
  content: "\e85c";
}
.lnr-icon-volume:before {
  content: "\e85d";
}
.lnr-icon-mic:before {
  content: "\e85e";
}
.lnr-icon-hourglass:before {
  content: "\e85f";
}
.lnr-icon-undo:before {
  content: "\e860";
}
.lnr-icon-redo:before {
  content: "\e861";
}
.lnr-icon-sync:before {
  content: "\e862";
}
.lnr-icon-history:before {
  content: "\e863";
}
.lnr-icon-clock:before {
  content: "\e864";
}
.lnr-icon-download:before {
  content: "\e865";
}
.lnr-icon-upload:before {
  content: "\e866";
}
.lnr-icon-enter-down:before {
  content: "\e867";
}
.lnr-icon-exit-up:before {
  content: "\e868";
}
.lnr-icon-bug:before {
  content: "\e869";
}
.lnr-icon-code:before {
  content: "\e86a";
}
.lnr-icon-link:before {
  content: "\e86b";
}
.lnr-icon-unlink:before {
  content: "\e86c";
}
.lnr-icon-thumbs-up:before {
  content: "\e86d";
}
.lnr-icon-thumbs-down:before {
  content: "\e86e";
}
.lnr-icon-magnifier:before {
  content: "\e86f";
}
.lnr-icon-cross:before {
  content: "\e870";
}
.lnr-icon-menu:before {
  content: "\e871";
}
.lnr-icon-list:before {
  content: "\e872";
}
.lnr-icon-chevron-up:before {
  content: "\e873";
}
.lnr-icon-chevron-down:before {
  content: "\e874";
}
.lnr-icon-chevron-left:before {
  content: "\e875";
}
.lnr-icon-chevron-right:before {
  content: "\e876";
}
.lnr-icon-arrow-up:before {
  content: "\e877";
}
.lnr-icon-arrow-down:before {
  content: "\e878";
}
.lnr-icon-arrow-left:before {
  content: "\e879";
}
.lnr-icon-arrow-right:before {
  content: "\e87a";
}
.lnr-icon-move:before {
  content: "\e87b";
}
.lnr-icon-warning:before {
  content: "\e87c";
}
.lnr-icon-question-circle:before {
  content: "\e87d";
}
.lnr-icon-menu-circle:before {
  content: "\e87e";
}
.lnr-icon-checkmark-circle:before {
  content: "\e87f";
}
.lnr-icon-cross-circle:before {
  content: "\e880";
}
.lnr-icon-plus-circle:before {
  content: "\e881";
}
.lnr-icon-circle-minus:before {
  content: "\e882";
}
.lnr-icon-arrow-up-circle:before {
  content: "\e883";
}
.lnr-icon-arrow-down-circle:before {
  content: "\e884";
}
.lnr-icon-arrow-left-circle:before {
  content: "\e885";
}
.lnr-icon-arrow-right-circle:before {
  content: "\e886";
}
.lnr-icon-chevron-up-circle:before {
  content: "\e887";
}
.lnr-icon-chevron-down-circle:before {
  content: "\e888";
}
.lnr-icon-chevron-left-circle:before {
  content: "\e889";
}
.lnr-icon-chevron-right-circle:before {
  content: "\e88a";
}
.lnr-icon-crop:before {
  content: "\e88b";
}
.lnr-icon-frame-expand:before {
  content: "\e88c";
}
.lnr-icon-frame-contract:before {
  content: "\e88d";
}
.lnr-icon-layers:before {
  content: "\e88e";
}
.lnr-icon-funnel:before {
  content: "\e88f";
}
.lnr-icon-text-align-left:before {
  content: "\e898";
}
.lnr-icon-text-align-center:before {
  content: "\e899";
}
.lnr-icon-text-align-right:before {
  content: "\e89a";
}
.lnr-icon-text-align-justify:before {
  content: "\e89b";
}
.lnr-icon-sort-amount-asc:before {
  content: "\e8a4";
}
.lnr-icon-cart1:before {
  content: "\e003";
}
.lnr-icon-close:before {
  content: "\e005";
}
.lnr-icon-copy:before {
  content: "\e007";
}
.lnr-icon-dropdown:before {
  content: "\e00a";
}
.lnr-icon-favourite:before {
  content: "\e00c";
}
.lnr-icon-history1:before {
  content: "\e00f";
}
.lnr-icon-live-support:before {
  content: "\e012";
}
.lnr-icon-location-1:before {
  content: "\e013";
}
.lnr-icon-phone-plus:before {
  content: "\e01a";
}
.lnr-icon-rocket1:before {
  content: "\e01c";
}
.lnr-icon-search:before {
  content: "\e01d";
}
.lnr-icon-user1:before {
  content: "\e023";
}
.lnr-icon-arrow-left1:before {
  content: "\e029";
}
.lnr-icon-arrow-right1:before {
  content: "\e02a";
}
.lnr-icon-bars:before {
  content: "\e02b";
}
.lnr-icon-calendar:before {
  content: "\e02c";
}
.lnr-icon-comment:before {
  content: "\e034";
}
.lnr-icon-comments:before {
  content: "\e035";
}
.lnr-icon-envelope1:before {
  content: "\e037";
}
.lnr-icon-external-link-alt:before {
  content: "\e039";
}
.lnr-icon-eye1:before {
  content: "\e03a";
}
.lnr-icon-file-alt:before {
  content: "\e03b";
}
.lnr-icon-file-archive:before {
  content: "\e03c";
}
.lnr-icon-folder-open:before {
  content: "\e03d";
}
.lnr-icon-folder:before {
  content: "\e03e";
}
.lnr-icon-gift1:before {
  content: "\e040";
}
.lnr-icon-grid:before {
  content: "\e041";
}
.lnr-icon-heart-fill:before {
  content: "\e042";
}
.lnr-icon-heart1:before {
  content: "\e043";
}
.lnr-icon-home1:before {
  content: "\e044";
}
.lnr-icon-list1:before {
  content: "\e047";
}
.lnr-icon-map-marker-check:before {
  content: "\e04c";
}
.lnr-icon-meh:before {
  content: "\e04d";
}
.lnr-icon-minus:before {
  content: "\e04e";
}
.lnr-icon-plus:before {
  content: "\e051";
}
.lnr-icon-quote:before {
  content: "\e052";
}
.lnr-icon-random:before {
  content: "\e053";
}
.lnr-icon-shopping-cart:before {
  content: "\e057";
}
.lnr-icon-sign-out-alt:before {
  content: "\e058";
}
.lnr-icon-smile1:before {
  content: "\e059";
}
.lnr-icon-spinner:before {
  content: "\e05a";
}
.lnr-icon-square:before {
  content: "\e05b";
}
.lnr-icon-star1:before {
  content: "\e05c";
}
.lnr-icon-store1:before {
  content: "\e05d";
}
.lnr-icon-sync1:before {
  content: "\e05e";
}
.lnr-icon-tachometer-alt:before {
  content: "\e05f";
}
.lnr-icon-thumbtack:before {
  content: "\e062";
}
.lnr-icon-times-circle:before {
  content: "\e063";
}
.lnr-icon-times:before {
  content: "\e064";
}
.lnr-icon-truck:before {
  content: "\e065";
}
.lnr-icon-video:before {
  content: "\e066";
}
