.page-title {
    background-size: cover;
    background-position: center;
}

.blog-section {
    background-color: #f8f9fa;
}

.blog-post {
    background: #ee1623;
    color: white;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.blog-post:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.blog-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.btn {
    background-color: #007bff; /* Bootstrap primary color */
    border: none;
}

.btn:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.text-muted {
    font-size: 0.9rem;
}
