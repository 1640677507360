/* Career.css */

.page-header {
    position: relative;
    background: #f8f9fa;
    padding: 60px 0;
    text-align: center;
}

.page-header-shape {
    /* background: url('path/to/your/background/image.jpg') no-repeat center center/cover; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0.1;
}

.page-header-info h4 {
    font-size: 24px;
    color: #343a40;
}

.page-header-info h2 {
    font-size: 36px;
    color: #007bff;
    margin: 10px 0 0;
}

.section-title {
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 20px;
}

.section-subtitle {
    font-size: 16px;
    /* color: #6c757d; */
    margin-bottom: 40px;
}
.career-item  {
    color: white;
}
.career-item  {
    background-color: #ee1623; 
    color: #ffffff; 
    border-radius: 10px;
    /* transition: transform 0.3s, background-color 0.3s, color 0.3s; */
    padding: 20px; /* Added padding for better layout */
}

.career-item:hover {
    transform: translateY(-5px);
    background-color: #ee1623; 
    background-color: black;

}

.career-icon {
    color: #007bff;
}

.career-title {
    font-size: 20px;
    margin: 15px 0;
}

.career-title a {
    text-decoration: none;
    color: inherit; /* Inherit color from the parent */
}

.career-title a:hover {
    text-decoration: underline;
    color: #ffffff; /* Change link color on hover */
}

.career-item p {
    line-height: 1.6;
}

.career-item:hover p {
    color: #ffffff; 
}

@media (max-width: 768px) {
    .career-item {
        margin-bottom: 20px;
    }

    .career-title {
        font-size: 18px;
    }
}
